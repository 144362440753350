
import { useDndMonitor } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import SortableListItem from './SortableListItem';

const SortableList = (props) => {
    const {
        items,
        getItemId,
        onChange,
        renderItem,
        id,
    } = props;

    // DnD-kit requires an attribute named `id`. However, because the Plan-Apps ID might have a different
    // name (eg `_id` or `value`) we wrap each item in an object that has `id`
    const wrappedItems = items.map(item => ({
        id: getItemId(item),
        item,
    }));

    const getIndex = (id) => (
        wrappedItems.findIndex((item) => item.id === id)
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        // If multiple SortableLists are present, only update the list that is being dragged over
        if(id && active.data.current.sortable.containerId !== id) return;
        if (active.id !== over.id) {
            const oldIndex = getIndex(active.id);
            const newIndex = getIndex(over.id);
            const newItems = arrayMove(items, oldIndex, newIndex);
            onChange(newItems, oldIndex, newIndex);
        }
    };

    useDndMonitor({
        onDragEnd: handleDragEnd
    });

    return (
        <SortableContext id={id} items={wrappedItems} strategy={verticalListSortingStrategy} >
            {wrappedItems.map((wrappedItem, index) => (
                <SortableListItem
                    key={wrappedItem.id}
                    id={wrappedItem.id}
                    index={index}
                    item={wrappedItem.item}
                    renderItem={renderItem}
                />
            ))}
        </SortableContext>
    );
};

export default SortableList;
