import { Component } from 'react';

import { Http, Modal, Button } from '../../Components';
import css from './TermsAndConditions.module.scss';

import termsAndConditionsHtml from './termsAndConditionsContent.js';
import { config } from 'smg-common';
class TermsAndConditionsModal extends Component {
    state = {
        accepted: false,
        showDeclineMessage: false,
    }

    handleDecline = () => {
        this.setState({ showDeclineMessage: !this.state.showDeclineMessage });
    }

    handleAgree= () => {
        Http.put('/users/accept-terms-and-conditions')
            .then(() => {
                this.setState({ accepted: true });
            })
            .catch(error => {
                console.error(error);
            })
    }

    render() {
        const showTermsAndConditions = !this.state.accepted && this.props.user && !this.props.user.acceptedTerms;
        if (!showTermsAndConditions) {
            return null;
        }

        //TODO: make T&C's content editable in new Admin
        const termsAndConditionsContent = <div className={css.termsContainer}>
            {termsAndConditionsHtml}
        </div>;

        return (
            <>
                <Modal show={showTermsAndConditions} width="max">
                    <Modal.Header><strong>Terms &amp; Conditions</strong></Modal.Header>
                    <Modal.Body>
                        {termsAndConditionsContent}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleDecline()}>Decline</Button>
                        <Button onClick={() => this.handleAgree()} color="primary">Agree</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeclineMessage} onCancel={() => this.handleDecline()} title="Sorry" width="md">
                    <Modal.Body>
                        <p>You need to agree to the Terms and Conditions in order to use Plan-Apps. For any queries regarding the Terms and Conditions, please <a href={`mailto:${config.supportEmailAddress}`}>contact support</a>.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleDecline()}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default TermsAndConditionsModal;