import { AppsGrid, Button, Dropdown, Icon } from '../';

import css from './AppSwitcher.module.scss';

const AppSwitcher = () => (
    <Dropdown
        trigger={<Button>Apps <Icon name="arrow_drop_down" /></Button>}
        align="start"
    >
        <div className={css['apps-list']}>
            <Button className={css.home} color="primary" linkTo="/" size="large" textAlign="start">
                <Icon name="home" />
                <h3>Home</h3>
            </Button>

            <AppsGrid />
        </div>
    </Dropdown>
);

export default AppSwitcher;
