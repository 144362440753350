import * as PopoverPrimitive from '@radix-ui/react-popover';
import { PropsWithChildren, forwardRef } from 'react';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;

export const PopoverContent = forwardRef<HTMLDivElement, PropsWithChildren<PopoverPrimitive.PopoverContentProps>>(
    ({ children, ...props }, forwardedRef) => (
        <PopoverPrimitive.Content {...props} ref={forwardedRef}>
            {children}
        </PopoverPrimitive.Content>
    )
);
PopoverContent.displayName = 'PopoverContent';