import _ from 'lodash';

function countOptions(options) {
    // Count total number of options including nested options
    return _.reduce(options, (count, option) => {
        const subOptions = option.options;

        return subOptions
            ? count + 1 + subOptions.length
            : count + 1;
    }, 0);
}

export default countOptions;
