import css from './ProgressBar.module.scss';

const ProgressBar = ({ color, className, max = 100, min = 0, showLabel, speed = '0.25s', width = 10, value = 0 }) => {
    const getAdjustedValue = (value) => {
        if (+value < +min) return +min;
        else if (+value > +max) return +max;
        return +value;
    };

    const getNormalisedPercentage = (value) => {
        return 100 / (max - min) * (getAdjustedValue(value) - min);
    };

    const classes = [css.progress];
    if (className) classes.push(className);

    const style = {
        borderRadius: `${width / 2}px`,
        height: width,
    };

    const percentageValue = getNormalisedPercentage(value);

    return <div className={classes.join(' ').trim()} style={style}>
        <div className={css.bar}
            style={{
                backgroundColor: color,
                borderRadius: `${width / 2}px`,
                transition: `width ${speed} linear`,
                width: `${percentageValue}%`
            }}
        />
        {showLabel && <div
            className={css.label}
            style={{
                backgroundColor: color,
                // TODO: Normalise positioning as the label moves from left to right
                // so that it starts from left: 0, all the way up to calc(100% - <label width>)
                // to prevent the label overflowing the track
                left: `${percentageValue}%`,
            }}
        >
            {percentageValue}%
        </div>}
    </div>
};

export default ProgressBar;
