import { formatters } from 'smg-common';
import { Carousel, RenderFile } from 'Components';
import { RetailerSnapshot } from '../../SelfServe/types';
import { File } from '../../Types';

interface CampaignImageCarouselProps {
    activityImages: {
        image: File & { url: string };
        retailer: RetailerSnapshot;
        touchpointName: string;
        planName: string;
        startDate: string | Date;
    }[],
    index: string;
}

export const CampaignImageCarousel = (props: CampaignImageCarouselProps) => {
    const { activityImages, index } = props;
    return (
        <Carousel
            adaptiveHeight
            images={activityImages.map((activity) => (
                <div className="p-3 d-flex justify-content-end flex-column" style={{ minHeight: '400px' }} key={activity.image._id}>
                    <RenderFile file={activity.image} />
                    <div className="stack-col-center-center-4 p-3">
                        <p className="m-0 text-bold">{activity.touchpointName}</p>
                        <p className="m-0">{activity.planName}</p>
                        <p className="m-0">{formatters.asDate(activity.startDate)}</p>
                    </div>
                </div>
            ))}
            initialSlide={index}
        />
    );
};

export default CampaignImageCarousel;
