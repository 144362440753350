import { Navigate } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';

import { ForbiddenPage } from '../../App/ErrorPages';
import { useAuth } from '../';
import { isFunction } from 'formik';
import { config } from 'smg-common';

function defaultOnReject(permissions = [], policies = []) {
    const lacksPermissions = permissions.length > 0;
    const lacksPolicies = policies.length > 0;
    const lacksEntities = [];
    if (lacksPermissions) lacksEntities.push('permissions');
    if (lacksPolicies) lacksEntities.push('policies');

    const reasonTemplate = (<div>
        <p>You are trying to access a protected page without the required {lacksEntities.join(' and ')}.</p>
        <p>
            <div>If you believe you see this error message by mistake, <a href={`mailto:${config.supportEmailAddress}?subject=Cannot access Plan-Apps page"`} rel="noopener noreferrer" target="_blank">please let us know</a>.</div>
        </p>
        {lacksPermissions && (<>
            <strong>Missing permissions: </strong>
            <span>{permissions.join(', ')}</span>
        </>)}
        {lacksPolicies && (<>
            <strong>Missing policies: </strong>
            <span>{policies.join(', ')}</span>
        </>)}

    </div>);

    return (<ForbiddenPage reason={reasonTemplate} />);
}

const DEFAULT_OPTIONS = {
    onReject: defaultOnReject,
    permissions: [],
    policies: [],
};

const requiresAuth6 = (WrappedComponent, options = DEFAULT_OPTIONS) => {
    const AuthCheck = (props) => {
        const { me, setLoginRedirection } = useAuth();

        if (!me) {
            // prevent loginRedirect being overwritten with '/auth/login'. see PFX-5421.
            if (window.location.pathname !== '/auth/login')
                setLoginRedirection(window.location.pathname);
            return (<Navigate replace to="/auth/login" />);
        }

        if (!me.activeSubscription) {
            return (<ForbiddenPage message="You do not have an active subscription" />);
        }

        const rejected = {
            permissions: [],
            policies: [],
        };
        if (isArray(options.permissions)) {
            options.permissions.forEach((permission) => {
                if (!me.permissions[permission]) {
                    rejected.permissions.push(permission);
                }
            });
        }

        if (isArray(options.policies)) {
            options.policies.forEach((policy) => {
                if (!me.policies[policy]) {
                    rejected.policies.push(policy);
                }
            });
        }

        const hasRejections = !isEmpty(rejected.permissions) || !isEmpty(rejected.policies);

        if (hasRejections && isFunction(options.onReject)) return options.onReject(rejected.permissions, rejected.policies);
        if (hasRejections) return defaultOnReject(rejected.permissions, rejected.policies);
        return (<WrappedComponent {...props} />);
    };

    return AuthCheck;
};

export default requiresAuth6;
