import { createContext } from 'react';

// The context that is returned when the developer forgets to wrap the list in a `<ItemSelectorContext>` tag
const empty = {
    selected: [],
    setSelected: (value) => console.error('Missing `<CheckboxList />` wrapper when setting value', value),
};

const CheckboxListContext = createContext(empty);

export default CheckboxListContext;
