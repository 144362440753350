import { simpleDate } from 'smg-common';

// For the syntax in import of SVG files, see: https://create-react-app.dev/docs/adding-images-fonts-and-files#adding-svgs
import { ReactComponent as LogoLinearLight } from './SMG-logo-linear-light.svg';

const SmgLogo = (props) => {
    const { height = 'small' } = props;

    // Define standard sizes
    let imgHeight;
    if (height==='small') {
        imgHeight = '35px';
    } else if (height==='large') {
        imgHeight = '75px';
    } else {
        imgHeight = height;
    }

    const start = simpleDate.create(3, 5, 2023);
    const end = simpleDate.create(8, 5, 2023);
    const today = simpleDate.today();

    const isCoronationEventLive = simpleDate.isSameOrAfter(today, start) && simpleDate.isSameOrBefore(today, end);

    if (isCoronationEventLive) {
        if (height==='small') {
            imgHeight = '45px';
        } else if (height==='large') {
            imgHeight = '105px';
        }

        return (
            <img
                src="https://plan-apps-assets.s3.eu-west-2.amazonaws.com/coronation/coronation-logo-2.png"
                alt="Coronation logo"
                height={imgHeight}
            />
        );
    }


    return <LogoLinearLight height={imgHeight} />
}

SmgLogo.propTypes = {
    height: function(props, propName, componentName) {
        const value = props[propName];
        const isValid =
            value === undefined ||
            value === '' ||
            value ==='small' ||
            value ==='large' ||
            /[0-9]+(px|em|rem)/.test(value);
        if (!isValid) {
            return new Error(`Invalid prop "${propName}" supplied to "${componentName}"`);
        }
    },
};

export default SmgLogo;
