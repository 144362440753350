import PropTypes from 'prop-types';
import { size } from 'lodash';

import css from './PasswordStrength.module.scss';

function getLengthScore(length) {
    if (length < 8) {
        return 0;
    } else if (length < 16) {
        return 10;
    } else if (length < 32) {
        return 20;
    } else {
        return 30;
    }
}

function getComplexity(input) {
    const length = input.length;
    const hasNumbers = input.match(/[\d+]/g);
    const hasUpperCaseLetters = input.match(/[A-Z]/g);
    const hasLowerCaseLetters = input.match(/[a-z]/g);
    const hasSpecialCharacters = length !== size(hasNumbers) + size(hasUpperCaseLetters) + size(hasLowerCaseLetters);

    let score = getLengthScore(length);
    if (score < 10) {
        return 0;
    }
    if (hasNumbers) {
        score += 20;
    }
    if (hasUpperCaseLetters) {
        score += 20;
    }
    if (hasLowerCaseLetters) {
        score += 10;
    }
    if (hasSpecialCharacters) {
        score += 20
    }
    return score;
}

function getComplexityLabel(score) {
    let label = 'Weak';
    if (score >= 90) {
        label = 'Very Strong';
    } else if (score >= 70) {
        label = 'Strong';
    } else if (score >= 50) {
        label = 'Fair';
    } else {
        label = 'Weak';
    }
    return label;
}

function getComplexityColor(score) {
    let color = 'red';
    if (score >= 70) {
        color = 'green';
    } else if (score >= 50) {
        color = 'blue';
    }
    return color;
}

const PasswordStrength = ({ children, className='', input, ...otherProps }) => {
    const score = getComplexity(input);
    const complexity = {
        color: getComplexityColor(score),
        label: getComplexityLabel(score),
        score: score,
    };

    const classes = ['text-right', className, css['password-strength'], css[complexity.color]].filter(classname => !!classname);
    return (
        <div className={classes.join(' ')} {...otherProps}>
            <small>{complexity.label}</small>
        </div>
    );
};

PasswordStrength.propTypes = {
    color: PropTypes.oneOf(['default', 'primary', 'danger']),
    className: PropTypes.string,
};

export default PasswordStrength;
