// TODO: The artwork and booking deadlines should be moved to the CamapaignsCalendar
// and the timelines should allow for default and hover state control in their API

import axios from 'axios';
import { createRef, useState } from 'react';
import moment from 'moment';
import { find, isFunction, range } from 'lodash';
import { Link } from 'react-router-dom';

import EditEventModal from './EditEventModal';
import { Icon, file, Pill, dialog, toast, useUrl6 } from '../../';

import css from './Timeline.module.scss';
import Tooltip from '../../Tooltip/Tooltip';

const BORDER_RADIUS = '4px';
const PADDING = '2px';

const MonthlyTimeline = (props) => {
    const { endDate, events, onEventChange, onEventDelete, startDate, exportCalendar, setExportCalendar, hideMediaCosts } = props;
    const calendarRef = createRef();
    const [editableEventId, setEditableEventId] = useState(undefined);
    const from = moment(startDate).clone().startOf('day');

    const { queryParams } = useUrl6();
    const { view } = queryParams;

    // The calendar render weeks starting with the provided start date, which is not Sun or Mon necessarily.
    // .endOf('week') moves the date to the next Saturday, but we need to make sure that the calendar week
    // goes up to the arbitrary day of the 'from' date, that is either on or after moment's endOf('week') day.
    const endOfWeek = moment(endDate).clone().endOf('week').endOf('day');
    const to = endOfWeek.clone().day(7 + from.day() - 1);
    const durationInWeeks = Math.ceil(to.diff(from, 'weeks')) + 1;
    const durationInDays = Math.ceil(to.diff(from, 'days')) + 1;

    const visibleEvents = new Set();
    const visibleEventIds = new Set();

    // Will be used to render the events
    const weeks = range(durationInWeeks).map((week, index) => {
        const startDate = from.clone().add(index, 'weeks').startOf('day');
        const endDate = startDate.clone().add(index + 1, 'weeks').endOf('day');
        const weekRef = createRef();
        return {
            index,
            ref: weekRef,
            startDate,
            endDate,
            monthName: startDate.format('MMMM'),
            weekOfMonth: Math.ceil(startDate.date() / 7),
            days: range(7).map((day, dayIndex) => {
                const dayDate = startDate.clone().add(dayIndex, 'days').startOf('day');
                return {
                    classes: [
                        'stack-end-0',
                        'stack-stretch',
                        css['day-number'],
                        dayIndex === 6 ? css.visible : '',
                        dayDate.format('D') === '1' ? css['first-of-month'] : null
                    ],
                    unix: dayDate.toDate().getTime(),
                    dateMoment: dayDate,
                    dayName: dayDate.format('dddd'),
                    monthName: dayDate.format('MMMM'),
                    dayNumber: dayDate.format('DD'),
                    events: events.filter((event) => {
                        const id = [event.id];

                        if (event.deadlines?.booking?.moment.isSame(dayDate)) {
                            id.push('booking-deadline');
                        }
                        if (event.deadlines?.artwork?.moment.isSame(dayDate)) {
                            id.push('artwork-deadline');
                        }
                        if (event.deadlines?.briefing?.moment.isSame(dayDate)) {
                            id.push('briefing-deadline');
                        }
                        const eventId = id.join('-');
                        const isAlreadyRendered = visibleEvents.has(eventId);

                        if (isAlreadyRendered || !event.startDate || !event.endDate) {
                            return null;
                        }

                        const eventStartDate = moment(event.startDate).startOf('day');
                        const eventEndDate = moment(event.endDate).endOf('day');

                        const isEventLive = eventStartDate.isSame(dayDate)
                            || (eventStartDate.isBefore(dayDate) && eventEndDate.isAfter(dayDate) && !isAlreadyRendered);
                        const isDeadlineLive = event.deadlines?.artwork?.moment.isSame(dayDate)
                            || event.deadlines?.booking?.moment.isSame(dayDate)
                            || event.deadlines?.briefing?.moment.isSame(dayDate);

                        // An event duration on the calendar may be the remaining duration (if start date < now), or the actual (end - start) date of the activity
                        if (eventStartDate.isSameOrAfter(from) && eventEndDate.isSameOrBefore(to)) {
                            event.duration = Math.ceil(eventEndDate.diff(eventStartDate, 'days')) + 1;
                        } else if (eventStartDate.isBefore(from) && eventEndDate.isSameOrAfter(from) && eventEndDate.isSameOrBefore(to)) {
                            // Event started before start of calendar, but ended within the calendar range
                            const eventDuration = Math.ceil(eventEndDate.diff(from, 'days')) + 1;
                            // 'duration' is the total calendar duration
                            event.duration = eventDuration > durationInDays ? durationInDays : eventDuration;
                        } else if (eventStartDate.isSameOrAfter(from) && eventEndDate.isAfter(to)) {
                            // Event started  within the calendar range, but ended after end of calendar
                            const eventDuration = Math.ceil(to.diff(eventStartDate, 'days')) + 1;
                            // 'duration' is the total calendar duration
                            event.duration = eventDuration > durationInDays ? durationInDays : eventDuration;
                        } else if (eventStartDate.isBefore(from) && eventEndDate.isAfter(to)) {
                            // Event started before start of calendar, but ended after end of calendar
                            event.duration = durationInDays;
                        } else {
                            event.duration = 1;
                        }
                        const hasDuration = event.duration > 0;

                        const isVisibleEvent = (isDeadlineLive || (isEventLive && hasDuration)) && !isAlreadyRendered;
                        if (isVisibleEvent) {
                            visibleEventIds.add(event.id);
                            visibleEvents.add(eventId);
                        }

                        return isVisibleEvent;
                    })
                        .map((event) => ({
                            ...event,
                            endDate: event.endDate ? new Date(event.endDate) : undefined,
                            startDate: event.startDate ? new Date(event.startDate) : undefined,
                        })),
                    index: dayIndex,
                };
            }),
        };
    });

    // Calendar export to excel
    if (exportCalendar) {
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true,
            responseType: 'blob',
            headers: {
                'x-client-app-id': process.env.REACT_APP_CLIENT_APP_ID,
            },
        });
        api.post(`/planner/campaigns/export-calendar?hideMediaCosts=${hideMediaCosts}&view=${view}`, weeks)
            .then((res) => {
                const now = moment().format('YYYYMMD-HHmm');
                const filename = `${now} Calendar Export.xlsx`;

                file.downloadBlob(filename, res.data);
            })
            .catch((err) => {
                console.error(err);
                toast.error('Failed to download calendar. Please try again.')
            })
            .finally(() => setExportCalendar(false));
    }

    const showEditModal = (event) => {
        setEditableEventId(event.id);
    };

    const handleEventUpdated = (newEvent) => {
        const event = find(events, { id: newEvent.id });
        // When we change the start date, we need to offset the deadlines as well
        const startDate = moment(newEvent.startDate);
        const endDate = moment(newEvent.endDate);
        // Add 1 to diff to include start/end day
        event.duration = endDate.diff(startDate, 'days') + 1;
        event.startDate = startDate.toDate();
        event.endDate = endDate.toDate();

        if (event.deadlines?.artwork) {
            const touchpointArtworkDeadline = event.deadlines?.artwork?.touchpointArtworkDeadline;
            if (touchpointArtworkDeadline) {
                const date = moment(startDate).subtract(touchpointArtworkDeadline, 'days').toDate();
                event.deadlines.artwork.date = date;
                event.deadlines.artwork.moment = moment(date);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.artwork.user = null;
                event.deadlines.artwork.updatedAt = null;
            }
        }

        if (event.deadlines?.booking) {
            const touchpointBookingDeadline = event.deadlines?.booking?.touchpointBookingDeadline;
            if (touchpointBookingDeadline) {
                const date = moment(startDate).subtract(touchpointBookingDeadline, 'days').toDate();
                event.deadlines.booking.date = date;
                event.deadlines.booking.moment = moment(date);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.booking.user = null;
                event.deadlines.booking.updatedAt = null;
            }
        }

        if (event.deadlines?.briefing) {
            const touchpointBriefingDeadline = event.deadlines?.briefing?.touchpointBriefingDeadline;
            if (touchpointBriefingDeadline) {
                const date = moment(startDate).subtract(touchpointBriefingDeadline, 'days').toDate();
                event.deadlines.briefing.date = date;
                event.deadlines.briefing.moment = moment(date);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.briefing.user = null;
                event.deadlines.briefing.updatedAt = null;
            }
        }

        if (isFunction(onEventChange)) {
            onEventChange(newEvent);
        }
        setEditableEventId(undefined);
    };

    const handleDeleteActivity = async (event) => {
        const message = event.type === 'activities'
            ? `Are you sure you want to delete ${event.data?.touchpoint?.name} for the campaign: ${event.data?.campaign?.name}?`
            : `Are you sure you want to delete ${event.subtitle} for the campaign: ${event.campaignName}?`;
        dialog.confirm(message, 'Delete')
            .then(() => {
                if (isFunction(onEventDelete)) {
                    onEventDelete(event);
                }
            });
    }

    const weekStyle = {
        height: `calc((55px * ${visibleEventIds.size}) + 2rem)`,
        minHeight: '100%',
        width: '100%',
    };

    const handleMouseEnter = (event, eventRef, titleRef, buttonsRef, subtitleRef) => {
        const eventRect = eventRef.current?.getBoundingClientRect();
        const titleRect = titleRef.current?.getBoundingClientRect();
        const buttonsRect = buttonsRef.current?.getBoundingClientRect();
        const subtitleRect = subtitleRef.current?.getBoundingClientRect();

        if (!eventRect || !titleRect || !buttonsRect || !subtitleRect) {
            return;
        }

        // Fix the z-index issue with the tooltips
        eventRef.current.dataset.zIndex = eventRef.current.dataset.zIndex
            ||window.getComputedStyle(eventRef.current).zIndex;
        eventRef.current.style.zIndex = 25;

        const { marginRight } = window.getComputedStyle(titleRef.current);

        const longerRow = (titleRect.width + parseInt(marginRight) + buttonsRect.width) > subtitleRect.width
            ? (buttonsRect.width + parseInt(marginRight) + titleRect.width)
            : subtitleRect.width;
        const maxWidth = longerRow + (2 * parseInt(window.getComputedStyle(eventRef.current).padding));

        if (maxWidth > eventRect?.width) {
            // If the event is about to expand outside the edge of the calendar, expand it towards the left on hover
            const calendarRightBoundary = calendarRef.current.getBoundingClientRect().right;
            if (!eventRef.current.dataset.direction) {
                eventRef.current.dataset.direction = eventRect.x + maxWidth > calendarRightBoundary ? 'left' : 'right';
            }

            // Cache the original width so that the event knows what to revert to on mouse out
            if (!eventRef.current.dataset.width) {
                eventRef.current.dataset.width = `${eventRect?.width}px`;
            }
            eventRef.current.style.width = `${maxWidth}px`;

            // Animate the margin-left css property if expanding towards the left
            if (eventRef.current.dataset.direction === 'left') {
                const marginLeft = eventRef.current.dataset.marginLeft || maxWidth - eventRect?.width;
                eventRef.current.style.marginLeft = `-${marginLeft}px`;
                if (!eventRef.current.dataset.marginLeft) {
                    eventRef.current.dataset.marginLeft = marginLeft;
                }
            }
        }
    };

    const handleMouseLeave = (eventRef) => {
        // Fix the z-index issue with the tooltips
        if (eventRef.current?.dataset.zIndex) {
            eventRef.current.style.zIndex = eventRef.current.dataset.zIndex;
        }

        if (eventRef.current?.dataset.width) {
            eventRef.current.title = undefined;
            eventRef.current.style.pointerEvents = 'all';
            eventRef.current.style.marginLeft = 0;
            eventRef.current.style.width = eventRef.current.dataset.width;
        }
    };

    return (
        <div className={`stack-col-0 ${css.timeline}`} ref={calendarRef}>
            <div className={`stack-0 stack-stretch ${css.header}`}>
                {weeks.map((week, weekIndex) => {
                    return (
                        <div className={`${css['week-container']} ${weekIndex === 0 ? css['first-week'] : ''} stack-col-2 stack-stretch`} key={week.startDate} ref={week.ref}>
                            <div>
                                <div className={`${css['months-track']} stack-0 stack-stretch-items`}>
                                    {week.days.map((day, index) => {
                                        const firstOfMonth = day.dayNumber === '01';
                                        const firstWeek = weekIndex === 0;
                                        const showMonthName = firstOfMonth || (firstWeek && index === 0);
                                        return <div className={`${css['month-name']} ${firstOfMonth && !firstWeek ? css['first-of-month'] : ''}`.trim()} key={day.dayNumber}>
                                            <span style={{ marginLeft: '.5rem', position: 'absolute' }}>{showMonthName ? day.monthName : '\u00a0'}</span>
                                        </div>;
                                    })}
                                </div>
                                <div className={`${css['day-numbers-track']} stack-end-start-0`}>
                                    {week.days.map(day => <div className={day.classes.join(' ')} key={day.dayNumber}>{day.dayNumber}</div>)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={`stack-0 stack-stretch ${css.content}`}>
                {weeks.map((week, weekIndex) => {
                    return (
                        <div className={`${css['week-container']} stack-col-2 stack-stretch`} key={week.startDate} ref={week.ref}>
                            <div className={`${css.week} stack-0`} style={weekStyle}>
                                {
                                    week.days.map((day, dayIndex) => {
                                        return (
                                            <div className={`${css.day} stack-col-0`} key={day.dayNumber}>
                                                {
                                                    day.events.map((event) => {
                                                        const eventRef = createRef();
                                                        const titleRef = createRef();
                                                        const buttonsRef = createRef();
                                                        const subtitleRef = createRef();

                                                        const index = [...visibleEventIds].indexOf(event.id);

                                                        const startDate = moment(event.startDate);
                                                        const isCustomDeadline = event.isCustom;
                                                        // Custom deadlines are passed in as events, but need to be rendered as deadlines
                                                        const isEvent = moment(event.startDate).isSame(day.dateMoment, 'day') && !isCustomDeadline;
                                                        const isArtworkDeadline = event.deadlines?.artwork?.date.getTime() === day.unix;
                                                        const isBookingDeadline = event.deadlines?.booking?.date.getTime() === day.unix;
                                                        const isBriefingDeadline = event.deadlines?.briefing?.date.getTime() === day.unix;

                                                        // If event started before start of calendar, remove padding and border-radius from start
                                                        const isFirstDay = weekIndex === 0 && dayIndex === 0;
                                                        const isLastDay = (weekIndex === weeks.length - 1) && dayIndex === (week.days.length - 1);

                                                        const eventFromPrev = isFirstDay && event.startDate < from.toDate();
                                                        const eventToNext = isLastDay && event.endDate > to.toDate();

                                                        const eventContainerStyle = {
                                                            paddingLeft: eventFromPrev ? 0 : PADDING,
                                                            paddingRight: eventToNext ? 0 : PADDING,
                                                        };

                                                        const calendarEventStyle = {
                                                            height: '52px',
                                                            // Add 1px per week to cover the borders between them, then add the padding applied on the container
                                                            top: `calc((${index} * 55px) + 1rem)`,
                                                        };

                                                        const eventStyle = {
                                                            ...calendarEventStyle,
                                                            background: event.backgroundColor,
                                                            borderBottomLeftRadius: eventFromPrev ? 0 : BORDER_RADIUS,
                                                            borderBottomRightRadius: eventToNext ? 0 : BORDER_RADIUS,
                                                            borderTopLeftRadius: eventFromPrev ? 0 : BORDER_RADIUS,
                                                            borderTopRightRadius: eventToNext ? 0 : BORDER_RADIUS,
                                                            color: event.color,
                                                            width: `calc((${event.duration} * 100%) + ${event.duration}px - (2 * ${PADDING}))`,
                                                        };

                                                        const tooltip = (
                                                            <>
                                                                {event.deadlines?.booking && <div>Booking deadline: {event.deadlines.booking.moment.format('Do MMMM YYYY')}</div>}
                                                                {event.deadlines?.artwork && <div>Artwork deadline: {event.deadlines.artwork.moment.format('Do MMMM YYYY')}</div>}
                                                                {event.deadlines?.briefing && <div>Briefing deadline: {event.deadlines.briefing.moment.format('Do MMMM YYYY')}</div>}
                                                                <div>Start date: {startDate.format('Do MMMM YYYY')}</div>
                                                                <div>End date: {moment(event.endDate).format('Do MMMM YYYY')}</div>
                                                            </>
                                                        );

                                                        // Set deadlines titles
                                                        const deadlineTitles = [];
                                                        if (isArtworkDeadline) deadlineTitles.push('ARTWORK');
                                                        if (isBookingDeadline) deadlineTitles.push('BOOKING');
                                                        if (isBriefingDeadline) deadlineTitles.push('BRIEFING');
                                                        const deadlineTitle = isCustomDeadline
                                                            ? `${event.title} - CUSTOM DEADLINE`
                                                            : `${event.data?.touchpoint?.name || 'n/a'} - ${deadlineTitles.join(' & ')} DEADLINE${deadlineTitles.length > 1 ? 'S' : ''}`;
                                                        const deadlineSubtitle = `${event.data?.plan?.name ||  'n/a'}${event.data?.campaign?.name ? ` - ${event.data?.campaign?.name}` : ''}`

                                                        return <div
                                                            key={`event-${event.id}`}
                                                            onMouseEnter={() => handleMouseEnter(event, eventRef, titleRef, buttonsRef, subtitleRef)}
                                                            onMouseLeave={() => handleMouseLeave(eventRef, buttonsRef)}
                                                            style={eventContainerStyle}
                                                        >
                                                            {/* Deadlines */}
                                                            {
                                                                (isArtworkDeadline || isBookingDeadline || isBriefingDeadline || isCustomDeadline) && (
                                                                    <div className={css.deadline} style={calendarEventStyle}>
                                                                        <div className={css['short-title']}>
                                                                            <Icon name="query_builder" size="14px" />
                                                                        </div>
                                                                        <div className={css.title}>{deadlineTitle}</div>
                                                                        <div className={css.subtitle}>{deadlineSubtitle}</div>
                                                                    </div>
                                                                )
                                                            }

                                                            {/* Activity */}
                                                            {
                                                                (isEvent || eventFromPrev || eventToNext) && (
                                                                    <div className={css.event} ref={eventRef} style={eventStyle}>
                                                                        <div className="stack-even-center-3">
                                                                            <div className={css.title} ref={titleRef}>
                                                                                { event.planStatus === 'draft' &&
                                                                                    <Pill
                                                                                        text={event.planStatus}
                                                                                        className={`mr-2 ${css.draft}`}
                                                                                        style={{ backgroundColor: '#fff', color: '#262626' }}
                                                                                    />
                                                                                }
                                                                                {/* Since promotions are hard to read - use pill as title */}
                                                                                { event.type === 'promotions'
                                                                                    ? <Pill text={event.title} className={css.draft}
                                                                                        style={{ backgroundColor: event.data?.retailer?.backgroundColor || '#FFF', color: event.data?.retailer?.textColor || '#262626' }}/>
                                                                                    : <>{ event.title }</>
                                                                                }
                                                                            </div>
                                                                            <div className="stack-even-center-0" ref={buttonsRef} style={{ pointerEvents: 'all' }}>
                                                                                {
                                                                                    event.url && (
                                                                                        <Tooltip
                                                                                            align={event.type === 'promotions' ? 'left' : 'top'}
                                                                                            hover="Go to plan"
                                                                                        >
                                                                                            <Link className="not-like-link" rel="noopener noreferrer" target="_blank" to={event.url}>
                                                                                                <Icon className={css.icon} name="link" />
                                                                                            </Link>
                                                                                        </Tooltip>
                                                                                    )
                                                                                }
                                                                                <Tooltip
                                                                                    align={event.type === 'promotions' ? 'left' : 'top'}
                                                                                    hover={event.type !== 'promotions' && tooltip}
                                                                                >
                                                                                    { event.type !== 'promotions' && <Icon className={css.icon} name="info" /> }
                                                                                </Tooltip>
                                                                                {
                                                                                    event.editable && (
                                                                                        <>
                                                                                            <Tooltip
                                                                                                align={event.type === 'promotions' ? 'left' : 'top'}
                                                                                                hover="Edit activity dates"
                                                                                            >
                                                                                                <Icon className={css.icon} name="edit" onClick={() => showEditModal(event)} />
                                                                                            </Tooltip>
                                                                                            <Tooltip
                                                                                                align={event.type === 'promotions' ? 'left' : 'top'}
                                                                                                hover="Delete activity"
                                                                                            >
                                                                                                <Icon className={css.icon} name="delete" onClick={() => handleDeleteActivity(event)} />
                                                                                            </Tooltip>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="stack-even-center-3">
                                                                            <div className={css.subtitle} ref={subtitleRef}>
                                                                                { hideMediaCosts === 'true' && event.type === 'activities' ? event.noCostSubtitle : event.subtitle }
                                                                            </div>
                                                                            {
                                                                                editableEventId === event.id && (
                                                                                    <EditEventModal
                                                                                        event={event}
                                                                                        onCancel={() => setEditableEventId(undefined)}
                                                                                        onSave={handleEventUpdated}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MonthlyTimeline;
