import { createContext, useState, useContext } from 'react';
import immer from 'immer';
import { get, set } from 'lodash';

/**
 * Implements an application-wide state.
 *  const [language, setLanguage] = useAppState('locale.language');
 *  setLanguage('EN');
 */

// Create a React context
const AppStateContext = createContext();

// A HOC to expose the context to all child components.
// Should normally be used only once, where we initialise the application.
const AppStateProvider = (props) => {
    const { initialValue = {} } =  props;
    const [appState, setAppState] = useState(initialValue);
    return (
        <AppStateContext.Provider value={{ appState, setAppState }}>
            { props.children }
        </AppStateContext.Provider>
    );
}

// The hook that provides an API to read and write the appState
const useAppState = (path, defaultValue) => {
    const { appState, setAppState } = useContext(AppStateContext) || {};
    let value = get(appState, path);

    // Set the value by creating a new object and deep-setting the value
    const setter = (value) => {
        const newState = immer(appState, (draft) => {
            set(draft, path, value);
        });
        setAppState(newState);
    };

    if (value === undefined) {
        value = defaultValue;
    }

    // The last object is meant for debugging. Allows clients to inspect the whole appState
    return [value, setter, { appState }];
}

export { AppStateProvider };
export default useAppState;
