import { ColoredPanel } from 'Components';
import { HtmlHTMLAttributes } from 'react';

interface MetricPanelProps extends React.PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>> {
    label: string;
    /** Accepts JSX.Element to allow custom formatting */
    value: number | JSX.Element;
}

/** Provides the basic styling used for summary metric panels used throughout Plan-Apps */
const MetricPanel = (props: MetricPanelProps) => {
    const { children, label, value, ...restProps } = props;

    return (
        <ColoredPanel color="primary" {...restProps}>
            {/* The p tag has some default styles that do not apply in this case */}
            <p className="d-flex justify-content-between m-0 text-bold" style={{ fontSize: '1.25rem' }}>
                <span>{label}</span>
                {value}
            </p>
            {children}
        </ColoredPanel>
    );
};

export default MetricPanel;
