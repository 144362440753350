import { metaInsightMetricOptions, sortNodes } from './helperFunctions';
import { DailyInsightsChart, MetaConversionsTable, MetaOverviewTable } from './index';
import type { AttributionWindowOption, ConversionLevelOption } from './InFlight.types';
import type { MetaCampaignInsights } from 'Types/MetaCampaignInsights';

interface ViewMetaInsightsProps {
    metaCampaignInsights: MetaCampaignInsights;
    selectedAttributionWindow: AttributionWindowOption;
    selectedConversionLevel: ConversionLevelOption;
    isSelfServe: boolean;
}

const ViewMetaInsights = (props: ViewMetaInsightsProps) => {
    const { metaCampaignInsights, selectedAttributionWindow, selectedConversionLevel, isSelfServe } = props;

    const sortedNodes = sortNodes({ type: 'meta', nodes: metaCampaignInsights.nodes });
    const campaignNode = sortedNodes.find((node) => node.level === 'campaign');
    // Build the options for the "Ad-sets" dropdown
    const adSetOptions = sortedNodes.filter((node) => node.level === 'adset').map((node) => ({ value: node.metaId, label: node.name }));
    adSetOptions.unshift({ value: campaignNode?.metaId || 'N/A', label: 'Campaign total' });

    // Build the options for the "Ads" dropdown
    const adOptions = sortedNodes
        .filter((node) => node.level === 'adset')
        .map((adSetNode) => {
            const options = sortedNodes
                .filter((node) => node.level === 'ad' && node.parentId === adSetNode.metaId)
                .map((node) => ({ value: node.metaId, label: node.name }));
            return {
                value: adSetNode.metaId,
                label: adSetNode.name,
                options,
            };
        });

    return (
        <>
            <DailyInsightsChart
                type="meta"
                data={sortedNodes}
                adsetOptions={adSetOptions}
                adOptions={adOptions}
                metricOptions={metaInsightMetricOptions}
                selectedAttributionWindow={selectedAttributionWindow}
                selectedConversionLevel={selectedConversionLevel}
                isSelfServe={isSelfServe}
            />
            <MetaOverviewTable
                data={sortedNodes}
                attributionWindow={selectedAttributionWindow}
                conversionLevel={selectedConversionLevel}
                isSelfServe={isSelfServe}
            />
            <MetaConversionsTable attributionWindow={selectedAttributionWindow} data={metaCampaignInsights} />
        </>
    );
};

export default ViewMetaInsights;
