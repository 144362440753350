import { createRef, Component } from 'react';

import css from './FormControl.module.scss';
import AsFormik from './AsFormik';
import FormGroup from './FormGroup';

/**
 * A wrapper for input fields.
 */
class FileInput extends Component {
    constructor(props) {
        super(props);

        this.fileInputRef = createRef();
    }

    openFileSelectionDialog = () => {
        this.fileInputRef.current.click();
    };

    render() {
        const {
            accept,
            label,
            required,
            className='',
            errorMsg,
            helpMsg,
            hidden,
            name,
            successMsg,
            topRightSlot,
            value,
            ...restProps
        } = this.props;

        const style = {
            display: hidden ? 'none' : 'inline-block',
        };

        return (
            <FormGroup
                label={label}
                topRightSlot={topRightSlot}
                required={required}
                className={className}
                errorMsg={errorMsg}
                helpMsg={helpMsg}
                style={style}
                successMsg={successMsg}
                data-smg-field={name}
            >
                <input
                    accept={accept}
                    className={`${css['form-control']} ${errorMsg ? css['is-invalid'] : ''}`}
                    // This is an IDL attribute and is valid, not sure why eslint is complaining
                    // eslint-disable-next-line react/no-unknown-property
                    files={value}
                    name={name}
                    ref={this.fileInputRef}
                    type="file"
                    {...restProps}
                />
            </FormGroup>
        );
    }
}

const FFileInput = AsFormik(FileInput);

export { FileInput, FFileInput };
