import { useState } from 'react';

import DigitalMediaSearch from './DigitalMediaSearch';
import type { DigitalMediaSearchCampaign } from './DigitalMediaSearch';

import { Http, toast } from 'Components';

interface PinterestDigitalMediaSearchProps {
    pinterestAdAccountIds: string[];
    linkedCampaignId?: string;
    onDownloadDataClick?: () => void;
    onLinkClick: (campaignId: string, supplierKey: string, adAccountId?: string) => void;
}

interface PinterestDigitalMediaSearchCampaign extends Omit<DigitalMediaSearchCampaign, 'adAccountId'>  {
    adAccountId: string;
}

const PinterestDigitalMediaSearch = (props: PinterestDigitalMediaSearchProps) => {
    const { pinterestAdAccountIds, linkedCampaignId, onDownloadDataClick, onLinkClick } = props;

    const [searchCampaigns, setSearchCampaigns] = useState<PinterestDigitalMediaSearchCampaign[]>();
    const handleSearchClick = (searchString: string) => {
        return Http
            .post('/in-flight/search/pinterest', { pinterestAdAccountIds, searchString })
            .then(setSearchCampaigns)
            .catch((error) => {
                console.error(error);
                toast.error('Could not connect to Pinterest');
            });
    };
    return (
        <DigitalMediaSearch
            linkedCampaignId={linkedCampaignId}
            linkedCampaignLabel="Linked to Pinterest campaign: "
            onDownloadDataClick={onDownloadDataClick}
            onLinkClick={(campaignId, adAccountId) =>
                onLinkClick(campaignId, 'pinterest', adAccountId || undefined)
            }
            onSearchClick={handleSearchClick}
            searchCampaigns={searchCampaigns}
            searchFieldLabel="Search by campaign ID"
        />
    )
};

export default PinterestDigitalMediaSearch;