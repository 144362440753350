import { useState, useEffect } from 'react';

import Comment from './Comment';
import AddComment from './AddComment';
import { ErrorMessage, Http, Loading } from '..';
import { isFunction } from 'lodash';

// We use the same endpoint to retrieve and post new comments, hence the post request
async function getComments(endpoint, method = 'post') {
    return Http[method](endpoint);
}

const CommentsPanel = (props) => {
    const {
        className,
        endpoint,
        method,
        onCreate,
        setUnreadCounter,
        AddCommentEndpoint = endpoint,
        AddCommentMethod = method,
    } = props;

    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (endpoint) {
            getComments(endpoint, method).then((data) => {
                if (isFunction(setUnreadCounter)) {
                    setUnreadCounter(0);
                }
                setComments(data);
            })
                .catch(err => setErrorMessage(err))
                .finally(() => setIsLoading(false));
        }
    }, [endpoint, setUnreadCounter, method])

    const handleCommentCreated = async (comment) => {
        if (typeof onCreate === 'function') {
            await onCreate(comment);
        }
        setComments([comment, ...comments]);
    };

    const handleCommentsChanged = (comments) => setComments(comments);

    if (isLoading) {
        return <Loading />
    }
    if (errorMessage) {
        return <ErrorMessage />
    }

    return (
        <div className={className}>
            <AddComment endpoint={AddCommentEndpoint} method={AddCommentMethod} onCreate={handleCommentCreated} />
            { comments.map(comment => (
                <Comment
                    key={comment._id}
                    comment={comment}
                    endpoint={AddCommentEndpoint}
                    onChange={handleCommentsChanged}
                />
            ))}
        </div>
    );
};

export default CommentsPanel;
