const pinterest = {
    labelOptions: [
        { label: 'Custom', value: 'custom' },
        { label: 'Signup', value: 'signup' },
        { label: 'Lead', value: 'lead' },
        { label: 'Checkout', value: 'checkout' },
    ],
    attributionWindowOptions: [
        { label: '1 day', value: 1 },
        { label: '7 days', value: 7 },
        { label: '14 days', value: 14 },
        { label: '30 days', value: 30 },
    ],
    conversionLevelOptions: [
        { label: 'Brand', value: 'brand' },
    ]
};

export default pinterest;