import numeral from 'numeral';
import { isNumber, orderBy } from 'lodash';
import Pill from '../Pill/Pill';
import { config } from 'smg-common';

const IndexPill = (props) => {
    const { className, value, border, ...restProps } = props;

    const indexBenchmarks = orderBy(config.studies?.indexBenchmarks, 'minValue', 'desc');

    const getPillColor = (value) => {
        const benchmark = indexBenchmarks.find((benchmark) => value > benchmark.minValue);
        return benchmark?.color || 'smg-darkGrey';
    };

    const getText = (value) => {
        if ((isNumber(value) && value < 0) || !value) return 'n/a';
        else if (isNumber(value)) return numeral(value).format('0');
        else return value;
    };

    return (
        <Pill
            className={className}
            colour={getPillColor(value)}
            text={getText(value)}
            type="indices"
            style={border ? { border: 'thin solid white' } : ''}
            {...restProps}
        />
    );
}

export default IndexPill;
