import ColoredPanel from 'Components/ColoredPanel/ColoredPanel'
import { PropsWithChildren } from 'react';
import UpgradeModal from './UpgradeModal';

const UpgradeInfobox = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <div className="p-2" style={{position: 'absolute', bottom: 0, left: 0}}>
            <ColoredPanel dismissible color="smg-mud">
                <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    {children}
                    <UpgradeModal trigger={(<strong style={{cursor: 'pointer'}}>Upgrade today</strong>)} />
                </div>
            </ColoredPanel>
        </div>
    )
}

export default UpgradeInfobox;