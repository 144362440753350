import AsFormik from './AsFormik';
import { useState } from 'react';
import { debounce } from 'lodash';

import { FReactSelect, Http } from '../';

function getDisplayName(user) {
    return `${user.firstName} ${user.lastName} <${user.email}>`.trim()
        .replace(/\s+, ' '/g);
}

const UserSelect = (props) => {
    const { initialValue, label, name, ...restProps } = props;

    const [usersOptions, setUsersOptions] = useState([]);

    if (initialValue && !usersOptions.find((option) => option.value === initialValue._id)) {
        usersOptions.push({ label: getDisplayName(initialValue), value: initialValue._id });
    }

    const handleInputChanged = debounce((value = '') => {
        if (value.length > 2) {
            Http.get(`/admin/collections/users?$searchTerm=${value}&$searchIn[]=firstName&$searchIn[]=lastName&$searchIn[]=email`)
                .then((res) => setUsersOptions(
                    res
                        ? res.items.map((user) => ({ ...user, label: getDisplayName(user), value: user._id }))
                        : []
                ));
        }
    }, 500);

    return (<FReactSelect
        isClearable={true}
        label={label}
        name={name}
        noOptionsMessage="No matches"
        onInputChange={handleInputChanged}
        options={usersOptions}
        placeholder="Start typing to search users"
        style={{ maxWidth: '300px' }}
        {...restProps}
    />)
};

const FUserSelect = AsFormik(UserSelect, {
    extractValue: option => option?.value,
});

export { UserSelect, FUserSelect };
