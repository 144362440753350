import { useState } from 'react';
import ReactDOM from 'react-dom';

import { Button } from '../../Components';
import css from './CookieConsent.module.scss';

/**
 * Show the "I agree to cookies" pop-over
 * We store the user's choice both in local state (for performance and convenience) and
 * in localStorage (persists across page-refresh and tabs)
 */
const CookieConsent = () => {
    const [agreed, setAgreed] = useState(localStorage.getItem('agreedToCookies') || false);

    const handleOkClick = () => {
        localStorage.setItem('agreedToCookies', true);
        setAgreed(true);
    };

    return (
        agreed
            ? null
            : ReactDOM.createPortal(
                <div className={`${css.cookieConsentContainer} d-flex`}>
                    <div className="flex-grow-1 p-4">
                        <h2>We use cookies!</h2>
                        <p>
                        We respect your privacy. We use cookies to help us provide the best possible experience for you.
                        By using <span className="text-nowrap">Plan-Apps</span> you consent to our use of cookies.
                        </p>
                    </div>
                    <div className="stack-col-end-end p-4">
                        <Button className="text-nowrap ml-3" onClick={handleOkClick}>I understand</Button>
                    </div>
                </div>,
                document.getElementById('cookie-consent-portal'),
            )
    )
};

export default CookieConsent;
