import { useEffect, useState } from 'react';
import * as Promise from 'bluebird';
import { isFunction } from 'lodash';

import { Layout, Loading, useAuth, useUrl6 } from '../../Components/';
import { RichErrorPage } from '../../App/ErrorPages';


const Loader = (props) => {
    const { WrappedComponent, pageTitle, loadOptions, childProps } = props;

    const [loadState, setLoadState] = useState({ isLoading: true });
    const { error, isLoading, loadedItems } = loadState;

    const { canAccess, me } = useAuth();
    const { params, queryParams } = useUrl6();

    const fetch = () => {
        setLoadState({ isLoading: true, errorMessage: undefined });
        const promises = loadOptions ? loadOptions({ canAccess, me, params, queryParams, ...childProps }) : {};
        return Promise.props(promises)
            .then(loadedItems => {
                setLoadState({
                    isLoading: false,
                    errorMessage: undefined,
                    loadedItems,
                });
            })
            .catch((error) => {
                console.error('PageLoader failed to load items', error);
                setLoadState({
                    error,
                    isLoading: false,
                });
            });
    }

    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return (
            <Layout pageTitle={pageTitle} suppressPageViewLog={true}>
                <div className="box-raised p-3">
                    <Loading />
                </div>
            </Layout>
        );
    } else if (error) {
        console.error(error.message);
        return (
            <RichErrorPage message={error.message} status={error.status} title={error.info?.title || error.message} />
        );
    } else {
        return <WrappedComponent {...childProps} {...loadedItems} reload={fetch} />
    }
}

const PageLoader = (WrappedComponent, pageTitle, loadOptions) => {
    if (loadOptions && !isFunction(loadOptions)) {
        throw Error('PageLoader can only accept an optional method as the third argument');
    }

    return (childProps) => Loader({ WrappedComponent, pageTitle, loadOptions, childProps });
}

export default PageLoader;
