import { Button, Http, Icon, Loading, Modal, Tooltip, useFetch, useModal, } from '../../';
import { useState } from 'react';

import BookingFormActionsMenu from './BookingFormActionsMenu';
import BookingFormDiceIcon from './BookingFormDiceIcon';
import BookingFormStatus from '../BookingFormStatus';
import CreateBookingFormModal from '../CreateBookingFormModal/CreateBookingFormModal';

import css from './ViewBookingFormsModal.module.scss';

const ViewBookingFormsModal = (props) => {
    const { campaign, plan, selfServeCampaign, modal } = props;

    const { show: showCreateBookingFormModal } = useModal({title: 'Create booking form', template: CreateBookingFormModal});

    const isSelfServe = !!selfServeCampaign;

    const [isLoading, setIsLoading] = useState(false);

    const [bookingForms, isLoadingBookingForms, errorMessage, forceReload] = useFetch(
        () => Http.get(isSelfServe
            ? `/self-serve/booking-forms?selfServeCampaign=${selfServeCampaign._id}&plan=${plan._id}`
            : `/booking-forms?campaign=${campaign._id}&plan=${plan._id}`
        ),
        [],
    );

    const handleNewBookingFormClicked = () => {
        modal.hide();
        showCreateBookingFormModal({ campaign, plan, selfServeCampaign });
    };

    const renderRecipients = (bookingForm) => {
        if (!bookingForm?.recipients) return 'n/a';

        const recipients = bookingForm.recipients.map((recipient) => (
            <div key={recipient._id}>
                {`${recipient.role}: ${recipient.email}`}<br />
            </div>
        ));

        return(<Tooltip hover={recipients}>
            <Icon name={bookingForm.recipients.length === 1 ? 'person' : 'people'} />
        </Tooltip>);
    };

    return (
        <Modal.Body>
            {(isLoading || isLoadingBookingForms) && <Loading />}
            {errorMessage && <div className="errorMsg">{errorMessage}</div> }
            {!isLoading && !isLoadingBookingForms && !errorMessage && (<>
                {!bookingForms?.length && <div className="stack-center">This plan does not have any booking forms yet.</div>}
                {bookingForms?.length > 0 && <div className="table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Document</th>
                                <th className="text-center">Activities {isSelfServe ? '' : '& items'}</th>
                                <th className="text-center">Recipients</th>
                                <th className="text-center">Status</th>
                                {/* <th className="text-center">Send/Link</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingForms?.map((bookingForm, index) => (
                                <tr key={index}>
                                    <td className={bookingForm.status === 'document.voided' ? css.strike : null}>{bookingForm.name}</td>
                                    <td className="text-center"><BookingFormDiceIcon bookingForm={bookingForm} plan={plan}/></td>
                                    <td className="text-center">{renderRecipients(bookingForm)}</td>
                                    <td className="text-center"><BookingFormStatus status={bookingForm.status} /></td>
                                    <td><BookingFormActionsMenu bookingForm={bookingForm} setIsLoading={setIsLoading} forceReload={forceReload} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                <div className="mt-3 stack-end">
                    <Button color="primary" onClick={handleNewBookingFormClicked}>Create new booking form</Button>
                </div>
            </>)}
        </Modal.Body>
    );
};

export default ViewBookingFormsModal;
