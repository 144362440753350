import { map } from 'lodash';
import { NavLink } from 'react-router-dom';

import css from './NavigationTabs.module.scss';

const NavigationTabs = (props) => {
    const { tabs, ...restProps } = props;

    return (
        <div {...restProps}>
            {map(tabs, (tab, index) => (
                <NavLink
                    className={({ isActive }) => isActive ? css.activeLink : css.link}
                    // See: https://stackoverflow.com/questions/64718464/navlink-exact-prop-not-working-for-react-router-dom-6
                    end={Boolean(tab.exact)}
                    key={index}
                    to={tab.linkTo}
                >
                    <h3>{tab.label}</h3>
                </NavLink>
            ))}
        </div>
    );
};

export default NavigationTabs;