import { isEmpty, isInteger, isString } from 'lodash-es';
import moment from 'moment';

moment.updateLocale('en', {
    week: {
        dow : 1, // Monday is the first day of the week.
    }
});

/**
 * Provides utility functions to manipulate Date objects that are time agnostic, also called Simple.
 * See: https://app.nuclino.com/Shopper-Media-Group-Ltd/Mean-Lobsters/Handling-Dates-in-PlanApps-79081a62-1930-4f86-bbeb-94eb58c652bb
 */

const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;

/**
 * Checks if `input` is of a valid input type for the simpleDate functions
 * @param {Date|string|null|undefined} input when string, it should be at the same format that JSON.stringify produces
 * @returns true when the parameter is a valid Date or string, false when it is null/undefined
 * @throws when the parameter is an invalid Date or an invalid string or any other unexpected type
 */
function isValidInput(input) {
    if (input instanceof Date) {
        if (isNaN(input)) {
            throw new Error('Invalid date at `simpleDate`');
        }
        return true;
    }

    if (isString(input)) {
        if (isoRegex.test(input)) {
            return true;
        }
        if (input === '') {
            return false;
        }
        throw new Error(`Invalid parameter type for \`simpleDate\`: "${input}"`);
    }

    if (input === null || input === undefined) {
        return false;
    }

    throw new Error('Invalid parameter type for `simpleDate`. Allowed values are: date, string, null, undefined.');
}

function add(simpleDate, number, period) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().add(number, period).toDate()
        : undefined;
}

function plusDays(simpleDate, number) {
    return add(simpleDate, number, 'days');
}

function plusWeeks(simpleDate, number) {
    return add(simpleDate, number, 'weeks');
}

function plusMonths(simpleDate, number) {
    return add(simpleDate, number, 'months');
}

function minusDays(simpleDate, number) {
    return add(simpleDate, -number, 'days');
}

function minusWeeks(simpleDate, number) {
    return add(simpleDate, -number, 'weeks');
}

function minusMonths(simpleDate, number) {
    return add(simpleDate, -number, 'months');
}

/**
 * @param {Date|string|null|undefined} endSimpleDate
 * @param {Date|string|null|undefined} startSimpleDate
 * @param {boolean} isEndDateInclusive when true, the endDate is included. For example the diff between 13/6 and 13/6 is 1 and not 0.
 * @returns the number of days between the two days. The diff is negative when the start date is after the end date
 */
 function diff(endSimpleDate, startSimpleDate, isEndDateInclusive = true) {
    if (!isValidInput(endSimpleDate) || !isValidInput(startSimpleDate)) {
        return undefined;
    }
    const numberOfDays = Math.round(moment(endSimpleDate).diff(startSimpleDate, 'days', true));
    if (isEndDateInclusive) {
        return numberOfDays < 0 ? numberOfDays - 1 : numberOfDays + 1;
    } else {
        return numberOfDays;
    }
}

function diffMonths(endSimpleDate, startSimpleDate) {
    if (!isValidInput(endSimpleDate) || !isValidInput(startSimpleDate)) {
        return undefined;
    }
    return moment(endSimpleDate).diff(startSimpleDate, 'months');
}

function diffWeeks(endSimpleDate, startSimpleDate) {
    if (!isValidInput(endSimpleDate) || !isValidInput(startSimpleDate)) {
        return undefined;
    }
    return moment(endSimpleDate).diff(startSimpleDate, 'weeks');
}

function startOf(simpleDate, period) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().startOf(period).toDate()
        : undefined;
}

function startOfDay(simpleDate) {
    return startOf(simpleDate, 'day')
}

function startOfWeek(simpleDate) {
    return startOf(simpleDate, 'week')
}

function startOfMonth(simpleDate) {
    return startOf(simpleDate, 'month')
}

function endOf(simpleDate, period) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().endOf(period).startOf('day').toDate()
        : undefined;
}

function endOfDay(simpleDate) {
    return endOf(simpleDate, 'day')
}

function endOfWeek(simpleDate) {
    return endOf(simpleDate, 'week');
}

function endOfMonth(simpleDate) {
    return endOf(simpleDate, 'month');
}

function format(simpleDate, formatStr, missing = 'n/a') {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().format(formatStr)
        : missing;
}

function formatAsLocalDate(simpleDate, formatStr, missing = 'n/a') {
    return isValidInput(simpleDate)
        ? moment(simpleDate).local().format(formatStr)
        : missing;
}

function today() {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
}

// For months: Jan - 1, Feb - 2, Mar - 3 etc.
function create(day, month, year) {
    const value = {};
    if (isInteger(parseInt(day))) {
        value.date = parseInt(day)
    }
    if (isInteger(parseInt(month))) {
        // Moment uses zero-index for months therefore we -1
        value.month = parseInt(month) - 1
    }
    if (isInteger(parseInt(year))) {
        value.year = parseInt(year)
    }

    return !isEmpty(value)
        ? moment.utc().set(value).startOf('day').toDate()
        : undefined;
}

function parse(dateString, format = 'DD/MM/YYYY') {
    if (!dateString) return undefined;
    return moment(dateString, format).utc(true).toDate();
}

function getDayOfWeek(simpleDate, number) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().day(number).toDate()
        : undefined;
}

function getDay(simpleDate) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().day()
        : undefined;
}

function getWeek(simpleDate) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().week()
        : undefined;
}

function getMonth(simpleDate, humanise = false) {
    if (!isValidInput(simpleDate)) {
        return;
    }

    const monthIndex = moment(simpleDate).utc().month();

    if (!humanise) {
        return monthIndex;
    }

    const matchingMonth = months().find((month) => month.value === monthIndex + 1);

    return matchingMonth.label;
}

function getYear(simpleDate) {
    return isValidInput(simpleDate)
        ? moment(simpleDate).utc().year()
        : undefined;
}

// startOfFinancialYear is a month: 1 = January, 12 = December
function getFinancialYear(simpleDate, startOfFinancialYear) {
    if (!isValidInput(simpleDate)) {
        return undefined;
    }

    const month = getMonth(simpleDate) + 1; // getMonth() is 0 based
    return !startOfFinancialYear || startOfFinancialYear <= month
        ? getYear(simpleDate)
        : getYear(simpleDate) - 1;
}

/**
 *
 * @param {Date|string|null|undefined} simpleDate
 * @param {number} startMonth A number representing the month the Fy starts at (e.g. 1 = Jan, 6 = Jun, 12 = Dec)
 * @returns {number} Week of year date falls in, relative to startMonth, between 1 and 53
 */
const getWeekOfFy = (simpleDate, startMonth) => {
    if (!isValidInput(simpleDate)) {
        return undefined;
    }

    // don't populate if company hasn't got FY configured
    if (!startMonth) return undefined;

    // create a local date at the start of fy
    const fyDate = moment
        .utc(simpleDate) // using utc ignores timezones so it doesn't adjust for BST
        .month(startMonth - 1) // month is zero indexed
        .date(1) // first of the chosen month
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .locale('en-gb'); // british locale sets first day of week to be monday

    const activityDate = moment(simpleDate).locale('en-gb');

    let fyWeek = (activityDate.week() - fyDate.week()) + 1;

    // If < 1 must be in previous FY
    if (fyWeek < 1) fyWeek = 52 - Math.abs(fyWeek);

    return fyWeek;
};

function isSame(firstDate, secondDate, period = 'day') {
    if (!isValidInput(firstDate) || !isValidInput(secondDate)) {
        return false;
    }
    return moment(firstDate).isSame(secondDate, period);
}

function isBefore(firstDate, secondDate, period = 'day') {
    if (!isValidInput(firstDate) || !isValidInput(secondDate)) {
        return undefined;
    }
    return moment(firstDate).isBefore(secondDate, period);
}

function isAfter(firstDate, secondDate, period = 'day') {
    if (!isValidInput(firstDate) || !isValidInput(secondDate)) {
        return undefined;
    }
    return moment(firstDate).isAfter(secondDate, period);
}

function isSameOrAfter(firstDate, secondDate, period = 'day') {
    if (!isValidInput(firstDate) || !isValidInput(secondDate)) {
        return undefined;
    }
    return moment(firstDate).isSameOrAfter(secondDate, period);
}

function isSameOrBefore(firstDate, secondDate, period = 'day') {
    if (!isValidInput(firstDate) || !isValidInput(secondDate)) {
        return undefined;
    }
    return moment(firstDate).isSameOrBefore(secondDate, period);
}

function months() {
    return [
        { value: 1, label: 'January', offset: 0 },
        { value: 2, label: 'February', offset: 1 },
        { value: 3, label: 'March', offset: 2 },
        { value: 4, label: 'April', offset: 3 },
        { value: 5, label: 'May', offset: 4 },
        { value: 6, label: 'June', offset: 5 },
        { value: 7, label: 'July', offset: 6 },
        { value: 8, label: 'August', offset: 7 },
        { value: 9, label: 'September', offset: 8 },
        { value: 10, label: 'October', offset: 9 },
        { value: 11, label: 'November', offset: 10 },
        { value: 12, label: 'December', offset: 11 },
    ];
}

// Converts a date in the browser's local timezone to a LocalDate (effectively stips out time and timezone)
function toLocalDate(jsDate) {
    if (!jsDate) {
      return jsDate;
    }
    const tm = new Date(jsDate);
    const localDate = new Date(Date.UTC(tm.getFullYear(), tm.getMonth(), tm.getDate()));
    return localDate;
}

function datesBetween (startDate, endDate, formatStr = 'DD/MM/YYYY') {
    if (!isValidInput(startDate) || !isValidInput(endDate)) return undefined;

    let now = moment(startDate);
    let dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(format(now.toDate(), formatStr));
        now.add(1, 'day');
    }

    return dates;
}

export default {
    isValidInput,
    plusDays,
    plusWeeks,
    plusMonths,
    minusDays,
    minusWeeks,
    minusMonths,
    diff,
    diffMonths,
    diffWeeks,
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfDay,
    endOfWeek,
    endOfMonth,
    format,
    formatAsLocalDate,
    parse,
    today,
    create,
    getDayOfWeek,
    getDay,
    getWeek,
    getMonth,
    getYear,
    getFinancialYear,
    getWeekOfFy,
    isSame,
    isBefore,
    isAfter,
    isSameOrAfter,
    isSameOrBefore,
    months,
    toLocalDate,
    datesBetween,
};
