import { useEffect } from 'react';
import Snowflakes from 'magic-snowflakes';

const now = new Date();
const isXmas = now.getMonth() === 11 && now.getDate() >= 10;

const showFlakes = () => {
    const snowflakes = new Snowflakes({ maxSize: 30, count: 10 });
    snowflakes.start();
    // Hide after 30 seconds
    setTimeout(() => {snowflakes.destroy()}, 30000);
}

const ChristmasSnowflakes = () => {
    useEffect(() => {
        isXmas && showFlakes();
    }, []);

    return null;
}

export default ChristmasSnowflakes;
