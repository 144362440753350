import { Pill } from '../';

const ActivityStatus = (props) => {
    const { activity, className } = props;

    if (!activity) return 'n/a';

    let color = 'smg-darkGrey';
    let text = 'n/a';

    if (activity.evaluated) {
        text = 'evaluated';
        color = 'success';
    } else if (activity.live) {
        text = 'live';
        color = 'success';
    } else if (activity.status === 'planned') {
        text = activity.status;
        color = 'secondary';
    } else if (activity.status === 'confirmed' || activity.status === 'booked' || activity.status === 'briefed') {
        text = activity.status;
        color = 'primary';
    }

    return (
        <div className={className}>
            {/* Show historic flag if the activity is not planned */}
            {activity.historic && activity.status !== 'planned' && !activity.evaluated && (
                // We use <div> here because we don't want the historic pill inline
                <div><Pill colour="smg-darkGrey" text="historic" type="status" className="mb-1" /></div>
            )}
            {activity.cancelled
                // Cancelled activity
                ? (<Pill colour="smg-darkGrey" text="cancelled" type="status" />)
                : (<Pill colour={color} text={text} type="status" />)}
        </div>
    );
};

export default ActivityStatus;
