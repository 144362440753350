import css from './RenderFile.module.scss';

interface RenderFileProps {
    file: {
        url: string;
        mimetype: string;
    };
}

const RenderFile = (props: RenderFileProps) => {
    const { file } = props;
    const fileTypeHandlers = {
        image: (
            <>
                <div className={css['image-container']}>
                    <div className={css['image-preview']} style={{ backgroundImage: `url("${file.url}")` }} />
                </div>
            </>
        ),
        pdf: (
            <>
                <div>
                    <iframe title="Preview file" className={css['pdf-preview']} src={file.url} />
                </div>
            </>
        ),
        audio: (
            <>
                <div className="m-5 d-flex justify-content-center align-items-center w-100">
                    <audio controls>
                        <source src={file.url} type={file.mimetype} />
                        Your browser does not support the audio tag.
                    </audio>
                </div>
            </>
        ),
        video: (
            <>
                <video width="100%" controls>
                    <source src={file.url} type={file.mimetype} />
                    Your browser does not support the video tag.
                </video>
            </>
        ),
        default: (
            <>
                <div>No preview available</div>
            </>
        ),
    };

    const imgFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/tiff', 'image/svg', 'image/bmp'];
    const audioFormats = ['audio/mpeg', 'audio/wav'];
    const videoFormats = ['video/mp4', 'video/mpeg', 'video/quicktime'];

    if (imgFormats.includes(file.mimetype)) {
        return fileTypeHandlers.image;
    } else if (file.mimetype === 'application/pdf') {
        return fileTypeHandlers.pdf;
    } else if (audioFormats.includes(file.mimetype)) {
        return fileTypeHandlers.audio;
    } else if (videoFormats.includes(file.mimetype)) {
        return fileTypeHandlers.video;
    } else {
        return fileTypeHandlers.default;
    }
};

export default RenderFile;
