import moment from 'moment';
import {
    formatters,
    getStartAndEndDatesOfActivitiesAndItems,
    getTotalCostOfSelectedActivitiesAndItems,
    simpleDate,
} from '../../index.js';

const signetTemplate = {
    name: 'Signet',
    pandaDocId: 'S8TyRBLmuPzEMk2WYjXvNQ',
    companies: ['627d0fe46dae914ec8ec3765'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'Invoice month', value: 'invoiceMonth' },
        { label: 'Threefold job number', value: 'jobNumber' },
        { label: 'Additional information', value: 'additionalInfo', isOptional: true },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, items);

        if (!startDate) {
            throw new Error('Unable to create booking form due to missing activity dates.');
        }

        const liveMonth = simpleDate.getMonth(startDate, true);
        const brandName = campaign.brands[0].name;
        const campaignName = campaign.name;
        const now = moment().format('YYMMDD-HHmm');

        return `[${now}] Campaign Confirmation Form - ${liveMonth} - ${brandName} - ${campaignName}`;
    },

    getTokens: (campaign, plan, activities, items) => {
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, items);

        if (!startDate || !endDate) {
            throw new Error('Unable to create booking form due to missing activity dates.');
        }

        const totalCost = getTotalCostOfSelectedActivitiesAndItems(plan, activities, items);
        const duration = simpleDate.diffWeeks(endDate, startDate);

        return [
            { label: 'Brand name', name: 'brand.name', value: campaign.brands[0].name },
            { label: 'Campaign name', name: 'campaign.name', value: campaign.name },
            { label: 'Plan retailer', name: 'retailer.name', value: plan.retailer.name },
            { label: 'Campaign total cost', name: 'campaign.totalCost', value: formatters.asCurrency(totalCost, { decimals: 2 }) },
            { label: 'Campaign start date', name: 'campaign.startDate', value: formatters.asDate(startDate) },
            { label: 'Campaign duration', name: 'campaign.duration', value: `${duration} ${duration === 1 ? 'week' : 'weeks'}`},
        ];
    },
};

export default signetTemplate;
