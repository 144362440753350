import { Pill, Tooltip } from '../';

const ShopperJourneyPill = (props) => {
    const { shopperJourney } = props;

    if (!shopperJourney) return 'n/a';

    return (<Tooltip block={true} hover={shopperJourney.name}>
        <Pill colour={shopperJourney.backgroundColor} text={shopperJourney.shortName} textColor={shopperJourney.color} type="shopper-journey" />
    </Tooltip>);
};

export default ShopperJourneyPill;
