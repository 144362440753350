import { createRoot } from 'react-dom/client'; // React 18
import { BrowserRouter } from 'react-router-dom';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// This *has* to be before the AppRoutes, otherwise some styles are not applied properly
import './Components/css/index.scss';

import Analytics from './Components/Analytics/Analytics';
import AppRoutes from './App/AppRoutes';
import AutoLogout from './App/AutoLogout/AutoLogout';
import DialogContainer from './Components/Dialog/DialogContainer';
import CookieConsent from './App/CookieConsent/CookieConsent';
import GenericErrorBoundary from './App/GenericErrorBoundary';
import EasterEggHuntIntro from 'Components/EasterEggs/EasterEggHuntIntro';
import ModalContainer from './Components/Modal/ModalContainer';
import TermsAndConditionsModal from './App/TermsAndConditions/TermsAndConditionsModal';
import { AppStateProvider } from './Components/hooks/useAppState';
import { ErrorPage } from './App/ErrorPages';
import { Http, CampaignsRenamePopUp } from './Components';
import ChristmasSnowflakes from 'Components/ChristmasSnowflakes';

// Analytics
const ANALYTICS_INTERVAL = 10000;
const VISIBILITY_INTERVAL = 1000;

let timeAlive = 0;

const analyticsInterval = setInterval(() => {
    if (timeAlive > 0) {
        Analytics.log('USER-SESSION-DURATION', { duration: timeAlive });
        timeAlive = 0;
    }
}, ANALYTICS_INTERVAL);

const visibilityInterval = setInterval(() => {
    if (document.hasFocus()) {
        timeAlive += VISIBILITY_INTERVAL;
    }
}, VISIBILITY_INTERVAL);

// On exit
window.onbeforeunload = () => {
    Analytics.log('USER-EXIT');
    clearInterval(visibilityInterval);
    clearInterval(analyticsInterval);
    if (timeAlive > 0) {
        Analytics.log('USER-SESSION-DURATION', { duration: timeAlive });
    }
};

// Get currently logged in user and apps they have access to (the timestamp is needed to prevent caching when users click the back button)
Http.get(`/app-bootstrap/reference-data?t=${Date.now()}`)
    .then((data) => {
        // Render app
        const root = createRoot(document.getElementById('root'));
        root.render(
            <GenericErrorBoundary>
                <AppStateProvider initialValue={{ ...data }}>
                    <DndContext collisionDetection={closestCenter}>
                        <BrowserRouter basename="/">
                            <AutoLogout />
                            <AppRoutes />
                            <CookieConsent />
                            <DialogContainer />
                            <ModalContainer />
                            <TermsAndConditionsModal user={data?.auth?.user} />
                            <EasterEggHuntIntro />
                            <ToastContainer icon={false} />
                            <CampaignsRenamePopUp />
                        </BrowserRouter>
                    </DndContext>
                </AppStateProvider>
                <ChristmasSnowflakes />
            </GenericErrorBoundary>
        );
    })
    .catch(error => {
        // A network error occurred while trying to check if user is logged in
        const root = createRoot(document.getElementById('root'));
        root.render(<ErrorPage />, document.getElementById('root'));
        console.error('Could not fetch current user', error);
    })
    .finally(() => {
        document.getElementById('loading-message').remove();
    });
