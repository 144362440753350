import Pill from '../Pill/Pill';

import css from './Badge.module.scss';

const Badge = ({ children, color, label, textColor, small, truncate }) => {
    const classes = [];
    if (children) classes.push(css.badge);
    if (truncate) classes.push(css.truncate);

    return (
        <span className={css.badgeWrapper}>
            <Pill
                className={classes.join(' ').trim()}
                colour={color}
                small={small}
                textColor={textColor}
            >{label}</Pill>
            {children}
        </span>
    );
}

export default Badge;
