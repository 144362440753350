import { useState } from 'react';

import { Http, toast } from 'Components';

import type { DigitalMediaSearchCampaign } from './DigitalMediaSearch';
import DigitalMediaSearch from './DigitalMediaSearch';

interface MetaDigitalMediaSearchProps {
    metaAdAccountIds: string[];
    linkedCampaignId?: string;
    onDownloadDataClick?: () => void;
    onLinkClick: (campaignId: string, supplierKey: string) => void;
}

const MetaDigitalMediaSearch = (props: MetaDigitalMediaSearchProps) => {
    const { metaAdAccountIds, linkedCampaignId, onDownloadDataClick, onLinkClick } = props;

    const [searchCampaigns, setSearchCampaigns] = useState<DigitalMediaSearchCampaign[]>();

    const handleSearchClick = (searchString: string) => {
        return Http
            .post('/in-flight/search/meta', { metaAdAccountIds, searchString })
            .then(setSearchCampaigns)
            .catch((error) => {
                console.error(error);
                toast.error('Could not connect to Meta');
            });
    };

    return (
        <DigitalMediaSearch
            linkedCampaignId={linkedCampaignId}
            linkedCampaignLabel="Linked to Meta campaign: "
            onDownloadDataClick={onDownloadDataClick}
            onLinkClick={(campaignId) => onLinkClick(campaignId, 'meta')}
            onSearchClick={handleSearchClick}
            searchCampaigns={searchCampaigns}
            searchFieldLabel="Search name or description..."
        />
    )
};

export default MetaDigitalMediaSearch;
