import { formatters } from 'smg-common';
import { compact, map, max, min } from 'lodash';

function getPlanDates(plan) {
    // Don't include cancelled activities in dates
    // Unless they have been studied
    const earliestStartDate = min(compact(map(plan.activities, activity => {
        const hasStudies = activity.studies?.length;
        if (!activity.cancelled || hasStudies) {
            return activity.startDate;
        }
    })));
    const latestEndDate = max(compact(map(plan.activities, activity => {
        const hasStudies = activity.studies?.length;
        if (!activity.cancelled || hasStudies) {
            return activity.endDate;
        }
    })));

    const startDate = formatters.asDate(earliestStartDate);
    const endDate = formatters.asDate(latestEndDate);

    return  `${startDate} - ${endDate}`;
}

export {
    getPlanDates,
};
