import { config } from 'smg-common';

const ErrorPage = () => (
    <div className="mt-5 text-center">
        <h1>Oops, an unexpected error occurred.</h1>
        <p>If the problem persists you can contact support at <a href={`mailto:${config.supportEmailAddress}`}>{config.supportEmailAddress}</a></p>
        <p>
            <a href={process.env.REACT_APP_BASE_FOLDER}>Go back to home page</a>
        </p>
    </div>
);

export default ErrorPage;
