import Icon from '../Icon/Icon';
import css from './IconMessage.module.scss'

/**
 * A component that displays a large icon and a message
 */
const IconMessage = (props) => {
    const { message, iconName='texture', ...restProps } = props;
    return (
        <h1 {...restProps}>
            <Icon name={iconName} style={{ fontSize: '48px' }} />
            <span style={{ verticalAlign: 'middle', marginLeft: '0.5rem', lineHeight: '48px' }}>{message}</span>
        </h1>
    )
};

/**
 * An opinionated component that displays the "Loading..." message
 */
const Loading = (props) => {
    const { label = 'Loading...' } = props;
    return (
        <div className="stack-center my-3" data-smg-section="loading">
            <div className={css.loader}></div>
            <h1 className="pl-3">{label}</h1>
        </div>
    );
}

// TODO: Disassociate ErrorMessage from IconMessage to make it reusable anywhere we want to show an error message
/**
 * An opinionated component that displays the "Error" message
 */
const ErrorMessage = (props) => {
    const {
        className = '',
        icon = true,
        message = 'An error occured',
        ...restProps
    } = props;

    const classes = [css['error-message']];
    if (className) classes.push(className);
    if (icon) classes.push('stack-center');

    return icon
        ? <IconMessage className={classes.join(' ')} iconName="error_outline" message={message} {...restProps} />
        : <div className={classes.join(' ')} {...restProps}>{message}</div>;
}

export {
    ErrorMessage,
    IconMessage,
    Loading,
}
