import { connect } from 'formik';
import { get } from 'lodash';

import Icon from '../Icon/Icon';

import css from './FormGroup.module.scss';

const Checkbox = (props) => {
    const {
        checked,
        defaultChecked,
        className = '',
        errorMsg,
        helpMsg,
        icon,
        label,
        onKeyDown,
        required,
        successMsg,
        tabIndex = 1,
        ...restProps
    } = props;

    return (
        <div className={className}>
            <div>
                <label className={props.disabled ? css.labelGrey : css.label} onKeyDown={onKeyDown} tabIndex={tabIndex}>
                    <Icon
                        className={`like-link ${props.disabled ? css['checkbox-disabled'] : css.checkbox}`}
                        name={icon || `check_box${(checked || defaultChecked) ? '' : '_outline_blank'}`}
                    />
                    <input
                        defaultChecked={checked}
                        style={{ display: 'none' }}
                        type="checkbox"
                        {...restProps}
                    />
                    {label}
                    {required && <span className={css.required}>*</span>}
                </label>
            </div>
            {errorMsg && <div className={css.errorMsg}>{errorMsg}</div>}
            {helpMsg && <div className={css.helpMsg}>{helpMsg}</div>}
            {successMsg && <div className={css.successMsg}>{successMsg}</div>}
        </div>
    );
};

// Wraps a Checkbox and attaches to formik context
// NOTE: This is effectively a copy-paste from AsFormik, but is needed due to the
// differences between checkboxes and input elements in React (checked vs value etc)
const FCheckbox = connect(({ name, formik, tabIndex = 1, ...restProps }) => {
    const isTouched = get(formik.touched, name);
    const errorMsg = isTouched ? get(formik.errors, name) : undefined;
    const value = get(formik.values, name, '');

    const handleKeyDown = (event) => {
        if (['Enter', 'Space'].includes(event.code)) {
            event.preventDefault();
            formik.setFieldValue(name, !formik.values[name]);
            formik.setFieldTouched(name, true);
        }
    };

    return (
        <Checkbox
            checked={Boolean(value)}
            errorMsg={errorMsg}
            name={name}
            onBlur={() => formik.setFieldTouched(name, true)}
            onChange={e => formik.setFieldValue(name, e.target.checked)}
            onKeyDown={handleKeyDown}
            tabIndex={tabIndex}
            {...restProps}
        />
    );
});


export {
    Checkbox,
    FCheckbox,
};
