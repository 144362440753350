const shopperJourneys = {
    groupOptions: [
        { label: 'In-store', value: 'in-store'},
        { label: 'E-commerce', value: 'e-comm'},
        { label: 'Digital', value: 'digital'},
        // PFX-5273: Hidiing 'other' option until we have enough data for it
        // { label: 'Other', value: 'other'}
    ],
};

export default shopperJourneys;
