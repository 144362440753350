import { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { DropdownMenu, Icon } from '../../../Components';

import css from './BreadcrumbToolbar.module.scss';
import { DropdownMenuProps } from 'Components/DropdownMenu/DropdownMenu';


interface BreadcrumbToolbarProps {
    breadcrumbs: {
        label: string,
        link?: string,
    }[],
}

const BreadcrumbToolbar = (props: PropsWithChildren<BreadcrumbToolbarProps>) => {
    const { breadcrumbs, children } = props;

    const [collapsed, setCollapsed] = useState(false)
    const navigate = useNavigate()
    const containerRef = useRef<HTMLDivElement>(null)

    const onIntersect: IntersectionObserverCallback = (entries) => {
        const [entry] = entries;

        if (!entry.isIntersecting) {
            setCollapsed(true)
        }
    }

    useEffect(() => {
        const curr = containerRef.current;
        const observer = new IntersectionObserver(onIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        })

        if (curr) observer.observe(curr)

        return () => {
            if (curr) observer.unobserve(curr)
        }
    }, [containerRef])

    const ExpandBreadcrumb = ({ ...props }: Omit<DropdownMenuProps, 'icon'>) => <><DropdownMenu icon="more_horiz" {...props} /><Icon name="chevron_right" /></>

    const classes = [
        css.breadcrumb,
        'stack-even-center-3',
        'mx-3',
        'my-2',
    ];

    return (<div className={classes.join(' ')} ref={containerRef}>
        <h2 className={css.content}>
            {breadcrumbs.map((breadcrumb, index) => {
                if (collapsed && index !== 0 && index !== (breadcrumbs.length - 1)) {
                    if (index === 1) {
                        return (
                            <ExpandBreadcrumb
                                key={index}
                                styles={{ items: { fontSize: '1rem' } }}
                                options={
                                    breadcrumbs
                                        .slice(1, -1)
                                        .map(item => ({
                                            label: item.label,
                                            onClick: () => item.link ? navigate(item.link) : null
                                        }))
                                }
                            />
                        )
                    } else {
                        return null
                    }
                }

                return (
                    <Fragment key={index}>
                        {breadcrumb.link && <Link className="no-color" to={breadcrumb.link}>{breadcrumb.label}</Link>}
                        {!breadcrumb.link && breadcrumb.label}
                        {index !== (breadcrumbs.length - 1) && <Icon name="chevron_right" />}
                    </Fragment>
                )
            })}
        </h2>
        {children && (<div className="stack-end-center-3">
            {children}
        </div>)}
    </div>);
};

export default BreadcrumbToolbar;
