import { isNil } from 'lodash-es';

const sort = (retailers, fields = ['order', 'name', 'label']) => {
    // We add .slice() so we don't mutatue the original retailers array
    return retailers.slice(0).sort((r1, r2) => {
        for (const i in fields) {
            const field = fields[i];
            // We want nulls to go to bottom so we change them to infinity
            const r1Value = isNil(r1[field]) ? Infinity : r1[field];
            const r2Value = isNil(r2[field]) ? Infinity : r2[field];

            if (r1Value > r2Value) return 1;
            if (r1Value < r2Value) return -1;
        }

        return 0;
    });
};

export default {
    sort,
};
