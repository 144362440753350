import _ from 'lodash';
import { useState } from 'react';
import { config } from 'smg-common';
import { Form, Http, Loading, Modal, toast, useAuth, useModal } from 'Components';

import ViewBookingFormsModal from '../ViewBookingFormsModal/ViewBookingFormsModal';

import { buildActivityOptions, buildItemOptions, buildTemplateOptions } from './optionBuilderFunctions';

const CreateBookingFormModal = (props) => {
    const { campaign, plan, selfServeCampaign, modal } = props;

    const { me } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { show: showViewBookingFormsModal } = useModal({title: 'Booking forms', size: 'lg', template: ViewBookingFormsModal});

    // Build the dropdown options
    const activityOptions = buildActivityOptions(plan.activities);
    const itemOptions = buildItemOptions(plan.items);
    const isSelfServe = !!selfServeCampaign;
    const templateOptions = buildTemplateOptions(me.canAccessDataFrom, isSelfServe);

    const initialValues = {
        activityIds: [],
        itemIds: [],
        template: '',
    };

    const handleBackClicked = () => {
        modal.hide();
        showViewBookingFormsModal({ campaign, plan, selfServeCampaign });
    };

    const handleSave = (values) => {
        setIsLoading(true);
        setErrorMessage(null);

        // Recipient fields are dynamic depending on the selected template, we need to convert the form values to an array of recipients for the API
        const selectedTemplate = config.pandaDoc.templates.find((template) => template.pandaDocId === values.template);
        const recipients = selectedTemplate.recipientRoles?.map((role) => {
            return { email: values[role.value], role: role.value };
        });

        const bookingForm = {
            ...values,
            recipients,
        };

        const path = isSelfServe ? `/self-serve/campaigns/${selfServeCampaign._id}/plans/${plan._id}/booking-form` : `/planner/campaigns/${campaign._id}/plans/${plan._id}/booking-form`;

        Http.post(path, bookingForm)
            .then(() => {
                // HACK: artificial delay as it takes some time for the document to be created in Pandadoc
                const interval = setTimeout(() => {
                    setIsLoading(false);
                    toast.success('Booking form was successfully created.');
                    modal.hide();
                    showViewBookingFormsModal({ campaign, plan, selfServeCampaign });
                    clearInterval(interval);
                }, 3000);
            })
            .catch(err => {
                setErrorMessage(err.message || 'Could not create booking form.');
                toast.error('Failed to create booking form.');
                setIsLoading(false);
            });
    };

    const handleTemplateChanged = (selectedOption, formBag) => {
        const { values, setValues } = formBag;

        // Create new form state from static fields (fields not specific to any template) and newly selected template value
        const newValues = {
            ..._.pick(values, _.keys(initialValues)),
            template: selectedOption.value,
        };

        // Add defaults for dynamic fields so form validation messaging shows when submitting a form with dynamic fields left blank
        const selectedTemplate = config.pandaDoc.templates.find((template) => template.pandaDocId === selectedOption.value);

        if (!_.isEmpty(selectedTemplate.customFields)) {
            const initialCustomValues = {};

            selectedTemplate.customFields.forEach((customField) => {
                initialCustomValues[customField.value] = '';
            });

            newValues.customFields = initialCustomValues;
        }

        if (!_.isEmpty(selectedTemplate.recipientRoles)) {
            selectedTemplate.recipientRoles.forEach((role) => {
                newValues[role.value] = '';
            });
        }

        setValues(newValues);
    };

    const validateBookingForm = (values) => {
        const { activityIds, customFields = {}, itemIds, template } = values;

        const errors = {};

        // General validation
        if (!activityIds.length && !itemIds.length) {
            errors.activityIds = `At least one activity${itemOptions.length ? ' or item ' : ' '}is required.`;
            errors.itemIds = `At least one item${activityOptions.length ? ' or activity ' : ' '}is required.`;
        }

        if (!template) {
            errors.template = 'Template is required.';
        }

        const selectedTemplate = config.pandaDoc.templates.find((template) => template.pandaDocId === values.template);

        if (!selectedTemplate) {
            return errors;
        }

        // Template-specific validation
        if (!_.isEmpty(selectedTemplate.customFields)) {
            selectedTemplate.customFields.forEach((customField) => {
                if (customFields[customField.value] || customField.isOptional) {
                    return;
                }

                // Create the nested customFields object if it does not already exist
                if (!errors.customFields) {
                    errors.customFields = {};
                }

                errors.customFields[customField.value] = `${customField.label} is required`;
            });
        }

        if (!_.isEmpty(selectedTemplate.recipientRoles)) {
            selectedTemplate.recipientRoles.forEach((role) => {
                if (!values[role.value]) {
                    errors[role.value] = `${role.label} is required.`;
                }
            });
        }

        return errors;
    };

    const renderCustomFields = (selectedTemplateId) => {
        const selectedTemplate = config.pandaDoc.templates.find((template) => template.pandaDocId === selectedTemplateId);

        if (_.isEmpty(selectedTemplate.customFields)) {
            return null;
        }

        return selectedTemplate.customFields.map((customField) => {
            switch (customField.type) {
            case 'select': return (
                <Form.Select
                    key={customField.value}
                    label={customField.label}
                    name={`customFields.${customField.value}`}
                    options={customField.options}
                    required={!customField.isOptional}
                />
            );
            default: return (
                <Form.Input
                    key={customField.value}
                    label={customField.label}
                    name={`customFields.${customField.value}`}
                    required={!customField.isOptional}
                />
            );
            }
        });
    };

    const renderRecipientFields = (selectedTemplateId) => {
        const selectedTemplate = config.pandaDoc.templates.find((template) => template.pandaDocId === selectedTemplateId);

        if (_.isEmpty(selectedTemplate.recipientRoles)) {
            return null;
        }

        return (<>
            <h3 className="mt-3 mb-2">Recipients</h3>
            {selectedTemplate.recipientRoles.map((role) => (
                <Form.Input
                    key={role.value}
                    label={role.label}
                    name={role.value}
                    required
                />
            ))}
        </>);
    };

    return (
        <Modal.Body>
            {isLoading && <Loading />}
            {errorMessage && <div className="errorMsg">{errorMessage}</div> }
            {!isLoading && (<>
                <div className="like-link mb-3" onClick={handleBackClicked}>&lt; Back</div>
                <Form initialValues={initialValues} labelSubmit="Generate booking form" onSubmit={handleSave} validate={validateBookingForm}>
                    {(formBag) => (<>
                        {!_.isEmpty(activityOptions) && (
                            <Form.MultiSelect
                                label="Activities"
                                name="activityIds"
                                options={activityOptions}
                            />
                        )}
                        {!_.isEmpty(itemOptions) && (
                            <Form.MultiSelect
                                label="Other items"
                                name="itemIds"
                                options={itemOptions}
                            />
                        )}
                        <Form.Select
                            label="Template"
                            name="template"
                            onChange={(selectedOption) => handleTemplateChanged(selectedOption, formBag)}
                            options={templateOptions}
                            required
                        />
                        {formBag.values.template && (<>
                            {renderCustomFields(formBag.values.template)}
                            {renderRecipientFields(formBag.values.template)}
                        </>)}
                    </>)}
                </Form>
            </>)}
        </Modal.Body>
    );
};

export default CreateBookingFormModal;
