import { Carousel, Icon, Modal, RichTextContent, useLocalStorage } from '../';

const PopUpModal = (props) => {
    const { modal, popUps } = props;
    const [viewedPopUpIds, setViewedPopUpIds] = useLocalStorage('popUps.viewed', []);

    const isSinglePopup = popUps?.length === 1;

    const handleClose = () => {
        const popupIds = popUps.map((popup) => popup._id);
        const unViewedPopupIds = popupIds.filter((popupId) => !viewedPopUpIds.includes(popupId));

        setViewedPopUpIds([...viewedPopUpIds, ...unViewedPopupIds]);

        modal.hide()
    };

    return <Carousel
        images={popUps.map((popUp) => (<Modal.Body key={popUp._id}>
            <div className="stack-even">
                <h2 className={isSinglePopup ? '' : 'ml-5'}>{popUp.headline}</h2>
                <Icon
                    className="like-link subtle"
                    name="close"
                    onClick={handleClose}
                />
            </div>
            <RichTextContent className={`mt-3 ${isSinglePopup ? '' : 'mx-5'}`} text={popUp.body} />
        </Modal.Body>))}
    />;
};

export default PopUpModal;
