import * as yup from 'yup';

import { findIndex } from 'lodash';
import immer from 'immer';
import { config } from 'smg-common';

import { Http, Form, Modal, toast } from '../';

const EditAttachmentModal = (props) => {
    const { attachment, modal, setStudy, study } = props;

    const documentTypeOptions = config.studies?.documentTypes;
    const initialValues = attachment ? attachment : {
        documentType: null,
        file: null,
        uploadedAt: null,
    };

    const handleSubmit = (values) => {
        const newStudy = immer(study, (draft) => {
            if (attachment?._id) {
                const index = findIndex(draft.attachments, { _id: attachment._id });
                draft.attachments[index] = values;
            } else {
                draft.attachments = draft.attachments || [];
                draft.attachments.push(values);
            }
        });

        Http.post(`/evaluations/studies/${study._id}/attachments`, newStudy)
            .then((updatedStudy) => {
                toast.success('Attachment successfully saved.');
                setStudy({ ...study, attachments: updatedStudy.attachments });
                modal.hide();
            })
            .catch(error => {
                toast.error('Attachment failed to save. Please try again.');
                console.error(error);
            });
    };

    const validationSchema = yup.object().shape({
        documentType: yup.string().nullable().required('Document type is required.'),
        file: yup.object().shape({
            _id: yup.string().nullable().required('File is required.'),
        }),
    });

    return (
        <Modal.Body>
            <Form initialValues={initialValues} onSubmit={handleSubmit} onCancel={modal.hide} validationSchema={validationSchema}>
                {() => (<>
                    <Form.Select
                        label="Document type"
                        name="documentType"
                        options={documentTypeOptions}
                        required
                    />
                    <Form.Dropzone label="Drop file here." multi={false} name="file" />
                </>)}
            </Form>
        </Modal.Body>
    );
};

export default EditAttachmentModal;
