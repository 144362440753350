import { findIndex, isFunction, map } from 'lodash';

import css from './ProgressArrow.module.scss';

const ProgressArrow = (props) => {
    const { className, color, onChange, options, current, ...restProps } = props;

    // Highlights all options preceding current option
    const currentIndex = findIndex(options, { value: current });

    const handleOnClick = (newValue) => onChange(newValue);

    const classes = [
        css.progressArrows,
        'my-2',
    ];
    if (color && css[color]) classes.push(css[color]);
    if (className) classes.push(className);

    return (<ol className={classes.join(' ').trim()} {...restProps}>
        { map(options, (option, index) => {
            const key = option.value || `option-${index}`;

            const itemClasses = [];
            if (isFunction(onChange)) itemClasses.push(css.selectable);
            if (index <= currentIndex) itemClasses.push(css.current);
            if (option.color && css[option.color]) itemClasses.push(css[option.color]);

            return isFunction(onChange)
                // Current value can be changed on click
                ? <li
                    className={itemClasses.join(' ').trim()}
                    key={key}
                    onClick={() => handleOnClick(option.value)}
                >
                    {option.label}
                </li>
                // Read only progress arrows
                : <li
                    className={index <= currentIndex ? css.current : ''}
                    key={key}
                >
                    {option.label}
                </li>
        })}
    </ol>);
};

export default ProgressArrow;
