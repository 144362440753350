import { formatters } from 'smg-common';

const AsPercentage = (props) => {
    const {
        decimals,
        na,
        value,
    } = props;

    return formatters.asPercentage(value, { decimals, na });
}

export default AsPercentage;
