import { useState } from 'react';
import { filter, flow, get, map } from 'lodash';

import { Highcharts, ReactSelect } from '../../Components';

import css from './TouchpointChart.module.scss';

const TouchpointChart = (props) => {
    const { benchmarkData, themeGrey, title } = props;

    const contextOptions = [{
        value: 'upliftPercent',
        label: 'Uplift %',
        format: '{point.y:.1f}%',
    }, {
        value: 'upliftPoundPerStore',
        label: 'Uplift £ per store',
        format: '£{point.y:.0f}',
    }, {
        value: 'roi',
        label: 'ROI',
        format: '£{point.y:.2f}',
    }];

    const [selectedContext, setSelectedContext] = useState(contextOptions[0]);

    const levels = [{
        value: 'featuredSku',
        label: 'Featured SKU',
    }, {
        value: 'brand',
        label: 'Brand',
    }, {
        value: 'category',
        label: 'Category',
    }];

    const series = flow(
        (levels) => filter(levels, (level) => {
            return get(benchmarkData, `${level.value}.${selectedContext.value}.value`);
        }),
        (levels) => map(levels, (level) => {
            return {
                name: level.label,
                data: [get(benchmarkData, `${level.value}.${selectedContext.value}.value`)],
            }
        })
    )(levels);

    const chartOptions = {
        chart: { type: 'column' },
        title: { text: undefined },
        xAxis: {
            labels: { enabled: false },
        },
        yAxis: {
            title: { text: selectedContext.label },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: selectedContext.format,
                },
            }
        },
        tooltip: { enabled: false },
        exporting: { enabled: true },
        series: series,
    };

    // If TouchpointChart is placed on a grey background, we need to set Highcharts backgroundColor to null. This will make it transparent.
    if (themeGrey) {
        chartOptions.chart.backgroundColor = null;
        chartOptions.exporting.buttons = {contextButton: { theme: { fill: '#f3f3f3' }} };
    }

    return (<div className="stack-col">
        <div className={`${title ? 'stack-even' : 'stack-end'} mb-3`}>
            {title && <h2>{title}</h2>}
            <ReactSelect
                className={`col-4 ${themeGrey ? 'theme-white' : ''}`}
                options={contextOptions}
                onChange={(option) => setSelectedContext(option)}
                value={selectedContext.value}
            />
        </div>
        <div className={css['chart-container']}>
            <Highcharts options={chartOptions} />
        </div>
    </div>);
};

export default TouchpointChart;
