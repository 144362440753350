import { Button, Grid, useAppState } from '../';

import css from './AppsGrid.module.scss';
import { Tooltip } from '../';

const AppsGrid = (props) => {
    const { size = 'large' } = props;
    const [apps] = useAppState('apps');

    const classes = [css.button];

    const getButton = (app) => {
        return (
            <Button
                className={classes.concat(app.degraded ? [css.degraded] : []).join(' ')}
                color="primary"
                linkTo={app.path}
                size={size}
                textAlign="start"
                data-smg-app-link={app.name}
            >
                {app.name}
            </Button>
        );
    };

    const appButtons = apps.map(app => {
        return app.degraded
            ? <Tooltip hover="This app is not included in your subscription. Click to find out more." key={app.path}>
                {getButton(app)}
            </Tooltip>
            : <div key={app.path}>{getButton(app)}</div>
    });

    return (
        <div>
            <Grid columns="2" width="200px">{appButtons}</Grid>
        </div>
    );
};

export default AppsGrid;
