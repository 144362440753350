const audiences = {
    emailGroups: [
        { retailerId: '5988315ba041de3c587492ea', retailerName: 'Boots', emailGroupSlug: 'bmg-bespoke-audience-brief-submitted' },
    ],
    statusOptions: [
        {label: 'Draft', value: 'draft'},
        {label: 'Submitted', value: 'submitted'},
        {label: 'Approved', value: 'approved'},
    ],
    typeOptions: [
        { label: 'Propensity', value: 'propensity' },
        { label: 'Age', value: 'age' },
        { label: 'Gender', value: 'gender' },
        { label: 'Basket size', value: 'basket_size' },
        { label: 'Events', value: 'events' },
        { label: 'Company', value: 'company' },
        { label: 'Brand', value: 'brand' },
        { label: 'Bespoke', value: 'bespoke' },
        { label: 'Category', value: 'category' },
    ],
};

export default audiences;
