const bookingFormsBulkDownloads  = {
    statusOptions: [
        { label: 'Scheduled', value: 'scheduled' },
        { label: 'Running', value: 'running' },
        { label: 'Completed', value: 'completed' },
        { label: 'Failed', value: 'failed' },
    ],
    filterType: [
        { label: 'Live month', value: 'liveMonth' },
        { label: 'POS cycle', value: 'posCycle' },
    ],
};

export default bookingFormsBulkDownloads;
