import { Modal } from 'Components';
import { useState } from 'react';
import { simpleDate } from 'smg-common';

const CampaignsRenamePopUp = () => {
    const [isVisible, setIsVisible] = useState(true);

    // Check if past expiry date for pop up
    const today = simpleDate.today();
    const end = simpleDate.create(9, 7, 2023);
    const expired = simpleDate.isAfter(today, end);

    // Check if user has already seen the pop up
    const hasSeenPopUp = localStorage.getItem('hasSeenCampaignsRenamePopUp') ?? false;

    if (expired || hasSeenPopUp) return null;

    const handleCancel = () => {
        localStorage.setItem('hasSeenCampaignsRenamePopUp', 'true');
        setIsVisible(false);
    };

    return (
        <Modal
            clickOutsideToClose={true}
            title="Planner becomes Campaigns 📢"
            show={isVisible}
            onCancel={handleCancel}
        >
            <Modal.Body>
                <div className="p-5">
                    <img
                        alt="A person using Plan-Apps on a laptop."
                        src="https://plan-apps-assets.s3.eu-west-2.amazonaws.com/planner-rename/campaigns-user.png"
                        width="100%"
                    />
                    <div className="mt-3" style={{ fontSize: '1.2rem' }}>
                            Today you'll notice that our Planner application (that we all know and love) has been renamed to Campaigns.
                        <br/>
                        <br/>
                            We'll be sharing some more updates around what will be launching within Campaigns very soon and how we'll
                            be optimising the workflow from planning through to evaluations, but for now you don't need to worry about
                            any other changes than its name!
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CampaignsRenamePopUp;
