import { get, isBoolean, isEmpty, isNumber, isString, omitBy } from 'lodash';
import { CSSProperties } from 'react';

import { Icon } from '../../Components';

import css from './FilterPills.module.scss';

type FilterValues = { [key: string]: (string | number | null | undefined)[] | null | undefined }; // The selected filter slug to array of values

interface FilterPillsProps {
    className?: string;
    filterNames: {
        slug: string;
        name: string;
    }[];
    filterOptions: { [filterSlug: string]: any }; // filter-slug --> filter-options
    onChange: (value: FilterValues) => void;
    value: FilterValues; // The selected filter slug to array of values
    style?: CSSProperties;
}

// Displays the selected filters as a series of dismissible Pills
const FilterPills = (props: FilterPillsProps) => {
    const {
        className = '',
        filterNames,
        filterOptions,
        onChange,
        value,
        style = {},
    } = props;

    return (
        <div className={className} style={style}>
            {Object.keys(value).map((filterSlug) => {
                const selectedOptions = value[filterSlug];

                // Filter name
                const filter = filterNames.find((f) => f.slug === filterSlug);
                const filterName = filter?.name || 'ERROR';

                // Filter value (label)
                let label;
                if (!selectedOptions || selectedOptions.length === 0) {
                    return null;
                } else if (isBoolean(selectedOptions)) {
                    label = selectedOptions ? 'Yes' : 'No';
                } else if (isString(selectedOptions)) {
                    switch (filterName.toLowerCase()) {
                    case 'owner':
                        label = selectedOptions === 'owner' ? 'My Campaigns' : 'My Company';
                        break;
                    case 'order':
                        label = selectedOptions === 'name' ? 'Name' : 'Date';
                        break;
                    default:
                        const options = filterOptions[filterSlug];
                        const option = options.find((o: any) => o.value === selectedOptions);
                        label = option.label;
                        break;
                    }
                } else if (isNumber(selectedOptions)) {
                    label = selectedOptions.toString()
                } else if (selectedOptions.length === 1) {
                    // Get the filter options for this specific filter
                    const options = get(filterOptions, filterSlug);
                    // Some filter options can have child options. We flatten them all here
                    const flatOptions = options.flatMap((option: any) => option.options ? [option, ...option.options] : option);
                    // Find the selected option and get its label
                    const option = flatOptions.find((o: any) => o.value === selectedOptions[0]);
                    label = option ? option.label : 'ERROR';
                } else {
                    label = `${selectedOptions.length} selected`;
                }

                label = label.length > 15 ? `${label.slice(0, 15).trim()}...` : label;

                return (
                    <span className={css.pill} key={filterSlug}>
                        <span>{filterName}: </span>
                        <strong className={css.short}>{label}</strong>
                        <Icon
                            className={`like-link no-color ml-2 ${css['clear-button']}`}
                            name="close"
                            onClick={() => onChange({ ...value, [filterSlug]: null })}
                        />
                    </span>
                );
            })}
            {!isEmpty(omitBy(value, isEmpty)) && <span className="like-link ml-2" onClick={() => onChange({})}>Clear all</span>}
        </div>
    );
};

export default FilterPills;
