import moment from 'moment';
import { Form, Formik } from 'formik';
import { isFunction } from 'lodash';

import { Button, FDatePickerField, Modal, Submit } from '../..';

const EditEventModal = (props) => {
    const { event, onCancel, onSave } = props;

    const handleSubmit = (values) => {
        if (isFunction(onSave)) {
            onSave(values);
        }
    };

    const handleCancel = () => {
        if (isFunction(onCancel)) {
            onCancel();
        }
    };

    const validate = function(values) {
        const errors = {};

        if (values.endDate && values.startDate && new Date(values.startDate) > new Date(values.endDate)) {
            errors.endDate = 'End date needs to be after start date';
        }

        return errors;
    };

    return (
        <Modal onCancel={handleCancel} show={true} title="Edit event" >
            <Formik initialValues={event} onSubmit={handleSubmit} validate={validate}>
                {({ setFieldValue, values }) => {
                    return (
                        <Form className="p-3">
                            <div className="p-3">
                                <div className="row">
                                    <FDatePickerField
                                        className="col-md"
                                        label="Start date"
                                        name="startDate"
                                        onChange={(startDate) => {
                                            const diff = moment(startDate).diff(moment(values.startDate), 'days');
                                            setFieldValue('startDate', startDate);
                                            setFieldValue('endDate', moment(values.endDate).add(diff, 'days').toDate());
                                        }}
                                    />
                                    <FDatePickerField
                                        className="col-md"
                                        label="End date"
                                        name="endDate"
                                        onChange={(endDate) => {
                                            const diff = moment(endDate).diff(moment(values.endDate), 'days');
                                            setFieldValue('startDate', moment(values.startDate).add(diff, 'days').toDate());
                                            setFieldValue('endDate', endDate);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="stack-end-3 mt-4">
                                <Button onClick={handleCancel}>Cancel</Button>
                                <Submit>Save</Submit>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default EditEventModal;
