const retailers = {
    documentTypes: [
        { label: 'Rate card & toolkit', value: 'rate-card-and-toolkit' },
        { label: 'Briefing forms', value: 'briefing-forms' },
        { label: 'Deadlines, calendars & promo periods', value: 'deadlines-calendars-and-promo-periods' },
        { label: 'Store lists', value: 'store-lists' },
        { label: 'Other', value: 'other' },
    ],
};

export default retailers;
