import StepWizard from 'react-step-wizard';
import WizardNav from './WizardNav';
import { cloneElement } from 'react';
import { isFunction } from 'lodash';

import css from './Wizard.module.scss';

const Wizard = (props) => {
    const { allowClick, children, className, hideNav, steps, ...restProps } = props;

    const nav = hideNav ? null : (<WizardNav className="mb-3" allowClick={allowClick} steps={steps} />);

    const classes = [css.wizard];
    if (className) classes.push(className);

    return (<StepWizard className={classes.join(' ').trim()} isLazyMount nav={nav} {...restProps}>
        {steps && steps.map((step, index) => {
            const key = step._id || `step-${index}`;

            if (step.component) return cloneElement(step.component, {
                ...step.component.props,
                key,
            });
            if (isFunction(step.render)) return (<div className={css.step} key={key}>{step.render(step)}</div>);
            return (<div className={css.step} key={key}></div>);
        })}
        {!steps && children}
    </StepWizard>);
};

export default Wizard;
