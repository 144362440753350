import { toast, useAuth, useFetch } from 'Components'
import Http from 'Components/Http'
import moment from 'moment'

// 2023-05-12 MP: This should be imported from the common type once it's been created
interface ICronjobSchema {
    scriptName: string,
    schedule: string,
    status: 'scheduled' | 'running' | 'failed',
    log?: string,
    ranAt: Date,
    duration: number, // miliseconds
    description?: string,
    enabled?: boolean,
}

interface CronjobStatusProps {
    jobName: string,
    showRecalculateButton: boolean
}

const CronjobStatus = ({ jobName, showRecalculateButton = false }: CronjobStatusProps) => {
    const { me } = useAuth()

    const [cronJob, isLoading, errorMessage, refetch] = useFetch<ICronjobSchema>(() => (
        Http.get(`/cronjobs/${jobName}`)
    ), [jobName])

    const has = (permissionSlug: string) => me.permissions[permissionSlug];

    const runCronjob = (scriptName: CronjobStatusProps['jobName']) => {
        Http.post(`/cronjobs/${scriptName}/run`)
            .then(() => {
                toast.success(`${scriptName} started succesfully.`)
                refetch()
            })
            .catch(error => {
                console.error('Failed to run cronjob', error);
                toast.error(error.message || 'Failed to run cronjob');
            })
    };


    if (isLoading || !cronJob || errorMessage) return
    return(
        <div className="stack">
            <span>
                {cronJob.status === 'running' ?
                    'This report is currently refreshing' :
                    `This report's data was calculated at ${moment(cronJob.ranAt).format('Do MMM YYYY [at] HH[:]mm')}`
                }
            </span>
            {(has('runDevTasks') && cronJob.status !== 'running' && showRecalculateButton) && <span className="like-link ml-4" onClick={() => runCronjob(jobName)}>Recalculate Now</span>}
        </div>
    )
}

export default CronjobStatus