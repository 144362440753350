import { isObject, isString } from 'lodash';
import Observable from '../Observable';

const defaultOptions = {
    content: 'Are you sure?',
    okLabel: 'Yes',
    cancelLabel: 'No',
    okColor: 'primary',
    cancelColor: 'default',
    rejectOnClose: false, // When true, the promise gets rejected if the user clicks "Cancel"
};

export const dialogObservable = new Observable();

const dialog = (content, options) => {
    return new Promise(function(resolve, reject) {
        dialogObservable.notify({
            ...defaultOptions,
            ...options,
            content,
            resolve,
            reject,
        });
    });
}

dialog.alert = (content) => dialog(content, { okLabel: undefined, cancelLabel: 'OK' });
dialog.confirm = (content, defaultOptions = {}) => {
    const options = {
        okColor: 'danger',
        okLabel: 'OK',
        cancelColor: 'default',
        cancelLabel: 'No',
    };

    if (isString(defaultOptions)) {
        options.okLabel = defaultOptions;
    } else if (isObject(defaultOptions)) {
        Object.assign(options, defaultOptions);
    }

    return dialog(content, options);
};

export default dialog;
