import { isFinite, padEnd } from 'lodash';
import numeral from 'numeral';

const Currency = (props) => {
    const { fixedDecimals, number, invalid = '-', symbol = '' } = props;

    let formatStr = '0,0[.]00';
    if (isFinite(fixedDecimals)) {
        formatStr = fixedDecimals === 0 ? '0,0' : '0.' + padEnd('', fixedDecimals, '0')
    }

    if (isFinite(number)) {
        // Show negative sign before symbol
        const isNegative = +Number(+number).toFixed(2) < 0;
        return isNegative ? '-' + symbol + numeral(Number(Math.abs(number)).toLocaleString()).format(formatStr) : symbol + numeral(Number(number).toLocaleString()).format(formatStr);
    } else {
        return invalid;
    }
}

export default Currency;
