import { Editor } from '@tinymce/tinymce-react';
import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';
import { Loading } from '../../Components';

import css from './RichTextareaField.module.scss';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

const RichTextareaField = (props) => {
    const {
        className,
        errorMsg,
        helpMsg,
        label,
        maxSizeWarning = undefined, // Number. When the value has more characters than this number, we show a "text-too-large" warning
        onChange: onEditorChange,
        required,
        successMsg,
        style,
        value: initialValue,
        ...restProps
    } = props;

    const [loaded, setLoaded] = useState(false)

    /* Wait until component mounts to load TinyMCE. TinyMCE is huge (~1 MB), so by waiting until the
    component mounts to load it, we reduce the size of the main JS bundle throughout the application
    and improve initial page loading times. */
    useEffect(() => {
        const doImports = async () => {
            // Import TinyMCE so the global var exists
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/tinymce')

            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/models/dom/model')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/themes/silver')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/icons/default')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/skins/ui/oxide/skin.min.css')

            // Plugins
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/plugins/advlist')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/plugins/autolink')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/plugins/image')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/plugins/link')
            await import(/* webpackChunkName: 'tinymce' */ 'tinymce/plugins/lists')
        }

        doImports().then(() => setLoaded(true))
    })

    // TinyMCE needs the state managed otherwise the cursor jumps with every rerender
    const [value, setValue] = useState(initialValue);

    // PFX-5321 Some users, mostly admins, have the bad habit of uploading huge images in base64 format. This causes
    // an increased size in bytes of the value and causes other pages of Plan-Apps to become very slow.
    // Here we add a warning to the user when the size of the value exceeds some limits.
    let effectiveHelpMsg = helpMsg;
    const showSizeWarning = maxSizeWarning && value && value.length > maxSizeWarning;
    if (showSizeWarning) {
        effectiveHelpMsg = (
            <div>
                {helpMsg && (<div>{helpMsg}</div>)} {/* Keep the original helpMsg */}
                <div className="color-warn">
                    This text is so large that it could cause Plan-Apps to become slow. If you have copy/pasted images or videos in this text
                    please consider hosting them outside Plan-Apps and adding only a link to that resource. If you need help, please contact
                    the Plan-Apps team.
                </div>
            </div>
        );
    }

    const options = {
        menubar: false,
        plugins: ['advlist', 'autolink', 'link', 'lists', 'image'],
        selector: '',
        statusbar: false,
        toolbar: 'styles bold italic underline strikethrough blockquote | bullist numlist outdent indent | link image | removeformat',
    };

    const init = {
        skin: false, // Stops TinyMCE from trying to download static css
        content_css: false, // Stops TinyMCE from trying to download static css
        content_style: [contentCss, contentUiCss || ''].join('\n'),
        height: '200px',
        ...options,
    };

    const handleChange = (newValue) => {
        setValue(newValue);

        // If the user uses this as an unmanaged component, we need to bubble up the onEditorChange event
        if (isFunction(onEditorChange)) {
            onEditorChange(newValue);
        }
    };

    if (!loaded) return <Loading/>;
    return (
        <FormGroup
            className={`${css.editor} ${className}`.trim()}
            errorMsg={errorMsg}
            helpMsg={effectiveHelpMsg}
            label={label}
            required={required}
            successMsg={successMsg}
            style={style}
        >
            <Editor
                init={init}
                onEditorChange={handleChange}
                value={value}
                {...restProps}
            />
        </FormGroup>
    );
};

const FRichTextareaField = AsFormik(RichTextareaField, {
    extractValue: (value) => value,
});

export {
    RichTextareaField,
    FRichTextareaField,
};
