import { RichErrorPage } from './';

const PageNotFound = () => {
    const message = (<span>The page you requested does not exist.<br />Please check that the link you followed is correct.</span>);

    return (<RichErrorPage
        message={message}
        status="404"
        title="Page not found"
    />);
};

export default PageNotFound;
