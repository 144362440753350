import { HelpModal } from '../';

const AdditionalCodingHelpModal = (props) => {
    const { field } = props;

    const renderList = (listItems) => {
        return <ul>
            {listItems.map((item, index) => {
                return <li key={index}><span className="text-bold">{item.title}:</span>&nbsp;{item.description}</li>
            })}
        </ul>
    };

    const contents = [
        {
            field: 'isFeaturedCompetition',
            title: 'Competition',
            description: renderList([
                {title: 'Does this campaign feature a competition?', description: 'A contest, which is being featured on the media creative, in which shoppers can enter to win a prize.'},
                {title: 'Competition prize', description: 'What the competition prize is.'},
                {title: 'Entry mechanics', description: 'How shoppers can enter the competition.'},
                {title: 'On-Pack', description: 'When the competition is featured on the packaging itself.'},
            ]),
        },
        {
            field: 'isNpd',
            title: 'NPD',
            description: renderList([
                {title: 'Is this campaign for an NPD?', description: 'A new product that has less than 12 weeks of pre period sales.'},
                {
                    title: 'NPD type',
                    description: renderList([
                        {title: 'Range extension', description: 'a new product added to an existing range (e.g. a new flavour).'},
                        {title: 'New range', description: 'a new range of products added to a brand\'s existing portfolio (e.g. a crisp brand creating a new range of spicy crisps).'},
                        {title: 'True innovation', description: 'a totally new product to the brand\'s existing portfolio (e.g. a crisp brand launching crackers as a new product).'},
                    ]),
                },
            ]),
        },
        {
            field: 'newFlash',
            title: 'NEW message',
            description: 'The media creative is stating that the product is \'new\'.',
        },
        {
            field: 'isRetailerEvent',
            title: 'Retailer event',
            description: 'By \'event\' we mean a retailer event, where the brand has activated media as part of a retailer event rather than going solus.',
        },
        {
            field: 'touchpointCreative',
            title: 'Touchpoint creative',
            description: 'Refers to whether the brand has utilised a retailer template for their media, or used their own branding, as well as whether there is a price messaging on the media creative or not.',
        },
        {
            field: 'isOnPromotion',
            title: 'Promotion',
            description: renderList([
                {title: 'Is this campaign aligned to a promotion?', description: 'Use the time series to check whether the Featured SKU was on promotion during the live period.'},
                {title: 'Promotion discount', description: 'The % discount from the Featured SKU being off promotion to on promotion. If unknown set as -1.'},
            ]),
        },
        {
            field: 'brandPartnership',
            title: 'Brand partnership',
            description: <><p>A brand partnership is where two brands have come together in the same retailer to collaborate on a campaign and share media space.</p><p>For example: a drinks brand partnering with a snacks brand for a 'Friday night in' campaign.</p><p>Note that a competition is not a brand partnership.</p></>,
        },
        {
            field: 'hasOtherMessaging',
            title: 'Other messaging',
            description: <><p>Determines what other messaging is on the creative.</p><p>For example: a serving suggestion is an image demonstrating how to use the product, such as wine in a glass.</p></>,
        },
        {
            field: 'storeFormat',
            title: 'Store format',
            description: 'Refers to whether the activity went live in main estate stores (e.g. superstore/supermarket) or convenience stores (e.g. local/express).',
        },
        {
            field: 'numberFeaturedSku',
            title: 'No. of FSKU(s)',
            description: 'The number of SKUs that are being featured on the media creative.',
        },
    ];

    const content = contents.find((content) => content.field === field);

    if (!content) {
        return null;
    }

    return (<HelpModal title={content.title}>
        {content.description}
    </HelpModal>);
};

export default AdditionalCodingHelpModal;
