import { get, xor } from 'lodash-es';

// Checks if the touchpoints in the study exactly match the touchpointIds.
export const verifyExactTouchpointMatch = (study, touchpointIds) => {
    const checkMultipleTouchpoints = (touchpointIds, study) => {
        const symmetricDifference = xor(
            touchpointIds?.map((touchpointId) => String(touchpointId)),
            study.touchpoints?.map((touchpoint) => String(touchpoint._id)),
        );
        return symmetricDifference.length === 0;
    };
    const checkSingleTouchpoint = (touchpointIds, study) => String(touchpointIds[0]) === String(study.touchpoints[0]._id);

    if (touchpointIds === null || touchpointIds === undefined) {
        return false;
    } else if (touchpointIds.length === 0 || study.touchpoints.length === 0) {
        return false;
    } else if (touchpointIds.length !== study.touchpoints.length) {
        return false;
    } else if (touchpointIds.length === 1 && study.touchpoints.length === 1) {
        return checkSingleTouchpoint(touchpointIds, study);
    } else if (touchpointIds.length > 1 && study.touchpoints.length > 1) {
        return checkMultipleTouchpoints(touchpointIds, study);
    }
    return false;
};

const aggregateStudies = {
    // Media benchmark for a single touchpoint or multiple touchpoints
    // Ex: Media, View study page (Evals) index pills / category pills, Export plan as pptx index pills
    touchpointFilter: (study, touchpointIds, rCategoryId) => {
        return study.status === 'aggregated'
            && !study.neverAggregate
            /* If touchpointIds is specified, verify that the touchpoints in the study exactly match the touchpointIds. 
            If touchpointIds is not specified, we should only consider studies with a single touchpoint. touchpointIds is optional, but 
            the only place we don't use it is calculateTouchpointChooserBenchmarks, where only single touchpoint studies should be 
            considered to provide the most accurate estimate of performance for the individual touchpoint. */
            && (touchpointIds ? verifyExactTouchpointMatch(study, touchpointIds) : study.numberOfTouchpoints === 1)
            && (rCategoryId ? String(rCategoryId) === String(study.rCategory._id) : true);
    },
    // Media benchmark for retailer specific touchpoints
    // Ex: Select touchpoint page (Campaigns), "benchmark brand % uplift"
    touchpointsFilter: (study, retailerId) => {
        return study.numberOfTouchpoints === 1
            && study.status === 'aggregated'
            && !study.neverAggregate
            && get(study, 'retailer._id') === String(retailerId);
    },
    // Trends page
    trendsFilter: (study, shopperJourneyGroup, retailerId, xCategoryId, mediaChannelId) => {
        return study.numberOfTouchpoints === 1
            && study.status === 'aggregated'
            && !study.neverAggregate
            && (!shopperJourneyGroup || get(study, 'touchpoints[0].shopperJourneyGroup') === shopperJourneyGroup)
            && (!retailerId || get(study, 'retailer._id') === retailerId)
            && (!xCategoryId || get(study, 'parentCategory._id') === xCategoryId || get(study, 'category._id') === xCategoryId)
            && (!mediaChannelId || get(study, 'touchpoints[0].mediaChannel._id') === mediaChannelId);
    }
};

export default aggregateStudies;
