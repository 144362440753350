import { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren, ReactNode, useState } from 'react';
import { isArray, sortBy, uniqBy } from 'lodash';
import { GroupBase, MenuListProps, components as RS } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import type { Option } from 'smg-common';

import { ReactSelect } from './ReactSelect';
import AsFormik from './AsFormik';

interface SmartSelectOption extends Option {
    [k: string]: any,
}

const SmartMenuListHeader = ({ children, ...props }: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    return (
        <button
            style={{
                padding: '8px 12px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                boxSizing: 'border-box',
                borderBottom: '1px solid black'
            }}
            {...props}
        >
            {children}
        </button>
    )
}

interface SmartMenuListProps extends MenuListProps<SmartSelectOption> {
    header?: ReactNode,
    footer?: ReactNode,
}

const SmartMenuList = ({ header, footer, children, ...props }: PropsWithChildren<SmartMenuListProps>) => {
    return (
        <RS.MenuList {...props}>
            {header}
            {children}
            {footer}
        </RS.MenuList>
    )
}

interface SmartSelectProps extends StateManagerProps<SmartSelectOption> {
    marker: Date | number,
    style?: Partial<CSSStyleDeclaration>,
    value?: any,
    onChange?: (value: any) => void,
    spread?: number, // number of results to display, split before and after the marker
    customComparator?: keyof SmartSelectOption, // Use when we want to compare on another field that isn't value
}

const filterOptionsBySurrounding = (
    marker: SmartSelectProps['marker'],
    options: SmartSelectProps['options'],
    spread: SmartSelectProps['spread'],
    customComparator: SmartSelectProps['customComparator'],
    currentValue: SmartSelectProps['value']
) => {
    // Flatten grouped options so they can be searched on
    const flatOptions = options?.flatMap<SmartSelectOption>(o => Object.prototype.hasOwnProperty.call(o, 'options') ? (o as GroupBase<SmartSelectOption>).options : o as SmartSelectOption)
    const selectedOption = flatOptions?.find(o => o.value === currentValue)

    // Sorts dates or numbers based on distance from the marker in an greaterThan:lessThan fashion
    // For example, for array of integers [a, b, C, d, e] where C is the marker
    // resulting array would be [C, b, d, a, e]
    // C comes first as it's distance is 0, then it's closest neighbour greaterThan, then lessThan
    // this then repeats for all values until end of array
    const surroundingValues = Array.from(flatOptions ?? []).sort((a, b) => {
        let aVal, bVal;

        if (customComparator && a[customComparator] && b[customComparator]) {
            aVal = a[customComparator] // must be number or date, but haven't added type guards for brevity
            bVal = b[customComparator] // must be number or date, but haven't added type guards for brevity
        } else if (typeof a.value === 'number' && typeof b.value === 'number') {
            aVal = a.value
            bVal = b.value
        } else if (a.value && b.value && a.value instanceof Date && b.value instanceof Date) {
            aVal = a.value
            bVal = b.value
        } else {
            aVal = null
            bVal = null
        }

        if (!aVal || !bVal) return -1;
        return Math.abs(marker.valueOf() - aVal.valueOf()) - Math.abs(marker.valueOf() - bVal.valueOf())
    })

    // Pick the top N surrounding values, then sort them by value
    const sortedOptions = sortBy(surroundingValues.slice(0, spread), customComparator ?? 'value')

    const selectedOptionWrapped = selectedOption ? [selectedOption] : []

    // Ensure currently selected option is always visible
    return uniqBy([...selectedOptionWrapped, ...sortedOptions], 'value')
}

const SmartSelect = ({ marker, spread = 6, options, customComparator, value, ...props }: SmartSelectProps) => {
    const [isFiltered, setIsFiltered] = useState(true)

    const filteredOptions = filterOptionsBySurrounding(marker, options, spread, customComparator, value)

    return (
        <ReactSelect
            components={{
                MenuList: ({ ...menuProps }: MenuListProps<SmartSelectOption>) => (
                    <SmartMenuList
                        header={
                            options &&
                            options.length > 0 &&
                            filteredOptions.length < options.flatMap(o => o.options).length &&
                            <SmartMenuListHeader
                                onClick={() => setIsFiltered(!isFiltered)}
                            >
                                {`Show ${isFiltered ? 'more' : 'less'}...`}
                            </SmartMenuListHeader>
                        }
                        {...menuProps}
                    />
                ),
            }}
            options={isFiltered ? filteredOptions : options}
            onInputChange={(v: string) => v.length ? setIsFiltered(false) : setIsFiltered(true)} // When searching we want to use the full options list, not the filtered one
            value={value}
            {...props}
        />
    )
}

const FSmartSelect = AsFormik(SmartSelect, {
    extractValue: (newValue: any) => {
        if (isArray(newValue)) {
            return newValue.map(option => option.value)
        } else {
            return newValue ? newValue.value : null;
        }
    }
});

export {
    SmartSelect,
    FSmartSelect,
}