import { Link } from 'react-router-dom';
import { useState } from 'react';

import { config } from 'smg-common';
import AppSwitcher from './AppSwitcher';
import ChangePasswordModal from './ChangePasswordModal';
// import Coins from './Coins/Coins';
import {
    Button,
    EasterEggCount,
    Feedback,
    Http,
    Icon,
    SmgLogo,
    UserAvatar,
    toast,
    useAuth,
    EasterEgg,
    Dropdown,
} from '..';

import css from './Header.module.scss';

const Header = (props) => {
    const { popUpButton, title} = props;
    const { logout, me } = useAuth();

    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

    const hasCompany = me?.company && !me?.requiresCompanyAssignment;

    const exit = () => {
        Http.post('/users/exit-impersonate')
            .then(() => window.location.reload())
            .catch(() => {
                toast.error('Could not exit impersonation mode');
            })
    };

    let userInfo, userTrigger;
    if (me) {
        userInfo = (<div className="stack-col-4 stack-stretch-items p-3">
            <div className="stack-col-2">
                <h4>{`${me.firstName} ${me.lastName}`.trim()}</h4>
                {hasCompany &&
                    <div className="stack-col-2">
                        <Link className={css['menu-link']} to={'/accounts/profile'}>Account settings</Link>
                    </div>
                }
                <EasterEgg eggId="6" style={{ float: 'right' }} />
            </div>

            {me.company && me.permissions.manageMyTeam && (
                <div className="stack-col-2">
                    <h4>{me.company.name}</h4>
                    <div className="stack-col-2">
                        <Link className={css['menu-link']} to={`/company/${me.company._id}/users`}>Contacts</Link>
                    </div>
                </div>
            )}

            <span className="stack-even-center-3">
                <Button onClick={() => setIsPasswordModalOpen(!isPasswordModalOpen)}>RESET PASSWORD</Button>
                {!me.impersonating && (<Button color="primary" onClick={() => logout()}>LOG OUT</Button>)}
                {me.impersonating && (<Button color="primary" onClick={() => exit()}>EXIT</Button>)}
            </span>
        </div>);
        userTrigger = (<UserAvatar clickable={true} user={me} />);
    }

    return (<div className="stack-even-center stack-stretch-items">
        <span className="stack-start-center-3">
            {hasCompany && <AppSwitcher />}
            <h1>{title}</h1>
        </span>
        <Link className="text-center" to="/">
            <SmgLogo height="small" />
        </Link>
        <span className="stack-end-center-3">
            {popUpButton}
            {/* PFX-5741 Hide vaultcoins for easter egg hunt */}
            {/* {me && hasCompany && <Coins coins={me.vaultcoins} />} */}
            {me && <EasterEggCount />}
            {me?.roles?.some(r => r.name === 'Guest') && (
                <a className="like-link no-color" href={config.upgradeUrl} rel="noreferrer noopener" target="_blank">
                    Upgrade
                </a>
            )}
            {me && <Feedback />}
            <a className="stack-start-center-1 like-link no-color" href="https://plan-apps.getlearnworlds.com/" rel="noreferrer" target="_blank">
                <Icon name="question_mark" />
            </a>
            {me && <Dropdown trigger={userTrigger} alignOffset={16}>{userInfo}</Dropdown>}
        </span>
        <ChangePasswordModal isOpen={isPasswordModalOpen} onCancel={() => setIsPasswordModalOpen(!isPasswordModalOpen)} />
    </div>);
}

export default Header;
