import { useState } from 'react';
import { Checkbox, GlossaryTerm } from 'Components';
import { insightMetricOptions } from './helperFunctions';
import { TtdInsightMetricOption } from './InFlight.types';
import { TtdNodeInsights } from 'Types/TtdCampaignInsights';

import css from './OverviewTable.module.scss';

interface TtdOverviewTableProps {
    data: TtdNodeInsights[],
}

const TtdOverviewTable = ({ data }: TtdOverviewTableProps) => {
    const [areAdsVisible, setAreAdsVisible] = useState<boolean>(false);
    
    function buildRow (node: TtdNodeInsights) {
        if (!areAdsVisible && node.level === 'creative') {            
            return null;
        }

        // Use common formats for numbers to ensure consistency between chart and table
        const formatInsightMetric = (node: TtdNodeInsights, field: TtdInsightMetricOption['value']) =>{
            const metric = node.totalInsightsMetrics && node.totalInsightsMetrics[field]
            const option = insightMetricOptions.find((o) => o.value === field)

            if (!metric || !option) return '-'

            return option.formatter(metric)
        };
        
        const className = node.level === 'campaign'
            ? 'campaign'
            : node.level === 'adgroup'
                ? 'adset'
                : node.level === 'creative'
                    ? 'ad'
                    : undefined;

        return (
            <tr key={`${node.ttdId}-${node.parentId}`}>
                <td className={css[`${className}Row`]}>{node.level === 'campaign' ? 'Campaign total' : node.name}</td>
                <td className={`text-right ${css[`${className}Row`]}`}>{formatInsightMetric(node, 'impressions')}</td>
                <td className={`text-right ${css[`${className}Row`]}`}>{formatInsightMetric(node, 'cost')}</td>
                <td className={`text-right ${css[`${className}Row`]}`}>{formatInsightMetric(node, 'cpm')}</td>
            </tr>
        )
    }

    return (
        <div className="box-raised p-3 my-3">
            <div className="stack-even">
                <h2>Performance analysis</h2>
                <div>
                    <Checkbox
                        checked={areAdsVisible}
                        label="Display individual creatives"
                        onChange={() => setAreAdsVisible(!areAdsVisible)}
                    />
                </div>
            </div>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="text-right"><GlossaryTerm slug="impressions">Impressions</GlossaryTerm></th>
                            <th className="text-right">Spend</th>
                            <th className="text-right"><GlossaryTerm slug="cpm">CPM</GlossaryTerm></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((node) => buildRow(node))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TtdOverviewTable;