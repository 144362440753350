import { Form, Formik } from 'formik';
import { get, size } from 'lodash';
import * as yup from 'yup';

import { FTextareaField, Http, Submit, useUrl6 } from '..';

const validationSchema = yup.object().shape({
    text: yup.string().trim().required('Missing text.')
});

const AddComment = (props) => {
    const { onCreate } = props;
    const { queryParams } = useUrl6();

    const handleCreateComment = (values, formikBag) => {
        Http.post('/comments', values)
            .then((savedComment) => {
                if (typeof onCreate === 'function') {
                    onCreate(savedComment);
                }
                formikBag.resetForm({ text: '' });
            })
            .catch(error => {
                console.error(error);
                formikBag.setSubmitting(false);
                formikBag.setStatus({ errorMessage: 'Failed to save comment.' });
            });
    }

    return (
        <div className="mb-5">
            <Formik initialValues={{ text: '' }} onSubmit={handleCreateComment} validationSchema={validationSchema}>
                {({ values, status }) => {
                    const errorMessage = get(status, 'errorMessage');
                    return (
                        <Form>
                            { errorMessage && <div className="errorMsg">{errorMessage}</div> }
                            <FTextareaField
                                helpMsg={`${200-size(values.text)} characters`}
                                maxLength="200"
                                name="text"
                                placeholder="Add a comment..."
                                rows="6"
                                autoFocus={queryParams.scrollTo === 'comments'}
                            />
                            <div className="btn-container-right">
                                <Submit>Save</Submit>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default AddComment;
