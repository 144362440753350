import { formatters } from 'smg-common';

const AsNumber = (props) => {
    // GOTCHA: if you pass `null` in any of the options, it will override the default
    // value, whereas `undefined` will result in the default value being used
    const {
        decimals,
        humanise,
        na,
        value,
    } = props;

    return formatters.asNumber(value, { decimals, humanise, na });
}

export default AsNumber;
