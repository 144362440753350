import { includes, isFunction, without } from 'lodash';

import AsFormik from './AsFormik';
import Icon from '../Icon/Icon';

import css from './FormGroup.module.scss';

const ArrayCheckbox = (props) => {
    const {
        // Value management
        value, // An array of selected values
        valueKey, // The key of this specific checkbox
        onChange,

        // Display
        disabled,
        errorMsg,
        helpMsg,
        label,
        required,
        successMsg,

        // Rest
        ...restProps
    } = props;

    const checked = includes(value, valueKey);
    const iconName = checked ? 'check_box' : 'check_box_outline_blank';

    const handleClick = () => {
        if (!isFunction(onChange) || disabled) {
            return false;
        } else if (checked) {
            onChange(without(value, valueKey));
        } else {
            onChange([...value, valueKey]);
        }
    }

    return (
        <div {...restProps}>
            <div>
                <label className={disabled ? css.labelGrey : css.label} onClick={handleClick}>
                    <Icon
                        className={`like-link ${props.disabled ? css['checkbox-disabled'] : css.checkbox}`}
                        name={iconName}
                    />
                    {label}
                    {required && (<span className={css.required}>*</span>)}
                </label>

            </div>
            {errorMsg && (<div className={css.errorMsg}>{errorMsg}</div>)}
            {helpMsg && (<div className={css.helpMsg}>{helpMsg}</div>)}
            {successMsg && (<div className={css.successMsg}>{successMsg}</div>)}
        </div>
    );
};

const FArrayCheckbox = AsFormik(ArrayCheckbox, {
    extractValue: value => value,
});

export {
    ArrayCheckbox,
    FArrayCheckbox
};
