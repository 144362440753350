import css from './Layout/Layout.module.scss';

const environment = process.env.REACT_APP_ENV;

const EnvironmentBanner = () => {
    const developClasses = [css['environment-banner'], css['develop']];
    const stagingClasses = [css['environment-banner'], css['staging']];

    return (
        <>
            { environment === 'development' &&
                <div className={developClasses.join(' ')}>
                    <span className={css['bold']}>DEVELOPMENT ENVIRONMENT</span>
                    &nbsp;- for <span className={css['bold']}>developing</span> purposes only
                </div>
            }
            { environment === 'staging' &&
                <div className={stagingClasses.join(' ')}>
                    <span className={css['bold']}>STAGING ENVIRONMENT</span>
                    &nbsp;- for <span className={css['bold']}>testing</span> purposes only
                </div>
            }
        </>
    )
}

export default EnvironmentBanner;