import { get } from 'lodash';
import { Http, dialog, DropdownMenu, toast } from '../../';
import {
    canBeReSent,
    canBeSent,
    canBeVoided,
    canGetLink,
} from '../../../HelperFunctions/index.js';

const BookingFormActionsMenu = (props) => {
    const { bookingForm, setIsLoading, forceReload } = props;

    const options = [];

    const getRecipientsText = (bookingForm) => {
        return bookingForm.recipients?.length === 1
            ? get(bookingForm, 'recipients.0.email')
            : `${bookingForm.recipients.length} recipients`;
    };

    // To be used when document is in the 'draft' status and it ready to send to the recipients
    const handleSendClicked = (bookingForm) => {
        const recipients = getRecipientsText(bookingForm);

        dialog.confirm(`Are you sure you want to send the document '${bookingForm.name}' to ${recipients}?`, { okLabel: 'Yes, send', okColor: 'primary' })
            .then(() => {
                setIsLoading(true);
                return Http.post(`/booking-forms/${bookingForm._id}/send`);
            })
            .then(() => {
                forceReload();
                toast.success('Document sent successfully.');
            })
            .catch(err => {
                toast.error('Failed to send document.');
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    /**
     * To be used when the document has already been sent, but the recipients have lost the original link
     * To send a document the status must be draft, so this function will revert the document to draft and then send it again
     */
    const handleReSendClicked = (bookingForm) => {
        const recipients = getRecipientsText(bookingForm);

        dialog.confirm(`Are you sure you want to resend the document '${bookingForm.name}' to ${recipients}?`, { okLabel: 'Yes, resend', okColor: 'primary' })
            .then(() => {
                setIsLoading(true);
                return Http.post(`/booking-forms/${bookingForm._id}/resend`);
            })
            .then(() => {
                forceReload();
                toast.success('Document resent successfully.');
            })
            .catch(err => {
                toast.error('Failed to resend document.');
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleVoidClicked = (bookingForm) => {
        dialog.confirm(`Are you sure you want to void the document '${bookingForm.name}'? This cannot be undone.`, { okLabel: 'Yes, void', okColor: 'danger' })
            .then(() => {
                setIsLoading(true);
                return Http.post(`/booking-forms/${bookingForm._id}/void`);
            })
            .then(() => {
                forceReload();
                toast.success('Document voided successfully.');
            })
            .catch(err => {
                toast.error('Failed to void document.');
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleGetLinkClicked = (bookingForm) => {
        Http.post(`/booking-forms/${bookingForm._id}/document-link`)
            .then((res) => {
                navigator.clipboard.writeText(res);
                toast.success('Link copied to clipboard. This link will expire in 5 days.');
            })
            .catch((err) => {
                toast.error('Failed to get link for document.');
                console.log(err);
            });
    };

    const handleOpenDocumentClicked = (bookingForm) => {
        window.open(`${process.env.REACT_APP_API_URL}/booking-forms/${bookingForm._id}/download?open-in-browser=true`, '_blank');
    };

    const handleExportPdfClicked = (bookingForm) => {
        toast.info('Exporting...');
        window.location.href = `${process.env.REACT_APP_API_URL}/booking-forms/${bookingForm._id}/download`;
    };

    options.push({ label: 'Open document', icon: 'description', onClick: () => handleOpenDocumentClicked(bookingForm) });

    if (canBeSent(bookingForm)) {
        options.push({ label: 'Send', icon: 'send', onClick: () => handleSendClicked(bookingForm) });
    }

    if (canBeReSent(bookingForm)) {
        options.push({ label: 'Resend', icon: 'send', onClick: () => handleReSendClicked(bookingForm) });
    }

    if (canBeVoided(bookingForm)) {
        options.push({ label: 'Void', icon: 'clear', onClick: () => handleVoidClicked(bookingForm) });
    }

    if (canGetLink(bookingForm)) {
        options.push({ label: 'Get temporary link', icon: 'link', onClick: () => handleGetLinkClicked(bookingForm) });
    }

    options.push({ label: 'Export PDF', icon: 'download', onClick: () => handleExportPdfClicked(bookingForm) });

    return (
        <DropdownMenu
            icon="menu"
            align="end"
            options={options}
        />
    );
};

export default BookingFormActionsMenu;
