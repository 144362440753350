import { isFinite } from 'lodash';

const Percentage = (props) => {
    const { fixedDecimals=0, number, invalid='-' } = props;

    return isFinite(number)
        ? number.toFixed(fixedDecimals) + '%'
        : invalid;
}

export default Percentage;
