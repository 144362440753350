import css from './FormControl.module.scss';
import AsFormik from './AsFormik';
import FormGroup from './FormGroup';

/**
 * Textarea
 */
const TextareaField = (props) => {
    const { label, required, className='', errorMsg, helpMsg, successMsg, theme, topRightSlot, ...restProps } = props;

    const textAreaClasses = [css['form-control']];
    if (errorMsg) textAreaClasses.push(css['is-invalid']);
    if (theme) textAreaClasses.push(css[`${theme}-theme`]);

    return (
        <FormGroup
            label={label}
            required={required}
            className={className}
            errorMsg={errorMsg}
            helpMsg={helpMsg}
            successMsg={successMsg}
            topRightSlot={topRightSlot}
        >
            <textarea
                className={textAreaClasses.join(' ')}
                {...restProps}
            />
        </FormGroup>
    );
};

const FTextareaField = AsFormik(TextareaField);

export {
    TextareaField,
    FTextareaField,
};
