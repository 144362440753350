import { useEffect, useState } from 'react';

import { Http, useAuth } from '../../Components';

const getXsCookie = () => {
    const match = document.cookie.match(new RegExp('xs=([^;]+)'));
    return match ? match[1] : undefined;
}

/**
 * This component silently checks every few secs if the currently logged in user has changed. If it has, it redirects to
 * the home page. This ensures that if a user logs out from one tab, the rest of the tabs do not show stale data. See: PFX-3541
 */
const AutoLogout = () => {
    const { me } = useAuth();
    const [latestCookie, setLatestCookie] = useState(getXsCookie());

    useEffect(() => {
        const interval = setInterval(() => {
            // Performance: if the cookie has stayed the same, we know the user did NOT change
            if (latestCookie === getXsCookie()) {
                return;
            }

            // If the cookie changed, it could be because the token expired and was refreshed.
            // Check with the server again to find the actual current user.
            Http.get(`/users/me?t=${new Date().getTime()}&onlyId=true`)
                .then(response => {
                    if (response?.user?._id === me?._id) {
                        setLatestCookie(getXsCookie());
                    } else {
                        // The user changed
                        window.location.href = process.env.REACT_APP_BASE_FOLDER;
                    }
                });
        }, 5000);

        return () => clearInterval(interval);
    });

    return null;
}

export default AutoLogout;
