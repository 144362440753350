import type { Option } from 'smg-common';
import { Pill } from 'Components';

interface DigitalMediaVendorOption extends Option {
    backgroundColor: string;
    textColor: string;
}

interface DigitalMediaSupplierPillProps {
    supplier: 'criteo' | 'meta' | 'theTradeDesk' | 'pinterest';
    digitalMediaVendorOptions: DigitalMediaVendorOption[];
}

/** Renders a coloured pill for digital media suppliers */
const DigitalMediaSupplierPill = (props: DigitalMediaSupplierPillProps) => {
    const { supplier, digitalMediaVendorOptions } = props;

    const CRITEO_VENDOR_ID = '6405f98fff321e3b20c2e830';
    const META_VENDOR_ID = '6481e3a60f82723b9fad1161';
    const PINTEREST_VENDOR_ID = '66740e14f78de74fcaec25f1';
    const TTD_VENDOR_ID = '64c252b68727626871b32bc5';

    const supplierOption = (() => {
        switch (supplier) {
        case 'criteo': return digitalMediaVendorOptions.find((option) => option.value === CRITEO_VENDOR_ID);
        case 'meta': return digitalMediaVendorOptions.find((option) => option.value === META_VENDOR_ID);
        case 'pinterest': return digitalMediaVendorOptions.find((option) => option.value === PINTEREST_VENDOR_ID);
        case 'theTradeDesk': return digitalMediaVendorOptions.find((option) => option.value === TTD_VENDOR_ID);
        }
    })();

    if (!supplierOption) {
        throw new Error('Should not happen. Activity supplier does not exist in configuration.');
    }

    return (
        <Pill
            colour={supplierOption.backgroundColor}
            text={supplierOption.label}
            textColor={supplierOption.textColor}
        />
    );
};

export default DigitalMediaSupplierPill;
