function add(date, value, interval) {
    let getter;
    let setter;

    switch (interval) {
        case 'day':
        case 'days':
            getter = 'getDate';
            setter = 'setDate';
            break;
        case 'month':
        case 'months':
            getter = 'getMonth';
            setter = 'setMonth';
            break;
        case 'year':
        case 'years':
            getter = 'getFullYear';
            setter = 'setYear';
            break;
    }

    return new Date(new Date(date)[setter](date[getter]() + value));
}

function subtract(date, value, interval) {
    return add(date, -value, interval);
}

function diff(date1, date2, interval) {
    const date1Ms = date1 instanceof Date ? date1.getTime() : new Date(date1).getTime();
    const date2Ms = date2 instanceof Date ? date2.getTime() : new Date(date2).getTime();

    switch (interval) {
        case 'days': return Math.floor((date1Ms - date2Ms) / (1000 * 60 * 60 * 24)) + 1;
        case 'weeks': return Math.floor((date1Ms - date2Ms) / (1000 * 60 * 60 * 24 * 7)) + 1;
    };
}

function formatDuration(periodInDays) {
    const years = Math.floor(periodInDays / 365);
    const yearsRemainder = periodInDays % 365;

    const months = Math.floor(yearsRemainder / 30);
    const days = yearsRemainder % 30;

    const yearsPart = years > 0 ? `${years} year${years === 1 ? '' : 's'}` : null;
    const monthsPart = months > 0 ? `${months} month${months === 1 ? '' : 's'}` : null;
    const daysPart = days > 0 ? `${days} day${days === 1 ? '' : 's'}` : null;

    return [yearsPart, monthsPart, daysPart].filter((part) => Boolean(part))
        .join(', ');
}

function parse(dateString) {
    if (dateString.length === 10) {
        return new Date(dateString.slice(-4), dateString.slice(3,5) - 1, dateString.slice(0, 2));
    }
    throw new Error(`Could not parse '${dateString}' as a date. Make sure the date string is formatted as 'DD/MM/YYYY'.`);
}

export {
    add,
    diff,
    formatDuration,
    parse,
    subtract,
};
