import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import { isFunction, isString } from 'lodash';

import FieldArray from './FieldArray';
import FormRow from './FormRow';
import Submit from './Submit';
import {
    Button,
    FCheckbox,
    FColorPicker,
    FDatePickerField,
    FDropzone,
    FReactSelect,
    FSmartSelect,
    FTextField,
    FTextareaField,
    FUserSelect,
    FFileInput,
    FlashError,
} from '../';
import { FMultiSelect } from './MultiSelect';
import { FRadioButton } from './RadioButton';
import { FPasswordField } from './PasswordField';
import { FRichTextareaField } from './RichTextareaField';
import { FSwitch } from './Switch';
import { FArrayCheckbox } from './ArrayCheckbox';
import { FTagSelect } from './TagSelect';
import { FAsyncSelect } from './AsyncSelect';

const Cancel = (props) => {
    const { children, onClick, label } = props;

    return (
        <Button onClick={onClick}>{label || children || 'Cancel'}</Button>
    );
};

/**
 *  Provides the formik wrapper for our forms and the Cancel/Submit buttons.
 */
const Form = (props) => {
    const {
        children,
        component,
        hideSubmit,
        initialValues,
        labelCancel = 'Cancel',
        labelSubmit = 'Save',
        onCancel, // Can be a function or a string URL
        onSubmit,
        render,
        validate,
        validationSchema,
        enableReinitialize,
        ...restProps
    } = props;

    Form.AsyncSelect = FAsyncSelect;
    Form.CheckboxArray = FArrayCheckbox;
    Form.Cancel = Cancel;
    Form.Checkbox = FCheckbox;
    Form.Color = FColorPicker;
    Form.DatePicker = FDatePickerField;
    Form.Dropzone = FDropzone;
    Form.FieldArray = FieldArray;
    Form.File = FFileInput;
    Form.FlashError = FlashError;
    Form.MultiSelect = FMultiSelect;
    Form.Password = FPasswordField;
    Form.Radio = FRadioButton;
    Form.Select = FReactSelect;
    Form.SmartSelect = FSmartSelect;
    Form.Input = FTextField;
    Form.RichTextArea = FRichTextareaField;
    Form.Row = FormRow;
    Form.Submit = Submit;
    Form.Switch = FSwitch;
    Form.TagSelect = FTagSelect;
    Form.TextArea = FTextareaField;
    Form.UserSelect = FUserSelect;

    return (
        <Formik
            component={component || ((...args) => (
                <FormikForm {...restProps}>
                    <FlashError className="mb-3" />
                    {isFunction(children) ? children(...args) : children}
                    {(onCancel || !hideSubmit) && (
                        <div className="btn-container-right mt-5">
                            {isFunction(onCancel) && <Button onClick={onCancel}>{labelCancel}</Button>}
                            {isString(onCancel) && <Button linkTo={onCancel}>{labelCancel}</Button>}
                            {onSubmit && !hideSubmit && <Submit>{labelSubmit}</Submit> }
                        </div>
                    )}
                </FormikForm>
            ))}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validate={validate}
            enableReinitialize={enableReinitialize}
        />
    );
};

const useForm = () => {
    const context = useFormikContext();

    return { Form, ...context };
};

export default Form;
export {
    useForm,
};
