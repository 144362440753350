import EnvironmentBanner from '../../Components/Navigation/EnvironmentBanner';
import { SmgLogo, useLogPageView } from '../../Components';

import css from './AuthPageLayout.module.scss';

/**
 * Provides consistent style and layout for all auth pages
 */
const AuthPageLayout = (props) => {
    const { className = '', children, navigation, ...restProps } = props;

    useLogPageView();

    return (<>
        <EnvironmentBanner />
        <div className={`stack-center-center ${css['auth-page']} ${className}`} {...restProps}>
            <div className={css['form-container']}>
                <div className={`stack-center ${css['logo-container']}`}>
                    <SmgLogo height="large" />
                </div>
                <div className="box-flat p-3 pt-4">
                    {children}
                </div>
                <div className="mt-5 text-center">
                    {navigation}
                </div>
            </div>
        </div>
    </>);
}

export default AuthPageLayout;
