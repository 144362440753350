import { formatters } from 'smg-common';

const AsDate = (props) => {
    // GOTCHA: if you pass `null` in any of the options, it will override the default
    // value, whereas `undefined` will result in the default value being used
    const {
        format, // 'short' (31/12/2021) OR 'long' (31 December 2021)
        na,
        value,
    } = props;

    return formatters.asDate(value, { format, na });
}

export default AsDate;
