import { sortBy } from 'lodash';
import type { MetaCampaignInsights } from 'Types/MetaCampaignInsights';
import { formatters, PinterestNode } from 'smg-common'
import {
    AttributionWindowOption,
    ConversionLevelOption,
    CriteoInsightMetricOption,
    InsightMetricOption,
    MetaInsightMetricOption,
    PinterestInsightMetricOption,
    TtdInsightMetricOption,
} from './InFlight.types';
import { TtdCampaignInsights } from 'Types/TtdCampaignInsights';

interface SortNodesOptionsMeta {
    type: 'meta',
    nodes: MetaCampaignInsights['nodes'],
}

interface SortNodesOptionsTtd {
    type: 'ttd',
    nodes: TtdCampaignInsights['nodes'],
}

interface SortNodesOptionsPinterest {
    type: 'pinterest',
    nodes: PinterestNode[],
}

type SortNodesOptions = SortNodesOptionsMeta | SortNodesOptionsTtd | SortNodesOptionsPinterest;

function sortNodes(options: SortNodesOptionsMeta): MetaCampaignInsights['nodes']
function sortNodes(options: SortNodesOptionsTtd): TtdCampaignInsights['nodes']
function sortNodes(options: SortNodesOptionsPinterest): PinterestNode[]
function sortNodes(options: SortNodesOptions) {
    switch (options.type) {
    case 'meta':
        return sortMetaNodes(options.nodes);
    case 'ttd':
        return sortTtdNodes(options.nodes);
    case 'pinterest':
        return sortPinterestNodes(options.nodes);
    }
}
/*
Sort the nodes array for a MetaCampaignInsights object so that:

1. The parent campaign node appears first.
2. Adset nodes appear after the campaign, sorted alphabetically by name.
3. Ad nodes appear immediately after their parent adset node, also sorted alphabetically.

This ensures the node hierarchy is campaign -> adsets -> ads and nodes are sorted logically within each level.
*/
const sortMetaNodes = (nodes: MetaCampaignInsights['nodes']) => {
    let sortedNodes: MetaCampaignInsights['nodes'] = [];
    const rootNode = nodes.filter(node => !node.parentId);
    sortedNodes = [...sortedNodes, ...rootNode];

    const adsetNodes = sortBy(
        nodes.filter((node) => node.level === 'adset'),
        'name'
    );

    for (const adsetNode of adsetNodes) {
        const adNodes = sortBy(
            nodes.filter(node => node.parentId === adsetNode.metaId),
            'name',
        );

        sortedNodes = [...sortedNodes, adsetNode, ...adNodes];
    }

    return sortedNodes;
}
const sortTtdNodes = (nodes: TtdCampaignInsights['nodes']) => {
    let sortedNodes: TtdCampaignInsights['nodes'] = [];
    const rootNode = nodes.filter(node => !node.parentId);

    sortedNodes = [...sortedNodes, ...rootNode];

    const adsetNodes = sortBy(
        nodes.filter((node) => node.level === 'adgroup'),
        'name'
    );

    for (const adsetNode of adsetNodes) {
        const adNodes = sortBy(
            nodes.filter(node => node.parentId === adsetNode.ttdId),
            'name',
        );

        sortedNodes = [...sortedNodes, adsetNode, ...adNodes];
    }
    return sortedNodes;
}

const sortPinterestNodes = (nodes: PinterestNode[]) => {
    let sortedNodes: PinterestNode[] = [];
    const rootNode = nodes.filter(node => !node.parentId);

    sortedNodes = [...sortedNodes, ...rootNode];

    const adGroupNodes = sortBy(
        nodes.filter((node) => node.level === 'adGroup'),
        'name'
    );

    for (const adGroupNode of adGroupNodes) {
        const adNodes = sortBy(
            nodes.filter(node => node.parentId === adGroupNode.pinterestId),
            'name',
        );

        sortedNodes = [...sortedNodes, adGroupNode, ...adNodes];
    }
    return sortedNodes;
}

// Pinterest sales values are stored in micro-dollars, so we need to divide by 1,000,000 to get the actual value
const formatPinterestSalesValue = (...values: number[]) => values.reduce((acc, value) => acc + (value / 1000000), 0);

const insightMetricOptions: InsightMetricOption[] = [
    {
        label: 'Clicks',
        value: 'clicks',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'CPC',
        value: 'cpc',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'CPM',
        value: 'cpm',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'CTR',
        value: 'ctr',
        formatter: (value: number) => formatters.asNumber(value, { decimals: 1 }),
    },
    {
        label: 'Frequency',
        value: 'frequency',
        formatter: (value: number) => formatters.asNumber(value, { decimals: 1 }),
    },
    {
        label: 'Impressions',
        value: 'impressions',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'Reach',
        value: 'reach',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'Spend',
        value: 'spend',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'No. of sales',
        value: 'conversions',
        formatter: (value:  number) => formatters.asNumber(value),
    },
    {
        label: 'Sales value',
        value: 'salesValues',
        formatter: (value:  number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'Spend',
        value: 'cost',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'Attributed Orders',
        value: 'attributedOrders',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'Attributed Units',
        value: 'attributedUnits',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'Attributed Sales',
        value: 'attributedSales',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'CPO',
        value: 'cpo',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'ROAS',
        value: 'roas',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'AOV',
        value: 'aov',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'ROI',
        value: 'roi',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    },
    {
        label: 'Link Clicks',
        value: 'inlineLinkClicks',
        formatter: (value: number) => formatters.asNumber(value),
    },
    {
        label: 'Link Click CTR',
        value: 'inlineLinkClickCtr',
        formatter: (value: number) => formatters.asPercentage(value, { decimals: 2 }),
    },
    {
        label: 'Cumulative ROAS',
        value: 'cumulativeRoas',
        formatter: (value: number) => formatters.asCurrency(value, { decimals: 2 }),
    }
]

const metaInsightMetricOptions: MetaInsightMetricOption[] = insightMetricOptions.filter(option => (
    option.value !== 'cost' &&
    option.value !== 'attributedOrders' &&
    option.value !== 'attributedUnits' &&
    option.value !== 'attributedSales' &&
    option.value !== 'cpo' &&
    option.value !== 'roas' &&
    option.value !== 'aov' &&
    option.value !== 'roi'
)) as MetaInsightMetricOption[];

const ttdInsightMetricOptions: TtdInsightMetricOption[] = insightMetricOptions.filter(option => (
    option.value !== 'spend' &&
    option.value !== 'salesValues' &&
    option.value !== 'conversions' &&
    option.value !== 'attributedOrders' &&
    option.value !== 'attributedUnits' &&
    option.value !== 'attributedSales' &&
    option.value !== 'cpo' &&
    option.value !== 'roas' &&
    option.value !== 'cumulativeRoas'
)) as TtdInsightMetricOption[]

const criteoInsightMetricOptions: CriteoInsightMetricOption[] = insightMetricOptions.filter(option => (
    option.value !== 'cpm' &&
    option.value !== 'salesValues' &&
    option.value !== 'conversions' &&
    option.value !== 'cost' &&
    option.value !== 'cumulativeRoas'
)) as CriteoInsightMetricOption[]

const pinterestInsightMetricOptions: PinterestInsightMetricOption[] = insightMetricOptions.filter(option => (
    option.value === 'cpm' ||
    option.value === 'ctr' ||
    option.value === 'frequency' ||
    option.value === 'impressions' ||
    option.value === 'reach' ||
    option.value === 'spend' ||
    option.value === 'clicks' ||
    option.value === 'salesValues' ||
    option.value === 'conversions' ||
    option.value === 'aov' ||
    option.value === 'roi'
)) as PinterestInsightMetricOption[]

const attributionWindowOptions: AttributionWindowOption[] = [
    {
        label: '1d Click',
        value: '1d_click'
    },
    {
        label: '7d Click',
        value: '7d_click',
    },
    {
        label: '28d Click',
        value: '28d_click',
    },
    {
        label: '1d View',
        value: '1d_view'
    },
    {
        label: '7d View',
        value: '7d_view',
    },
    {
        label: '28d View',
        value: '28d_view',
    }
]

const conversionLevelOptions: ConversionLevelOption[] = [
    {
        label: 'Brand',
        value: 'brand',
    },
    {
        label: 'SKU',
        value: 'sku',
    },
];

export {
    sortNodes,
    insightMetricOptions,
    metaInsightMetricOptions,
    ttdInsightMetricOptions,
    pinterestInsightMetricOptions,
    formatPinterestSalesValue,
    criteoInsightMetricOptions,
    attributionWindowOptions,
    conversionLevelOptions,
};
