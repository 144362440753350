import { Pill, useAuth } from '../';
import css from './CampaignStatus.module.scss';

const CampaignStatus = (props) => {
    const { campaign, className, showBoth } = props;
    const { me } = useAuth();
    const { viewDraftData } = me.permissions;

    if (!campaign) {
        console.error('Missing campaign at <CampaignStatus>');
        return null;
    }

    if (showBoth) {
        return (
            <div className={className}>
                { (campaign.archived || campaign.historic) &&
                    <div>
                        <Pill text={campaign.archived ? 'Archived' : 'Historic'} className={`mb-1 ${css.grey}`} type="status" />
                    </div>
                }
                {/* External users can only view historic/archived campign status */}
                { viewDraftData &&
                    <div>
                        <Pill text={campaign.status} className={campaign.status === 'published' ? css.blue : css.orange} type="status" />
                    </div>
                }
            </div>
        );
    } else {
        let color = null;
        let text = campaign.status;
        if (campaign.archived) {
            text = 'Archived';
            color = css.grey;
        } else if (campaign.historic) {
            text = 'Historic';
            color = css.grey;
        } else if (campaign.status === 'draft') {
            color = css.orange;
        } else {
            color = css.blue;
        }

        return (
            // External users can only view historic/archived campign status
            viewDraftData ? <Pill text={text} className={className + ' ' + color} type="status" /> : null
        );
    }

}

export default CampaignStatus;