const exceedra = {
    filters: [
        { name: 'Buy-in start date', slug: 'buyInStartDate' },
        { name: 'Buy-in end date', slug: 'buyInEndDate' },
        { name: 'Company', slug: 'company' },
        { name: 'Description', slug: 'description' },
        { name: 'Start date', slug: 'startDate' },
        { name: 'End date', slug: 'endDate' },
        { name: 'Pack size', slug: 'packSize' },
        { name: 'Product class', slug: 'productClass' },
        { name: 'Promo code', slug: 'promoCode' },
        { name: 'Promo Sub-tactic', slug: 'promoSubTactic' },
        { name: 'Retailer', slug: 'retailer' },
        { name: 'SKU', slug: 'sku' },
        { name: 'Trade type', slug: 'tradeType' },
    ],
};

export default exceedra;
