import { find, get } from 'lodash';
import { config } from 'smg-common';

import { Http, Icon, Tooltip, dialog, toast, useAuth, useModal } from '../';
import EditAttachmentModal from './EditAttachmentsModal';

const AttachmentsTable = (props) => {
    const { setStudy, study, page } = props;
    const { me } = useAuth();
    const { editEvaluationAttachments, deleteEvaluationAttachments } = me.permissions;
    // Users can always edit/delete attachments in Admin -> Edit study form
    const evaluationsPage = page === 'evaluations';
    const canEdit = editEvaluationAttachments || !evaluationsPage;
    const canDelete = deleteEvaluationAttachments || !evaluationsPage;

    const { show: showAddAttachment } = useModal({title: 'Add attachment', template: EditAttachmentModal});
    const { show: showEditAttachment } = useModal({title: 'Edit attachment', template: EditAttachmentModal});

    const hasAttachments = study.attachments?.length > 0;
    const documentTypeOptions = get(config, 'studies.documentTypes');

    const handleDownloadFileClicked = (attachment) => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        window.location.href = `${BASE_URL}/studies/${study?._id}/attachment/${attachment._id}`;
    };

    const handleDeleteAttachment = (attachment) => {
        dialog.confirm(`Are you sure you want to delete: ${attachment?.file?.name}? This cannot be undone!`, 'Delete')
            .then(() => Http.delete(`/evaluations/studies/${study._id}/attachments/${attachment._id}`))
            .then((updatedStudy) => {
                toast.success('The attachment was successfully deleted.');
                setStudy({ ...study, attachments: updatedStudy.attachments });
            })
            .catch(error => {
                console.error('Could not delete attachment.', error);
                toast.error('Failed to delete the attachment.');
            });
    };

    return (
        <div className="p-3">
            <div className="stack-even-center">
                <h3>Attachments</h3>
                {canEdit &&
                    <span className="like-link" onClick={() => showAddAttachment({ setStudy, study })}>
                        + Add attachment
                    </span>
                }
            </div>

            {/* No attachments yet */}
            { !hasAttachments &&
                <p className="text-subtle stack-center">This study does not have any attachments.</p>
            }

            {/* Table of attachments */}
            { hasAttachments &&
                <div className="table-container">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>File name</th>
                                <th>Document type</th>
                                <th>{/* Download file */}</th>
                                {(canEdit || canDelete) && <th>{/* Edit/delete */}</th>}
                            </tr>
                            {
                                study.attachments.map(attachment => {
                                    const documentType = find(documentTypeOptions, { value: attachment.documentType });
                                    return (
                                        <tr key={attachment._id}>
                                            <td>{attachment.file?.name}</td>
                                            <td>{documentType?.label}</td>
                                            <td>
                                                <Tooltip hover="Download file" align="left">
                                                    <Icon
                                                        className="like-link subtle"
                                                        name="cloud_download"
                                                        onClick={() => handleDownloadFileClicked(attachment)}
                                                    />
                                                </Tooltip>
                                            </td>
                                            {(canEdit || canDelete) &&
                                                <td>
                                                    {canEdit &&
                                                        <Tooltip align="left" hover="Edit attachment">
                                                            <Icon className="like-link subtle mr-2" name="edit"
                                                                onClick={() => showEditAttachment({ attachment, setStudy, study })}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {canDelete &&
                                                        <Tooltip align="left" hover="Delete attachment">
                                                            <Icon className="like-link subtle" name="delete"
                                                                onClick={() => handleDeleteAttachment(attachment)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default AttachmentsTable;
