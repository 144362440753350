import { config } from 'smg-common';

import { IconButton, Panel, Pill, RichTextContent, useAppState } from '../../../Components';

function getLabelColour(contentType) {
    switch(contentType.value) {
    case 'media-alert': return 'smg-grape';
    case 'news': return 'smg-sea';
    case 'new-feature': return 'smg-mud';
    case 'other': return 'smg-beach';
    default: return 'smg-grey';
    }
}

const WhatsNew = (props) => {
    const { className, ...restProps } = props;

    const [whatsNew] = useAppState('whatsNew');
    const contentTypeOptions = config.whatsNew?.contentTypeOptions;

    const renderWhatsNewItem = (item) => {
        const contentType = contentTypeOptions.find((option) => option.value === item.contentType);
        const isExternalLink = (item.link.indexOf('http') === 0)
            || (item.link.indexOf('mailto://') === 0);

        return (
            <Panel
                className="box-flat"
                collapsed
                flat
                key={item._id}
                rightSlot={item.link && !item.linkLabel && (
                    <IconButton color="primary" name="arrow_forward" onClick={() => window.open(item.link, isExternalLink ? '_blank' : undefined)} />
                )}
                tight
                trigger={(<div className="stack-col-1">
                    <h3>{item.headline}</h3>
                    <Pill colour={getLabelColour(contentType)} small text={contentType.label} />
                </div>)}
            >
                <hr />
                <RichTextContent className="mt-3" text={item.body} />
                {item.link && item.linkLabel &&
                    <div className="stack-end like-link" onClick={() => window.open(item.link, isExternalLink ? '_blank' : undefined)}>
                        {item.linkLabel}
                    </div>
                }
            </Panel>
        );
    };

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    return whatsNew?.length > 0 && (
        <div className={classes.join(' ').trim()} {...restProps}>
            <h2>What's New?</h2>
            <hr />
            <div className="stack-col-2 stack-stretch-items pt-2">
                {whatsNew.map(item => renderWhatsNewItem(item))}
            </div>
        </div>
    );
};

export default WhatsNew;
