import { useState } from 'react';

import { Button, Modal, Http, TextareaField, toast } from '..';

const UpgradeModal = (props) => {
    const { trigger } = props;
    const [comments, setComments] = useState();
    const [isOpen, setIsOpen] = useState();

    const handleSave = () => {
        Http.post('request-upgrade', { comments })
            .then(() => {
                toast.success('Your request has been sent!');
                setIsOpen();
            })
            .catch(error => {
                console.error('Could not send request', error);
                toast.error('Could not send request');
            });
    };

    return (<>
        <span onClick={() => setIsOpen(true)}>{trigger}</span>

        <Modal onCancel={() => setIsOpen()} show={isOpen} title="Upgrade today!">
            <Modal.Body>
                <TextareaField label="Comments:" onChange={(e) => setComments(e.target?.value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setIsOpen()}>Cancel</Button>
                <Button color="primary" onClick={handleSave}>Send</Button>
            </Modal.Footer>
        </Modal>

    </>);
};

export default UpgradeModal;
