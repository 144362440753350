/**
 * A very thin wrapper around react-toastify.
 * It provides default options for SMG notifications
 */
import { toast } from 'react-toastify';

const defaultSmgOptions = {
    hideProgressBar: true,
    icon: false,
    pauseOnHover: true,
    position: 'bottom-left',
    theme: 'colored',
};

const wrap = (fx) => {
    return (message, options = {}) => {
        return fx(message, {
            ...defaultSmgOptions,
            ...options,
        });
    };
};

const toastWrapper = {
    dismiss: toast.dismiss,
    info: wrap(toast.info),
    success: wrap(toast.success),
    warn: wrap(toast.warn),
    error: wrap(toast.error),
};

export default toastWrapper;
