import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isUndefined, omitBy } from 'lodash';

/**
 * 
 * @param {URLSearchParams} searchParams 
 * @returns {Object<string, string | string[]>} An object where keys are the param name, and values are either a string or array of strings
 */
const searchParamsToObject = (searchParams) => {
    const params = {};
    for (const key of searchParams.keys()) {
        const values = searchParams.getAll(key)

        // If only one query param, treat as a string
        if (values.length === 1) {
            params[key] = values[0]
        } else {
            params[key] = values
        }
    }

    return params;
}

const useUrl6 = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let params = useParams();
    let navigate = useNavigate();

    const queryParams = searchParamsToObject(searchParams);

    // Sets a URL query parameter. It leaves the rest of the URL untouched. If `options.replace===true`, it does not create a new history entry
    const setQueryParams = (values, options={}) => {
        // Merge the new query params with the existing ones
        let newQueryParams = {
            ...queryParams,
            ...values,
        };
        // Remove the `undefined` values. This helps create cleaner URLs when the query parameter is missing, ie avoid the `?search=undefined`
        newQueryParams = omitBy(newQueryParams, isUndefined);
        // Change the URL
        setSearchParams(newQueryParams, { replace: options.replace });
    };

    // Sets a URL parameter. It leaves the rest of the URL untouched. If `options.replace===true`, it does not create a new history entry
    const setParams = (values, options={}) => {
        console.log('About to set params', values, options);
        // TODO: build this function
    };

    // Replaces the path part of the ULR while keep the rest of it as is.
    // YD, 2022-10-06: This a convenience function to work around the problems with `serParams()`, needed for trends
    const replacePath = (newPath) => {
        navigate({
            pathname: newPath,
            search: createSearchParams(searchParams).toString(),
            replace: true,
        });
    };

    // Returns the query parameters as a string, ready to be appended to a URL
    const getQueryParamsAsString = () => {
        return new URLSearchParams(queryParams).toString();
    };
    
    return {
        params,
        setParams,
        queryParams,
        setQueryParams,
        replacePath,
        getQueryParamsAsString,
    };
}

export default useUrl6;
