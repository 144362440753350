import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Http, useAuth } from 'Components';

/**
 * Workaround: `react-router` v6 does not have an API to get the current path template. This has
 * caused our analytics to log the literal path (eg '/planner/campaigns/633549be19bd18f2de9a1fd2')
 * instead of the template, (eg '/planner/campaigns/:campaignId'), which in turns does not allow
 * us to run reports that show the most popular pages of the app.
 *
 * This function reconstructs the template path by replacing the param *values* in the literal
 * path with the corresponding params *keys*. See: https://stackoverflow.com/a/70754791/17876
 */
const getRoutePath = (pathname, params) => {
    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue && paramName !== '*') {
            path = path.replace('/' + paramValue, `/:${paramName}`);
        }
    });
    return path;
};

/**
  * A React hook that logs a page-view for analytic purposes.
  * @param {object} options:
  *   @param {boolean} suppressPageViewLog: if true, it does not log the page view (this hook becomes a no-op). Typically used for the "loading..." pages
  *   @param {string} trackUserActivity: When present, its value is logged along with the current app name and url under the authenticated user's recent activity
  */
const useLogPageView = (options = {}) => {
    const { suppressPageViewLog, trackUserActivity } = options;

    const { me, setUser } = useAuth();
    const { pathname: url } = useLocation();
    const params = useParams();

    const path = getRoutePath(url, params);

    useEffect(() => {
        if (!suppressPageViewLog) {
            Http.post('/analytics/page-view', { path, track: trackUserActivity, url })
                .then(user => {
                    if (user) {
                        setUser({ ...me, recentActivity: user.recentActivity });
                    }
                });
        }
    }, [path, suppressPageViewLog, trackUserActivity, url]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useLogPageView;
