import Carousel from './Carousel';
import useModal from '../hooks/useModal';

import css from './ImageCarousel.module.scss';
import RenderFile from '../RenderFile/RenderFile';

const ImageCarousel = (props) => {
    const { images } = props;

    const { show: showImages } = useModal({
        className: css['photo-preview'],
        clickOutsideToClose: true,
        header: 'Images',
        template: (props) => {
            const { images, index } = props;

            return (<Carousel
                adaptiveHeight
                images={images.map((image) => (
                    <div className={`d-flex justify-content-center flex-column p-2 ${css.image}`} key={image._id}>
                        <RenderFile file={image} />
                        {image.caption && (<div className={css.caption}>{image.caption}</div>)}
                    </div>
                ))}
                initialSlide={index}
            />);
        },
    });

    return (
        <Carousel
            style={{background: '#f8f8f8'}}
            images={images.map((image, index) => (
                <div key={image._id} className={`${css.image} d-flex justify-content-center flex-column`} onClick={() => showImages({ images, index })}>
                    <RenderFile file={image} key={index} minHeight="300px" />
                    {image.caption && (<div className={css.caption}>{image.caption}</div>)}
                </div>
            ))}
            slidesToShow={images.length > 4 ? 4 : images.length}
            slidesToScroll={4}
            swipe={false}
        />
    );
}

export default ImageCarousel;