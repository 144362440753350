function downloadBlob(filename, blob) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}

const file = {
    downloadBlob,
};

export default file;
