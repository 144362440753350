import { connect } from 'formik';
import { get } from 'lodash';

const fromTargetValue = (event) => get(event, 'target.value');

/**
 * Wraps any form element with formik context. Can be customised as following:
 * - options.extractValue: a function that transforms the onChange() value. By default it reads "event.target.value" which is
 *   the default for most native HTML controls.
 */
const AsFormik = (FormElement, options={}) => {
    return connect(({ errorMsg, formik, name, required, ...restProps }) => {
        if (!formik) {
            console.error(`Form control '${name}' has to be rendered within a formik form`);
        }

        const isTouched = get(formik.touched, name);
        const formikErrorMsg = isTouched ? get(formik.errors, name) : undefined;

        // Note: we need to cast null to empty string, even if it exists as value
        const rawValue = get(formik.values, name);
        const value = (rawValue === null || rawValue === undefined)
            ? ''
            : rawValue;

        const { extractValue = fromTargetValue } = options;

        return (
            <FormElement
                errorMsg={errorMsg || formikErrorMsg}
                name={name}
                onBlur={() => formik.setFieldTouched(name, true)}
                onChange={e => {
                    formik.setFieldTouched(name, true);
                    formik.setFieldValue(name, extractValue(e));
                }}
                required={required}
                value={value}
                {...restProps}
            />
        );
    });
}

export default AsFormik;
