import { isFunction } from 'lodash';
import { useState } from 'react';

import { modalObservable } from '../Modal/ModalContainer';

type ModalOptions = {
  clickOutsideToClose: boolean;
  context: Record<string, unknown>;
  header: React.ReactNode | null;
  onClose: () => void;
  isVisible: boolean;
  size: string;
  template: () => React.ReactNode;
  title?: string;
};

const DEFAULT_OPTIONS = {
    clickOutsideToClose: true,
    context: {},
    header: null,
    onClose: () => null,
    isVisible: false,
    size: 'md',
    template: () => null,
};

const useModal = (options: Partial<ModalOptions> = DEFAULT_OPTIONS) => {
    const modalOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const [context, setContext] = useState(modalOptions.context);
    const [isVisible, setIsVisible] = useState(Boolean(modalOptions.isVisible));

    const hide = () => {
        if (isFunction(modalOptions.onClose)) {
            modalOptions.onClose();
        }
        setIsVisible(false);
        modalObservable.notify({
            ...modalOptions,
            isVisible: false,
        });
    };

    const show = (newContext?: Record<string, unknown>) => {
        if (newContext) {
            setContext(newContext);
        }
        setIsVisible(true);
        modalObservable.notify({
            ...modalOptions,
            context: newContext || context || modalOptions.context,
            hide,
            isVisible: true,
            show,
        });
    };

    return { context, hide, isVisible, options: modalOptions, show };
};

export default useModal;