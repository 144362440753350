import { isEmpty, isUndefined } from 'lodash';

import { Error, Icon, Info, Warning } from '..';

const CompositeMessage = (props) => {
    const {
        className,
        helpMsg, // pass null to omit defaultHelpMsg
        messages, // array of strings
        level = 'info', // 'info' || 'error' || 'warning'
    } = props;

    if (isEmpty(messages)) {
        return null;
    }

    let color, header, defaultHelpMsg, icon;

    switch (level) {
    case 'error':
        color = 'color-danger';
        header = 'Errors';
        defaultHelpMsg = 'You cannot continue until all the errors have been fixed.';
        icon = 'error';
        break;
    case 'warning':
        color = 'color-warn';
        header = 'Warnings';
        defaultHelpMsg = 'You can continue without fixing the warnings, but some parts might not appear as expected.';
        icon = 'warning';
        break;
    default:
        // level === 'info'
        color = 'color-info';
        header = 'Information';
        icon = 'info';
        break;
    }

    const content = (<>
        <h4 className={`stack-start-center ${color}`}>
            <Icon className="mr-2" name={icon} />
            {header}
        </h4>
        <div className="text-small text-subtle">
            {isUndefined(helpMsg) ? defaultHelpMsg : helpMsg}
        </div>

        <ul>
            {messages.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    </>);

    return (<>
        {level === 'info' && <Info className={className}>{content}</Info>}
        {level === 'error' && <Error className={className}>{content}</Error>}
        {level === 'warning' && <Warning className={className}>{content}</Warning>}
    </>)
};

export default CompositeMessage;
