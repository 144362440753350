
import type { TtdCampaignInsights } from 'Types/TtdCampaignInsights';

import DailyInsightsChart from './DailyInsightsChart';
import TtdOverviewTable from './TtdOverviewTable';
import { sortNodes, ttdInsightMetricOptions } from './helperFunctions';

interface ViewTtdInsightsProps {
    ttdCampaignInsights: TtdCampaignInsights;
}

const ViewTtdInsights = (props: ViewTtdInsightsProps) => {
    const { ttdCampaignInsights } = props;

    const sortedNodes = sortNodes({ type: 'ttd', nodes: ttdCampaignInsights.nodes});

    const campaignNode = sortedNodes.find(node => node.level === 'campaign');
    const adgroupOptions = sortedNodes.filter(node => node.level === 'adgroup').map(node => ({ value: node.ttdId, label: node.name }));
    const creativeOptions = sortedNodes
        .filter(node => node.level === 'adgroup')
        .map(adSetNode => {
            const options = sortedNodes
                .filter(node => node.level === 'creative' && node.parentId === adSetNode.ttdId)
                .map(node => ({ value: `${node.ttdId}-${adSetNode.ttdId}`, label: node.name }));
            return {
                value: adSetNode.ttdId,
                label: adSetNode.name,
                options,
            }
        });
    adgroupOptions.unshift({ value: campaignNode?.ttdId || 'N/A' , label: 'Campaign total' });

    return (
        <>
            <DailyInsightsChart type="ttd" data={sortedNodes} adsetOptions={adgroupOptions} adOptions={creativeOptions} metricOptions={ttdInsightMetricOptions} />
            <TtdOverviewTable data={sortedNodes} />
        </>
    );
};

export default ViewTtdInsights;
