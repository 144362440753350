import { compact, find, get, includes, isArray, isNil, isString, join, map, split } from 'lodash-es';

// Convert any slug a user-readable name
function toName(slug) {
    const noDashes = slug.replace(/-/g, " ");
    return noDashes.charAt(0).toUpperCase() + noDashes.slice(1);
}

// Convert any name to a slug
function toSlug(name) {
    return name.toLowerCase()
        .replace('@', '-at-')
        .replace('£', 'pound')
        .replace('%', 'percent')
        .replace('&', 'and')
        .replace(/[^\w]+|[-\s]+/g, '-')
        .replace(/^-|-$/g, '');
}

/**
 * Convert a dot-delimeted list of slugs to an array of IDs (or optionally a single ID string)
 * @param {*} referenceDataOptions An array of standard referenceData-options that also have a slug, ie at minimun: { value, label, slug }
 * @param {*} slug A string with one or more slugs, eg: 'tesco' or 'tesco.asda.boots'
 * @param {*} options.forceArray When true will convert 'tesco' to ['588b5385d5a04e22509ebe71'] instead of the default '588b5385d5a04e22509ebe71'
 */
function decodeSlug(referenceDataOptions, slug, options = {}) {
    const {
        forceArray = false,
    } = options;

    if (isNil(slug)) {
        return undefined;
    }
    if (isString(slug) && !includes(slug, '.')) {
        // It is a single slug
        const option = find(referenceDataOptions, { slug })
            || find(map(referenceDataOptions, 'options').flat(), { slug });

        const id = get(option, 'value');
        return id
            ? forceArray ? [id] : id
            : undefined;
    }
    if (isString(slug) && includes(slug, '.')) {
        // It is a dot delimeted list of slugs
        const slugs = split(slug, '.');
        const ids = map(slugs, slug => decodeSlug(referenceDataOptions, slug));
        return compact(ids); // Remove (ignore) undefined
    }
    throw new Error(`Invalid input for decodeSlug. Expected string/null/undefined but got "${slug}"`);
}

/**
 * Convert a one or more IDs to slugs
 * @param {*} referenceDataOptions An array of standard referenceData-options that also have a slug, ie at minimun: { value, label, slug }
 * @param {*} ids An array of IDs or a single ID, eg: '588b5385d5a04e22509ebe71' OR ['588b5385d5a04e22509ebe71', '5988315ba041de3c587492ea']
 */
function encodeSlug(referenceDataOptions, ids) {
    if (isNil(ids)) {
        return undefined;
    }
    if (isString(ids)) {
        const option = find(map(referenceDataOptions, 'options').flat(), (option) => get(option, 'value') === ids && option.slug)
            || find(referenceDataOptions, { value: ids });
        return get(option, 'slug');
    }
    if (isArray(ids)) {
        let slugs = map(ids, id => encodeSlug(referenceDataOptions, id));
        slugs = compact(slugs); // Remove (ignore) undefined
        return join(slugs, '.') || undefined; // Cast empty string to undefined, so that we hide the URL query if it has no value
    }
    throw new Error(`Invalid input for encodeSlug. Expected string/null/undefined but got "${ids}"`);
}

export {
    decodeSlug,
    encodeSlug,
    toName,
    toSlug,
};
