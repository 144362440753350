import { useContext } from 'react';
import { ArrayCheckbox } from '../Forms';

import CheckboxListContext from './CheckboxListContext';

// The checkbox that is toggling a single item in the list
const One = (props) => {
    const {
        value,
        ...restProps // for things like className, style, errorMsg etc
    } = props;
    const { selected, setSelected } = useContext(CheckboxListContext);

    return (
        <ArrayCheckbox
            {...restProps}
            onChange={setSelected}

            // Gotcha: Notice that `ArrayCheckbox` is using `value` for the array of all the selected values and `valueKey` for the
            // current ID. Therefore, we need to be careful to use the correct values provided by the context
            value={selected}
            valueKey={value}
        />
    );
}

export default One;
