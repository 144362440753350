const companies = {
    companyDashboardOptions: [
        { value: false, label: 'External' },
        { value: true, label: 'Internal' },
    ],
    companyTypeOptions: [
        { value: 'internal', label: 'Internal' },
        { value: 'client', label: 'Client' },
        { value: 'mediaAgency', label: 'Media agency' },
        { value: 'retailer', label: 'Retailer' },
    ],
    supplierCodeOptions: [
        {label: 'ASDA supplier code', value: 'asda-supplier-code'},
        {label: 'TVG supplier code', value: 'tvg-supplier-code'},
    ],
};

export default companies;
