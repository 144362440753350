import { Component } from 'react';

import NewVersionPage from './NewVersion/NewVersionPage';
import { ErrorPage } from './ErrorPages';
import { Http } from '../Components';

class GenericErrorBoundary extends Component {
    state = {
        hasError: false,
        triedToLoadStaleChunk: false,
    };

    static getDerivedStateFromError(error) {
        // When the users do not refresh their page after a new release, the Webpack chunks in their index.html
        // do not match those in the server. In such cases, we want to force a page reload so that the browser
        // loads the new version of JS and CSS. See PFX-3047
        const triedToLoadStaleChunk = error.message && /Loading( CSS)? chunk [0-9]+ failed/.test(error.message);

        return {
            hasError: true,
            triedToLoadStaleChunk,
        };
    }

    componentDidCatch(error, info) {
        if (this.state.triedToLoadStaleChunk) {
            // Do not log anything because we have recovered
            return;
        }

        // Try to log the error at OpenSearch
        Http.post('/dev/client-side-log/error', {
            componentStack: info.componentStack,
            msg: error.message,
            referer: window.location.href,
            err: {
                message: error.message,
                stack: error.stack,
            }
        })
            .catch(err => {
                console.error('Could not notify the server about the error', err);
                // Nothing else we can do
            });
    }

    render() {
        if (this.state.triedToLoadStaleChunk) {
            return <NewVersionPage />;
        } else if (this.state.hasError) {
            return <ErrorPage />;
        } else {
            return this.props.children;
        }
    }
}

export default GenericErrorBoundary;
