function getItemCost(item, activitiesTotalCost) {
    if (!item.cancelled && item.cost) {
        if (item.percentage && Number.isFinite(item.percentageInput)) {
            return activitiesTotalCost * (item.percentageInput/100);
        }
        if (item.flatRate && Number.isFinite(item.flatRateInput)) {
            return item.flatRateInput;
        }
    }
    return 0;
};

export {
    getItemCost,
};
