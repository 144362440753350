import { createRef, useEffect, useState } from 'react';

import css from './Grid.module.scss';

const gridRef = createRef();

const Grid = (props) => {
    const {
        alignItems,
        children,
        className = '',
        columns = 'auto',
        gap,
        height,
        maxColumns,
        maxHeight,
        maxWidth,
        minHeight,
        minWidth,
        placeContent,
        rows = 'auto',
        style = {},
        width,
        ...restProps
    } = props;

    const [template, setTemplate] = useState();

    const colCount = columns === 'auto' ? 'auto-fill' : columns;
    const colSize = (width || minWidth || maxWidth) ? `minmax(${width || minWidth || 0}, ${width || maxWidth || '1fr'})` : '1fr';
    const rowCount = columns === 'auto' ? 'auto-fill' : rows;
    const rowSize = (height || minHeight || maxHeight) ? `minmax(${height || minHeight || 0}, ${height || maxHeight || '1fr'})` : '1fr';

    // In the case of max columns, we need to update the grid template on resize
    useEffect(() => {
        function updateTemplate() {
            const newTemplate = {
                gridAutoColumns: colSize,
                gridAutoRows: rowSize,
                gridTemplateColumns: `repeat(${colCount}, ${colSize})`,
                gridTemplateRows: `repeat(${rowCount}, ${rowSize})`,
            };

            if (maxColumns) {
                const minColWidth = parseInt(minWidth) || 0;
                const gapSizeHorizontal = parseInt(window.getComputedStyle(gridRef.current).gridGap?.split(' ')?.[1]) || 0;
                const breakpoint = minColWidth * maxColumns + gapSizeHorizontal * (maxColumns - 1) * 1;

                if (breakpoint < gridRef.current?.getBoundingClientRect().width) {
                    newTemplate.gridTemplateColumns = `repeat(${maxColumns}, 1fr)`;
                }
            }

            setTemplate(newTemplate);
        }
        updateTemplate();

        window.addEventListener('resize', updateTemplate);
        return () => window.removeEventListener('resize', updateTemplate);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const gridStyle = {
        ...template,
        ...style,
    };
    if (gridStyle) gridStyle.placeContent = placeContent;

    const classes = [css.grid];
    if (alignItems) classes.push(css[`grid-align-items-${alignItems}`]);
    if (gap) classes.push(css[`grid-gap-${gap}`]);
    if (className) classes.push(className.trim());

    return (
        <div className={classes.join(' ')} ref={gridRef} style={gridStyle} {...restProps}>
            {/** 
             * Only render the grid content after the component has finished processing the template.
             * Ensures that the content will not 'jump' when the template is finally processed, causing
             * unexpected sizing and positioning issues.
             */}
            {template ? children : null}
        </div>
    );
}

export default Grid;
