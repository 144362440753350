import { IdAndName, NamingConvention } from '../types';

interface ActivityBase<ID> {
    _id: ID;
    uid?: string;
    description?: string;
    touchpoint: {
        _id: ID;
        integrations?: {
            criteoConfig?: {
                formatKey?: string;
            };
        };
    };
}

// This function overload enables name generation for campaigns/line items created from both activities and plans.
// The optional activity parameter is only used when a campaign or line item is created from an activity.
function getNameFromConvention<Campaign extends IdAndName<ID>, Plan extends IdAndName<ID>, ID = unknown>(
    namingConvention: NamingConvention | undefined,
    campaign: Campaign,
    plan: Plan,
): string;
function getNameFromConvention<Campaign extends IdAndName<ID>, Plan extends IdAndName<ID>, Activity extends ActivityBase<ID>, ID = unknown>(
    namingConvention: NamingConvention | undefined,
    campaign: Campaign,
    plan: Plan,
    activity: Activity,
): string;
function getNameFromConvention<Campaign extends IdAndName<ID>, Plan extends IdAndName<ID>, Activity extends ActivityBase<ID> | undefined, ID = unknown>(
    namingConvention: NamingConvention | undefined,
    campaign: Campaign,
    plan: Plan,
    activity?: Activity,
): string {
    if (!namingConvention) {
        return 'No line item naming convention provided for Criteo account';
    }

    const values = namingConvention.values.map((value) => {
        switch (value) {
            case 'campaign-id':
                return campaign._id;
            case 'campaign-name':
                return campaign.name;
            case 'plan-id':
                return plan._id;
            case 'plan-name':
                return plan.name;
            case 'activity-id':
                return activity?._id;
            case 'activity-uid':
                return activity?.uid?.toString();
            case 'activity-description':
                return activity?.description;
            case 'touchpoint-format-key':
                return activity?.touchpoint?.integrations?.criteoConfig?.formatKey;
            default:
                return 'NA';
        }
    });


    return values.filter((v) => !!v).join(namingConvention.separator);
}

export { getNameFromConvention };
