import type { InsightsMetrics } from 'Types';
import { criteoInsightMetricOptions } from './helperFunctions';

import css from './OverviewTable.module.scss';
import { GlossaryTerm } from 'Components';

interface CriteoOverviewTableRow {
    level: 'campaign' | 'group' | 'product';
    name: string,
    data: InsightsMetrics;
}

interface CriteoOverviewTableProps {
    rows: CriteoOverviewTableRow[];
}

const buildRow = (row: CriteoOverviewTableRow, key: number) => {
    const formatInsightMetric = (field: keyof InsightsMetrics) => {
        const option = criteoInsightMetricOptions.find(o => o.value === field)
        const metric = row.data[field]

        if (!metric || !option) return '-'
        return option.formatter(metric)
    }

    return (
        <tr key={key}>
            {/* Products tend to have longer names so widen the name column for them only */}
            <td className={`${row.level === 'product' ? css.productNameRow : css[`${row.level}Row`]}`}>{row.name}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('impressions')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('clicks')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('spend')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('reach')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('attributedOrders')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('attributedUnits')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('attributedSales')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('ctr')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('cpc')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('cpo')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('roas')}</td>
            <td className={`text-right ${css[`${row.level}Row`]}`}>{formatInsightMetric('frequency')}</td>
        </tr>
    );
}

const CriteoOverviewTable = (props: CriteoOverviewTableProps) => {
    const { rows } = props;

    return (
        <div className="box-raised p-3 mt-3">
            <h2>Performance analysis</h2>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="text-right"><GlossaryTerm slug="impressions">Impressions</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="Clicks">Clicks</GlossaryTerm></th>
                            <th className="text-right">Spend</th>
                            <th className="text-right"><GlossaryTerm slug="Reach">Reach</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="AO">Attributed orders</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="AU">Attributed units</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="AS">Attributed sales</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="ctr">CTR</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="CPC">CPC</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="CPO">CPO</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="roas">ROAS</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="Frequency">Frequency</GlossaryTerm></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => buildRow(row, index))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export type {
    CriteoOverviewTableRow,
};

export default CriteoOverviewTable;
