import type { ReferenceData } from 'SelfServe/types';

import useReferenceData from './useReferenceData';

type UseSelfServeReferenceDataReturnType = [ReferenceData, (force: boolean) => Promise<void>];

const useSelfServeReferenceData = () => {
    return useReferenceData('/self-serve/reference-data', { cache: true, name: 'selfServe' }) as UseSelfServeReferenceDataReturnType;
};

export default useSelfServeReferenceData;
