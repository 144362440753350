import AsCurrency from './AsCurrency';
import AsDate from './AsDate';
import AsHumanised from './AsHumanised';
import AsNumber from './AsNumber';
import AsPercentage from './AsPercentage';
import AsTimeFromNow from './AsTimeFromNow';

const Format = {
    AsCurrency,
    AsDate,
    AsHumanised,
    AsNumber,
    AsPercentage,
    AsTimeFromNow,
};

export default Format;
