const analytics = {
    personaOptions: [
        { value: 'tester', label: 'Tester' },
        { value: 'retailer', label: 'Retailer'},
        { value: 'internal', label: 'Internal' },
        { value: 'subscriber', label: 'Subscriber' },
        { value: 'guest', label: 'Guest' },
        { value: 'unknown', label: 'Unknown' },
    ],
};

export default analytics;