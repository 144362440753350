import { compact } from 'lodash';
import { Dice, Tooltip } from '../../';

const BookingFormDiceIcon = (props) => {
    const { bookingForm, plan } = props;

    const activities = bookingForm.activities?.map((bookingFormActivity) => {
        return plan.activities?.find((planActivity) => {
            return planActivity._id === bookingFormActivity;
        });
    });

    const items = bookingForm.items?.map((bookingFormItem) => {
        return plan.items?.find((planItem) => {
            return planItem._id === bookingFormItem;
        });
    });

    // Some booking forms are missing activities/items from a previous bug, removing these with compact prevents the modal from crashing
    const activitiesAndItems = compact(activities.concat(items));

    const count = +activitiesAndItems?.length;

    const tooltip = activitiesAndItems?.map((activityOrItem, index) => (
        <div key={index}>
            {activityOrItem.description || activityOrItem.touchpoint?.name || activityOrItem.title}<br />
        </div>
    ));

    return <div className="stack-center">
        <Tooltip hover={tooltip}>
            <Dice value={count} />
        </Tooltip>
    </div>;
};

export default BookingFormDiceIcon;
