const NewVersionPage = () => (
    <div className="mt-5 text-center">
        <h1>Plan-Apps has been upgraded.</h1>
        <p>
            <a href={process.env.REACT_APP_BASE_FOLDER}>Load the new version</a>
        </p>
    </div>
);

export default NewVersionPage;
