import { Fragment, useState } from 'react';
import { times } from 'lodash';

import { Checkbox, ReactSelect } from 'Components';
import type { MetaCampaignInsights, MetaNodeInsights, SalesAttribution } from 'Types/MetaCampaignInsights';
import type { AttributionWindowOption, InsightMetricOption } from './InFlight.types';
import { insightMetricOptions, sortNodes } from './helperFunctions';

import css from './OverviewTable.module.scss';
import type { Option } from 'smg-common';

interface MetaConversionsTableProps {
    attributionWindow: AttributionWindowOption;
    data: MetaCampaignInsights;
}
interface BaseOption {
    label: string,
    value: string,
    options: Option[]
}

const NUMBER_OF_COLUMNS = 3;

const baseOptions: BaseOption[] = [
    {
        label: 'Brand',
        value: 'brand',
        options: []
    },
    {
        label: 'SKU',
        value: 'sku',
        options: []
    }
]

// Creates the overview table in the Meta in-flight page displaying impressions, reach, frequency, etc.
const MetaConversionsTable = (props: MetaConversionsTableProps) => {
    const { data, attributionWindow } = props;

    const [areAdsVisible, setAreAdsVisible] = useState<boolean>(false);
    const [selectedCompareOptions, setSelectedCompareOptions] = useState<(Option | null)[]>(new Array(NUMBER_OF_COLUMNS).fill(null))

    const selectOptions = baseOptions.map(opt => ({
        ...opt,
        // Group the customConversionIds by brand and sku
        options: data.customConversionIds?.filter(ccid => ccid.level === opt.value)
            .map(ccid => ({
                label: ccid.purchaseType ? `${ccid.name} - ${ccid.purchaseType}` : ccid.name,
                value: ccid.attributionId
            }))
    })
    )

    const getAttributionDataFromNode = (node: SalesAttribution[], compareOption: (Option | null), window: AttributionWindowOption, field: InsightMetricOption['value']) => {
        const attribution = node.find(ac => ac.attributionId === compareOption?.value)
        const value = attribution?.attributionData[window.value]
        const option = insightMetricOptions.find(opt => opt.value === field)

        if (!option || !value) return '-'
        return option.formatter(value)
    }

    const buildRow = (node: MetaNodeInsights) => {
        if (!areAdsVisible && node.level === 'ad') return null;

        return (
            <tr key={node.metaId}>
                <td className={css[`${node.level}Row`]}>{node.level === 'campaign' ? 'Campaign total' : node.name}</td>
                {times(NUMBER_OF_COLUMNS, (index) => {
                    const showDivider = index !==0 ? css.columnDivider : ''

                    return (
                        <Fragment key={index}>
                            <td className={`text-right ${css[`${node.level}Row`]} ${showDivider}`}>{getAttributionDataFromNode(node.attributedConversions, selectedCompareOptions[index], attributionWindow, 'conversions')}</td>
                            <td className={`text-right ${css[`${node.level}Row`]}`}>{getAttributionDataFromNode(node.attributedSalesValues, selectedCompareOptions[index], attributionWindow, 'salesValues')}</td>
                        </Fragment>
                    )
                })}
            </tr>
        );
    }

    const handleCompareWithChange = (index: number, value: any) => {
        setSelectedCompareOptions((prev) => prev.map((item, idx) => (idx === index ? value : item)));
    };

    return (
        <div className="box-raised p-3">
            <div className="stack-even">
                <h2>Conversion analysis</h2>
                <div>
                    <Checkbox
                        checked={areAdsVisible}
                        label="Display individual ads"
                        onChange={() => setAreAdsVisible(!areAdsVisible)}
                    />
                </div>
            </div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ width: '50%' }}></th>
                            {times(NUMBER_OF_COLUMNS, (index) => (
                                <td key={index} colSpan={2} style={{ width: `${50 / NUMBER_OF_COLUMNS}%` }}>
                                    <div style={{ lineHeight: 'initial' }}>
                                        <ReactSelect
                                            options={selectOptions}
                                            value={selectedCompareOptions[index]?.value}
                                            onChange={(value: any) => handleCompareWithChange(index, value)}
                                            className={css.compareBrandSkuSelect}
                                            isClearable
                                        />
                                    </div>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <th>Name</th>
                            {times(NUMBER_OF_COLUMNS, (index) => {
                                const showDivider = index !==0 ? css.columnDivider : ''

                                return (
                                    <Fragment key={index}>
                                        <th className={showDivider}>No. of sales</th>
                                        <th>Sales value</th>
                                    </Fragment>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {sortNodes({ type: 'meta', nodes: data.nodes}).map((node) => buildRow(node))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MetaConversionsTable;
