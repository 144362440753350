import Icon from '../Icon/Icon';

interface RenderThumbnailProps {
    file: {
        name: string;
        url?: string;
        src?: string;
        mimetype: string;
    };
    containerStyles?: {
        height: number;
    };
    imageStyles?: {
        height: number;
    };
}

const mimetypes = {
    archive: new RegExp('^application/((x-b)?zip2?|x-7z-compressed|vnd.rar|x-tar)'),
    audio: new RegExp('^audio/.{2,}'),
    excel: new RegExp('^application/(vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.ms-excel)|text/csv'),
    image: new RegExp('^image/.{2,}'),
    powerpoint: new RegExp('^application/(vnd.openxmlformats-officedocument.presentationml.presentation|vnd.ms-powerpoint)'),
    video: new RegExp('^video/.{2,}'),
    word: new RegExp('^application/(vnd.openxmlformats-officedocument.wordprocessingml.document|msword)'),
};

const fileExtensions = {
    archive: ['zip', 'tar', 'tgz', 'gz', 'rar'],
    audio: ['wav', 'mp3', 'ogg', 'flac', 'aiff'],
    excel: ['xls', 'xlsx', 'xltx', 'xlsb', 'csv'],
    image: ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'svg', 'bmp'],
    powerpoint: ['ppt', 'pptx', 'pptm'],
    video: ['mp4', 'mov', 'wmv', 'avi', 'flv', 'mkv', 'webp'],
    word: ['doc', 'docx', 'docm', 'dot', 'odt', 'rtf'],
};

const getIconName = (file: RenderThumbnailProps['file']) => {
    const fileExtension = file.name.split('.').pop() || '';

    for (const [filetype, mimetype] of Object.entries(mimetypes)) {
        // If the file is of the correct mimetype or file extension, return the corresponding icon
        if (mimetype.test(file.mimetype) || fileExtensions[filetype as keyof typeof fileExtensions].includes(fileExtension)) {
            return `file${filetype.charAt(0).toUpperCase() + filetype.slice(1)}`;
        }
    }

    return 'fileGeneric';
};

const RenderThumbnail = (props: RenderThumbnailProps) => {
    const { file, containerStyles, imageStyles } = props;

    const containerHeight = containerStyles?.height || 300;

    const thumbnailContainerStyles = {
        height: `${containerHeight}px`,
        width: `${containerHeight}px`,
        ...containerStyles,
    };

    const thumbnailIconStyles = {
        maxHeight: '50%',
        naxWidth: '50%',
        zoom: `calc(${containerHeight / 2} / 24)`,
    };

    // Default Icon
    const fileExtension = file?.name?.split('.').pop() || '';

    if (mimetypes.image.test(file.mimetype) || ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'svg', 'bmp'].includes(fileExtension)) {
        return (
            <img
                src={file.url || file.src}
                style={{
                    height: 'auto',
                    maxHeight: `${imageStyles?.height || 300}px`,
                    width: 'auto',
                    maxWidth: '100%',
                }}
                alt="Preview"
            />
        );
    }

    return (
        <div className="d-flex justify-content-center align-items-center" style={thumbnailContainerStyles}>
            <Icon name={getIconName(file)} style={thumbnailIconStyles} />
        </div>
    );
};

export default RenderThumbnail;
