import { formatters } from 'smg-common';

interface AsHumanisedProps {
    decimals?: number;
    na?: string;
    value: number;
}

const AsHumanised = (props: AsHumanisedProps) => {
    const {
        decimals,
        na,
        value,
    } = props;

    return formatters.humanise(value, { decimals, na });
}

export default AsHumanised;
