/**
 * Functions to generate the planner-charts highchart data
 */

import _ from 'lodash-es';
import numeral from 'numeral';

import { getPlanTotals, hasPlansWithValidActivities } from './planFunctions.js';

const credits = {
    text: 'Copyright © Shopper Media Group Ltd. 2017 - ' + (new Date()).getFullYear(),
    href: 'https://www.shoppermediagroup.com/',
};

// Group by retailer and sum cost, £ uplift etc
function sumActivitiesByRetailer(plans) {
    if (!hasPlansWithValidActivities(plans)) {
        return null;
    }

    const plansWithTotals = plans.map(plan => ({ ...plan,...getPlanTotals(plan) }));
    const plansPerRetailer = _.groupBy(plansWithTotals, 'retailer._id');
    const totals = _.mapValues(plansPerRetailer, plans => {
        const total = {
            impressions: undefined,
            costForCpm: undefined,
            impressionsForCpm: undefined,
        };
        _.forEach(plans, plan => {
            if (plan.costForCpm && plan.impressions) {
                total.costForCpm = plan.costForCpm + (total.costForCpm || 0);
                total.impressionsForCpm = plan.impressions + (total.impressionsForCpm || 0);
            }
        });

        return {
            totalCost: _.sumBy(plans, 'cost') || undefined,
            totalUplift: _.sumBy(plans, 'estimatedPoundUplift') || undefined,
            totalImpressions: _.sumBy(plans, 'impressions') || undefined,
            cpm: total.costForCpm && total.impressionsForCpm ? total.costForCpm / total.impressionsForCpm * 1000 : undefined,
            retailer: plans[0].retailer,
        };
    });

    return _.values(totals);
}

// Group by shopper journey and sum cost, £ uplift etc (PFX-2699, charts three and four)
function sumActivitiesByShopperJourney(plans) {
    if (!hasPlansWithValidActivities(plans)) {
        return null;
    }

    const plansWithTotals = plans.map(plan => ({ ...plan,...getPlanTotals(plan) }));
    const activities = _.flatten(_.map(plansWithTotals, plan => {
        return plan.filteredActivities;
    }));

    const perShopperJourney = _.groupBy(activities, 'touchpoint.shopperJourneySlug');

    const totals = _.mapValues(perShopperJourney, activities => {
        const { shopperJourneySlug } = _.get(activities, '[0].touchpoint', {});
        const total = {
            impressions: undefined,
            costForCpm: undefined,
            impressionsForCpm: undefined,
            estimatedPoundUplift: undefined,
        };
        _.forEach(activities, activity => {
            if (activity.cost && activity.impressions) {
                total.costForCpm = activity.cost + (total.costForCpm || 0);
                total.impressionsForCpm = activity.impressions + (total.impressionsForCpm || 0);
            }
            if (!activity.excludeEstimates && activity.estPoundUpliftBrand) {
                total.estimatedPoundUplift = activity.estPoundUpliftBrand + (total.estimatedPoundUplift || 0);
            }
        });

        return {
            totalCost: _.sumBy(activities, 'cost') || undefined,
            totalUplift: total.estimatedPoundUplift,
            totalImpressions: _.sumBy(activities, 'impressions') || undefined,
            cpm: total.costForCpm && total.impressionsForCpm ? total.costForCpm / total.impressionsForCpm * 1000 : undefined,
            shopperJourney: shopperJourneySlug,
        };
    });

    return _.values(totals);
}

// Group by touchpoint and sum cost, £ uplift etc (PFX-2699, charts one and two)
function sumActivitiesByTouchpoint(plans) {
    if (!hasPlansWithValidActivities(plans)) {
        return null;
    }

    const plansWithTotals = plans.map(plan => ({ ...plan,...getPlanTotals(plan) }));
    const activities = _.flatten(_.map(plansWithTotals, plan => {
        return plan.filteredActivities;
    }));
    const perTouchpoint = _.groupBy(activities, 'touchpoint._id');

    const totals = _.mapValues(perTouchpoint, activities => {
        const total = {
            impressions: undefined,
            costForCpm: undefined,
            impressionsForCpm: undefined,
            estimatedPoundUplift: undefined,
        };
        _.forEach(activities, activity => {
            if (activity.cost && activity.impressions) {
                total.costForCpm = activity.cost + (total.costForCpm || 0);
                total.impressionsForCpm = activity.impressions + (total.impressionsForCpm || 0);
            }
            if (!activity.excludeEstimates && activity.estPoundUpliftBrand) {
                total.estimatedPoundUplift = activity.estPoundUpliftBrand + (total.estimatedPoundUplift || 0);
            }
        });

        return {
            totalCost: _.sumBy(activities, 'cost') || undefined,
            totalUplift: total.estimatedPoundUplift,
            totalImpressions: _.sumBy(activities, 'impressions') || undefined,
            cpm: total.costForCpm && total.impressionsForCpm ? total.costForCpm / total.impressionsForCpm * 1000 : undefined,
            touchpoint: _.get(activities[0], 'touchpoint.name', 'n/a'),
        };
    });

    return _.values(totals);
}

// Create HighChart options for chart one (PFX-2699)
function impressionsByTouchpoint(plans, chartSubtitle) {
    const data = _.sortBy(sumActivitiesByTouchpoint(plans), 'totalImpressions');

    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalImpressions && item.cpm);
    const totalImpressions = _.sumBy(filteredData, 'totalImpressions') || undefined;

    if (!totalImpressions) {
        return null;
    }

    return {
        chart: { type: 'bar' },
        title: { text: 'Impressions & CPM by touchpoint' },
        subtitle: { text: chartSubtitle },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `CPM: £${this.point.cpm}`;
                    }
                }
            }
        },
        xAxis: { categories: _.map(filteredData, item => item.touchpoint) },
        yAxis: {
            min: 0,
            title: { text: 'Impressions' }
        },
        legend: { enabled: false },
        tooltip: {
            formatter: function () {
                return `CPM: £${this.point.cpm}`;
            }
        },
        series: [{
            name: 'Touchpoints',
            data: _.map(filteredData, (item) => ({
                cpm: numeral(item.cpm).format('0,0.00'),
                y: item.totalImpressions,
            })),
        }],
        credits,
    };
}

// Create HighChart options for chart two (PFX-2699)
function costAndUpliftByTouchpoint(plans, chartSubtitle) {
    const data = _.orderBy(sumActivitiesByTouchpoint(plans), 'totalCost', 'desc');
    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalCost && item.totalUplift);

    if (filteredData.length === 0) {
        return null;
    }

    return {
        chart: { type: 'column' },
        title: { text: 'Cost and Est. £ Uplift by touchpoint' },
        subtitle: { text: chartSubtitle },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            valuePrefix: '£',
        },
        xAxis: { categories: _.map(filteredData, item => item.touchpoint), crosshair: true },
        yAxis: [
            { labels: { title: { text: 'Total cost (£)' } } },
            { title: { text: '£ Uplift' }, opposite: true },
        ],
        series: [
            { name: 'Cost', yAxis: 0, data: _.map(filteredData, item => item.totalCost) },
            { name: '£ Uplift', yAxis: 1, data: _.map(filteredData, item => item.totalUplift) },
        ],
        credits,
    };
}

function impressionsByRetailer(plans, chartSubtitle) {
    const data = sumActivitiesByRetailer(plans);
    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalImpressions && item.cpm);
    const totalImpressions = _.sumBy(filteredData, 'totalImpressions') || undefined;

    if (!totalImpressions) {
        return null;
    }

    const totalStr = `
        ${numeral(totalImpressions).format('0a')} total
        <br>impressions
    `;
    return {
        chart: { type: 'pie' },
        plotOptions: {
            pie: {
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return numeral(this.y).format('0a');
                    },
                }
            }
        },
        title: { text: 'Impressions by retailer' },
        subtitle: { text: chartSubtitle },
        caption: {
            text: totalStr,
            align: 'center',
            verticalAlign: 'middle',
            y: 30
        },
        tooltip: {
            formatter: function () {
                return `CPM: £${this.point.cpm}`;
            }
        },
        series: [{
            innerSize: '60%',
            data: _.map(filteredData, item => {
                return {
                    name: item.retailer.name,
                    y: item.totalImpressions,
                    color: item.retailer.backgroundColor,
                    cpm: numeral(item.cpm).format('0,0.00'),
                }
            }),
        }],
        credits,
    };
}

function costAndUpliftByRetailer(plans, chartSubtitle) {
    const data = _.orderBy(sumActivitiesByRetailer(plans), 'totalCost', 'desc');
    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalCost && item.totalUplift);

    if (filteredData.length === 0) {
        return null;
    }

    return {
        chart: { type: 'column' },
        title: { text: 'Cost and Est. £ Uplift by retailer' },
        subtitle: { text: chartSubtitle },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            valuePrefix: '£',
        },
        xAxis: {
            categories: _.map(filteredData, item => item.retailer.name),
            crosshair: true
        },
        yAxis: [
            { title: { text: 'Total cost (£)' } },
            { title: { text: '£ Uplift' }, opposite: true },
        ],
        series: [
            { name: 'Cost', yAxis: 0, data: _.map(filteredData, item => item.totalCost) },
            { name: '£ Uplift', yAxis: 1, data: _.map(filteredData, item => item.totalUplift) },
        ],
        credits,
    };
}

// Create HighChart options for chart three (PFX-2699)
function impressionsByShopperJourney(plans, chartSubtitle) {
    const data = sumActivitiesByShopperJourney(plans);
    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalImpressions && item.cpm);
    const totalImpressions = _.sumBy(filteredData, 'totalImpressions') || undefined;

    if (!totalImpressions) {
        return null;
    }

    const totalStr = `
        ${numeral(totalImpressions).format('0a')} total
        <br>impressions
    `;
    return {
        chart: { type: 'pie', },
        plotOptions: {
            pie: {
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return numeral(this.y).format('0a');
                    },
                }
            }
        },
        title: { text: 'Impressions by shopper journey' },
        subtitle: { text: chartSubtitle },
        caption: {
            text: totalStr,
            align: 'center',
            verticalAlign: 'middle',
            y: 30
        },
        tooltip: {
            formatter: function () {
                return `CPM: £${this.point.cpm}`;
            }
        },
        series: [{
            innerSize: '60%',
            data: _.map(filteredData, item => {
                return {
                    name: _.get(item, 'shopperJourney', 'n/a'),
                    y: item.totalImpressions,
                    cpm: numeral(item.cpm).format('0,0.00'),
                }
            }),
        }],
        credits,
    };
}

// Create HighChart options for chart four (PFX-2699)
function costAndUpliftByShopperJourney(plans, chartSubtitle) {
    const data = _.orderBy(sumActivitiesByShopperJourney(plans), 'totalCost', 'desc');
    // Remove empty items
    const filteredData = _.filter(data, (item) => item.totalCost && item.totalUplift);

    if (filteredData.length === 0) {
        return null;
    }

    return {
        chart: { type: 'column' },
        title: { text: 'Cost and Est. £ Uplift by shopper journey' },
        subtitle: { text: chartSubtitle },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            valuePrefix: '£',
        },
        xAxis: {
            categories: _.map(filteredData, 'shopperJourney'),
            crosshair: true
        },
        yAxis: [
            { title: { text: 'Total cost (£)' } },
            { title: { text: '£ Uplift', }, opposite: true },
        ],
        series: [
            { name: 'Cost', yAxis: 0, data: _.map(filteredData, item => item.totalCost) },
            { name: '£ Uplift', yAxis: 1, data: _.map(filteredData, item => item.totalUplift) },
        ],
        credits,
    };
}

export default {
    impressionsByTouchpoint,
    costAndUpliftByTouchpoint,
    impressionsByRetailer,
    costAndUpliftByRetailer,
    impressionsByShopperJourney,
    costAndUpliftByShopperJourney,
}