import css from './FormControl.module.scss';
import AsFormik from './AsFormik';
import FormGroup from './FormGroup';

/**
 * Select (drop-down)
 * `Options` are of type: `{ label, value }` where both are strings
 */
const SelectField = (props) => {
    const {
        allowEmpty=true,
        className='',
        errorMsg,
        helpMsg,
        label,
        options,
        placeholder,
        required,
        successMsg,
        ...restProps
    } = props;

    // TODO: support complex (object) values. In <options> set the _id and onChange, find object

    const groupClasses = [css['select-container'], className];
    const classes = [css['form-control'], css['select-field']];
    if (errorMsg) classes.push(css['is-invalid']);

    return (
        <FormGroup
            className={groupClasses.join(' ')}
            errorMsg={errorMsg}
            helpMsg={helpMsg}
            label={label}
            required={required}
            successMsg={successMsg}>
            <select
                className={classes.join(' ')}
                {...restProps}>
                { allowEmpty && <option value="">{placeholder}</option> }
                { options.map(opt => <option key={opt.key || opt.value} value={opt.value}>{opt.label}</option>) }
            </select>
            <span className={css['select-arrow']}>▾</span>
        </FormGroup>
    );
};

const FSelectField = AsFormik(SelectField);

export {
    SelectField,
    FSelectField,
};
