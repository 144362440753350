import { get } from 'lodash';

import css from './UserAvatar.module.scss';

function formatName(user) {
    const initials = get(user, 'firstName.0', '') + get(user, 'lastName.0', '');
    return initials ? initials.toUpperCase() : '-';
}

const UserAvatar = (props) => {
    const { user, clickable, className='', ...restProps } = props;

    return (<span color="inverse" className={`${className} ${css.avatar} ${clickable ? css.clickable : ''}`} {...restProps}>
        { formatName(user) }
    </span>);
};

export default UserAvatar;
