import { Link } from 'react-router-dom';
import { useState } from 'react';

import { Icon } from '../';
import css from './ColoredPanel.module.scss';

const ColoredPanel = (props) => {
    const {
        children,
        className,
        color,
        dismissible,
        href,
        linkTo,
        size,
        textColor,
        ...restProps
    } = props;

    const [dismissed, setDismissed] = useState(false);

    if (dismissed) return null;

    const classes = [css['colored-panel']];
    if (color && css[color]) classes.push(css[color]);
    if (textColor && css[textColor]) classes.push(css[textColor]);
    if (size) classes.push(css[size]);
    if (className) classes.push(className);

    const styles = props.styles || {};
    if (color && !css[color]) styles.background = color;
    if (textColor && !css[textColor]) styles.color = textColor;

    const content = (<>
        {dismissible && (<Icon className={css['close-button']} name="cancel" onClick={() => setDismissed(true)} />)}
        {children}
    </>);

    if (href) {
        return <a href={href} className={classes.join(' ').trim()} style={styles} {...restProps}>{content}</a>;
    } else if (linkTo) {
        return <Link className={classes.join(' ').trim()} style={styles} to={linkTo} {...restProps}>{content}</Link>;
    } else {
        return <div className={classes.join(' ').trim()} style={styles}>{content}</div>;
    }
}

export default ColoredPanel;
