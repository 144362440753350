import Select from 'react-select';
import { find, flatten, isArray, isFunction, isObject, map, omit } from 'lodash';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';

import css from './ReactSelect.module.scss';

const ReactSelect = (props) => {
    const {
        controlStyle = {},
        className = '',
        disabled,
        errorMsg,
        helpModal,
        helpMsg,
        isClearable,
        isMulti,
        label,
        noOptionsMessage,
        options,
        required,
        isRequired,
        style = {},
        successMsg,
        tabIndex = 1,
        theme,
        topRightSlot,
        value,
        ...restPropsWithData
    } = props;

    // TODO: run conditionally only when e2e tests are enabled (check an .env variable)
    const fieldName = restPropsWithData['data-smg-field'] || label;
    const restProps = omit(restPropsWithData, 'data-smg-field');

    const compiledControlStyle = base => ({
        ...base,
        ...(isMulti ? {} : { height: '35px' }), // Align the single-select with the other form components, while allowing the multi-select to wrap lines
        marginTop: '3px',
        minHeight: '35px',
        backgroundColor: '#f3f3f3',
        ...controlStyle,
    });

    const classes = [css['react-select-container']];
    if (errorMsg) classes.push(css['is-invalid']);
    if (theme) classes.push(css[`${theme}-theme`]);

    // Find the selected value. We have the following considerations:
    // - The value could potentially be an array of values, in case we use the `isMulti` option
    // - react-select needs a reference to the original option object (not the `value` property)
    // - when we have grouped options, we need to search in options.options
    // - react-select distinguishes between undefined and null/'', so we need to convert undefined to null
    // - we haven't checked how grouped options with object values behave - if this is your case, have fun
    const findSelectedValue = (value) => {
        let selectedValue = find(options, { value });
        if (!isObject(value) && selectedValue === undefined) {
            const allSubOptions = flatten(map(options, option => option.options));
            selectedValue = find(allSubOptions, { value });
        }
        /* This handles situations where a value stored in the DB is no longer in the list of options.
        e.g. if an L2 rCat stored in a plan is changed to an L3 cat, the rCat ID is still stored in the plan
        but doesn't appear in the list of L2 cats, so ReactSelect can't find its label from the options. */
        if ((!isObject(value) && !!value) && selectedValue === undefined) {
            console.error(`Value "${value}" passed to ReactSelect not found in options for field "${fieldName}". This value is no longer a valid option for this field.`);
            return { label: '<ERROR: UNKNOWN VALUE>', value: value };
        }

        // Undefined will not clear the value in Formik context, must be null if not found
        return selectedValue ?? null;
    }
    const selectedValue = isArray(value)
        ? map(value, v => findSelectedValue(v))
        : findSelectedValue(value);

    return (
        <FormGroup
            className={className}
            data-smg-field={fieldName}
            data-smg-field-type="ReactSelect"
            errorMsg={errorMsg}
            helpModal={helpModal}
            helpMsg={helpMsg}
            label={label}
            required={required}
            successMsg={successMsg}
            style={style}
            topRightSlot={topRightSlot}
        >
            <Select
                className={classes.join(' ').trim()}
                classNamePrefix="react-select"
                noOptionsMessage={isFunction(noOptionsMessage) ? noOptionsMessage : () => noOptionsMessage}
                options={options}
                isOptionDisabled={(option) => option?.disabled}
                isClearable={isClearable}
                isDisabled={disabled}
                isMulti={isMulti}
                styles={{
                    control: compiledControlStyle
                }}
                tabIndex={tabIndex}
                value={selectedValue ?? null}
                required={required}
                {...restProps}
            />
        </FormGroup>
    );
}

const FReactSelect = AsFormik(ReactSelect, {
    extractValue: newValue => {
        if (isArray(newValue)) {
            return newValue.map(option => option.value)
        } else {
            return newValue ? newValue.value : null;
        }
    }
});

export {
    ReactSelect,
    FReactSelect,
};
