import _ from 'lodash';

function checkForPartialMatch(queryString, option) {
    // A label could be raw text or rich html (e.g. Trends touchpoint options)
    const label = option.rawLabel || option.label;
    const comparisonLabel = label.toString().toLowerCase();
    const comparisonQueryString = queryString.toLowerCase();

    return _.includes(comparisonLabel, comparisonQueryString);
}

function findMatchingOptions(queryString, options = []) {
    const newOptions = [];

    _.forEach(options, (option) => {
        const isMatch = checkForPartialMatch(queryString, option);

        if (isMatch) {
            newOptions.push(option);
            return;
        }

        // If the parent label does not match, search the children for matches
        const matchingChildren = _.filter(option.options, (option) => checkForPartialMatch(queryString, option));

        // If there are matching children, include the parent but only include matching children
        if (!_.isEmpty(matchingChildren)) {
            newOptions.push({ ...option, options: matchingChildren });
        }
    });

    return newOptions;
}

export default findMatchingOptions;
