import { get, find } from 'lodash-es';
import moment from 'moment';

import {
    calculateActivityDiscountsValue,
    formatters,
    getStartAndEndDatesOfActivitiesAndItems,
    getTotalCostOfActivities,
    getTotalCostOfSelectedActivitiesAndItems,
    getItemCost,
    simpleDate,
} from '../../index.js';

const asdaTemplate = {
    name: 'Asda',
    pandaDocId: 'yVEkq23rvbxomZAt2tjCSA',
    companies: ['6491790fc4a2e08512d38b4f'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'Billed by', value: 'billedBy' },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, items);

        if (!startDate) {
            throw new Error('Unable to create booking form due to missing activity dates.');
        }

        const liveMonthIndex = simpleDate.getMonth(startDate)
        const liveMonth = simpleDate.months().find((m) => m.offset === liveMonthIndex);

        return `[${now}] AMP BM - ${campaign.company.name} - ${campaign.name} - ${liveMonth.label}`;
    },

    getTokens: (campaign, plan, activities, items) => {
        const totalCost = getTotalCostOfSelectedActivitiesAndItems(plan, activities, items);
        const planLink = `https://app.plan-apps.com/planner/campaigns/${campaign._id}/plans/${plan._id}`;

        const asdaSupplierCode = get(find(campaign.company.supplierCodes, { label: 'asda-supplier-code' }), 'value');

        return [
            { label: 'Company name', name: 'company.name', value: campaign.company.name },
            { label: 'ASDA supplier code', name: 'company.asdaSupplierCode', value: asdaSupplierCode || '-' },
            { label: 'Brand name', name: 'brand.name', value: campaign.brands[0].name },
            { label: 'Plan retailer category', name: 'category.name', value: plan.rCategory?.name || '-' },
            { label: 'Plan retailer sub-category', name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
            { label: `Plan owner's email`, name: 'plan.owner.email', value: plan.owner.user.email },
            { label: 'Campaign name', name: 'campaign.name', value: campaign.name },
            { label: 'Link to plan', name: 'plan.link', value: planLink },
            { label: 'Campaign total cost', name: 'campaign.totalCost', value: formatters.asCurrency(totalCost) },
        ];
    },

    getPricingTables: (plan, activities, items) => {
        let rows = [];
        const options = {
            optional: false,
            optional_selected: true,
            qty_editable: false,
        };

        const allActivitiesTotalCost = getTotalCostOfActivities(plan.activities);

        activities.forEach((activity) => {
            const activityTotalDiscountAmount = calculateActivityDiscountsValue(activity);

            rows.push({
                options,
                data: {
                    QTY: 1,
                    ActivityId: activity.uid || 'n/a',
                    ShopperJourney: activity.shopperJourney?.name || 'n/a',
                    Name: activity.touchpoint?.name,
                    Description: activity.description,
                    StartDate: formatters.asDate(activity.startDate),
                    EndDate: formatters.asDate(activity.endDate),
                    Stores: activity.numberOfStores || 'n/a',
                    Price: activity.cost || 0,
                    ActualCost: formatters.asCurrency(activity.actualCost),
                    // 'Discount' is a reserved word for PandaDoc pricing tables - so we need to use our own term
                    DiscountValue: formatters.asCurrency(activityTotalDiscountAmount),
            }});
        });

        items.forEach((item) => {
            rows.push({
                options,
                data: {
                    QTY: 1,
                    ActivityId: 'n/a',
                    ShopperJourney: 'n/a',
                    Name: item.title,
                    Description: '',
                    StartDate: formatters.asDate(item.startDate),
                    EndDate: formatters.asDate(item.endDate),
                    Stores: 'n/a',
                    Price: item.cost ? getItemCost(item, allActivitiesTotalCost) : 0,
                }
            });
        });

        return [{
            name: 'Media Breakdown',
            data_merge: true,
            options: {
                currency: 'GBP',
            },
            sections: [
                {
                    title: 'Activities Section',
                    default: true,
                    rows,
                },
            ],
        }];
    },
};

export default asdaTemplate;
