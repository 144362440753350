import { get } from 'lodash';

import { Http, useAppState, useModal } from 'Components';
import EasterEggFoundModal from './EasterEggFoundModal/EasterEggFoundModal';

import css from './EasterEgg.module.scss';

const EasterEgg = (props) => {
    const { eggId, style } = props;

    const [easterEggs, setEasterEggs] = useAppState('easterEggs');
    const newEasterEggCount = get(easterEggs, 'length') + 1;

    const { show: showEasterEggModal } = useModal({
        clickOutsideToClose: true,
        title: 'You found an egg!',
        template: () => <EasterEggFoundModal numOfEggsFound={newEasterEggCount} />,
    });

    if (easterEggs === null || easterEggs === undefined || easterEggs.includes(eggId)) {
        return null;
    }

    const handleClick = () => {
        Http.put(`/easter-eggs/${eggId}`)
            .then(foundEggs => setEasterEggs(foundEggs));
        showEasterEggModal();
    }

    return (
        <div style={style}>
            <img
                className={css.eggImage}
                src={`https://plan-apps-assets.s3.eu-west-2.amazonaws.com/easter-eggs/${eggId}.png`}
                alt="Easter egg"
                onClick={() => handleClick()}
            />
        </div>
    );
};

export default EasterEgg;
