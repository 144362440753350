import { useCallback, useEffect, useRef, useState } from 'react';

import { filter, forEach, flatten, includes, isEmpty, map, size, uniq, without } from 'lodash';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';
import { Button, Icon, Popover, PopoverTrigger, PopoverContent, Tooltip } from '../';
import { Checkbox } from './Checkbox';

import css from './FormControl.module.scss';
import { isFunction } from 'formik';

const MultiSelect = (props) => {
    const {
        all, // String. When set, if all leaf options are select, instead of showing all the option labels, show this text
        captionRenderer, // Function. The function that renders the selected values. Use when you have rich content options (JSX) and want to render a custom value for selection.
        className = '',
        disabled,
        errorMsg,
        helpMsg,
        label,
        onChange,
        options,
        placeholder,
        required,
        successMsg,
        tabIndex = 1,
        theme,
        value,
        style = {},
        ...restProps
    } = props;

    const [caption, setCaption] = useState(''); // The text that the user has typed
    const [innerValue, setInnerValue] = useState(value || []); // The internal value. Array of option.value
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
        setCaption('');
        setInnerValue(value || []);
    }, [value]);

    const handleSubmit = () => {
        if (isFunction(onChange)) {
            onChange(innerValue.length ? innerValue : []);
        }
        setIsOpen(false);
        setCaption('');
    }

    // When the external value changes, update the innerValue too
    useEffect(() => {
        setInnerValue(value || []);
    }, [value]);

    // When there are groups, it returns all the leaf-options as a flat array. It omits the groups (parent options)
    const getLeafOptions = () => {
        return flatten(map(options, option => option.options ? option.options : option));
    }

    const buildLabel = () => {
        const leafOptions = getLeafOptions();
        if (all) {
            // If the client specified a special text for "all" and all leaf options are selected, show that text
            let allAreSelected = true;
            forEach(leafOptions, option => {
                if (!includes(value, option.value)) {
                    allAreSelected = false;
                }
            });
            if (allAreSelected) {
                return all;
            }
        }
        // In every other, build a comma delimitted list of labels
        const selectedOptions = filter(leafOptions, option => includes(value, option.value));

        if (isFunction(captionRenderer)) {
            return captionRenderer(selectedOptions);
        } else {
            const selectedLabels = map(selectedOptions, option => option.label);
            return selectedLabels.join(', ');
        }
    }

    const handleToggleOption = (option) => {
        const isSelected = includes(innerValue, option.value);
        if (isSelected) {
            setInnerValue(without(innerValue, option.value));
        } else {
            setInnerValue([...innerValue, option.value]);
        }
    }

    // `toggleTo` is either 'on' or 'off'
    // `parentOption` is optional. When undefined, we toggle all leaf nodes
    const handleAllNone = (toggleTo, parentOption) => {
        const optionsToToggle = parentOption ? parentOption.options : getLeafOptions();
        const valuesToToggle = filter(optionsToToggle, option => !option.isDisabled)
            .map(option => option.value);

        const newValue = toggleTo === 'on'
            ? uniq([...innerValue, ...valuesToToggle])
            : without(innerValue, ...valuesToToggle);

        setInnerValue(newValue);
    }

    const buildOptionsList = (options, value) => {
        let list = [];
        let index = 0;
        options.forEach((option, parentIndex) => {
            // Group options by parent if children exist
            if (option.options) {
                // Group header
                list.push(
                    <div key={`group-${parentIndex}`} className="stack-even-center text-small">
                        <div className="text-subtle mt-2 mb-1">{option.label}</div>
                        <div>
                            <span className="like-link mr-2" onClick={() => handleAllNone('on', option)}>All</span>
                            <span className="like-link" onClick={() => handleAllNone('off', option)}>None</span>
                        </div>
                    </div>
                );
                // Group options
                option.options.forEach((option) => {
                    list.push(
                        <Checkbox
                            className="my-1"
                            checked={value.indexOf(option.value) > -1}
                            disabled={option.isDisabled}
                            key={`option-${index}`}
                            label={option.label}
                            onChange={() => { handleToggleOption(option) }}
                            onKeyDown={(event) => {
                                if (['Space', 'Enter'].includes(event.code)) {
                                    event.preventDefault();
                                    handleToggleOption(option);
                                }
                            }}
                        />
                    );
                    index++;
                });
            } else {
                list.push(
                    <Checkbox
                        checked={includes(value, option.value)}
                        className="my-1"
                        disabled={option.isDisabled}
                        key={`option-${index}`}
                        label={option.label}
                        onChange={() => { handleToggleOption(option) }}
                        onKeyDown={(event) => {
                            if (['Space', 'Enter'].includes(event.code)) {
                                event.preventDefault();
                                handleToggleOption(option);
                            }
                        }}
                    />
                );
                index++;
            }
        });
        return list;
    }

    const classes = [css['react-select-container']];
    if (errorMsg) classes.push(css['is-invalid']);
    if (theme) classes.push(css[`${theme}-theme`]);

    // Filter options. First, filter child elements (when there are groups), than filter top-level options
    const textMatches = (text, searchTerm) => !searchTerm || !text || String(text).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    const withFilteredChildren = map(options, topLevelOption => {
        return topLevelOption.options
            ? {
                ...topLevelOption,
                options: topLevelOption.options.filter(childOption => textMatches(childOption.label, caption))
            }
            : topLevelOption;
    });
    const visibleOptions = filter(withFilteredChildren, option => {
        return option.options
            ? option.options.length
            : textMatches(option.label, caption)
    });

    const inputClasses = [css['form-control']];
    if (errorMsg) inputClasses.push(css['is-invalid']);
    if (theme) inputClasses.push(css[`${theme}-theme`]);

    const trigger = (
        <div className={css['multi-select-trigger']}>
            <input
                disabled={disabled}
                className={inputClasses.join(' ')}
                onFocus={() => setIsOpen(true)}
                onChange={(e) => {
                    e.stopPropagation();
                    setCaption(e.target.value);
                }}
                placeholder={placeholder}
                tabIndex={tabIndex}
                type="text"
                value={isOpen ? caption : buildLabel()}
                ref={inputRef}
            />
            <Icon className="like-link no-color" name="expand_more" onClick={() => isOpen ? handleCancel() : setIsOpen(true)} />
        </div>
    );

    const handleKeyDownOnAll = (event) => {
        if (['Enter', 'Space'].includes(event.code)) {
            event.preventDefault();
            handleAllNone('on');
        }
    };

    const handleKeyDownOnNone = (event) => {
        if (['Enter', 'Space'].includes(event.code)) {
            event.preventDefault();
            handleAllNone('off');
        }
    };

    return (
        <FormGroup
            className={className}
            errorMsg={errorMsg}
            helpMsg={helpMsg}
            label={label}
            required={required}
            style={style}
            successMsg={successMsg}
            {...restProps}
        >
            <Popover
                open={isOpen}
                onOpenChange={() => {
                    // Prevent autoclose when the input is focused
                    if (inputRef.current.contains(document.activeElement)) {
                        return
                    }
                    setIsOpen(!isOpen)
                }}
            >
                <PopoverTrigger asChild>{trigger}</PopoverTrigger>
                <PopoverContent
                    align="start"
                    style={{
                        backgroundColor: 'white', //smg-white
                        border: '1px solid gray', //smg-border
                        borderRadius: '0.25rem',
                        margin: '4px 0',
                        zIndex: '1000', //z-index-modal
                    }}
                    onInteractOutside={(e) => {
                        e.preventDefault()
                        // Don't close the dropdown if the user clicks on the input
                        if (inputRef.current.contains(e.target)) {
                            return;
                        }
                        handleCancel()
                    }}
                    onOpenAutoFocus={(e) => {
                        e.preventDefault()
                        // Focus the input when the dropdown opens
                        inputRef.current.focus()
                    }}
                >
                    {/* All none header */}
                    <div className="p-2 stack-even text-small" style={{ backgroundColor: '#eee' }}>
                        <div>{size(innerValue)} selected</div>
                        <div className="stack-end-2">
                            <span className="like-link mr-2" onClick={() => handleAllNone('on')} onKeyDown={handleKeyDownOnAll} tabIndex={tabIndex}>All</span>
                            <span className="like-link" onClick={() => handleAllNone('off')} onKeyDown={handleKeyDownOnNone} tabIndex={tabIndex}>None</span>
                        </div>
                    </div>
                    {/* Options */}
                    <div className={`${css['multi-select-options']} p-2`}>
                        {buildOptionsList(visibleOptions, innerValue)}
                        {isEmpty(visibleOptions) && <div className="text-subtle">No options</div>}
                    </div>
                    {/* Buttons */}
                    <div className={`${css['multi-select-footer']} p-2 stack-end-2`}>
                        <Button onClick={handleCancel} tabIndex={tabIndex}>Cancel</Button>
                        {
                            required && innerValue.length < 1
                                ? (
                                    <Tooltip hover="You need to select at least 1 option">
                                        <Button color="primary" disabled={true} onClick={handleSubmit} tabIndex={tabIndex}>OK</Button>
                                    </Tooltip>
                                )
                                : <Button color="primary" onClick={handleSubmit} tabIndex={tabIndex}>OK</Button>
                        }
                    </div>
                </PopoverContent>
            </Popover>
        </FormGroup >
    );
}

const FMultiSelect = AsFormik(MultiSelect, {
    extractValue: value => value,
});

export {
    MultiSelect,
    FMultiSelect,
};
