import { useState } from 'react';

import { Http, toast } from 'Components';

import type { DigitalMediaSearchCampaign } from './DigitalMediaSearch';
import DigitalMediaSearch from './DigitalMediaSearch';

interface TtdDigitalMediaSearchProps {
    ttdAdvertiserId: string;
    linkedCampaignId?: string;
    onLinkClick: (campaignId: string, supplierKey: string) => void;
}

const TtdDigitalMediaSearch = (props: TtdDigitalMediaSearchProps) => {
    const { ttdAdvertiserId, linkedCampaignId, onLinkClick } = props;

    const [searchCampaigns, setSearchCampaigns] = useState<DigitalMediaSearchCampaign[]>();

    const handleSearchClick = (searchString: string) => {
        return Http
            .post('/in-flight/search/ttd', { ttdAdvertiserId, searchString })
            .then(setSearchCampaigns)
            .catch((error) => {
                console.error(error);
                toast.error('Could not connect to The Trade Desk');
            });
    };

    return (
        <DigitalMediaSearch
            linkedCampaignId={linkedCampaignId}
            linkedCampaignLabel="Linked to The Trade Desk campaign: "
            onLinkClick={(campaignId) => onLinkClick(campaignId, 'theTradeDesk')}
            onSearchClick={handleSearchClick}
            searchCampaigns={searchCampaigns}
            searchFieldLabel="Search name or description..."
        />
    )
};

export default TtdDigitalMediaSearch;
