import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button, FlashError, FPasswordField, FTextField, Http, Modal, Submit } from '../../Components';

const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
};

const validationSchema = yup.object().shape({
    password: yup.string().required('Current password is required.'),
    newPassword: yup.string()
        .min(10, 'Your new password must be at least 10 characters long.')
        .required('New password is required.')
        .max(256,'We really encourage strong passwords, but due to technical limitations, we must enforce a maximum limit as well! Anyway, 256 characters are enough to keep your data secure!'),
    confirmPassword: yup.string().required('Please confirm your new password.').test(
        'samePassword',
        'Must be the same as your new password.',
        function(value) {
            return value === this.parent.newPassword;
        },
    ),
});

const ChangePasswordModal = (props) => {
    const { isOpen, onCancel } = props;

    // Silly performance optimization. Because this is on every page, avoid rendering the content of the modal when hidden
    if (!isOpen) {
        return null;
    }

    const handleSubmit = (values, formikBag) => {
        formikBag.setStatus(undefined);
        Http.post('/users/change-password', values)
            .then(() => {
                onCancel();
            })
            .catch(error => {
                formikBag.setStatus({ errorMessage: error.message || 'An error occured.' });
                formikBag.setSubmitting(false);
                console.error('Failed to change password', error);
            });
    }

    return (
        <Modal show={isOpen} onCancel={onCancel} title="Change password">
            <Formik className="row" initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form className="p-3" noValidate>
                    <FlashError />
                    <FTextField label="Current password" name="password" required type="password" />
                    <FPasswordField label="New password" name="newPassword" required />
                    <FTextField label="Confirm new password" name="confirmPassword" required type="password" />

                    <div className="btn-container-right mt-3">
                        <Button onClick={onCancel}>Cancel</Button>
                        <Submit>Confirm</Submit>
                    </div>
                </Form>
            </Formik>
        </Modal>
    );
}

export default ChangePasswordModal;
