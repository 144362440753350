import _ from 'lodash-es';

/**
 * Takes an array of functions (optionally) returning promises and executes them in batches of the specified size.
 * If a batch takes longer than a second to resolve, execution will wait for resolution before the next batch is processed.
 * This function takes functions which return promises, not promises themselves, so that the point at which the promises are initialised can be controlled.
 * Ignores errors thrown by any input functions by default so that bulk operations (e.g. mass emails) are not blocked by a single rejected promise.
 * @template T
 * @param {() => T | Promise<T>[]} fns An array of functions optionally returning promises
 * @param {number} numPerSecond The number of functions to execute per second
 * @param {boolean} rejectOnError A flag to determine if the function should reject if there is an error
 * @returns {Promise<T[]>}
 */
async function throttleAsyncFns(fns, numPerSecond, rejectOnError = false) {
    const batches = _.chunk(fns, numPerSecond);

    const results = Array(fns.length);

    for (let batchIndex = 0; batchIndex < batches.length; batchIndex += 1) {
        const batch = batches[batchIndex];

        for (let fnIndex = 0; fnIndex < batch.length; fnIndex += 1) {
            const fn = batch[fnIndex];

            try {
                const result = await fn();
                const originalIndex = (numPerSecond * batchIndex) + fnIndex;
                results[originalIndex] = result;
            } catch (err) {
                if (rejectOnError) {
                    return Promise.reject(err);
                }
            }
        }

        await new Promise((resolve) => {
            // There should be no delay for the first batch
            const delay = batchIndex === 0 ? 0 : 1000;

            setTimeout(resolve, delay);
        });
    }

    return results;
}

const delay = (retryCount) => new Promise(resolve => { setTimeout(resolve, 10 ** retryCount); });

/**
 * @typedef {Object} ExponentialBackoffOptions
 * @prop {(Error) => boolean} retryCondition A function which takes an Error and returns a boolean indicating whether the request should be retried
 * @prop {number} retryCount How many times has the promise been retried.
 * @prop {Error} lastError The final error that was thrown by the Promise on the last retry loop
 * @prop {number} retryLimit How many times should the Promise be retried before failing. Retries are exponential on a factor of 10, i.e. 10ms -> 100ms -> 1s -> 10s -> 1.6m
 */

/**
 * 
 * @param {() => Promise<T>} callback A function returning a Promise which needs to be retried
 * @param {ExponentialBackoffOptions} options 
 * @returns 
 */
const exponentialBackoff = (callback, options) => async (...args) => {
    const defaultOptions = {
        retryCount: 0,
        lastError: undefined,
        retryLimit: 5,
        retryCondition: () => true,
    }

    const { retryCount, lastError, retryLimit, retryCondition } = Object.assign(defaultOptions, options);

    if (retryCount > retryLimit) throw new Error(lastError);

    try {
        return await callback(...args);
    } catch (error) {
        if (retryCondition(error)) {
            await delay(retryCount);
            return exponentialBackoff(callback, {
                retryCount: retryCount + 1,
                lastError: error,
                retryLimit: retryLimit,
                retryCondition: retryCondition
            });
        } else {
            return Promise.reject(error)
        }
    }
};

export default {
    throttleAsyncFns,
    exponentialBackoff,
};
