import { Button, Icon, Modal } from '..';
import useModal from '../hooks/useModal';

/**
 * A component that displays a help icon, that trigger a help modal when clicked.
 */
const HelpModal = (props) => {
    const {
        children,
        size, // modal size: sm, lg, xl
        iconClassName = '',
        title,
    } = props;

    const {
        show,
        hide,
    } = useModal({
        clickOutsideToClose: true,
        header: title,
        isVisible: false,
        size,
        template: () => (
            <>
                <Modal.Body>
                    { children }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hide}>Close</Button>
                </Modal.Footer>
            </>
        ),
    });

    return (<Icon className={`like-link subtle ${iconClassName}`} name="info" onClick={show} />);
}

export default HelpModal;
