import { config } from 'smg-common';
import immer from 'immer';

import { Form, ReactSelect, TextField } from '../';

import AdditionalCodingHelpModal from './AdditionalCodingHelpModal';

import css from './AdditionalCodingForm.module.scss';

/**
 * AdditionalCodingForm is used in 3 places: Edit campaign, Edit study in Admin & Edit study in Evaluations
 * Different teams will complete the form in the different locations. Some teams might not know all of the information (or it might not be applicable)
 * For this reason we show/hide certain fields using the flags in the props to simplify the UI
 */

const AdditionalCodingForm = (props) => {
    const { onChange, values, showAdminFields, showNpdField, showStudySpecificFields } = props;

    if (!values) {
        return null;
    }

    const handleChange = (field, value) => {
        const updatedValues = immer(values, (draft) => {
            draft[field] = value;
        });
        onChange(updatedValues);
    };

    const renderRadioButtons = (field, label) => {
        return (
            <div className={`${css.radioRow} row my-3 p-2`}>
                <span className="col-md-6">
                    {label}&nbsp;
                    <AdditionalCodingHelpModal field={field} />
                </span>
                <Form.Radio
                    className="col-md"
                    checked={values[field] === 'Yes'}
                    label="Yes"
                    onClick={() => { handleChange(field, 'Yes') }}
                />
                <Form.Radio
                    className="col-md"
                    checked={values[field] === 'No'}
                    label="No"
                    onClick={() => { handleChange(field, 'No') }}
                />
                <Form.Radio
                    className="col-md"
                    checked={values[field] === 'Unknown'}
                    label="Unknown"
                    onClick={() => { handleChange(field, 'Unknown') }}
                />
                <div className="col-md">
                    {values[field] && <span className="like-link" onClick={() => { handleChange(field, undefined) }}>Clear</span>}
                </div>
            </div>
        );
    };

    // We use ReactSelect instead of Form.Select because this form is used for Campaigns & Studies which store the values in different places
    const renderSelect = (field, label, options) => {
        return (<ReactSelect
            className="col-md-4"
            options={options}
            label={label}
            value={values[field]}
            onChange={(option) => { handleChange(field, option?.value) }}
            isClearable
        />);
    };

    return (<>
        {renderRadioButtons('isFeaturedCompetition', <>Does this campaign feature a <span className="text-bold">competition</span>?</>)}
        {values.isFeaturedCompetition === 'Yes' && <div className="row">
            {renderSelect('competitionPrize', 'Competition prize', config.studies.competitionPrizeOptions)}
            {renderSelect('entryMechanics', 'Entry mechanics', config.studies.entryMechanicsOptions)}
            {renderSelect('onPack', 'On-pack', config.studies.onPackOptions)}
        </div>}

        {showNpdField && <>
            {renderRadioButtons('isNpd', <>Is this campaign for an <span className="text-bold">NPD</span>?</>)}
            {values.isNpd === 'Yes' && <div className="row">
                {renderSelect('npdType', 'NPD type', config.studies.npdTypeOptions)}
            </div>}
        </>}

        {renderRadioButtons('isRetailerEvent', <>Is this campaign part of a <span className="text-bold">retailer event</span>?</>)}

        {showStudySpecificFields && <>
            {renderRadioButtons('newFlash', <>Is there a <span className="text-bold">NEW message</span> on the media?</>)}
            {renderRadioButtons('brandPartnership', <>Does this campaign have a <span className="text-bold">brand partnership</span>?</>)}
            {renderRadioButtons('hasOtherMessaging', <>Does this campaign have <span className="text-bold">other messaging</span>?</>)}
            {values.hasOtherMessaging === 'Yes' && <div className="row">
                {renderSelect('otherMessaging', 'Other messaging', config.studies.otherMessagingOptions)}
            </div>}
        </>}

        {showAdminFields && <>
            {renderRadioButtons('isOnPromotion', <>Is this campaign aligned to a <span className="text-bold">promotion</span>?</>)}
            {values.isOnPromotion === 'Yes' && <div className="row">
                {renderSelect('promoType', 'Promotion type', config.studies.promotionTypes)}
                <TextField
                    className="col-md-4"
                    label="Promotion discount (%)"
                    value={values.promoDiscount}
                    helpMsg="-1 if promotion discount % is unknown"
                    onChange={(value) => { handleChange('promoDiscount', value) }}
                    type="number"
                    isClearable
                />
            </div>}
            {renderRadioButtons('isOwnLabel', <>Is the Featured SKU supermarket <span className="text-bold">own-label</span>?</>)}
        </>}

        {showStudySpecificFields && <>
            <div className={`${css.formSelectHelpIcon} row`}>
                {renderSelect('touchpointCreative', 'Touchpoint creative', config.studies.touchpointCreative)}
                <span className="col mt-3"><AdditionalCodingHelpModal className="col mt-3" field="touchpointCreative" /></span>
            </div>
        </>}

        {showAdminFields && <>
            <div className={`${css.formSelectHelpIcon} row mt-3`}>
                {renderSelect('storeFormat', 'Store format', config.studies.storeFormatOptions)}
                <span className="col mt-3"><AdditionalCodingHelpModal field="storeFormat" /></span>
            </div>
            <div className={`${css.formSelectHelpIcon} row mt-3`}>
                {renderSelect('numberFeaturedSku', 'No. of FSKU(s)', config.studies.noOfFeaturedSkuOptions)}
                <span className="col mt-3"><AdditionalCodingHelpModal field="numberFeaturedSku" /></span>
            </div>
        </>}
    </>)
}

export default AdditionalCodingForm;
