import { Icon } from 'Components';

import css from './Dice.module.scss';

interface DiceProps {
    value: number;
}

const Dice = (props: DiceProps) => {
    const { value } = props;

    if (value >= 1 && value <= 6) {
        return (<Icon name={`dice${value}`} size="24" />);
    }

    return (<div className={css.dice}>{value}</div>);
};

export default Dice;
