import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';

import { Dropdown, Icon } from '../';

import css from './Pill.module.scss';

const Pill = (props) => {
    const { children, className, colour, label, onChange, options, renderAsBlock, small, style = {}, text, textColor, type, value: initialValue, ...rest } = props;
    const [isOpen, setIsOpen] = useState(false);

    const classes = [css.pill];
    if (colour && css[colour]) classes.push(css[colour]);
    if (textColor && css[`text-${textColor}`]) classes.push(css[`text-${textColor}`]);
    if (renderAsBlock && css['block']) classes.push(css['block']);
    if (small) classes.push(css.small);
    if (type && css[type]) classes.push(css[type]);
    if (className) classes.push(className);

    const newStyle = { ...style };
    if (colour && !css[colour]) newStyle.backgroundColor = colour;
    if (textColor && !css[`text-${textColor}`]) newStyle.color = textColor;

    const [value, setValue] = useState(initialValue || options?.[0].value);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleOptionClicked = (option) => {
        if (isFunction(option.onChange)) option.onChange(option);
        setValue(option.value);
        setIsOpen(false);
    };

    if (options) {
        const option = value
            ? options.find((option) => option.value === value)
            : options[0];

        classes.push(css['has-options']);

        if (option.colour && css[option.colour]) classes.push(css[option.colour]);
        if (option.textColor && css[`text-${option.textColor}`]) classes.push(css[`text-${option.colour}`]);
        if (option.colour && !css[option.colour]) {
            newStyle.backgroundColor = option.colour;
        } else {
            delete newStyle.backgroundColor;
        }
        if (option.textColor && !css[`text-${option.textColor}`]) newStyle.textColor = option.textColor || null;

        const trigger = (<span className={classes.join(' ')} style={{ ...newStyle }} {...rest }>
            <span className={css.label}>{option.label}</span>
            <Icon name="arrow_drop_down" size={16} />
        </span>);

        return (<div>
            {/* DropdownMenu would have been preferred here, but the custom styling of each option suits Dropdown better */}
            <Dropdown trigger={trigger} open={isOpen} onTriggerClick={() => setIsOpen(!isOpen)}>
                <div className="stack-col-0 stack-stretch-items">
                    {options.map((option) => (<div
                        className={[css.option, css.pill, css[option.colour], css[`text-${option.textColor}`], option.className].join(' ').trim()}
                        key={option.value}
                        onClick={() => handleOptionClicked(option)}
                        style={{ backgroundColor: option.colour || undefined, borderRadius: 0, color: option.textColor || undefined }}
                    >
                        {option.label}
                    </div>))}
                </div>
            </Dropdown>
        </div>);
    } else {
        return (<span className={classes.join(' ')} style={{ ...newStyle }} {...rest}>{text || children}</span>);
    }
};

export default Pill;
