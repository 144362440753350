/* eslint-disable react-hooks/exhaustive-deps */

import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Panel, TextField } from 'Components';

import FilterOption from './FilterOption/FilterOption';
import findMatchingOptions from './findMatchingOptions';
import sanitiseValue from './sanitiseValue';
import countOptions from './countOptions';

import css from './Filter.module.scss';

const Filter = (props) => {
    const {
        className,
        collapsed,
        helpMsg,
        isDisabled = false,
        label,
        name,
        topSlot,
        onChange,
        options,
        value,
        isDisabledWhenOnlyOneOption = true,
        onSearchInputChange,
    } = props;

    // If initial filter contains values not in the options, they must be removed
    const sanitisedValue = sanitiseValue(value, options);

    const [selectedValues, setSelectedValues] = useState(sanitisedValue);
    const [queryString, setQueryString] = useState('');

    // There are instances where the options or value prop may be changed from the outside (e.g. from a clear all button)
    // selectedValues must be reset in these cases
    useEffect(() => { setSelectedValues(sanitisedValue) }, [options, value]);

    // This is required for Campaigns to work
    // TODO: Ideally the way that we handle the filters in Campaigns should be revised so that this onChange does not need to be called
    useEffect(() => {
        if (_.isEqual(value || [], sanitisedValue)) return;
        if (!_.isFunction(onChange)) return;

        if (_.isEmpty(sanitisedValue)) {
            onChange();
        } else {
            onChange(sanitisedValue);
        }
    }, []);

    const visibleOptions = findMatchingOptions(queryString, options);

    // TODO: This should ideally be removed - we should not be rendering the Filter without options
    if (!options || _.isEmpty(options)) return null;

    const getFilterControlClasses = () => {
        const filterControlClasses = [
            css['search-actions'],
            'stack-even-center-2',
            'stack-stretch',
        ];

        if (_.isEqual(selectedValues, sanitisedValue)) {
            return filterControlClasses.join(' ');
        }

        return [...filterControlClasses, css.visible].join(' ');
    }

    const handleApply = () => {
        if (!_.isFunction(onChange)) return;

        // TODO: This should be changed to pass selectedValues blindly (instead of sometimes passing an array and sometimes passing undefined)
        // First ensure there is no code elsewhere that depends on undefined being passed
        if (_.isEmpty(selectedValues)) {
            onChange();
            return;
        }

        onChange(selectedValues);
    };

    const handleDeselectAll = (show) => {
        show();

        setQueryString('');
        setSelectedValues([]);
    };

    const handleSelectAll = (show) => {
        show();

        setQueryString('');

        const nestedValues = _.flatMap(options, (option) => _.map(option.options, 'value'));

        if (_.isEmpty(nestedValues)) {
            setSelectedValues(_.map(options, 'value'));
            return;
        }

        setSelectedValues(nestedValues);
    };

    const handleToggle = (option) => {
        if (_.includes(selectedValues, option.value)) {
            setSelectedValues(_.filter(selectedValues, (value) => value !== option.value));
            return;
        }

        setSelectedValues([...selectedValues, option.value].sort());
    };

    const handleToggleAll = (option) => {
        const nestedValues = _.map(option.options, 'value');
        const matchingValues = _.intersection(selectedValues, nestedValues);

        if (!_.isEmpty(matchingValues)) {
            setSelectedValues(_.xor(selectedValues, matchingValues));
            return;
        }

        setSelectedValues([...selectedValues, ...nestedValues]);
    };

    return (
        <Panel
            className={`${css.filter} ${className} stack-col-1 stack-stretch stack-stretch-items`}
            collapsed={collapsed}
            flat
            rightSlot={options.length > 1 && (({ show }) => (
                <small className={`${css.controls} stack-2`}>
                    <span className="like-link" onClick={() => handleSelectAll(show)}>All</span>
                    <span className="like-link" onClick={() => handleDeselectAll(show)}>None</span>
                </small>
            ))}
            trigger={label}
        >
            <div className="stack-col-2 stack-stretch-items">
                {topSlot}
                {/* Search bar is not necessary when there are less than 5 options */}
                {countOptions(options) >= 5 && (
                    <TextField
                        isClearable
                        onChange={(newValue) => {
                            onSearchInputChange && onSearchInputChange(newValue);
                            setQueryString(newValue)
                        }}
                        placeholder={`Search ${name.toLowerCase()}`}
                        value={queryString}
                    />
                )}
                {_.isEmpty(visibleOptions) && (<div className="mb-3"><em><small>No options available</small></em></div>)}
                {!_.isEmpty(visibleOptions) && (
                    <div className={`${css['filters-list']} stack-col-1 stack-stretch-items`}>
                        {_.map(visibleOptions, (option) => (
                            <FilterOption
                                key={option.value}
                                isDisabled={isDisabled || (isDisabledWhenOnlyOneOption && options.length === 1)}
                                option={option}
                                onToggle={handleToggle}
                                onToggleAll={handleToggleAll}
                                selectedValues={selectedValues}
                            />
                        ))}
                    </div>
                )}
                {helpMsg && <div className="text-small text-subtle">{helpMsg}</div>}
                <div className={getFilterControlClasses()}>
                    <Button color="primary" onClick={handleApply} size="small">Apply</Button>
                    <small className="like-link" onClick={() => setSelectedValues(sanitisedValue)}>Reset</small>
                </div>
            </div>
        </Panel>
    );
};

export default Filter;
