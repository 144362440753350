import { useState } from 'react';

import { Button, Modal as ModalTemp, useAppState } from 'Components';

// TS is inferring the Modal types incorrectly
// This workaround saves us from having to add types to the Modal at this time
const Modal = ModalTemp as any;

const EasterEggHuntIntroModal = () => {
    const [isEasterEggHuntLive] = useAppState('isEasterEggHuntLive');
    const [isVisible, setIsVisible] = useState(true);

    if (!isEasterEggHuntLive) return null;

    const hasSeenEasterEggHuntIntro = localStorage.getItem('hasSeenEasterEggHuntIntro');

    const handleCancel = () => {
        localStorage.setItem('hasSeenEasterEggHuntIntro', 'true');
        setIsVisible(false);
    };

    const handleButtonClick = () => {
        window.open('https://www.plan-apps.com/easter-terms', '_blank');
    };

    return (
        <Modal
            title="Happy Easter!"
            show={isVisible && !hasSeenEasterEggHuntIntro}
            onCancel={handleCancel}
            clickOutsideToClose
        >
            <Modal.Body>
                <img
                    src="https://plan-apps-assets.s3.eu-west-2.amazonaws.com/easter-eggs/easter-egg-hunt-intro.png"
                    alt="easter-egg-found"
                    width="100%"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={handleButtonClick}>View terms</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EasterEggHuntIntroModal;
