import { CSSProperties } from 'react';
import { Icon } from 'Components';

import css from './ApprovalStateIcon.module.scss'

interface ApprovalStateIconProps {
    status: 'draft' | 'submitted' | 'approved' | 'rejected' | 'unactioned';
    style?: CSSProperties;
}

const ApprovalStateIcon = (props: ApprovalStateIconProps) => {
    const { status, style } = props;

    const icons = {
        draft: 'pending',
        submitted: 'watch_later',
        approved: 'check_circle',
        rejected: 'cancel',
        unactioned: 'hourglass_empty'
    } as const;

    return <Icon style={style} className={css[status]} name={icons[status]} />;
}

export default ApprovalStateIcon;
