import _ from 'lodash-es';
import { default as numbers} from '../numbers.js';

// Returns true, if the nomination has all the data needed before it becomes `completed`
const isNominationCompletable = (nomination) => {
    const { ensureNumber } = numbers;
    let completeNomination = nomination.company
        && nomination.name
        && nomination.brands[0] !== null
        && nomination.rCategory
        && nomination.posCycle
        && nomination.activationPeriod
        && !_.isEmpty(nomination.dod?.files)
        && !_.isEmpty(nomination.activities)
        && !_.isEmpty(nomination.skus);
    nomination.activities.forEach(activity => {
        const hasCost = Number.isFinite(ensureNumber(activity.cost));
        const hasRatecardCostPerStore = Number.isFinite(ensureNumber(activity.ratecardCostPerStore));
        const hasRequestedStores = Number.isFinite(ensureNumber(activity.requestedStores));

        if (!hasCost || !hasRatecardCostPerStore || !hasRequestedStores || !activity.touchpoint) {
            completeNomination = false;
        }
    });
    nomination.skus.forEach(sku => {
        if (!sku.number || !sku.description) completeNomination = false;
    });
    return completeNomination;
};

export {
    isNominationCompletable,
};
