import { debounce } from 'lodash';

import { TextField as Input, useUrl6 } from '../'; 

const search = debounce(function(q, setQueryParams) {
    setQueryParams({ q: q || undefined });
}, 500);

const SearchCampaigns = (props) => {
    const { className } = props;
    const { queryParams, setQueryParams } = useUrl6();

    const handleSearchInputChanged = (q) => {
        search(q, setQueryParams);
    };

    return (<Input
        className={className}
        defaultValue={queryParams.q}
        onChange={handleSearchInputChanged}
        placeholder="Search..."
    />);
};

export default SearchCampaigns;
