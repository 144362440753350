import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { Http } from '../';
import useAppState from './useAppState';

const DEFAULT_CACHE_DURATION = 60000; // 1 minute

const useReferenceData = (url, options = {}) => {
    const { cache, cacheDuration = DEFAULT_CACHE_DURATION, name } = options;

    function fetchItems(force) {
        const timeSinceLastFetch = Date.now() - (referenceData?.timestamp || 0);
        const fetchAgain = force || !cache || (timeSinceLastFetch > cacheDuration);
        if (fetchAgain) {
            return Http.get(url)
                .then((items) => {
                    if (!isEqual(items, referenceData?.items)) {
                        setReferenceData({
                            items,
                            timestamp: new Date(),
                        });
                    }
                })
                .catch((err) => {
                    // TODO: How should we handle this case?
                    throw err;
                });
        }
    }

    const [referenceData, setReferenceData] = useAppState(`reference.data[${name || url}]`);

    useEffect(() => {
        fetchItems();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [referenceData?.items, fetchItems];
};

export default useReferenceData;
