import { PageTypeWithCategories } from '../types';

const attributionScopeOptions = ['unknown', 'sameSku', 'sameSkuCategory', 'sameSkuCategoryBrand'] as const;
const bidStrategyOptions = ['clicks', 'conversion', 'revenue', 'unknown'] as const;
const cappingTypeOptions = ['day', 'session'] as const;
const clickAttributionWindowOptions = ['7D', '14D', '30D', 'unknown'] as const;
const lineItemStatusOptions = ['active', 'paused', 'scheduled', 'ended', 'budgetHit', 'noFunds', 'draft', 'archived'] as const;
const namingConventionOptions = [
    'campaign-id',
    'campaign-name',
    'plan-id',
    'plan-name',
    'activity-id',
    'activity-uid',
    'activity-description',
    'touchpoint-format-key',
] as const;
const pacingOptions = ['standard', 'accelerated', 'unknown'] as const;
const pageTypeOptions = ['category', 'checkout', 'home', 'merchandising', 'productDetail', 'search'] as const;
const pageTypesWithCategories = ['category', 'productDetail', 'merchandising'] as PageTypeWithCategories[];
const viewAttributionWindowOptions = ['none', 'unknown', '1D', '7D', '14D', '30D'] as const;

export {
    attributionScopeOptions,
    bidStrategyOptions,
    cappingTypeOptions,
    clickAttributionWindowOptions,
    lineItemStatusOptions,
    namingConventionOptions,
    pacingOptions,
    pageTypeOptions,
    pageTypesWithCategories,
    viewAttributionWindowOptions,
};
