import { connect } from 'formik';
import { get } from 'lodash';

import Icon from '../Icon/Icon';

import css from './FormGroup.module.scss';

const RadioButton = (props) => {
    const {
        checked,
        className,
        defaultChecked,
        disabled,
        errorMsg,
        helpMsg,
        label,
        required,
        successMsg,
        ...restProps
    } = props;

    return (
        <div className={className}>
            <div>
                <label className={css.label}>
                    <Icon
                        className={`like-link ${disabled ? css['radio-button-disabled'] : css['radio-button']}`}
                        name={`radio_button_${(checked || defaultChecked) ? 'checked' : 'unchecked'}`}
                    />
                    { label }
                    <input
                        checked={checked}
                        disabled={disabled}
                        style={{
                            display: 'none'
                        }}
                        type="radio"
                        {...restProps}
                    />
                    { required && <span className={css.required}>*</span> }
                </label>
            </div>
            { errorMsg && <div className={css.errorMsg}>{errorMsg}</div> }
            { helpMsg && <div className={css.helpMsg}>{helpMsg}</div> }
            { successMsg && <div className={css.successMsg}>{successMsg}</div> }
        </div>
    );
};

// Wraps a Checkbox and attaches to formik context
// NOTE: This is effectively a copy-paste from AsFormik, but is needed due to the
// differences between checkboxes and input elements in React (checked vs value etc)
const FRadioButton = connect(({ name, formik, ...restProps }) => {
    const isTouched = get(formik.touched, name);
    const errorMsg = isTouched ? get(formik.errors, name) : undefined;
    const value = get(formik.values, name, '');

    return (
        <RadioButton
            checked={Boolean(value)}
            errorMsg={errorMsg}
            name={name}
            onBlur={() => formik.setFieldTouched(name, true)}
            onChange={e => formik.setFieldValue(name, e.target.checked)}
            {...restProps}
        />
    );
});


export {
    RadioButton,
    FRadioButton,
};
