import { useState } from 'react';

import All from './All';
import One from './One';
import CheckboxListContext from './CheckboxListContext';

// The top-level tag that wraps a list of checkboxes. It stores the state (list of selected items)
const CheckboxList = (props) => {
    const [selected, setSelected] = useState([]);

    return (
        <CheckboxListContext.Provider value={{ selected, setSelected }}>
            { props.children }
        </CheckboxListContext.Provider>
    );
};

CheckboxList.All = All;
CheckboxList.One = One;

export default CheckboxList;
