import { useContext } from 'react';
import { isFunction } from 'lodash';

import { DropdownMenu, Icon } from '..';

import CheckboxListContext from './CheckboxListContext';

// The checkbox that is toggling all the items in the list and is providing the actions dropdown
const All = (props) => {
    const {
        allItems,
        bulkActions: originalBulkActions, // Can be an array of {label, onClick}, OR a function that takes the selectedId and returns such an array
    } = props;

    const { selected, setSelected } = useContext(CheckboxListContext);

    const bulkActions = isFunction(originalBulkActions)
        ? originalBulkActions(selected)
        : originalBulkActions;

    // TODO: and exactly the same items!
    const allAreSelected = allItems.length === selected.length;
    const onlySomeAreSelected = !allAreSelected && selected.length > 0;

    // Determine the correct icon for the checkbox
    let iconName;
    if (allAreSelected) {
        iconName = 'check_box';
    } else if (onlySomeAreSelected) {
        iconName = 'indeterminate_check_box';
    } else {
        iconName = 'check_box_outline_blank';
    }

    const handleToggleAll = () => {
        if (allAreSelected || onlySomeAreSelected) {
            setSelected([]);
        } else {
            setSelected(allItems);
        }
    }

    const handleBulkActionClicked = (action) => {
        action.onClick(selected);
        setSelected([]); // TODO: is this the expected behaviour? Maybe make it a `prop`?
    };

    return (
        <>
            <Icon className="like-link" name={iconName} onClick={handleToggleAll} style={{ zoom: '0.8' }} />
            <DropdownMenu
                trigger={<Icon className="like-link no-color" name="keyboard_arrow_down" />}
                options={bulkActions.map(action => ({ label: action.label, onClick: () => handleBulkActionClicked(action) }))}
                styles={{
                    items: {
                        lineHeight: '1.5em',
                        fontWeight: 'normal',
                    }
                }}
            />
        </>
    );
};

export default All;
