import { config, simpleDate } from 'smg-common';
import { Activity, Workflow } from 'Types';

const canUserApproveWorkflow = (approvers: Workflow['approvers'], userId: string) => {
    const flattenedUsers = approvers.flatMap((approver) => approver.users.flatMap((user) => user._id));

    return flattenedUsers.includes(userId);
};

const canUserEditWorkflow = (submitters: Workflow['submitters'], userId: string) =>
    submitters.flatMap((user) => user._id).includes(userId);

const getDateColor = (date: string) => {
    const diff = simpleDate.diff(date, simpleDate.today(), false) as number;

    if (diff < 7) {
        return 'color-danger';
    } else if (diff < 14) {
        return 'color-warn';
    } else {
        return '';
    }
};

function getWorkflowCreativeType(activity: Pick<Activity, 'creativeWorkflow'>, workflowObjectId: string) {
    const { creativeWorkflow } = activity;

    // Map over the workflow types and match the key with ObjectId
    for (const key of config.workflowTypes) {
        if (creativeWorkflow && creativeWorkflow[key as keyof typeof creativeWorkflow] === workflowObjectId) {
            return key;
        }
    }
}

const getDeadlineDate = (
    activity: Pick<
        Activity,
        | 'briefingDeadline'
        | 'briefingApprovalDeadline'
        | 'initialCreativeApprovalDeadline'
        | 'initialCreativeDeadline'
        | 'artworkApprovalDeadline'
        | 'artworkDeadline'
    >,
    workflow: Workflow,
    workflowType: string,
) => {
    switch (workflowType) {
    case 'activityBrief':
        return workflow.status === 'draft' ? activity.briefingDeadline?.date : activity.briefingApprovalDeadline?.date;
    case 'initialCreative':
        return workflow.status === 'draft' ? activity.initialCreativeDeadline?.date : activity.initialCreativeApprovalDeadline?.date;
    case 'finalCreative':
        return workflow.status === 'draft' ? activity.artworkDeadline?.date : activity.artworkApprovalDeadline?.date;
    default:
        return '';
    }
};

const getDeadlineDateLabel = (
    workflow: Workflow,
    workflowType: string,
) => {
    switch (workflowType) {
    case 'activityBrief':
        return workflow.status === 'draft' ? 'Briefing deadline' : 'Briefing approval deadline';
    case 'initialCreative':
        return workflow.status === 'draft' ? 'Initial creative deadline' : 'Initial creative approval deadline';
    case 'finalCreative':
        return workflow.status === 'draft' ? 'Final creative deadline' : 'Final creative approval deadline';
    default:
        return '';
    }
};

const getWorkflowTypeLabel = (workflowType: string) => {
    switch (workflowType) {
    case 'activityBrief':
        return 'Creative brief';
    case 'initialCreative':
        return 'Initial creative';
    case 'finalCreative':
        return 'Final creative';
    default:
        return '';
    }
};

const isWorkflowOverdue = (deadlineDate: string | undefined, workflowStatus: string) => {
    return  simpleDate.isAfter(simpleDate.today(), deadlineDate) && workflowStatus !== 'approved';
}

export {
    canUserApproveWorkflow,
    canUserEditWorkflow,
    getDateColor,
    getWorkflowCreativeType,
    getDeadlineDate,
    getDeadlineDateLabel,
    getWorkflowTypeLabel,
    isWorkflowOverdue
};
