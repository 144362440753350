import { CSSProperties } from 'react';
import css from './Tabs.module.scss';

interface Option {
    label: string;
    value: string;
}

interface TabsProps {
    options: Option[];
    value: string;
    onChange: (newValue: string) => void;
    className?: string;
    style?: CSSProperties;
}

const Tabs = (props: TabsProps) => {
    const { className, onChange, options, style, value } = props;

    return (
        <div className={className} style={style}>
            {options.map((option) => (
                <div
                    key={option.value}
                    className={option.value === value ? [css.tab, css.selectedTab].join(' ') : css.tab}
                    onClick={() => onChange(option.value)}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
