const permissions = [
    // Evaluations
    {
        slug: 'viewEvaluationsStudies',
        label: 'View evaluation studies',
        helpMsg: 'Allows users to view Evaluations studies.',
    },
    {
        slug: 'editEvaluationsStudies',
        label: 'Edit evaluation studies',
        helpMsg: 'Allows users to edit Evaluations studies.',
    },
    {
        slug: 'editEvaluationAttachments',
        label: 'Edit evaluation attachments',
        helpMsg: 'Allows users to create/edit evaluation attachments.',
    },
    {
        slug: 'deleteEvaluationAttachments',
        label: 'Delete evaluation attachments',
        helpMsg: 'Allows users to delete evaluation attachments.',
    },
    {
        slug: 'viewDraftData',
        label: 'View draft data',
        helpMsg: 'Shows a statuses dropdown in Evaluations to allow filtering (in or) out studies by their status. Also allows you to view draft campaigns in Campaigns and Evaluations.',
    },

    // Campaigns
    {
        slug: 'exportBriefingForms',
        label: 'Export briefing forms',
        helpMsg: 'Can export briefing forms.',
    },
    {
        slug: 'editBudgets',
        label: 'Edit budgets in Campaigns',
        helpMsg: 'Edit the budget pots and retailer/brand spend.',
    },
    {
        slug: 'viewCampaignsCampaigns',
        label: 'View campaigns in Campaigns',
        helpMsg: 'Read-only access to campaign objects, including plans & activities.',
    },
    {
        slug: 'editCampaignsCampaigns',
        label: 'Edit campaigns in Campaigns',
        helpMsg: 'Create/edit all campaign objects, including plans & activities.',
    },
    {
        slug: 'deleteCampaignsCampaigns',
        label: 'Delete campaigns in Campaigns',
        helpMsg: 'Delete all campaign objects, including plans & activities.',
    },
    {
        slug: 'createCriteoCampaigns',
        label: 'Create Criteo campaigns',
        helpMsg: 'Can create Criteo campaigns from the Campaigns app.',
    },
    {
        slug: 'editCampaignsDiscounts',
        label: 'Edit discounts in Campaigns',
        helpMsg: 'Add/edit discounts in campaign activities.',
    },

    // Flexi-reports
    {
        slug: 'editFlexiReports',
        label: 'Edit reports',
        helpMsg: 'Create/edit/delete & export reports.',
    },

    // Admin - miscs
    { app: 'admin', slug: 'viewAdminPages', label: 'View admin pages', helpMsg: 'Can see the navigation to the admin pages & the admin reference data.' },
    { slug: 'viewFeedback', label: 'View user feedback' },
    {
        slug: 'runDevTasks',
        label: 'Run developer tasks',
        helpMsg: 'Can trigger developer scripts: cronjobs, migrations, smoke-tests',
    },
    // (If this changes to a permission for each dump see PR in PFX-3763)
    {
        slug: 'exportSystemData',
        label: 'Export system data',
        helpMsg: 'Can download excel dumps of system data: studies, companies etc. Can also view flagged data',
    },
    {
        slug: 'manageMyTeam',
        label: 'Manage my team',
        helpMsg: 'Can see the users that have access to the user\'s own company',
    },

    // Admin - collections
    { slug: 'destroyData', label: 'Destroy data', helpMsg: 'Can perform operations that irrevocably delete data (eg hard-delete).' },
    { slug: 'viewApps', label: 'View apps' },
    { slug: 'editApps', label: 'Edit/delete apps' },
    { slug: 'viewBookingForms', label: 'View booking forms', helpMsg: 'Can view booking forms in Admin. Create booking form is handled by the PandaDoc policy.' },
    { slug: 'viewBrands', label: 'View brands' },
    { slug: 'editBrands', label: 'Edit/delete brands' },
    { slug: 'manageBriefingForms', label: 'Manage briefing forms', helpMsg: 'Can view briefing forms in Admin. Can update activity status.' },
    { slug: 'viewCampaigns', label: 'View campaigns' },
    { slug: 'editCampaigns', label: 'Edit/delete campaigns' },
    { slug: 'viewCompanies', label: 'View companies' },
    { slug: 'editCompanies', label: 'Edit/delete companies' },
    { slug: 'viewFeedback', label: 'View feedback' },
    { slug: 'editFeedback', label: 'Edit/delete feedback' },
    { slug: 'viewMediaChannels', label: 'View media channels' },
    { slug: 'editMediaChannels', label: 'Edit/delete media channels' },
    { slug: 'viewMediaVendors', label: 'View media vendors'},
    { slug: 'editMediaVendors', label: 'Edit/delete media vendors'},
    { slug: 'viewRetailers', label: 'View retailers' },
    { slug: 'editRetailers', label: 'Edit/delete retailers' },
    { slug: 'viewRoles', label: 'View roles' },
    { slug: 'editRoles', label: 'Edit/delete roles' },
    { slug: 'viewShopperJourneys', label: 'View shopper journeys' },
    { slug: 'editShopperJourneys', label: 'Edit/delete shopper journeys' },
    { slug: 'viewStoreSets', label: 'View store sets' },
    { slug: 'editStoreSets', label: 'Edit/delete store sets' },
    { slug: 'viewStores', label: 'View stores' },
    { slug: 'editStores', label: 'Edit/delete stores' },
    { slug: 'viewStudies', label: 'View studies' },
    { slug: 'editStudies', label: 'Edit/delete studies' },
    { slug: 'viewTips', label: 'View tips' },
    { slug: 'editTips', label: 'Edit/delete tips' },
    { slug: 'viewTouchpoints', label: 'View touchpoints' },
    { slug: 'editTouchpoints', label: 'Edit/delete touchpoints' },
    { slug: 'manageTtdUploads', label: 'Manage TTD uploads', helpMsg: 'Can upload in-flight campaign data from The Trade Desk in Admin.' },
    { slug: 'viewUsers', label: 'View users' },
    { slug: 'editUsers', label: 'Edit/delete users' },
    { slug: 'viewLiveAudiences', label: 'View live audiences' },
    { slug: 'canApproveBespokeAudiences', label: 'Approve bespoke audiences' },
    { slug: 'viewRCategories', label: 'View rCategories' },
    { slug: 'editRCategories', label: 'Edit/delete rCategories' },
    { slug: 'viewXCategories', label: 'View xCategories' },
    { slug: 'editXCategories', label: 'Edit/delete xCategories' },
    { slug: 'viewGlossary', label: 'View glossary' },
    { slug: 'editGlossary', label: 'Edit/delete glossary' },
    { slug: 'viewWhatsNew', label: `View what's new` },
    { slug: 'editWhatsNew', label: `Edit/delete what's new` },
    { slug: 'viewPopUps', label: `View pop-ups` },
    { slug: 'editPopUps', label: `Edit/delete pop-ups` },
    { slug: 'viewWorkflowTemplates', label: `View workflow templates` },
    { slug: 'editWorkflowTemplates', label: `Edit/delete workflow templates` },
    { slug: 'startWorkflow', label: 'Start Workflow' },
    { slug: 'approveAllWorkflowGroups', label: 'Override workflow approvals', helpMsg: 'For workflows where the user is a workflow owner - can override approval groups setting the entire workflow as approved.' },
    { slug: 'viewCriteoAccounts', label: 'View Criteo accounts'},
    { slug: 'editCriteoAccounts', label: 'Edit Criteo accounts'},
    { slug: 'managePinterest', label: 'Manage Pinterest integration' },

    // Allocator - New World
    { slug: 'manageAllocations', label: 'Manage Allocations', helpMsg: 'Create, edit and delete POS Cycles, Allocate, Download kit list, Delete nominations' },

    // Trends
    { slug: 'viewStudyDetails', label: 'MvM - View study details', helpMsg: 'Can see study details in the MvM graph when a data point is click on' },

    // Self Serve
    { slug: 'setPricingStructure', label: 'Set pricing structure', helpMsg: 'Can change the Self-Serve pricing structure' },
    { slug: 'approveSelfServeActivities', label: 'Approve/reject activities', helpMsg: 'Can approve or reject Self-Serve activities' },

    // InFlightReports
    { slug: 'manageInFlightReports', label: 'Manage InFlight reports', helpMsg: 'Can publish draft InFlight reports' },
] as const;

type Permission = typeof permissions[number]['slug'];
type PermissionSet = Record<Permission, boolean>;

export type {
    Permission,
    PermissionSet,
};

export default permissions;
