import { formatters } from 'smg-common';

const AsCurrency = (props) => {
    // GOTCHA: if you pass `null` in any of the options, it will override the default
    // value, whereas `undefined` will result in the default value being used
    const {
        decimals,
        na,
        value,
    } = props;

    return formatters.asCurrency(value, { decimals, na });
}

export default AsCurrency;
