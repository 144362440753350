import { Children, cloneElement } from 'react';
import { isFunction } from 'lodash';

import css from './Button.module.scss';

const ButtonGroup = (props) => {
    const {
        activeColor,
        children,
        color,
        onChange,
        pushPull,
        value,
        ...restProps
    } = props;

    const handleChange = (event, newValue) => {
        if (isFunction(onChange)) {
            onChange(event, newValue);
        }
    };

    return (
        <div className={css['button-group']} {...restProps}>
            {
                Children.map(children, (child, index) => {
                    if (!child) {
                        return null;
                    }
                    const isSelected = index === value || (value !== undefined && child.props.value === value);
                    return cloneElement(child, {
                        color: isSelected ? activeColor : color,
                        className: `${child.className}${isSelected && !color ? ` ${css.selected}` : ''}`,
                        onClick: (event) => {
                            event.persist();

                            if (child.props.value === value && !pushPull) return false;

                            const newValue = (child.props.value === value && pushPull) ? undefined : child.props.value;
                            if (pushPull || value !== newValue) {
                                handleChange(event, newValue);
                                if (isFunction(child.onClick)) {
                                    child.onClick(event, newValue);
                                }
                            }
                        }
                    });
                })
            }
        </div>
    );
}

export default ButtonGroup;
