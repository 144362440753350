import axios from 'axios';
import { createRef, useState } from 'react';
import moment from 'moment';
import { simpleDate } from 'smg-common';
import { compact, find, isFunction, range } from 'lodash';
import { Link } from 'react-router-dom';

import EditEventModal from './EditEventModal';
import { Icon, Pill, dialog, file, toast, useUrl6 } from '../../';

import css from './Timeline.module.scss';
import Tooltip from '../../Tooltip/Tooltip';

const BORDER_RADIUS = '4px';
const PADDING = '2px';

const AnnualTimeline = (props) => {
    const { endDate, events, onEventChange, onEventDelete, startDate, hideMediaCosts, exportCalendar, setExportCalendar } = props;
    const calendarRef = createRef();
    const [editableEventId, setEditableEventId] = useState(undefined);
    const from = simpleDate.startOfDay(startDate, 'day');

    const { queryParams } = useUrl6();
    const { view } = queryParams;

    // The calendar render weeks starting with the provided start date, which is not Sun or Mon necessarily.
    // .endOf('week') moves the date to the next Saturday, but we need to make sure that the calendar week
    // goes up to the arbitrary day of the 'from' date, that is either on or after moment's endOf('week') day.
    const endOfWeek = simpleDate.endOfWeek(endDate);
    const to = simpleDate.getDayOfWeek(endOfWeek, 7 + simpleDate.getDay(from) - 1);
    // Add one for an entire year plus one month
    const durationInMonths = simpleDate.diffMonths(to, from) + 1;
    const durationInWeeks = simpleDate.diffWeeks(to, from) + 1;

    const visibleEvents = new Set();
    const visibleEventIds = new Set();

    // Will be used to render the events
    const months = range(durationInMonths).map(index => {
        const startDate = simpleDate.plusMonths(from, index);
        const endDate = simpleDate.plusMonths(startDate, index + 1);
        const monthRef = createRef();
        const startOfMonth = simpleDate.startOfMonth(startDate);
        // The week containing the 1st of the month will be included in the new month,
        // including any days trailing days from the previous month
        const startOfFirstWeek  = simpleDate.startOfWeek(startOfMonth);
        return {
            index,
            ref: monthRef,
            startDate,
            endDate,
            startOfMonth,
            startOfFirstWeek,
            // We check 6 times as no month has more than 4 full weeks, +2 to check for part weeks at the start/end
            year: simpleDate.format(startDate, 'YYYY'),
            monthName: simpleDate.format(startDate, 'MMM'),
            monthOfYear: simpleDate.getMonth(startDate),
            weeks: compact(range(6).map((week, weekIndex) => {
                const startOfWeek = simpleDate.plusWeeks(startOfFirstWeek, weekIndex);
                const endOfWeek = simpleDate.endOfWeek(startOfWeek);
                // Do not include this week if the month changes during the week, it will be included in the next month
                if (simpleDate.getMonth(endOfWeek) !== simpleDate.getMonth(startDate)) {
                    return null;
                }
                return {
                    classes: [
                        weekIndex === 0 ? '' : css.hidden,
                        'stack-end-0',
                        'stack-stretch',
                    ],
                    startOfWeek,
                    weekOfYear: simpleDate.getWeek(startOfWeek),
                    events: events.filter((event) => {
                        const id = [event.id];
                        const bookingIsSame = simpleDate.isSame(event.deadlines?.booking?.date, startOfWeek);
                        const artworkIsSame = simpleDate.isSame(event.deadlines?.artwork?.date, startOfWeek);

                        if (bookingIsSame) id.push('booking-deadline');
                        if (artworkIsSame) id.push('artwork-deadline');
                        const eventId = id.join('-');
                        const isAlreadyRendered = visibleEvents.has(eventId);

                        if (isAlreadyRendered || !event.startDate || !event.endDate) {
                            return null;
                        }

                        const eventStartDate = simpleDate.startOfWeek(event.startDate);
                        const eventEndDate = simpleDate.endOfWeek(event.endDate);

                        const isEventLive = simpleDate.isSame(eventStartDate, startOfWeek)
                            || (simpleDate.isSame(eventStartDate, startOfWeek) && simpleDate.isAfter(eventEndDate, startOfWeek) && !isAlreadyRendered);
                        const isDeadlineLive = artworkIsSame || bookingIsSame;

                        // An event duration on the calendar may be the remaining duration (if start date < now), or the actual (end - start) date of the activity
                        if (simpleDate.isSameOrAfter(eventStartDate, from) && simpleDate.isSameOrBefore(eventEndDate, to)) {
                            event.duration = Math.ceil(simpleDate.diff(eventEndDate, eventStartDate, false) / 7);
                        } else if (simpleDate.isBefore(eventStartDate, from) && simpleDate.isSameOrAfter(eventEndDate, from) && simpleDate.isSameOrBefore(eventEndDate, to)) {
                            // Event started before start of calendar, but ended within the calendar range
                            const eventDuration = Math.ceil(simpleDate.diff(eventEndDate, from, false) / 7);
                            // 'duration' is the total calendar duration
                            event.duration = eventDuration > durationInWeeks ? durationInWeeks : eventDuration;
                        } else if (simpleDate.isSameOrAfter(eventStartDate, from) && simpleDate.isAfter(eventEndDate, to)) {
                            // Event started  within the calendar range, but ended after end of calendar
                            const eventDuration = Math.ceil(simpleDate.diff(to, eventStartDate, false) / 7);
                            // 'duration' is the total calendar duration
                            event.duration = eventDuration > durationInWeeks ? durationInWeeks : eventDuration;
                        } else if (simpleDate.isBefore(eventStartDate, from) && simpleDate.isAfter(eventEndDate, to)) {
                            // Event started before start of calendar, but ended after end of calendar
                            event.duration = durationInWeeks;
                        } else {
                            event.duration = 1;
                        }
                        const hasDuration = event.duration > 0;

                        const isVisibleEvent = (isDeadlineLive || isEventLive) && !isAlreadyRendered && hasDuration;
                        if (isVisibleEvent) {
                            visibleEventIds.add(event.id);
                            visibleEvents.add(eventId);
                        }

                        return isVisibleEvent;
                    })
                        .map((event) => ({
                            ...event,
                            endDate: event.endDate ? new Date(event.endDate) : undefined,
                            startDate: event.startDate ? new Date(event.startDate) : undefined,
                        })),
                    index: weekIndex,
                };
            })),
        };
    });

    // Calendar export to excel
    if (exportCalendar) {
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true,
            responseType: 'blob',
            headers: {
                'x-client-app-id': process.env.REACT_APP_CLIENT_APP_ID,
            },
        });
        api.post(`/planner/campaigns/export-calendar?hideMediaCosts=${hideMediaCosts}&view=${view}`, months)
            .then((res) => {
                const now = moment().format('YYYYMMD-HHmm');
                const filename = `${now} Calendar Export.xlsx`;

                file.downloadBlob(filename, res.data);
            })
            .catch((err) => {
                console.error(err);
                toast.error('Failed to download yearly calendar. Please try again.')
            })
            .finally(() => setExportCalendar(false));
    }

    const showEditModal = (event) => {
        setEditableEventId(event.id);
    };

    const handleEventUpdated = (newEvent) => {
        const event = find(events, { id: newEvent.id });
        // When we change the start date, we need to offset the deadlines as well
        const startDate = simpleDate.startOfDay(newEvent.startDate);
        const endDate = simpleDate.endOfDay(newEvent.endDate);
        event.duration = simpleDate.diff(endDate, startDate, false);
        event.startDate = startDate;
        event.endDate = endDate;

        if (event.deadlines?.artwork) {
            const touchpointArtworkDeadline = event.deadlines?.artwork?.touchpointArtworkDeadline;
            if (touchpointArtworkDeadline) {
                event.deadlines.artwork.date = simpleDate.minusDays(startDate, touchpointArtworkDeadline);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.artwork.user = null;
                event.deadlines.artwork.updatedAt = null;
            }
        }

        if (event.deadlines?.booking) {
            const touchpointBookingDeadline = event.deadlines?.booking?.touchpointBookingDeadline;
            if (touchpointBookingDeadline) {
                event.deadlines.booking.date = simpleDate.minusDays(startDate, touchpointBookingDeadline);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.artwork.user = null;
                event.deadlines.artwork.updatedAt = null;
            }
        }

        if (event.deadlines?.briefing) {
            const touchpointBriefingDeadline = event.deadlines?.briefing?.touchpointBriefingDeadline;
            if (touchpointBriefingDeadline) {
                const date = moment(startDate).subtract(touchpointBriefingDeadline, 'days').toDate();
                event.deadlines.briefing.date = date;
                event.deadlines.briefing.moment = moment(date);
                // Reset the manual date set by user (PFX-3423)
                event.deadlines.briefing.user = null;
                event.deadlines.briefing.updatedAt = null;
            }
        }
        if (isFunction(onEventChange)) {
            onEventChange(newEvent);
        }
        setEditableEventId(undefined);
    };

    const handleDeleteActivity = async (event) => {
        const message = event.type === 'activities'
            ? `Are you sure you want to delete ${event.data?.touchpoint?.name} for the campaign: ${event.data?.campaign?.name}?`
            : `Are you sure you want to delete ${event.subtitle} for the campaign: ${event.campaignName}?`
        dialog.confirm(message, 'Delete')
            .then(() => {
                if (isFunction(onEventDelete)) {
                    onEventDelete(event);
                }
            });
    }

    const weekStyle = {
        height: `calc((55px * ${visibleEventIds.size}) + 2rem)`,
        minHeight: '100%',
        width: '100%',
    };

    const handleMouseEnter = (event, eventRef, titleRef, buttonsRef, subtitleRef) => {
        const eventRect = eventRef.current?.getBoundingClientRect();
        const titleRect = titleRef.current?.getBoundingClientRect();
        const buttonsRect = buttonsRef.current?.getBoundingClientRect();
        const subtitleRect = subtitleRef.current?.getBoundingClientRect();

        if (!eventRect || !titleRect || !buttonsRect || !subtitleRect) {
            return;
        }

        // Fix the z-index issue with the tooltips
        eventRef.current.dataset.zIndex = eventRef.current.dataset.zIndex
            ||window.getComputedStyle(eventRef.current).zIndex;
        eventRef.current.style.zIndex = 25;

        const { marginRight } = window.getComputedStyle(titleRef.current);

        const longerRow = (titleRect.width + parseInt(marginRight) + buttonsRect.width) > subtitleRect.width
            ? (buttonsRect.width + parseInt(marginRight) + titleRect.width)
            : subtitleRect.width;
        const maxWidth = longerRow + (2 * parseInt(window.getComputedStyle(eventRef.current).padding));

        if (maxWidth > eventRect?.width) {
            // If the event is about to expand outside the edge of the calendar, expand it towards the left on hover
            const calendarRightBoundary = calendarRef.current.getBoundingClientRect().right;
            if (!eventRef.current.dataset.direction) {
                eventRef.current.dataset.direction = eventRect.x + maxWidth > calendarRightBoundary ? 'left' : 'right';
            }

            // Cache the original width so that the event knows what to revert to on mouse out
            if (!eventRef.current.dataset.width) {
                eventRef.current.dataset.width = `${eventRect?.width}px`;
            }
            eventRef.current.style.width = `${maxWidth}px`;

            // Animate the margin-left css property if expanding towards the left
            if (eventRef.current.dataset.direction === 'left') {
                const marginLeft = eventRef.current.dataset.marginLeft || maxWidth - eventRect?.width;
                eventRef.current.style.marginLeft = `-${marginLeft}px`;
                if (!eventRef.current.dataset.marginLeft) {
                    eventRef.current.dataset.marginLeft = marginLeft;
                }
            }
        }
    };

    const handleMouseLeave = (eventRef) => {
        // Fix the z-index issue with the tooltips
        if (eventRef.current?.dataset.zIndex) {
            eventRef.current.style.zIndex = eventRef.current.dataset.zIndex;
        }

        if (eventRef.current?.dataset.width) {
            eventRef.current.title = undefined;
            eventRef.current.style.pointerEvents = 'all';
            eventRef.current.style.marginLeft = 0;
            eventRef.current.style.width = eventRef.current.dataset.width;
        }
    };

    return (<div className={`stack-col-0 ${css.timeline}`} ref={calendarRef}>
        <div className={`stack-0 stack-stretch ${css.header}`}>
            {months.map((month, monthIndex) => (<div className={`${css['week-container']} ${monthIndex === 0 ? css['first-week'] : ''} stack-col-2 stack-stretch`} key={months.startDate || `month-${monthIndex}`} ref={months.ref}>
                <div>
                    <div className={`${css['months-track']} stack-0 stack-stretch-items`}>
                        {month.weeks.map((week, index) => {
                        // Show year on first week of first month on timeline or every new
                            const startOfYear = monthIndex === 0 || month.monthOfYear === 0;
                            const showYear = index === 0 && startOfYear;
                            return (<div className={`${css['month-name']}${showYear ? ` ${css['first-of-year']}` : ''}`} key={week.weekOfYear}>
                                {showYear ? month.year : '\u00a0'}
                            </div>);
                        })}
                    </div>
                    <div className={`${css['day-numbers-track']} stack-end-start-0`}>
                        {month.weeks.map((week) => <div className={week.classes.join(' ')} key={week.weekOfYear}>{month.monthName}</div>)}
                    </div>
                </div>
            </div>))}
        </div>
        <div className={`stack-0 stack-stretch ${css.content}`}>
            {months.map((month, monthIndex) => (<div className={`${css['week-container']} ${monthIndex === 0 ? css['first-week'] : ''} stack-col-2 stack-stretch`} key={month.startDate} ref={month.ref}>
                <div className={`${css.week} stack-0`} style={weekStyle}>
                    {month.weeks.map((week, weekIndex) => (<div className={`${css.month} ${month.weeks.length === 5 ? css['five-weeks'] : css['four-weeks'] } stack-col-0`} key={week.weekOfYear}>
                        {week.events.map((event) => {
                            const eventRef = createRef();
                            const titleRef = createRef();
                            const buttonsRef = createRef();
                            const subtitleRef = createRef();

                            const index = [...visibleEventIds].indexOf(event.id);

                            const isCustomDeadline = event.isCustom;
                            // Custom deadlines are passed in as events, but need to be rendered as deadlines
                            const isEvent = simpleDate.isSame(simpleDate.startOfWeek(event.startDate), week.startOfWeek) && !isCustomDeadline;
                            const isArtworkDeadline = simpleDate.isSame(event.deadlines?.artwork?.date, week.startOfWeek);
                            const isBookingDeadline = simpleDate.isSame(event.deadlines?.booking?.date, week.startOfWeek);

                            // If event started before start of calendar, remove padding and border-radius from start
                            const isFirstWeek = monthIndex === 0 && weekIndex === 0;
                            const isLastWeek = (monthIndex === months.length - 1) && weekIndex === (month.weeks.length - 1);

                            const eventFromPrev = isFirstWeek && simpleDate.startOfWeek(event.startDate) < from;
                            const eventToNext = isLastWeek && simpleDate.endOfWeek(event.endDate) > to;

                            const eventContainerStyle = {
                                paddingLeft: eventFromPrev ? 0 : PADDING,
                                paddingRight: eventToNext ? 0 : PADDING,
                            };

                            const eventDurationInWeeks = Math.round(event.duration / 7);
                            const calendarEventStyle = {
                                height: '52px',
                                // Add 1px per week to cover the borders between them, then add the padding applied on the container
                                top: `calc((${index} * 55px) + 1rem)`,
                            };

                            const eventStyle = {
                                ...calendarEventStyle,
                                background: event.backgroundColor,
                                borderBottomLeftRadius: eventFromPrev ? 0 : BORDER_RADIUS,
                                borderBottomRightRadius: eventToNext ? 0 : BORDER_RADIUS,
                                borderTopLeftRadius: eventFromPrev ? 0 : BORDER_RADIUS,
                                borderTopRightRadius: eventToNext ? 0 : BORDER_RADIUS,
                                color: event.color,
                                width: `calc((${event.duration} * 100%) + (${eventDurationInWeeks}px) + ${PADDING})`,
                            };

                            const tooltip = (<>
                                {event.deadlines?.booking && (<div>Booking deadline: {simpleDate.format(event.deadlines.booking.date, 'Do MMMM YYYY')}</div>)}
                                {event.deadlines?.artwork && (<div>Artwork deadline: {simpleDate.format(event.deadlines.artwork.date, 'Do MMMM YYYY')}</div>)}
                                <div>Start date: {simpleDate.format(event.startDate, 'Do MMMM YYYY')}</div>
                                <div>End date: {simpleDate.format(event.endDate, 'Do MMMM YYYY')}</div>
                            </>);

                            // Set deadlines titles
                            const deadlineTitles = [];
                            if (isArtworkDeadline) deadlineTitles.push('ARTWORK');
                            if (isBookingDeadline) deadlineTitles.push('BOOKING');
                            const deadlineTitle = isCustomDeadline
                                ? `${event.title} - CUSTOM DEADLINE`
                                : `${event.data?.touchpoint?.name || 'n/a'} - ${deadlineTitles.join(' & ')} DEADLINE${deadlineTitles.length > 1 ? 'S' : ''}`;
                            const deadlineSubTitle = `${event.data?.plan?.name ||  'n/a'}${event.data?.campaign?.name ? ` - ${event.data?.campaign?.name}` : ''}`;

                            return <div
                                key={`event-${event.id}`}
                                onMouseEnter={() => handleMouseEnter(event, eventRef, titleRef, buttonsRef, subtitleRef)}
                                onMouseLeave={() => handleMouseLeave(eventRef)}
                                style={eventContainerStyle}
                            >
                                {/* Deadlines */}
                                {(isArtworkDeadline || isBookingDeadline || isCustomDeadline) && (<div className={css.deadline} style={calendarEventStyle}>
                                    <div className={css.title}>{deadlineTitle}</div>
                                    <div className={css['short-title']}>
                                        <Icon name="query_builder" size="14px" />
                                    </div>
                                    <div className={css.subtitle}>{deadlineSubTitle}</div>
                                </div>)}

                                {/* Activity */}
                                {(isEvent || eventFromPrev || eventToNext) && (<div className={css.event} ref={eventRef} style={eventStyle}>
                                    <div className="stack-even-center-3">
                                        <div className={css.title} ref={titleRef}>
                                            {event.planStatus === 'draft' && (<Pill
                                                text={event.planStatus}
                                                className={`mr-2 ${css.draft}`}
                                                style={{ backgroundColor: '#fff', color: '#262626' }}
                                            />)}
                                            {event.title}
                                        </div>
                                        <div className="stack-even-center-0" ref={buttonsRef} style={{ pointerEvents: 'all' }}>
                                            {event.url && (<Tooltip align={event.type === 'promotions' ? 'left' : 'top'} hover="Go to plan">
                                                <Link className="not-like-link" rel="noopener noreferrer" target="_blank" to={event.url}>
                                                    <Icon className={css.icon} name="link" />
                                                </Link>
                                            </Tooltip>)}
                                            <Tooltip hover={tooltip}>
                                                <Icon className={css.icon} name="info" />
                                            </Tooltip>
                                            {event.editable && (<>
                                                <Tooltip align={event.type === 'promotions' ? 'left' : 'top'} hover="Edit activity dates">
                                                    <Icon className={css.icon} name="edit" onClick={() => showEditModal(event)} />
                                                </Tooltip>
                                                <Tooltip align={event.type === 'promotions' ? 'left' : 'top'} hover="Delete activity">
                                                    <Icon className={css.icon} name="delete" onClick={() => handleDeleteActivity(event)} />
                                                </Tooltip>
                                            </>)}
                                        </div>
                                    </div>
                                    <div className="stack-even-center-3">
                                        <div className={css.subtitle} ref={subtitleRef}>
                                            { hideMediaCosts === 'true' && event.type === 'activities' ? event.noCostSubtitle : event.subtitle }
                                        </div>
                                        {editableEventId === event.id && (<EditEventModal
                                            event={event}
                                            onCancel={() => setEditableEventId(undefined)}
                                            onSave={handleEventUpdated}
                                        />)}
                                    </div>
                                </div>)}
                            </div>
                        })}
                    </div>))}
                </div>
            </div>))}
        </div>
    </div>);
};

export default AnnualTimeline;
