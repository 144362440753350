import { Icon, PreviewMediaModal, Tooltip, useModal } from '../';

import css from './PreviewMediaIcon.module.scss';

const PreviewMediaIcon = (props) => {
    const { touchpointId, touchpointsWithMetaData } = props;

    const { show: showPreviewMediaModal } = useModal({title: 'Preview touchpoint', template: PreviewMediaModal, size: 'xl',});

    const touchpointWithMetaData = touchpointsWithMetaData?.find((touchpoint) => { return touchpoint._id === touchpointId});
    const hasPreview = touchpointWithMetaData?.hasPreview;

    return (<>
        {hasPreview && (
            <Tooltip hover="Preview">
                <Icon
                    className="like-link mt-1"
                    name="remove_red_eye"
                    onClick={() => showPreviewMediaModal({touchpointId})}
                />
            </Tooltip>
        )}
        {!hasPreview && (<>
            <Tooltip hover="No preview available">
                <Icon className={`${css.grey} mt-1`} name="remove_red_eye" />
            </Tooltip>
        </>)}
    </>);
};

export default PreviewMediaIcon;
