import css from './Modal.module.scss';

const ModalFooter = ({ children, className='', ...restProps }) => {
    return (
        <div className={`${css['modal-footer']} ${className}`} {...restProps}>
            {children}
        </div>
    );
}

export default ModalFooter;
