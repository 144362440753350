import { Http } from '../../Components';

function log(slug, data) {
    return Http.post(`/analytics/events/${slug}`, data)
        .catch(err => console.error(err));
}

const Analytics = { log };

export default Analytics;
