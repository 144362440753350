import { Link } from 'react-router-dom';

import { ActivityStatus, Pill, useAuth } from '../';

import css from './DeadlineStatus.module.scss';

const DeadlineStatus = (props) => {
    const { cancelled, className, emptyStatus, evaluated, onChange, options, status, historic, live, url } = props;
    const { me } = useAuth();

    const classes = [css.status];
    if (className) classes.push(className);

    const content = !options || (cancelled || historic || live || evaluated) || (!me.permissions.editCampaignsCampaigns)
        ? (<ActivityStatus activity={props} />)
        : (<Pill
            className={classes.join(' ').trim()}
            onChange={onChange}
            options={options}
            renderAsBlock={true}
            value={emptyStatus === status ? null : status}
        />);

    return url
        ? (<Link to={url}>{content}</Link>)
        : content
};

export default DeadlineStatus;
