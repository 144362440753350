import { useMemo } from 'react';
import { isFunction, isString } from 'lodash';

import { useAppState } from '../';

const useLocalStorage = (key, initialValue) => {
    const getLocalStorageValue = (key) => {
        try {
            const item = key ? window.localStorage[key] : window.localStorage;
            if (!item) {
                return initialValue;
            } else if (item.length && (item[0] === '{' || item[0] === '[')) { // Parse if the value is an object or an array
                try {
                    return JSON.parse(item);
                } catch (error) {
                    console.error(`Failed to parse JSON for useLocalStorage key: ${key}`, error);
                    return initialValue;
                }
            } else {
                return item;
            }
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    };

    const storedValueMemo = useMemo(() => getLocalStorageValue(key), [key]); // eslint-disable-line react-hooks/exhaustive-deps

    const [storedValue, setStoredValue] = useAppState(key, storedValueMemo || initialValue);

    const setValue = (value) => {
        try {
            /**
             * When passing a function in the setter method, it gets executed with two arguments passed.
             * The first argument (storeValue) is the current value in the state, and the second one is
             * read directly from the local storage. This is for the case of another component writing in
             * the local storage.
             */
            const valueToStore = isFunction(value) ? value(storedValue, getLocalStorageValue(key)) : value;
            window.localStorage.setItem(key, isString(valueToStore) ? valueToStore : JSON.stringify(valueToStore));
            setStoredValue(valueToStore);
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
