import { PopoverContentProps, PopoverProps, PopoverTriggerProps } from '@radix-ui/react-popover';
import { Popover, PopoverContent, PopoverTrigger } from 'Components/Popover';
import { PropsWithChildren } from 'react';

import css from './Dropdown.module.scss';

interface DropdownProps extends PopoverContentProps {
    trigger: React.ReactNode;
    open?: PopoverProps['open']; // Use for controlled open state
    onOpenChange?: PopoverProps['onOpenChange']; // Use for controlled open state
    onTriggerClick?: PopoverTriggerProps['onClick']; // Use for controlled open state
}

const Dropdown = ({ trigger, children, align = 'end', open, onOpenChange, onTriggerClick, ...props }: PropsWithChildren<DropdownProps>) => {
    return (
        <Popover
            open={open}
            onOpenChange={onOpenChange}
        >
            <PopoverTrigger
                onClick={onTriggerClick}
            >
                {trigger}
            </PopoverTrigger>
            <PopoverContent
                className={`${css.content} box-raised`}
                align={align}
                {...props}
            >
                {children}
            </PopoverContent>
        </Popover>
    )
}

export default Dropdown;