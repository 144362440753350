import { connect } from 'formik';
import { get } from 'lodash';

const FieldArray = (props) => {
    const { children, formik: formBag, name, render } = props;

    const fieldArrayValues = get(formBag.values, name, []);

    return (children || render)({
        push: (value = {}) => formBag.setFieldValue(name, [...fieldArrayValues, value]),
        remove: (item) => formBag.setFieldValue(name, fieldArrayValues.filter((value) => value !== item)),
        removeIndex: (index) => formBag.setFieldValue(name, fieldArrayValues.filter((value, valueIndex) => valueIndex !== index)),
        replace: (index, value) => formBag.setFieldValue(name, fieldArrayValues.toSpliced(index, 1, value)),
        items: fieldArrayValues,
    });
};

export default connect(FieldArray);
