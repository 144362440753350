const meta = {
    attributionWindows: [
        {
            label: '1 day',
            value: '1',
            windows: [{ label: '1 day click sales', value: '1d_click' }, { label: '1 day view sales', value: '1d_view' }],
        },
        {
            label: '7 days',
            value: '7',
            windows: [{ label: '7 day click sales', value: '7d_click' }, { label: '7 day view sales', value: '7d_view' }],
        },
        {
            label: '28 days',
            value: '28',
            windows: [{ label: '28 day click sales', value: '28d_click' }, { label: '28 day view sales', value: '28d_view' }],
        },
    ],
    purchaseTypeOptions: [
        { label: 'Online', value: 'online' },
        { label: 'Offline', value: 'offline' },
    ],
};

export default meta;
