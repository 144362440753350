import { Form, Formik } from 'formik';
import { get } from 'lodash';
import { useState} from 'react';

import { Button, FDatePickerField, FTextareaField, FTextField, Http, Modal, Submit, toast } from '../../Components';

const RequestQuoteModal = (props) => {
    const [showQuoteModal, toggleQuoteModal] = useState(false);
    const { touchpoint, retailer } = props;

    const handleSave = (values, formikBag) => {
        const retailerSlug = retailer.slug;
        Http.post(`/media/touchpoints/${touchpoint._id}/request-quote`, { values, retailerSlug })
            .then(() => {
                toggleQuoteModal(false);
                toast.success('Your request has been submitted.')
            })
            .catch(error => {
                console.error('Could not request quote', error);
                formikBag.setStatus({ errorMessage: 'Failed to save.' });
                formikBag.setSubmitting(false);
            });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.activityStartDate) {
            errors.activityStartDate = 'Start date is required.';
        }
        return errors;
    }

    const initialValues = {
        activityStartDate: null,
    };

    return (
        <div className="mt-4">
            <Button color="primary" onClick={() => toggleQuoteModal(true)}>Request Quote / Book</Button>
            <Modal
                onCancel={() => toggleQuoteModal(false)}
                show={showQuoteModal}
                title={`Request a quote for ${touchpoint?.name} in ${retailer?.name || retailer?.label}`}
            >
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSave} validate={validate}>
                        {(formik) => {
                            const errorMessage = get(formik, 'status.errorMessage');
                            return (
                                <Form>
                                    { errorMessage && <div className="errorMsg">{errorMessage}</div> }
                                    <div className="row mb-3">
                                        <FDatePickerField className="col-md" label="Activity start date" name="activityStartDate" required />
                                    </div>
                                    <h2 className="mb-2">Other information:</h2>
                                    <div className="row">
                                        <FTextField className="col-md" label="Budget (if known)" name="budget" type="text" />
                                        <FTextField className="col-md" label="Duration (days)" name="duration" type="text" />
                                        <FTextField className="col-md" label="Brand" name="brand" type="text" />
                                    </div>
                                    <FTextareaField label="Notes" name="notes" rows="6" />

                                    <div className="btn-container-right mt-3">
                                        <Button onClick={() => toggleQuoteModal(false)}>Cancel</Button>
                                        <Submit>Request Quote</Submit>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RequestQuoteModal;
