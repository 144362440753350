import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Promise from 'bluebird';

import { Button, Http, Loading, Modal, ReactSelect, useFetch } from '../../Components';

const AddToPlanModal = (props) => {
    const { modal, touchpoint } = props;

    const navigate = useNavigate();
    const [brandOptions, setBrandOptions] = useState([]);
    const [brand, setBrand] = useState(undefined);
    const [company, setCompany] = useState(undefined);
    const [campaign, setCampaign] = useState(undefined);
    const [plan, setPlan] = useState(undefined);

    const [data, isLoading, errorMessage] = useFetch(
        () => {
            const campaignFilters = {
                groupedBrandOptions: [brand],
                companyOptions: [company],
                retailerOptions: [touchpoint.retailer],
            };

            return Promise.props({
                campaignData: campaign ? Http.get(`/campaigns/${campaign}`) : [],
                campaignOptionsData: company && brand ? Http.post('/planner/campaigns?view=no-pagination', { filters: campaignFilters}) : [],
                referenceData: Http.get('/planner/reference-data'),
            });
        },
        [campaign, company, brand],
    );

    const { companyOptions, groupedBrandOptions } = (!isLoading && !errorMessage)? data.referenceData : {};
    const campaignOptions = (!isLoading && !errorMessage) ? data.campaignOptionsData?.data?.map(campaign => ({ label: campaign.name, value: campaign._id })) : [];
    const campaignData = (!isLoading && !errorMessage) ? data.campaignData : [];
    // Media can only be added to plans with the same retailer as the touchpoint
    const applicablePlans = campaignData?.plans?.filter(plan => (plan.retailer._id === touchpoint.retailer));
    const planOptions = applicablePlans?.map(plan => ({ value: plan._id, label: plan.name }));
    const hasOneCompany = companyOptions?.length === 1;

    const handleCompanyChanged = (companyId) => {
        const company = groupedBrandOptions?.find((option) => option._id === companyId);
        setBrandOptions(company.options);
        setCompany(companyId);
        setPlan(undefined);
        setCampaign(undefined);

        if (company.options?.length === 1) {
            setBrand(company.options[0].value)
        } else {
            setBrand(undefined)
        }
    };

    const handleBrandChanged = (brandId) => {
        setPlan(undefined);
        setCampaign(undefined);
        setBrand(brandId);
    };

    const handleCampaignChanged = (campaignId) => {
        setPlan(undefined);
        setCampaign(campaignId);
    };

    const handleGoToPlan = () => {
        navigate(`/planner/campaigns/${campaign}/plans/${plan}/activities/new?tp=${touchpoint._id}`);
        modal.hide();
    }

    const handleCancel = () => {
        modal.hide();
    }

    // Users who can only access 1 company won't see the company selector, we need to pre-select it for them
    if (hasOneCompany && !company) {
        handleCompanyChanged(companyOptions[0].value);
    }

    return (<Modal.Body>
        {isLoading && <Loading />}
        {!isLoading && <>
            <div className="row">
                {!hasOneCompany && <ReactSelect
                    className="col-md"
                    label="Company"
                    name="company"
                    options={companyOptions}
                    onChange={(company) => handleCompanyChanged(company.value)}
                    value={company}
                    required
                />}
                <ReactSelect
                    className="col-md"
                    label="Brand"
                    name="brand"
                    options={brandOptions}
                    placeholder={!company ? 'Select a company first' : 'Select...'}
                    disabled={!company}
                    onChange={(brand) => handleBrandChanged(brand.value)}
                    value={brand}
                    required
                />
            </div>
            <div className="row">
                <ReactSelect
                    className="col-md"
                    label="Campaign"
                    name="campaign"
                    options={campaignOptions}
                    placeholder={!brand ? 'Select a brand first' : 'Select...'}
                    disabled={!brand}
                    onChange={(campaign) => handleCampaignChanged(campaign.value)}
                    value={campaign}
                    required
                />
            </div>
            <div className="row">
                <ReactSelect
                    className="col-md"
                    label="Plan"
                    name="plan"
                    options={planOptions}
                    placeholder={!campaign ? 'Select a campaign first' : 'Select...'}
                    disabled={!campaign}
                    onChange={(plan) => setPlan(plan.value)}
                    value={plan}
                    required
                />
            </div>
            <div className="stack-end mt-3">
                <Button onClick={() => handleCancel()}>Cancel</Button>
                <Button className="ml-3" color="primary" disabled={!plan} onClick={() => handleGoToPlan()}>Go to plan</Button>
            </div>
        </>}
    </Modal.Body>);
}

export default AddToPlanModal;
