import css from './Copyright.module.scss';

const Copyright = () => {
    return (
        <div className={`${css.copyright} stack-col-end-center-3 pb-3`}>
            Copyright © Shopper Media Group Ltd. 2017-{new Date().getFullYear()}.
        </div>
    );
}

export default Copyright;
