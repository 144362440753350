import { ProgressArrow } from '../';

const WizardNav = (props) => {
    const { allowClick, className, currentStep, goToStep, steps, totalSteps } = props;

    let options = [];

    // If steps are provided, extract their labels, otherwise 
    if (steps) {
        steps.forEach((step, index) => options.push({ label: step.title || `Step ${index + 1}`, value: index }));
    } else {
        for (let i = 0; i < totalSteps; i += 1) {
            options.push({ label: `Step ${i + 1}`, value: i });
        }
    }

    const handleStepClicked = allowClick
        ? (index) => goToStep(index + 1)
        : undefined;

    return (<ProgressArrow
        className={className}
        current={currentStep - 1}
        onChange={handleStepClicked} // goToStep expects the step number, not the offset
        options={options}
        style={{ width: '100%' }}
    />);
};

export default WizardNav;
