import { useState } from 'react';
import { Icon, IconButton, Http, toast, useAuth, useModal, Analytics, DropdownMenu } from '../';
import { includes } from 'lodash';

import WatchersModal from './WatchersModal';

const EmailAlertsIcon = (props) => {
    const { campaign, plan, onCampaignChange, isAdmin, size = 'standard' } = props;

    const { me } = useAuth();
    const watchers = plan ? plan.watchers : campaign?.watchers;
    const isWatching = includes(watchers, me._id);
    const [isLoading, setIsLoading] = useState(false);
    const { show: showWatchersModal } = useModal({title: 'Watch', template: WatchersModal});
    const type = plan ? 'plan' : 'campaign';

    const handleToggleWatch = () => {
        if (!isLoading) {
            setIsLoading(true);
            const url = plan
                ? `/campaigns/${campaign._id}/${isWatching ? 'unwatch-plan' : 'watch-plan'}/${plan._id}`
                : `/campaigns/${campaign._id}/${isWatching ? 'unwatch-campaign' : 'watch-campaign'}`;

            Http.put(url)
                .then((updatedCampaign) => {
                    toast.success(isWatching ? `You will no longer receive email alerts for this ${type}.` : `You will receive email alerts for this ${type}.`);
                    onCampaignChange(updatedCampaign);
                })
                .catch(error => {
                    console.error('Could not update email alerts preferences', error);
                    toast.error('Failed to update email alerts preferences.');
                })
                .finally(() => {
                    setIsLoading(false);
                    Analytics.log(plan ? 'PLANNER-VIEW-PLAN-ADD-WATCHERS' : 'PLANNER-VIEW-CAMPAIGN-ADD-WATCHERS')
                });
        }
    };

    const watchMessage = isWatching
        ? `Unwatch ${type}`
        : `Watch ${type}`;

    const trigger = <IconButton
        color={isWatching ? 'secondary' : 'default'}
        name={ isWatching ? 'notifications_active' : 'notifications_off' }
    />

    return (<>
        {size === 'standard' &&
            <DropdownMenu
                trigger={trigger}
                options={[
                    { label: watchMessage, onClick: handleToggleWatch },
                    // PFX-4880 Fix handling of the campaign state on admin form
                    ...(!isAdmin ? [{ label: 'Add watchers', onClick: () => showWatchersModal({ campaign, plan, onCampaignChange }) }] : [])
                ]}
            />
        }

        {size === 'large' &&
            <div className="stack-col-center-center like-link no-color" onClick={() => showWatchersModal({ campaign, plan, onCampaignChange })}>
                <Icon name="people" size="32px" />
                <div>Watch</div>
            </div>
        }
    </>)
};

export default EmailAlertsIcon;
