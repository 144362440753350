
import moment from 'moment';
import { config } from 'smg-common';

import {
    formatters,
    getTotalMediaInvestmentOfSelfServeActivities,
    getNetMediaInvestmentOfSelfServeActivities,
} from '../../index.js';

const coopConnectTemplate = {
    name: 'Co-op Connect template',
    isSelfServe: true,
    pandaDocId: 'us7fPtq6QCKUtGrMUwsXQR',
    companies: ['5a350b5c9fc5d92c8f5ea9f3'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'PO amount required', value: 'poAmount' },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        return `[${now}] ${plan.name} - ${campaign.name}`;
    },

    getTokens: (campaign, plan, activities, items) => {
        const totalMediaInvestment = getTotalMediaInvestmentOfSelfServeActivities(activities);
        const netMediaInvestment = getNetMediaInvestmentOfSelfServeActivities(activities);

        return [
            { label: `Plan owner's email`, name: 'plan.owner.email', value: campaign.owner.user.email },
            { label: 'Custom plan ID', name: 'plan.customPlanId', value: plan.customPlanId },
            { label: 'Company name', name: 'company.name', value: campaign.company.name },
            { label: 'Brand name', name: 'brand.name', value: campaign.brands[0].name },
            { label: 'Retailer name', name: 'retailer.name', value: plan.retailer.name },
            { label: 'Campaign name', name: 'campaign.name', value: campaign.name },
            { label: 'Total media investment', name: 'campaign.totalMediaInvestment', value: formatters.asCurrency(totalMediaInvestment, { decimals: 2 }) },
            { label: 'Net media investment', name: 'campaign.netMediaInvestment', value: formatters.asCurrency(netMediaInvestment, { decimals: 2 }) },
            { label: 'Plan retailer category', name: 'category.name', value: plan.rCategory?.name || '-' },
            { label: 'Plan retailer sub-category', name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
        ];
    },

    getPricingTables: (plan, activities) => {
        let rows = [];
        const options = {
            optional: false,
            optional_selected: true,
            qty_editable: false,
        };

        const audienceTypeOptions = config.audiences.typeOptions;


        activities.forEach((activity) => {
            activity.audienceSegments?.forEach((audienceSegment, index) => {
                const audienceType = audienceTypeOptions.find((option) => option.value === audienceSegment.type);

                rows.push({
                    options,
                    data: {
                        QTY: 1,
                        'Name': activity.touchpoint?.name,
                        'Description': activity.description,
                        'StartDate': formatters.asDate(activity.startDate),
                        'EndDate': formatters.asDate(activity.endDate),
                        'AudienceName': audienceSegment.label,
                        'AudienceType': audienceType.label,
                        'Price': 0, // Pricing tables must have a price field - it is not displayed on this template
                }});
            });

            if (!activity.audienceSegments?.length) {
                rows.push({
                    options,
                    data: {
                        QTY: 1,
                        'Name': activity.touchpoint?.name,
                        'Description': activity.description,
                        'StartDate': formatters.asDate(activity.startDate),
                        'EndDate': formatters.asDate(activity.endDate),
                        'AudienceName': 'n/a',
                        'AudienceType': 'n/a',
                        'Price': 0, // Pricing tables must have a price field - it is not displayed on this template
                }});
            }
        });

        return [{
            name: 'Pricing Table 1',
            data_merge: true,
            options: {
                currency: 'GBP',
            },
            sections: [
                {
                    title: 'Activities Section',
                    default: true,
                    rows,
                },
            ],
        }];
    },
};

export default coopConnectTemplate;
