import { Link, useNavigate } from 'react-router-dom';

import AuthPageLayout from '../../Auth/AuthPageLayout/AuthPageLayout';
import { Button, ErrorMessage, Layout, useAuth } from '../../Components';

const RichErrorPage = (props) => {
    const {
        message = props.err?.message || (props.err?.status === 500 ? 'Something went wrong' : null),
        status = props.err?.status || 500,
        title = props.err?.title || 'Server error',
    } = props;

    const { me } = useAuth();
    const navigate = useNavigate();

    const LayoutComponent = me ? Layout : AuthPageLayout;

    return (
        <LayoutComponent data-smg-page="forbidden">
            <ErrorMessage className="my-4" message={`${status}. ${title}.`} />

            {message && <div className="text-center mb-5">{message}</div>}

            <div className="stack-col-center-center-2">
                <Button className="like-link" color="primary" onClick={() => navigate(-1)}>Go back to the previous page</Button>
                <Link to="/">or go back to the home page</Link>
            </div>
        </LayoutComponent>
    );
};

export default RichErrorPage;
