import Slider from 'react-slick';
import { isValidElement } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import css from './Carousel.module.scss';

function ControlButton(props) {
    const { className, classNames, style, onClick } = props;
    return (
        <div
            className={`${css.control} ${className} ${classNames}`}
            style={{
                ...style,
                display: 'block',
            }}
            onClick={onClick}
        />
    );
}

const Carousel = (props) => {
    const {
        className = '',
        dots = true,
        height = '100%',
        images = [],
        minHeight = 400,
        nextArrow = (<ControlButton classNames={`${css.control} ${css.next}`} />),
        prevArrow = (<ControlButton classNames={`${css.control} ${css.prev}`} />),
        ...settings
    } = props;

    const slides = images.map((image, index) => {
        switch (typeof image) {
        case 'string': return {
            $el: <div style={{
                height,
                minHeight,
                background: `url(${image}) no-repeat center center`,
                backgroundSize: 'contain',
                maxWidth: '100%',
                width: 'auto',
            }} />,
            id: `slide-${index}`,
        };
        case 'object': {
            if (isValidElement(image)) {
                return {
                    $el: <div style={{
                        height,
                        minHeight,
                        maxWidth: '100%',
                        width: 'auto',
                    }}>{image}</div>,
                    id: `slide-${index}`,
                };
            } else {
                return {
                    $el: <div style={{
                        height,
                        minHeight,
                        background: `url(${image.src || image.url}) no-repeat center center`,
                        backgroundSize: 'contain',
                        maxWidth: '100%',
                        width: 'auto',
                    }} />,
                    id: `slide-${index}`,
                };
            }
        }
        default: return {
            $el: <div style={{ height, minHeight }}>{image}</div>,
            id: `slide-${index}`,
        };
        }
    });

    return (
        <Slider
            className={`${dots ? 'mb-4' : '' } ${className}`}
            dots={slides.length > 1} // Only show dots when there is more than one slide
            appendDots={(dots) => (
                <div
                    style={{
                        background: 'white',
                        boxSizing: 'border-box',
                        width: '100%',
                        padding: '0px',
                    }}
                >
                    <ul style={{ padding: '0px', margin: '0 0 10px 0' }}> {dots} </ul>
                </div>
            )}
            infinite={slides.length > 1} // Carousels render duplicates when there is only one slide
            nextArrow={nextArrow}
            prevArrow={prevArrow}
            arrows={slides.length > 1} // Only show arrows when there is more than one slide
            {...settings}
        >
            {slides.map(slide => <div key={slide.id}>{slide.$el}</div>)}
        </Slider>
    );
}

export default Carousel;
