import _ from 'lodash';

import { Checkbox, Icon } from 'Components';

import css from './FilterOption.module.scss';

const FilterOption = (props) => {
    const { isDisabled, option, onToggle, onToggleAll, selectedValues } = props;

    const getParentIconName = () => {
        const nestedValues = _.map(option.options, 'value').sort();
        const matchingValues = _.intersection(selectedValues, nestedValues).sort();

        // If every nested value is matched against a selected value, all are selected
        if (_.isEqual(matchingValues, nestedValues)) return 'check_box';
        // If some but not every nested value is matched, some are selected
        if (!_.isEmpty(matchingValues)) return 'indeterminate_check_box';
        return 'check_box_outline_blank';
    };

    return (<>
        {_.isEmpty(option.options) && (
            <Checkbox
                // Trade promotions checkbox should not be disabled or checked initially
                // TODO: This code is tightly coupled and should be moved out of the Filter component
                checked={isDisabled || _.includes(selectedValues, option.value)}
                disabled={isDisabled}
                label={option.renderLabel ? option.renderLabel() : option.label}
                onChange={() => onToggle(option)}
                // Checkbox does not accept null as a value
                value={option.value !== null && option.value}
            />
        )}
        {!_.isEmpty(option.options) && (<>
            <div className="mb-3">
                <div className={css['filter-group-header']}>
                    <Icon
                        className="like-link"
                        name={getParentIconName()}
                        onClick={() => onToggleAll(option)}
                        style={{ marginRight: '4px', zoom: '0.8' }}
                    />
                    <strong style={{ display: 'inline' }}>{option.label}</strong>
                    {option.options && (<hr />)}
                </div>
                <div className="stack-col-1 stack-stretch-items ml-4 mt-1 mb-3">
                    {_.map(option.options, (nestedOption) => (
                        <Checkbox
                            key={nestedOption.value}
                            checked={_.includes(selectedValues, nestedOption.value)}
                            label={nestedOption.renderLabel ? nestedOption.renderLabel() : nestedOption.label}
                            onChange={() => onToggle(nestedOption)}
                            // Checkbox does not accept null as a value
                            value={nestedOption.value !== null && nestedOption.value}
                        />
                    ))}
                </div>
            </div>
        </>)}
    </>);
};

export default FilterOption;
