// Escapes all special regex characters. Eg "[test*]" to "\[test\*\]"
const escapeRegex = (str) => {
    return typeof str === 'string'
        ? str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
        : str;
}

export {
    escapeRegex,
};
