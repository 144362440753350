import _ from 'lodash';
import { config } from 'smg-common';

function buildActivityOptions(activities = []) {
    const activityOptions = activities
        .filter((activity) => !activity.cancelled)
        .map((activity) => {
            let name = activity.touchpoint.name;
            // Append description to distinguish activities with the same tp
            if (activity.description) {
                name += ` (${activity.description})`;
            }

            return {
                value: activity._id,
                label: name,
            };
        });

    return activityOptions;
}

function buildItemOptions(items = []) {
    const itemOptions = items
        .filter((item) => !item.cancelled && !item.event)
        .map((item) => ({ value: item._id, label: item.title }));

    return itemOptions;
}

function buildTemplateOptions(companyIds, isSelfServe) {
    const templateOptions = config.pandaDoc.templates
        .filter((template) => {
            const canAccessCompany = companyIds.filter(id => template.companies.includes(id))?.length > 0;
            const isSameCampaignType = isSelfServe ? template.isSelfServe : !template.isSelfServe;

            return (_.isEmpty(template.companies) || canAccessCompany) && isSameCampaignType;
        })
        .map((template) => ({ value: template.pandaDocId, label: template.name }));

    return templateOptions;
}

export {
    buildActivityOptions,
    buildItemOptions,
    buildTemplateOptions,
};
