import { Component } from 'react';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';
import { Icon } from '../../Components';
import PasswordStrength from './PasswordStrength';
import { TextField } from './TextField';

import css from './PasswordField.module.scss';

/**
 * A wrapper for password fields.
 * Displays the password strength and allows the user to show/hide the password.
 */
class PasswordField extends Component {
    state = {
        isVisible: false,
    };

    handleToggleVisibility = () => {
        this.setState({
            isVisible: !this.state.isVisible
        });
    };

    render() {
        const { value, ...restProps } = this.props;
        return (
            <FormGroup>
                <div className={css.inputContainer}>
                    <TextField
                        {...restProps}
                        topRightSlot={<PasswordStrength input={value} />}
                        type={this.state.isVisible ? 'text' : 'password' }
                        value={value}
                    />
                    <Icon name={this.state.isVisible ? 'visibility_off' : 'visibility'} className={css.icon} onClick={this.handleToggleVisibility} />
                </div>
            </FormGroup>
        )
    }
}

const FPasswordField = AsFormik(PasswordField, {
    extractValue: value => value,
});

export {
    PasswordField,
    FPasswordField,
};
