export default {
    // Aer Rianta
    'aer-rianta': ['e-commerce', 'store', 'targeted-comms'],

    // Aldi
    'aldi': ['e-commerce', 'convenience', 'store', 'app', 'targeted-comms'],

    // Amazon
    'amazon': ['e-commerce', 'app', 'targeted-comms'],

    // Asda
    'asda': ['e-commerce', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // B&M
    'bm': ['e-commerce', 'store', 'targeted-comms'],

    // BP
    'bp': ['e-commerce', 'convenience', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Best one
    'best-one': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Bestway
    'bestway': ['e-commerce', 'store'],
    'bestway-other': ['e-commerce', 'store', 'targeted-comms'],
    'bestway-wholesale': ['e-commerce', 'store', 'targeted-comms'],

    // Bidfood
    'bidfood': ['e-commerce', 'store', 'targeted-comms'],

    // Booker
    'booker': ['e-commerce', 'store', 'targeted-comms'],

    // Booths
    'booths': ['e-commerce', 'store', 'targeted-comms'],

    // Boots
    'boots': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'targeted-comms', 'quick-commerce', 'pharmacy', 'airports-stations-services'],

    // Brakes
    'brakes': ['e-commerce', 'store', 'targeted-comms'],

    // Budgens
    'budgens': ['e-commerce', 'store', 'targeted-comms'],

    // C&C Group
    'cc-group': ['e-commerce', 'store', 'targeted-comms'],

    // Cafe nero
    'cafe-nero': ['store'],

    // Centra
    'centra': ['e-commerce', 'store', 'targeted-comms'],

    // Co-op
    'co-op': ['e-commerce', 'convenience', 'store', 'petrol-stations', 'targeted-comms', 'quick-commerce'],
    'co-op-societies': ['e-commerce', 'convenience', 'store', 'targeted-comms', 'quick-commerce'],

    // Compass
    'compass': ['e-commerce', 'store', 'targeted-comms'],

    // Condor Ferries
    'condor-ferries': ['e-commerce', 'store', 'targeted-comms'],

    // Costcutter
    'costcutter': ['store', 'targeted-comms'],

    // Cult Beauty
    'cult-beauty': ['e-commerce', 'app', 'targeted-comms'],

    // Cyprus Duty Free
    'cyprus-duty-free': ['e-commerce', 'store', 'targeted-comms'],

    // DFDS Ferries
    'dfds-ferries': ['e-commerce', 'store', 'targeted-comms'],

    // Deliveroo
    'deliveroo': ['app', 'targeted-comms'],

    // Dubai Duty Free
    'dubai-duty-free': ['e-commerce', 'store', 'targeted-comms'],

    // Dufry
    'dufry': ['e-commerce', 'store', 'targeted-comms'],

    // Dunnes
    'dunnes': ['e-commerce', 'store', 'targeted-comms'],

    // Ernest Jones
    'ernest-jones': ['e-commerce', 'store', 'targeted-comms'],

    // Eurogarages
    'eurogarages': ['petrol-stations', 'quick-commerce'],

    // Eurotunnel
    'eurotunnel': ['e-commerce', 'store', 'targeted-comms'],

    // Freemans
    'freemans': ['e-commerce', 'store', 'targeted-comms'],

    // Greene King
    'greene-king': ['store', 'targeted-comms'],

    // Gregg's
    'greggs': ['e-commerce', 'store', 'targeted-comms', 'quick-commerce'],

    // Gulf Beverages
    'gulf-beverages': ['e-commerce', 'store', 'targeted-comms'],

    // H Samual
    'h-samuel': ['e-commerce', 'store', 'targeted-comms'],

    // Heineken
    'heineken': ['store', 'targeted-comms'],

    // Heinemann
    'heinemann': ['e-commerce', 'store', 'targeted-comms'],

    // Holland & Barrett
    'holland-barrett': ['e-commerce', 'store', 'targeted-comms', 'quick-commerce'],

    // Home Bargains
    'home-bargains': ['e-commerce', 'store', 'targeted-comms'],

    // IDS
    'ids': ['e-commerce', 'store', 'targeted-comms'],

    // Iceland
    'iceland': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Lagardere Travel Retail
    'lagardere': ['e-commerce', 'store', 'targeted-comms'],

    // Lidl
    'lidl': ['store', 'targeted-comms'],

    // Littlewoods
    'littlewoods': ['e-commerce', 'app', 'targeted-comms'],

    // Londis
    'londis': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Look Fantastic
    'look-fantastic': ['e-commerce', 'app', 'targeted-comms'],

    // MSA
    'msa': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Marstons
    'marstons': ['store', 'targeted-comms'],

    // Matthew Clarke
    'matthew-clarke': ['e-commerce', 'store', 'targeted-comms'],

    // Mitchells and Butlers
    'mitchells-and-butlers': ['store', 'targeted-comms'],

    // McColls
    'mccolls': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Molson Coors
    'molson-coors': ['store', 'targeted-comms'],

    // Moodie Davitt
    'moodie-davitt': ['e-commerce', 'store', 'targeted-comms'],

    // Morrisons
    'morrisons': ['e-commerce', 'convenience', 'petrol-stations', 'targeted-comms', 'quick-commerce', 'store'],

    // Moto
    'moto': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Motor Fuel Group
    'mfg': ['petrol-stations', 'quick-commerce'],

    // Nisa
    'nisa': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Ocado
    'ocado': ['e-commerce', 'app', 'targeted-comms'],

    // Odeon
    'odeon': ['e-commerce', 'store', 'targeted-comms'],

    // One Stop
    'one-stop': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Other wholesale
    'other-wholesale': ['store', 'targeted-comms'],

    // P&O Ferries
    'po-ferries': ['e-commerce', 'store', 'targeted-comms'],

    // Parfetts - Go Local
    'parfetts': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],
    'parfetts-cash-and-carry': ['e-commerce', 'store', 'targeted-comms'],

    // Pizza Hut
    'pizza-hut': ['e-commerce', 'store', 'targeted-comms', 'quick-commerce'],

    // Poundland
    'poundland': ['store', 'targeted-comms'],

    // Premier
    'premier': ['e-commerce', 'convenience', 'targeted-comms', 'quick-commerce'],

    // Punch
    'punch': ['store', 'targeted-comms'],

    // Roadchef
    'roadchef': ['petrol-stations', 'targeted-comms', 'quick-commerce', 'airports-stations-services'],

    // Rontec
    'roadchef': ['petrol-stations', 'quick-commerce'],

    // SSP
    'ssp': ['store', 'targeted-comms'],

    // Sainsbury's
    'sainsburys': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Select Convenience
    'select-convenience': ['e-commerce', 'store', 'targeted-comms', 'quick-commerce'],

    // Shell
    'shell': ['e-commerce', 'convenience', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Signet
    'signet': ['e-commerce', 'store', 'targeted-comms'],

    // Spar
    'spar': ['e-commerce', 'convenience', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Stena Line
    'stena-line': ['e-commerce', 'store', 'targeted-comms'],

    // Stonegate
    'stonegate': ['store', 'targeted-comms'],

    // Subway
    'subway': ['e-commerce', 'store'],

    // Superdrug
    'superdrug': ['e-commerce', 'convenience', 'store', 'superstore', 'app'],

    // Supervalu
    'supervalu': ['store', 'targeted-comms'],

    // TR Business
    'tr-business': ['e-commerce', 'targeted-comms'],

    // TVG
    'tvg': ['e-commerce', 'app', 'targeted-comms'],

    // Tesco
    'tesco': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Uber Eats
    'uber-eats': ['app', 'targeted-comms'],

    // Unitas
    'unitas-all': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-db-ramsdens': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-dhamecha': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-east-end-foods': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-elbrook': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-filshill': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-global-foods': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-ht-drinks': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-hyperama': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-imperial': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-parfetts': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-time-wholesale': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-united-wholesale': ['e-commerce', 'store', 'targeted-comms'],
    'unitas-united-wholesale-scotland': ['e-commerce', 'store', 'targeted-comms'],

    // Vue
    'vue': ['e-commerce', 'store'],

    // Waitrose
    'waitrose': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'quick-commerce'],

    // Welcome Break
    'welcome-break': ['store', 'petrol-stations', 'targeted-comms', 'quick-commerce', 'airports-stations-services'],

    // WH Smith
    'whsmith': ['e-commerce', 'convenience', 'store', 'superstore', 'app', 'petrol-stations', 'targeted-comms', 'airports-stations-services'],

    // Wilko
    'wilko': ['e-commerce', 'store', 'targeted-comms'],

    // World Duty Free
    'world-duty-free': ['e-commerce', 'store', 'targeted-comms'],

    // Zapp
    'zapp': ['app', 'targeted-comms'],
};
