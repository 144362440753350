import { formatters } from 'smg-common';
import { HTMLProps, ReactNode, useEffect, useState } from 'react';

import IconButton from '../Button/IconButton';

import css from './Pagination.module.scss';

interface PaginationProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    total: number,
    align?: string,
    leftSlot?: ReactNode,
    limit?: number,
    page?: number,
    onChange?: (page: number) => void
}

const Pagination = (props: PaginationProps) => {
    const {
        align = 'end',
        className,
        leftSlot,
        limit = 50,
        onChange,
        page = 1,
        total,
        ...restProps
    } = props;

    const [normalisedPage, setNormalisedPage] = useState(page);

    useEffect(() => {
        if (normalisedPage !== page) {
            // @ts-expect-error The parseInt here shouldn't be needed, but didn't want to change component when converting to TS
            setNormalisedPage(Number.parseInt(page, 10) || 1);
        }
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onChange?.(normalisedPage);
    }, [normalisedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const start = (normalisedPage - 1) * limit + 1;
    const end = start + limit - 1;
    const last = end > total ? total : end;

    const handlePrevClicked = () => {
        setNormalisedPage(normalisedPage - 1);
    };

    const handleNextClicked = () => {
        setNormalisedPage(normalisedPage + 1);
    };

    const isFirstPage = start <= 1;
    const isLastPage = end >= total;

    const prevClasses = [css.previous];
    if (isFirstPage) prevClasses.push(css.disabled);
    const nextClasses = [css.next];
    if (isLastPage) nextClasses.push(css.disabled);

    const classes = ['stack-even-center stack-stretch-items'];
    if (className) classes.push(className);

    return (
        <div className={classes.join(' ').trim()} {...restProps}>
            {leftSlot}
            <div className={`stack-${align}-center-3`}>
                <span>Results {formatters.asNumber(start)}-{formatters.asNumber(last)} of {formatters.asNumber(total)}</span>
                <div>
                    <IconButton className={prevClasses.join(' ').trim()} disabled={isFirstPage} name="chevron_left" onClick={handlePrevClicked} />
                    <IconButton className={nextClasses.join(' ').trim()} disabled={isLastPage} name="chevron_right" onClick={handleNextClicked} />
                </div>
            </div>
        </div>
    );
};

export default Pagination;
