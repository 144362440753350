import { useState } from 'react';
import * as yup from 'yup';

import { Analytics, Form, Http, Loading, Modal, toast, useAuth, useFetch } from '../';

const WatchersModal = (props) => {
    const { campaign, plan, onCampaignChange, modal } = props;
    const { me } = useAuth();

    const [isSaving, setIsSaving] = useState(false);

    const [allUsers, isLoading, errorMessage] = useFetch(
        () => campaign ? Http.get(`/admin/campaigns/${campaign._id}/user-access-report`, { params: { planId: plan?._id } }) : Promise.resolve(),
        [],
    );

    const watcherIds = plan ? plan.watchers : campaign.watchers;
    const isCurrentUserWatching = !!watcherIds?.find(watcher => watcher === me._id);

    // The current user should not appear in the 'add others' options
    const addOthersOptions = allUsers?.filter((user) => (!watcherIds?.includes(user.value) && user.value !== me._id));
    const currentWatchers = allUsers?.filter((user) => watcherIds?.includes(user.value));

    const handleSave = (values) => {
        setIsSaving(true);
        const url = plan
            ? `/campaigns/${campaign._id}/plans/${plan._id}/invite-watchers`
            : `/campaigns/${campaign._id}/invite-watchers`;

        Http.put(url, values)
            .then((updatedCampaign) => {
                toast.success('New watchers added successfully!');
                onCampaignChange({ ...campaign, watchers: updatedCampaign.watchers });
                modal.hide();
            })
            .catch(error => {
                console.error('Failed to add new watchers.', error);
                toast.error('Failed to add new watchers.');
            })
            .finally(() => {
                setIsSaving(false);
                Analytics.log(plan ? 'PLANNER-VIEW-PLAN-ADD-WATCHERS' : 'PLANNER-VIEW-CAMPAIGN-ADD-WATCHERS')
            });
    };

    const validationSchema = yup.object().shape({
        newWatchers: yup.array()
            .test('watchersHasChanged', 'At least one new watcher is required.', function() {
                return this.parent.newWatchers?.length > 0 || this.parent.addMe !== isCurrentUserWatching;
            }),
    });

    const initialValues = {
        addMe: isCurrentUserWatching,
        newWatchers: [],
    };

    return (
        <Modal.Body>
            { errorMessage && <div className="errorMsg">{errorMessage}</div> }
            { !errorMessage && (isLoading || isSaving) && <Loading /> }
            { !errorMessage && !isLoading && !isSaving && (<>
                <Form initialValues={initialValues} onSubmit={handleSave} validationSchema={validationSchema}>
                    {() => (<>
                        <div className="row mb-3">
                            <div>Make sure you don't miss any changes by watching this {plan ? 'plan' : 'campaign'}.</div>
                        </div>
                        <div className="row mb-3">
                            <Form.Checkbox className="col-md" label="Add me" name="addMe" />
                        </div>
                        <div className="row mb-3">
                            <Form.MultiSelect
                                className="col-md"
                                label="Add others"
                                name="newWatchers"
                                placeholder="Start typing to search users"
                                options={addOthersOptions}
                                required
                            />
                        </div>

                        {currentWatchers?.length > 0 && (<>
                            <div>Current watchers:</div>
                            <ul>
                                {currentWatchers.map(user => {
                                    return <li key={user.value}>{user.label}</li>
                                })}
                            </ul>
                        </>)}
                    </>)}
                </Form>
            </>)}
        </Modal.Body>
    );
};

export default WatchersModal;
