type RetailerProductFeedLabels = Record<string, {
    name: string;
    companyName: string;
    uniqueId: string;
    uniqueId2?: string;
    uniqueId3?: string;
    hfss?: string;
}>;

const retailerProductFeedLabels: RetailerProductFeedLabels = {
    // Use the retailer slug as the key
    'asda': {
        name: 'Product',
        companyName: 'Supplier_Name',
        uniqueId: 'EAN',
        uniqueId2: 'CIN',
        uniqueId3: 'WIN',
        hfss: 'HFSSFlag',
    },
    'co-op': {
        name: 'Product Name',
        companyName: 'SUPPLIER',
        uniqueId: 'Retail Line',
        uniqueId2: 'EAN',
        hfss: 'HFSS',
    },
    'boots': {
        name: 'Name',
        companyName: 'Brand',
        uniqueId: 'Item Code',
    },
    "morrisons": {
        name: 'Product name',
        companyName: 'Supplier name',
        uniqueId: 'EAN',
        hfss: 'HFSS',
    },
    "tvg": {
        name: 'Line No Desc',
        companyName: 'Supplier Code Desc',
        uniqueId: 'Line No',
    },
}

export default retailerProductFeedLabels;
