import DatePicker from 'react-datepicker';
import moment from 'moment';
import { simpleDate } from 'smg-common';
import 'react-datepicker/dist/react-datepicker.css';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';

import css from './FormControl.module.scss';
import './DatePickerField.scss';

/**
 * IMPORTANT: For the semantics of 'LocalDate' and how dates work in Plan-Apps, see:
 * https://app.nuclino.com/Shopper-Media-Group-Ltd/Mean-Lobsters/Handling-Dates-in-PlanApps-79081a62-1930-4f86-bbeb-94eb58c652bb
 */

// Converts a LocalDate to the equivalent date in the browser's local timezone
function toJsDate(localDate) {
    if (!localDate) {
        return localDate;
    }
    const tm = new Date(localDate);
    const jsDate = new Date(tm.getUTCFullYear(), tm.getUTCMonth(), tm.getUTCDate());
    return jsDate;
}

const DatePickerField = (props) => {
    const {
        className='',
        errorMsg,
        helpMsg,
        id,
        label,
        onChange,
        placeholder,
        required,
        successMsg,
        theme,
        topRightSlot,
        value,
        ...restProps
    } = props;

    // NOTE: the underlying component (DatePicker) complains about type if value is not a Date or null
    // Our value will usually be a string (empty or ISO date), so here we cast it to Date object
    let dateValue = value;
    if (value === '') {
        dateValue = null;
    } else if (typeof value === 'string') { // It is an ISO representation of a date
        dateValue = new Date(value);
    }

    // Sanity check. Log a meaningful error when we pass an invalid value. Otherwise we get cryptic errors in other places.
    if (dateValue !== null && dateValue !== undefined && !moment(dateValue).isValid()) {
        console.error(`Invalid date value "${dateValue}" at field with name "${restProps.name}"`);
    }

    const inputClasses = [css['form-control']];
    if (errorMsg) inputClasses.push(css['is-invalid']);
    if (theme) inputClasses.push(css[`${theme}-theme`]);

    return (
        <FormGroup
            className={className}
            elementId={id}
            errorMsg={errorMsg}
            helpMsg={helpMsg}
            label={label}
            required={required}
            successMsg={successMsg}
            topRightSlot={topRightSlot}
        >
            <DatePicker
                autoComplete="off"
                className={inputClasses.join(' ')}
                dateFormat={props.showYearPicker ? 'yyyy' : 'd MMM yyyy'}
                id={id}
                onChange={date => {
                    onChange(props.showYearPicker ? date : simpleDate.toLocalDate(date))
                }}
                placeholderText={placeholder}
                selected={toJsDate(dateValue)}
                {...restProps}
            />
        </FormGroup>
    );
};

const FDatePickerField = AsFormik(DatePickerField, {
    extractValue: value => value,
});

export {
    DatePickerField,
    FDatePickerField,
};
