import { Http, Loader } from 'Components';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Activity, Campaign, Plan, Touchpoint, Workflow } from 'Types';
import WorkflowDeadline from 'Campaigns/Workflow/Components/WorkflowDeadline';
import WorkflowCreativeType from 'Campaigns/Workflow/Components/WorkflowCreativeType';
import WorkflowApprovalIcon from 'Campaigns/Workflow/Components/WorkflowApprovalIcon';

interface WorkflowApprovalsProps {
    className: string;
}

interface WorkflowWithCampaign extends Workflow {
    campaign: Pick<Campaign, '_id' | 'name'> & {
        plans: Pick<Plan, '_id'> & {
            activities: Pick<
                Activity,
                | '_id'
                | 'description'
                | 'startDate'
                | 'briefingDeadline'
                | 'briefingApprovalDeadline'
                | 'initialCreativeDeadline'
                | 'initialCreativeApprovalDeadline'
                | 'artworkDeadline'
                | 'artworkApprovalDeadline'
                | 'touchpoint'
                | 'creativeWorkflow'
            > & {
                touchpoint: Pick<Touchpoint, 'name'>;
            };
        };
    };
}

const WorkflowApprovals = (props: WorkflowApprovalsProps) => {
    const { className } = props;

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    return (
        <Loader className={classes.join(' ').trim()} promise={() => Http.get('/homepage/workflows')}>
            {(workflows: WorkflowWithCampaign[]) => (
                <>
                    <section>
                        <h2 className="mb-2">Approvals</h2>
                        <hr />
                    </section>
                    {isEmpty(workflows) && <h3 className="mt-3">You have no upcoming approval deadlines</h3>}
                    {!isEmpty(workflows) && (
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Activity</th>
                                        <th>Workflow Type</th>
                                        <th>Deadline</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workflows.map((workflow: WorkflowWithCampaign) => (
                                        <tr key={workflow._id}>
                                            <td>
                                                <Link
                                                    to={`/planner/campaigns/${workflow.campaign._id}/plans/${workflow.campaign.plans._id}/activities/${workflow.campaign.plans.activities._id}/workflow/${workflow._id}`}
                                                >
                                                    {workflow.campaign.plans.activities.touchpoint.name} <br />
                                                    <span className="text-small text-subtle">{workflow.campaign.plans.activities.description}</span>
                                                </Link>
                                            </td>
                                            <td>
                                                <WorkflowCreativeType
                                                    activity={workflow.campaign.plans.activities}
                                                    workflowId={workflow._id}
                                                />
                                            </td>
                                            <td>
                                                <WorkflowDeadline activity={workflow.campaign.plans.activities} workflow={workflow} />
                                            </td>
                                            <td>
                                                <WorkflowApprovalIcon activity={workflow.campaign.plans.activities} workflow={workflow} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
        </Loader>
    );
};

export default WorkflowApprovals;
