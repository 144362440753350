import { EasterEgg, IconButton, useAuth } from '../../../Components';

const RecentActivity = (props) => {
    const { className, ...restProps } = props;
    const { me } = useAuth();
    const { recentActivity } = me;

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    return (
        <div className={classes.join(' ').trim()} {...restProps}>
            <h2 className="mb-2">Recent activity</h2>
            <hr />
            {recentActivity.map(item => (
                <div className="box-flat mt-2 p-2" key={item._id}>
                    <div className="stack-even-center-2">
                        <div><b>{item.app + (item.label ? ':' : '')}</b> {item.label}</div>
                        <IconButton color="primary" name="arrow_forward" linkTo={item.url} />
                    </div>
                </div>
            ))}
            {recentActivity.length === 0 && <h3 className="mt-3">You do not have any recent activity</h3>}
            <EasterEgg eggId="5" style={{ margin: '50px 0 0 400px' }} />
        </div>
    );
};

export default RecentActivity;
