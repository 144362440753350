import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';


import HomePage from './Home/HomePage';
import ViewCompanyUsersPage from '../Admin/Companies/ViewCompanyUsersPage';
import { PageNotFound } from './ErrorPages';

const AccountsRoutes = lazy(() => import('../Accounts/AccountsRoutes'));
const AdminRoutes = lazy(() => import('../Admin/AdminRoutes'));
const AllocatorRoutes = lazy(() => import('../Allocator/AllocatorRoutes'));
const Allocator4Routes = lazy(() => import('../Allocator4/Allocator4Routes'));
const AudiencesRoutes = lazy(() => import('../Audiences/AudiencesRoutes'));
const AuthRoutes = lazy(() => import('../Auth/AuthRoutes'));
const BudgetingRoutes = lazy(() => import('../Budgeting/BudgetingRoutes'));
const DashboardRoutes = lazy(() => import('../Dashboard/DashboardRoutes'));
const DevRoutes = lazy(() => import('../Dev/DevRoutes'));
const EvaluationsRoutes = lazy(() => import('../Evaluations/EvaluationsRoutes'));
const FlexiReportsRoutes = lazy(() => import('../FlexiReports/FlexiReportsRoutes'));
const GlossaryRoutes = lazy(() => import('../Glossary/GlossaryRoutes'));
const MagicRoutes = lazy(() => import('../Magic/MagicRoutes'));
const MediaRoutes = lazy(() => import('../Media/MediaRoutes'));
const CampaignsRoutes = lazy(() => import('../Campaigns/CampaignsRoutes'));
const SelfServeRoutes = lazy(() => import('../SelfServe/SelfServeRoutes'));
const TrendsRoutes = lazy(() => import('../Trends/TrendsRoutes'));
const GalleryPage = lazy(() => import('../Gallery/GalleryPage'));

const TermsAndConditionsPage = lazy(() => import('./TermsAndConditions/TermsAndConditionsPage.js'));

const AppRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/accounts/*" element={<AccountsRoutes />} />
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/allocator/*" element={<AllocatorRoutes />} />
                <Route path="/allocator-new/*" element={<Allocator4Routes />} />
                <Route path="/audiences/*" element={<AudiencesRoutes />} />
                <Route path="/auth/*" element={<AuthRoutes />} />
                <Route path="/dashboard/*" element={<DashboardRoutes />} />
                <Route path="/budgeting/*" element={<BudgetingRoutes />} />
                <Route path="/dev/*" element={<DevRoutes />} />
                <Route path="/evaluations/*" element={<EvaluationsRoutes />} />
                <Route path="/flexi-reports/*" element={<FlexiReportsRoutes />} />
                <Route path="/glossary/*" element={<GlossaryRoutes />} />
                <Route path="/magic/*" element={<MagicRoutes />} />
                <Route path="/media/*" element={<MediaRoutes />} />
                <Route path="/planner/*" element={<CampaignsRoutes />} />
                <Route path="/self-serve/*" element={<SelfServeRoutes />} />
                <Route path="/trends/*" element={<TrendsRoutes />} />
                <Route path="/gallery" element={<GalleryPage />} />

                <Route path="/company/:companyId/users" element={<ViewCompanyUsersPage />} />
                <Route path="/privacy-policy" element={<TermsAndConditionsPage />} />

                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
