import Promise from 'bluebird';

import TouchpointBriefingPanel from 'Media/TouchpointOverview/TouchpointBriefingPanel';
import { Button, ErrorMessage, Http, Loading, Modal, useFetch } from '../';

const PreviewMediaModal = (props) => {
    const { modal, touchpointId, visiblePanels } = props;

    const showTrends = !visiblePanels || visiblePanels.includes('trends');

    const [data = {}, isLoading, errorMessage] = useFetch(
        () => showTrends
            ? Promise.props({
                touchpoint: Http.get(`/media/touchpoints/${touchpointId}?$populates=retailer`),
                benchmarkData: Http.get(`/media/touchpoint-benchmarks/${touchpointId}`),
            })
            : Promise.props({
                touchpoint: Http.get(`/media/touchpoints/${touchpointId}?$populates=retailer`),
                benchmarkData: {},
            }),
        [touchpointId],
    );

    return (<>
        <Modal.Body>
            {isLoading && (<Loading />)}
            {errorMessage && (<ErrorMessage />)}
            {!isLoading && !errorMessage && data?.touchpoint && (
                <TouchpointBriefingPanel
                    benchmarkData={data?.benchmarkData}
                    retailer={data?.touchpoint?.retailer}
                    touchpoint={data?.touchpoint}
                    visiblePanels={visiblePanels}
                    isBookable={false}
                />
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => {modal.hide()}}>Close</Button>
        </Modal.Footer>
    </>);
};

export default PreviewMediaModal;
