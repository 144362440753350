import { Activity } from 'Types';
import { getWorkflowCreativeType, getWorkflowTypeLabel } from '../utils';

interface RenderCreativeTypeProps {
    activity: Pick<Activity, 'creativeWorkflow'>;
    workflowId: string;
}

const WorkflowCreativeType = (props: RenderCreativeTypeProps) => {
    const { activity, workflowId } = props;
    const creativeType = getWorkflowCreativeType(activity, workflowId);
    return <span>{creativeType && getWorkflowTypeLabel(creativeType)}</span>;
};

export default WorkflowCreativeType;
