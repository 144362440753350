import moment from 'moment';
import { retailerFormatOptions } from '../index.js';
import {
    calculateActivityDiscountsValue,
    formatters,
    getStartAndEndDatesOfActivitiesAndItems,
    getTotalCostOfSelectedActivitiesAndItems,
    getTotalCostOfActivities,
    getItemCost,
} from '../../index.js';

const morrisonsInStoreTemplate = {
    name: 'Morrisons - In-store',
    pandaDocId: 'Emyj8esP4Akm3C8D76S6CW',
    companies: ['5b3dddac93c1d436671c07ac'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        return `[${now}] ${plan.customPlanId || plan.name}`;
    },

    getTokens: (campaign, plan, activities, items) => {
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, items);
        const totalCost = getTotalCostOfSelectedActivitiesAndItems(plan, activities, items);
        const planLink = `https://app.plan-apps.com/planner/campaigns/${campaign._id}/plans/${plan._id}`;

        return [
            { label: 'Custom plan ID', name: 'plan.customPlanId', value: plan.customPlanId || plan.name },
            { label: `Plan owner's email`, name: 'plan.owner.email', value: plan.owner.user.email },
            { label: 'Link to plan', name: 'plan.link', value: planLink },
            { label: 'Company name', name: 'company.name', value: campaign.company.name },
            { label: 'Brand name', name: 'brand.name', value: campaign.brands[0].name },
            { label: 'Campaign name', name: 'campaign.name', value: campaign.name },
            { label: 'Campaign total cost', name: 'campaign.totalCost', value: formatters.asCurrency(totalCost, { decimals: 2 }) },
            { label: 'Campaign start date', name: 'campaign.startDate', value: formatters.asDate(startDate) },
            { label: 'Campaign end date', name: 'campaign.endDate', value: formatters.asDate(endDate) },
            { label: 'Plan retailer category', name: 'category.name', value: plan.rCategory?.name || '-' },
            { label: 'Plan retailer sub-category', name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
        ];
    },

    getPricingTables: (plan, activities, items) => {
        let rows = [];
        const options = {
            optional: false,
            optional_selected: true,
            qty_editable: false,
        };

        const allActivitiesTotalCost = getTotalCostOfActivities(plan.activities);

        activities.forEach((activity) => {
            const retailerFormat = activity.retailerFormat
                ? retailerFormatOptions.find((option) => option.value === activity.retailerFormat).label
                : 'n/a';
            const activityTotalDiscountAmount = calculateActivityDiscountsValue(activity);

            rows.push({
                options,
                data: {
                    QTY: 1,
                    StoreFormat: retailerFormat,
                    Name: activity.touchpoint.name,
                    Description: activity.description,
                    StartDate: formatters.asDate(activity.startDate),
                    EndDate: formatters.asDate(activity.endDate),
                    Stores: activity.numberOfStores || 'n/a',
                    Price: activity.cost || 0,
                    ActualCost: formatters.asCurrency(activity.actualCost),
                    // 'Discount' is a reserved word for PandaDoc pricing tables - so we need to use our own term
                    DiscountValue: formatters.asCurrency(activityTotalDiscountAmount),
            }});
        });

        items.forEach((item) => {
            rows.push({
                options,
                data: {
                    QTY: 1,
                    StoreFormat: 'n/a',
                    Name: item.title,
                    Description: '',
                    StartDate: formatters.asDate(item.startDate),
                    EndDate: formatters.asDate(item.endDate),
                    Stores: 'n/a',
                    Price: item.cost ? getItemCost(item, allActivitiesTotalCost) : 0,
                }
            });
        });

        return [{
            name: 'Media Breakdown',
            data_merge: true,
            options: {
                currency: 'GBP',
            },
            sections: [
                {
                    title: 'Activities Section',
                    default: true,
                    rows,
                },
            ],
        }];
    },
};

export default morrisonsInStoreTemplate;
