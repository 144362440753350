/* eslint-disable prefer-template, no-param-reassign */

import _ from "lodash-es";
import moment from 'moment';
import simpleDate from "./simpleDate.js";

// Supports easy parsing of the parameters (value, [decimals], [options])
// ie, both `decimals` and `options` are optional and we determine from the type what to use
function parseArgs(allArgs) {
    if (allArgs.length >= 2 && _.isNumber(allArgs[1])) {
        return {
            value: allArgs[0],
            decimals: allArgs[1],
            options: allArgs[2] || {},
        };
    } else {
        return {
            value: allArgs[0],
            decimals: _.get(allArgs[1], 'decimals'),
            options: allArgs[1] || {},
        };

    }
}

function asCurrency(/* value, [decimals], [options] ={} */) {
    const { value, decimals = 0, options } = parseArgs(arguments);
    const {
        currency = 'GBP',
        na = '-',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    return value.toLocaleString(undefined, {
        currency,
        style: 'currency',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

function asDate(value, options = {}) {
    const {
        format = 'short', // 'short' (31/12/2021) OR 'long' (31 December 2021)
        na = '-', // N/A value
    } = options;

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    const isDate = value instanceof Date || (_.isString(value) && isoRegex.test(value));
    if (!isDate) {
        return na;
    }

    let pattern = 'short';
    if (format === 'short') {
        pattern = 'DD/MM/YYYY';
    } else if (format === 'long') {
        pattern = 'DD MMMM YYYY';
    } else {
        throw new Error(`Invalid format value "${format}" for asDate(). It should be 'short' or 'long'.`);
    }

    return simpleDate.format(value, pattern);
}

function asDateTime(dateOrTimestamp, options = {}) {
    const {
        na = '-',
        locale,
    } = options;

    const isoRegex = /^\d\d\d\d-\d\d-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ/;
    let date;

    if (dateOrTimestamp instanceof Date) {
        date = dateOrTimestamp;
    } else if (_.isString(dateOrTimestamp) && isoRegex.test(dateOrTimestamp)) {
        date = new Date(dateOrTimestamp);
    } else {
        return na;
    }

    return date.toLocaleString(locale);
}

// Replaces any invalid characters from the filename with a dash -
// See https://learn.microsoft.com/en-us/windows/win32/fileio/naming-a-file#naming-conventions
function asFilename(filename) {
    if (typeof filename !== 'string') {
        throw new Error(`Invalid type for asFilename(). It should be a string.`);
    }

    const safeName = filename.replace(/[\\/:*?"<>|]+/g, '-').trim();
    return safeName;
}

// Strip characters not allowed in HTTP headers (see https://stackoverflow.com/a/75998796 for allowed chars)
function asHTTPHeader(value) {
    return String(value).replace(/[^A-Za-z0-9-_.~!#$&'()*+,/:;=?@[\] ]/g, '');
}

function asNumber(/* value, [decimals], [options] ={} */) {
    const { value, decimals = 0, options } = parseArgs(arguments);
    const {
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }
    return value.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

function asPercentage(value, options = {}) {
    const {
        decimals = 2,
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    return `${asNumber(value, { decimals, na })}%`;
}

function humanise(value, options = {}) {
    const {
        decimals = 0,
        na = 'n/a',
    } = options;

    if (typeof value !== 'number' || isNaN(value)) {
        return na;
    }

    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;
    const trillion = 1000000000000;

    let output = value;
    if (value < thousand) {
        output = value.toFixed(decimals);
    } else if (value >= thousand && value < million) {
        output = (value / thousand).toFixed(decimals) + 'K';
    } else if (value >= million && value < billion) {
        output = (value / million).toFixed(decimals) + 'M';
    } else if (value >= billion && value < trillion) {
        output = (value / billion).toFixed(decimals) + 'B';
    } else if (value >= trillion) {
        output = (value / trillion).toFixed(decimals) + 'T';
    }
    return output;
}

// Returns the singularString if items is 1, otherwise returns pluralString
// Items can be a number or an array (the array length will be used)
function pluralise(items, singularString, pluralString, hideAmount) {
    let amount = items;

    if (_.isArray(items)) {
        amount = items.length;
    }

    if (!Number.isFinite(amount)) {
        return undefined;
    }

    const prefix = hideAmount ? '' : `${asNumber(amount)} `;
    return `${prefix}${amount === 1 ? singularString : pluralString}`;
}


function asTimeFromNow(value) {
    return moment(value).fromNow();
}

export default {
    asCurrency,
    asDate,
    asDateTime,
    asFilename,
    asHTTPHeader,
    asNumber,
    asPercentage,
    asTimeFromNow,
    humanise,
    pluralise,
};
