import { BookingForm } from 'Admin/BookingForms/BookingForms.types';

/**
 * Send, link & void can only be actioned on certain document statuses. See docs:
 * https://developers.pandadoc.com/reference/send-document
 * https://developers.pandadoc.com/reference/link-to-a-document
 * https://developers.pandadoc.com/reference/change-document-status-manually
 */
const canBeSent = (bookingForm: BookingForm) => {
    return bookingForm.status === 'document.draft';
};

/**
 * To resend a document, the document status must be 'draft'. PandaDoc allows any status to be reverted back to draft.
 *
 * However we only want to allow this if the document status is 'viewed' because
 * if the status is 'sent' a temporary link can be generated
 * and if the status is anything else there is no use case where the document would need to be resent.
 */
const canBeReSent = (bookingForm: BookingForm) => {
    return bookingForm.status === 'document.viewed';
};

const canBeVoided = (bookingForm: BookingForm) => {
    return bookingForm.status === 'document.sent' || bookingForm.status === 'document.viewed';
};

const canGetLink = (bookingForm: BookingForm) => {
    return bookingForm.status === 'document.sent'
        || bookingForm.status === 'document.waiting_pay'
        || bookingForm.status === 'document.paid'
        || bookingForm.status === 'document.completed';
};

export {
    canBeSent,
    canBeReSent,
    canBeVoided,
    canGetLink,
};
