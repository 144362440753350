// Renders a pill showing document status from Pandadoc
// See: https://developers.pandadoc.com/reference/document-status

import { config } from 'smg-common';
import { Pill } from '../';

import css from './BookingFormStatus.module.scss';

const BookingFormStatus = (props) => {
    const statusSlug = props.status;

    const { statusOptions } = config.pandaDoc;
    const status = statusOptions.find((option) => option.value === statusSlug);

    return (<Pill className={css.status} colour={status.color} text={status.label} />);
};

export default BookingFormStatus;
