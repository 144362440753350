import { Modal } from 'Components';

import css from './EasterEggFoundModal.module.scss';

interface EasterEggFoundModalProps {
    numOfEggsFound: number;
}

const EasterEggFoundModal = (props: EasterEggFoundModalProps) => {
    const { numOfEggsFound } = props;

    return (
        <Modal.Body>
            <div  style={{ position: 'relative' }}>
                <img
                    src="https://plan-apps-assets.s3.eu-west-2.amazonaws.com/easter-eggs/easter-egg-found.gif"
                    alt="easter-egg-found"
                    width="100%"
                />
                <div
                    className="text-center"
                    style={{ position: 'absolute', transform: 'translateY(-200px)', left: 0, right: 0 }}
                >
                    <h1>
                        <b>
                            <span>You have found </span>
                            <span>{numOfEggsFound}</span>
                            <span>/10 eggs.</span>
                        </b>
                    </h1>
                    <h2>Continue your hunt for more opportunities to win.</h2>
                    <h2 className={css.highlight}>Where will you look next?</h2>
                </div>
            </div>
        </Modal.Body>
    );
};

export default EasterEggFoundModal;
