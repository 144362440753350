import css from './Modal.module.scss';

const ModalHeader = ({ children, className='', ...restProps }) => {
    return (
        <div className={`${css['modal-header']} ${className}`} {...restProps}>
            <h3 className={css['modal-title']}>
                {children}
            </h3>
        </div>
    );
}

export default ModalHeader;
