import moment from 'moment';
import { calculateActivityDiscountsValue, formatters, getItemCost, getTotalCostOfActivities, simpleDate } from '../../index.js';

const captureTemplate = {
    name: 'Capture',
    pandaDocId: 'tZpJskLcXMM7j3V9ZvaVs8',
    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        return `[${now}] ${campaign.name} - ${plan.name}`;
    },
    companies: ['5a350b5c9fc5d92c8f5ea92d'],
    recipientRoles: [
        { label: 'Approver\'s email', value: 'Approver' },
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'PO to be raised to', value: 'poToBeRaisedTo' },
        {
            label: 'Booking type',
            value: 'bookingType',
            type: 'select',
            // These values are sentence case rather than as slugs as they are displayed on the booking form.
            // They are not stored in the database.
            options: [{
                label: 'Retailer',
                value: 'Retailer',
            }, {
                label: '3rd party',
                value: '3rd party',
            }, {
                label: 'Fees',
                value: 'Fees',
            }],
        },
    ],
    getTokens: (campaign, plan, activities, items, customFields) => {
        const tokens = [
            {
                label: 'Company name',
                name: 'companyName',
                value: campaign.company.name,
            },
            {
                label: 'Campaign name',
                name: 'campaignName',
                value: campaign.name,
            },
            {
                label: 'Plan name',
                name: 'planName',
                value: plan.customPlanId || plan.name,
            },
        ];

        return tokens;
    },
    getPricingTables: (plan, activities, items) => {
        // Prepare reference data
        const totalCostOfActivities = getTotalCostOfActivities(plan.activities);

        const rows = [];

        activities.forEach((activity) => {
            const activityTotalDiscountAmount = calculateActivityDiscountsValue(activity);

            const row = {
                // Options is a mandatory field
                options: {},
                data:{
                    // QTY is a mandatory field
                    QTY: 1,
                    // Cost is a mandatory field
                    Cost: activity.cost || 0,
                    Name: activity.touchpoint.name,
                    Description: activity.description,
                    StartDate: formatters.asDate(activity.startDate),
                    ArtworkDeadline: formatters.asDate(activity.artworkDeadline.date),
                    // Number of stores can sometimes be null, and PandaDoc doesn't allow null as a value
                    Stores: activity.numberOfStores || '',
                    Impressions: activity.impressions,
                    ActualCost: formatters.asCurrency(activity.actualCost),
                    // 'Discount' is a reserved word for PandaDoc pricing tables - so we need to use our own term
                    DiscountValue: formatters.asCurrency(activityTotalDiscountAmount),
                },
            };

            rows.push(row);
        });

        items.forEach((item) => {
            const row = {
                // Options is a mandatory field
                options: {},
                data: {
                    // QTY is a mandatory field
                    QTY: 1,
                    // Cost is a mandatory field
                    Cost: getItemCost(item, totalCostOfActivities),
                    Name: item.title,
                    Description: '',
                    StartDate: formatters.asDate(item.startDate),
                    ArtworkDeadline: '',
                    Stores: '',
                    Impressions: '',
                },
            };

            rows.push(row);
        });

        return [
            {
                name: 'Media Breakdown',
                data_merge: true,
                sections: [
                    {
                        // Title is a mandatory field
                        title: 'Activities and Items',
                        default: true,
                        rows,
                    },
                ],
            },
        ];
    },
};

export default captureTemplate;
