import { capitalize } from 'lodash';

import { Activity, Workflow } from 'Types';
import { ApprovalStateIcon, Icon, Tooltip } from 'Components';
import { getDeadlineDate, getWorkflowCreativeType, isWorkflowOverdue } from '../utils';

interface RenderApprovalIconProps {
    activity: Pick<
        Activity,
        | 'briefingDeadline'
        | 'briefingApprovalDeadline'
        | 'initialCreativeDeadline'
        | 'initialCreativeApprovalDeadline'
        | 'artworkDeadline'
        | 'artworkApprovalDeadline'
        | 'creativeWorkflow'
    >;
    workflow: Workflow;
}

const WorkflowApprovalIcon = (props: RenderApprovalIconProps) => {
    const { activity, workflow } = props;
    const creativeType = getWorkflowCreativeType(activity, workflow._id);
    const deadline =  creativeType && getDeadlineDate(activity, workflow, creativeType);
    return (
        <div className="d-flex align-items-center">
            <Tooltip hover={capitalize(workflow.status)}>
                <ApprovalStateIcon status={workflow.status} />
            </Tooltip>
            {isWorkflowOverdue(deadline, workflow.status) && (
                <Tooltip hover="Deadline exceeded">
                    <Icon name="priority_high" className="color-danger" />
                </Tooltip>
            )}
        </div>
    )
}

export default WorkflowApprovalIcon;