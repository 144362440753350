import { useEffect } from 'react';

// React hook to set the page title
const useTitle = (...args) => {
    useEffect(() => {
        const previous = document.title;
        document.title = [...args, 'Plan-Apps'].join(' • ');
        return () => {
            document.title = previous;
        }
    });
}

export default useTitle;
