import { Icon, Tooltip } from '../';

const ReadIcon = (props) => {
    const { className, hasBeenRead, onClick } = props;

    return (
        <Tooltip hover={hasBeenRead ? 'Mark as unread' : 'Mark as read'}>
            <Icon
                className={`${className} like-link`}
                name={hasBeenRead ? 'radio_button_unchecked' : 'circle'}
                onClick={() => onClick(hasBeenRead)}
            />
        </Tooltip>
    );
}

export default ReadIcon;
