import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { size } from 'lodash';
import { FTextareaField, Submit } from 'Components';

interface SummaryFormProps<Values> extends FormikConfig<Values> {
    maxLength?: number,
    rows?: number,
    dataAccessor: string,
}

const SummaryForm = ({ initialValues, onSubmit, maxLength = 200, rows = 6, dataAccessor, ...props }: SummaryFormProps<FormikValues>) => {
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} {...props}>
            {(formik) => (<Form>
                <FTextareaField
                    name={dataAccessor}
                    rows={rows}
                    maxLength={maxLength}
                    helpMsg={`${maxLength - size(formik.values[dataAccessor])} characters`}
                />
                <div className="stack-end-3 mt-4">
                    <Submit formik={formik}>Save</Submit>
                </div>
            </Form>)}
        </Formik>
    )
}

export default SummaryForm;