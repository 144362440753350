import { compact, map, max, min } from 'lodash-es';

import formatters from '../formatters.js';
import { getPlanTotals, isPlanCancelled } from './planFunctions.js';

function getCampaignDates(plans) {
    const planStartDates = [];
    const planEndDates = [];

    plans.forEach(plan => {
        if (!isPlanCancelled(plan)) {
            const nonCancelledActivities = plan.activities?.filter((activity) => !activity.cancelled);
            planStartDates.push(min(compact(map(nonCancelledActivities, activity => activity.startDate))));
            planEndDates.push(max(compact(map(nonCancelledActivities, activity => activity.endDate))));
        }
    });

    const earliestStartDate = min(compact(map(planStartDates, date => date)));
    const startDate = formatters.asDate(earliestStartDate);
    const latestEndDate = max(compact(map(planEndDates, date => date)));
    const endDate = formatters.asDate(latestEndDate);

    return  {
        dates: startDate + ' - ' + endDate,
        liveDate: earliestStartDate,
        endDate: latestEndDate,
    };
};

// Calculates the sum values for a list of plans (impressions, CPM, estimated ROI etc).
// They are displayed at the CampaignSummary table in planner and the pptx export of the same data
function getCampaignTotals(plans) {
    if (!plans) {
        return null;
    }

    const campaignTableTotals = {
        dates: '', // String. eg "13/01/2021 - 03/02/2021", "13/01/2021 - n/a" "n/a"
        impressions: null,
        cpm: null,
        estimatedPoundUplift: null,
        estimatedRoi: null,
        cost: null,
        actualCost: null,
        costForCpm: null,
        impressionsForCpm: null,
        costForEstimatedRoi: null,
        poundUpliftForEstimatedRoi: null,
        missingValuesForImpressions: false,
        missingValuesForCpm: false,
        missingValuesForEstimatedRoi: false,
    };

    plans.map(plan => {
        const tableTotals = getPlanTotals(plan);

        if (Number.isFinite(tableTotals.impressions)) {
            campaignTableTotals.impressions += tableTotals.impressions;
        }
        // Only calculate total CPM if an activity has a cost AND impressions
        if (Number.isFinite(tableTotals.costForCpm)) {
            campaignTableTotals.costForCpm += tableTotals.costForCpm;
        }
        if (Number.isFinite(tableTotals.impressionsForCpm)) {
            campaignTableTotals.impressionsForCpm += tableTotals.impressionsForCpm;
        }

        if (Number.isFinite(tableTotals.estimatedPoundUplift)) {
            campaignTableTotals.estimatedPoundUplift += tableTotals.estimatedPoundUplift;
        }
        // Only calculate est. roi if an activity has a cost AND estPoundUpliftBrand
        if (Number.isFinite(tableTotals.costForEstimatedRoi)) {
            campaignTableTotals.costForEstimatedRoi += tableTotals.costForEstimatedRoi;
        }
        if (Number.isFinite(tableTotals.poundUpliftForEstimatedRoi)) {
            campaignTableTotals.poundUpliftForEstimatedRoi += tableTotals.poundUpliftForEstimatedRoi;
        }

        campaignTableTotals.cost += tableTotals.cost || null;
        campaignTableTotals.actualCost += tableTotals.actualCost || null;

        if (tableTotals.missingValuesForImpressions) {
            campaignTableTotals.missingValuesForImpressions = true;
        }
        if (tableTotals.missingValuesForCpm) {
            campaignTableTotals.missingValuesForCpm = true;
        }
        if (tableTotals.missingValuesForEstimatedRoi) {
            campaignTableTotals.missingValuesForEstimatedRoi = true;
        }
    });

    campaignTableTotals.dates = getCampaignDates(plans).dates;
    campaignTableTotals.cpm = Number.isFinite(campaignTableTotals.costForCpm) && Number.isFinite(campaignTableTotals.impressionsForCpm) && campaignTableTotals.impressionsForCpm !== 0
        ? campaignTableTotals.costForCpm / (campaignTableTotals.impressionsForCpm / 1000)
        : null;
    campaignTableTotals.estimatedRoi = Number.isFinite(campaignTableTotals.poundUpliftForEstimatedRoi) && Number.isFinite(campaignTableTotals.costForEstimatedRoi) && campaignTableTotals.costForEstimatedRoi !== 0
        ? campaignTableTotals.poundUpliftForEstimatedRoi / campaignTableTotals.costForEstimatedRoi
        : null;

    return campaignTableTotals;
}


// Fallback logic: Briefing form -> Plan details -> Campaign brief
function getCampaignAttributes(campaign, plan) {
    if (!campaign) {
        return undefined;
    }

    const attributes = {
        promoType: undefined,
        isFeaturedCompetition: undefined,
        competitionPrize: undefined,
        entryMechanics: undefined,
        onPack: undefined,
        giftWithPurchase: undefined,
        coupons: undefined,
        isNpd: undefined,
        npdType:  undefined,
        isRetailerEvent: undefined,
    };

    Object.keys(attributes).forEach((name) => {
        let value;

        if (campaign.brief?.attributes && campaign.brief.attributes[name]) {
            value = campaign.brief.attributes[name];
        }

        if (plan && plan[name]) {
            value = plan[name];
        }

        if (campaign.briefingForm && campaign.briefingForm[name]) {
            value = campaign.briefingForm[name];
        }

        attributes[name] = value;
    });

    return attributes;
}

export {
    getCampaignAttributes,
    getCampaignTotals,
    getCampaignDates,
};
