import { capitalize, compact, groupBy, omit, pick, sortBy, uniqBy } from 'lodash';
import { config, type Option } from 'smg-common';

import { ReactSelect, RetailerPill, TextField } from 'Components';

import type { OptionProps } from 'react-select';
import type { AudienceFilters, AudienceSegment } from 'Types';

interface AudienceBrowserProps {
    audienceSegments: AudienceSegment[];
    onFiltersChanged: (filter: AudienceFilters) => void;
    selectedFilters: AudienceFilters;
    showRetailerComponents: boolean;
}

interface RSubCategoryOption extends Option {
    retailer?: {
        name: string;
        shortName: string;
        backgroundColor: string;
        textColor: string;
    };
}

interface CategoryOption extends Option {
    options?: RSubCategoryOption[];
}

function buildRSubCategoryOptionsTree(audienceSegments: AudienceSegment[]): CategoryOption[] {
    const allRSubCategories = compact(audienceSegments.flatMap((audience) => audience.rSubCategories));
    const uniqueRSubCategories = uniqBy(allRSubCategories, '_id');
    const rSubCategories = uniqueRSubCategories.map(c => ({ label: c.name, value: c._id, parent: c.parent, retailer: c.retailer }));
    const groupedRSubCategories = groupBy(rSubCategories, 'parent._id');

    const rCategories = Object.entries(groupedRSubCategories).map(([parentId, rSubCategories]) => {
        const parentLabel = rSubCategories[0].parent?.name;
        const options = sortBy(
            rSubCategories.map((subCategory) => pick(subCategory, 'label', 'value', 'retailer')),
            'label',
        );
        return {
            label: parentLabel ?? '',
            value: parentId,
            options,
        };
    });

    return sortBy(rCategories, 'label');
}

const getFilterOptions = (audienceSegments: AudienceSegment[]) => {
    const rSubCategoryOptions = buildRSubCategoryOptionsTree(audienceSegments);

    const uniqueRetailers = uniqBy(audienceSegments, 'retailer._id');
    const retailerOptions = sortBy(uniqueRetailers.map(r => ({ label: r.retailer.name, value: r.retailer._id })), 'label');

    const uniqueTypes = uniqBy(audienceSegments, 'type');
    const typeOptions = sortBy(uniqueTypes.map(t => {
        const option = config.audiences.typeOptions.find(type => type.value === t.type);
        return { label: option?.label, value: option?.value };
    }), 'label');

    const uniqueAudienceVendors = uniqBy(audienceSegments, 'audienceVendor');
    const audienceVendorOptions = sortBy(uniqueAudienceVendors.map(a => ({ label: capitalize(a.audienceVendor), value: a.audienceVendor })), 'label')

    return { rSubCategoryOptions, retailerOptions, typeOptions, audienceVendorOptions };
};

// To include retailer pills in the rSubCategory filter dropdown, we need to define a custom element for react-select to render each option.
// https://react-select.com/components#replacing-components
const CustomCategoryOption = (props: OptionProps<RSubCategoryOption>) => {
    // Extract retailer from the rSubCategory option to render RetailerPill
    const { retailer } = props.data;
    // Get styles for default option component for consistent styling
    const defaultStyles = props.getStyles('option', props);

    const styles = {
        // Omit accentColor due to type mismatch
        ...omit(defaultStyles, 'accentColor'),
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <div {...props.innerProps} style={styles}>
            {retailer && <RetailerPill className="p-0 mr-2" retailer={retailer}/>}
            {props.label}
        </div>
    );
}

const AudienceBrowserFilters = (props: AudienceBrowserProps) => {
    const { audienceSegments, onFiltersChanged, selectedFilters, showRetailerComponents } = props;

    const { name, retailer, type, objective, rSubCategory, audienceVendor } = selectedFilters;
    const { rSubCategoryOptions, retailerOptions, typeOptions, audienceVendorOptions } = getFilterOptions(audienceSegments);

    const clearFilters = () => {
        onFiltersChanged({
            name: '', // To clear the TextField we need to use an empty string
            retailer: undefined,
            type: undefined,
            objective: undefined,
            rSubCategory:  undefined,
            selectedAudience: undefined,
            audienceVendor: undefined,
        });
    };

    return (
        <div className="row align-items-center box-raised p-3 ml-2 mr-2">
            <TextField
                // @ts-expect-error TextField component is not typed correctly yet
                className="col-md-3"
                isClearable
                label="Audience name"
                onChange={(value: string) => onFiltersChanged({name: value})}
                placeholder="Search by name..."
                value={name}
            />
            <TextField
                // @ts-expect-error TextField component is not typed correctly yet
                className="col-md-3"
                isClearable
                label="Objective"
                onChange={(value: string) => onFiltersChanged({objective: value})}
                placeholder="Search by objective..."
                value={objective}
            />
            <ReactSelect
                className="col-md-3"
                isClearable
                label="Audience Vendor"
                options={audienceVendorOptions}
                value={audienceVendor}
                onChange={(option: Option) => onFiltersChanged({audienceVendor: option?.value as string | undefined})}
            />
            {showRetailerComponents && <ReactSelect
                className="col-md-3"
                isClearable
                label="Retailer"
                options={retailerOptions}
                onChange={(option: Option) => onFiltersChanged({retailer: option?.value as string | undefined})}
                value={retailer}
            />}
            <ReactSelect
                className="col-md-3"
                isClearable
                label="Type"
                options={typeOptions}
                onChange={(option: Option) => onFiltersChanged({type: option?.value as string | undefined})}
                value={type}
            />
            <ReactSelect
                className="col-md-3"
                isClearable
                label="Category"
                options={rSubCategoryOptions}
                onChange={(option: Option) => onFiltersChanged({rSubCategory: option?.value as string | undefined})}
                value={rSubCategory}
                // Use custom option component to include retailer pills
                components={{ Option: CustomCategoryOption }}
            />
            <div className="like-link col-md-3 mt-3" onClick={() => clearFilters()}>Clear all</div>
        </div>
    );
};

export default AudienceBrowserFilters;
