import { RichErrorPage } from './';

const ForbiddenPage = (props) => {
    const { missing, reason } = props;

    const message = missing
        ? (<span>This page requires the "{missing}" permission.<br />Please make sure you have this permission and try again.</span>)
        : reason;

    return (
        <RichErrorPage
            message={message}
            status="403"
            title="Forbidden"
        />
    )
};

export default ForbiddenPage;
