import { useState } from 'react';
import { Form, Formik } from 'formik';
import { get, isArray, isEmpty, sortBy } from 'lodash';
import { config } from 'smg-common';

import type { File } from 'Types';
import { Button, FDropzone, FReactSelect, Http, Icon, Modal, Submit, Tooltip, toast } from 'Components';

/**
 * This interface represents the bare minimum required for this component to work.
 * Attachment interface implementations may vary depending on the use case.
 * */
interface Attachment {
    _id: string;
    file: File;
    documentType: string;
}

interface EditAttachmentModalProps {
    saveBaseUrl: string,
    attachment?: Attachment,
    onSuccess: (values: any) => void
}

/*
    This component expects 2 endpoints for saving attachments:
        - Create new attachment
            POST :saveBaseUrl
        - Update existing attachment
            POST :saveBaseUrl/:attachmentId
*/
const EditAttachmentModal = (props: EditAttachmentModalProps) => {
    const { saveBaseUrl, attachment, onSuccess } = props;
    const [ showModal, setShowModal ] = useState(false);

    const documentTypeOptions = sortBy(get(config, 'campaigns.plans.documentTypes'), 'label');
    const initialisedAttachment = attachment ? attachment : {
        documentType: null,
        file: null,
        uploadedAt: null,
    };

    const validate = (values: typeof initialisedAttachment) => {
        const errors: { documentType?: string, file?: string } = {};

        if (!values.documentType) {
            errors.documentType = 'Document type is required.';
        }

        if (isEmpty(values.file)) {
            errors.file = 'File is required.';
        }

        return errors;
    }

    const handleSave = (values: typeof initialisedAttachment) => {
        if (attachment) {
            // Edit documentType of an existing attachment
            Http.post(`${saveBaseUrl}/${attachment._id}`, values)
                .then((response) => {
                    toast.success('Attachment successfully saved.');
                    setShowModal(false);
                    onSuccess(response);
                })
                .catch(error => {
                    toast.error('Attachment failed to save. Please try again.');
                    console.error(error);
                });
        } else {
            // Create a new attachment
            const { file } = values;
            // If we are uploading multiple attachments at once, we need to unwind the array
            const flatValues = isArray(file)
                ? file.map((fileItem) => ({
                    ...values,
                    file: fileItem,
                }))
                : [values];

            Http.post(saveBaseUrl, flatValues)
                .then((campaign) => {
                    toast.success('Attachment successfully saved.');
                    setShowModal(false);
                    onSuccess(campaign);
                })
                .catch(error => {
                    toast.error('Attachment failed to save. Please try again.');
                    console.error(error);
                });
        }
    };

    return (
        <>
            {
                attachment &&
                <Tooltip hover="Edit attachment" align="left">
                    <Icon className="like-link subtle ml-2" name="edit" onClick={() => setShowModal(true)}/>
                </Tooltip>
            }

            {
                !attachment &&
                <Button color="primary" onClick={() => setShowModal(true)}>Add attachment</Button>
            }

            <Modal onCancel={() => setShowModal(false)} show={showModal} title={attachment ? 'Edit attachment' : 'Add attachment'} width="lg">
                <Modal.Body>
                    <Formik initialValues={initialisedAttachment} onSubmit={handleSave} validate={validate}>
                        {(formik) => (
                            <Form>
                                <FReactSelect className="mt-3" label="Document type" name="documentType" options={documentTypeOptions} required />

                                {!attachment && (
                                    <FDropzone
                                        label="Drop file"
                                        multi
                                        name="file"
                                    />

                                )}

                                <div className="stack-end-3 mt-4">
                                    <Button onClick={() => setShowModal(false) }>Cancel</Button>
                                    <Submit formik={formik}>Save</Submit>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export type {
    Attachment,
}

export default EditAttachmentModal;
