import moment from 'moment';
import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';

import AnnualTimeline from './Components/AnnualTimeline';
import MonthlyTimeline from './Components/MonthlyTimeline';

import { Button, ButtonGroup, Checkbox, DatePickerField, EasterEgg, Icon, useUrl6 } from '../';

import css from './Calendar.module.scss';

const Calendar = (props) => {
    const {
        endDate,
        events = [],
        onEventChange,
        onDateChange,
        onEventDelete,
        sort,
        startDate = moment().startOf('day').toDate(),
        exportCalendar,
        setExportCalendar,
    } = props;

    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const [date, setDate] = useState(startDateMoment);
    const { queryParams, setQueryParams } = useUrl6();
    const { view = 'monthly' } = queryParams; // enum: ['monthly', 'annual']
    const { hideMediaCosts } = queryParams;

    // if (!view) {
    //     setQueryParams({ view: 'monthly' });
    // }

    useEffect(
        () => {
            if (!startDateMoment.isSame(date) && isFunction(onDateChange)) {
                onDateChange(date.toDate(), date.clone().add(3, 'months').toDate());
            }
        },
        [date], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const setToday = () => {
        setDate(moment());
    };

    const showPrev = () => {
        setDate(view === 'monthly' ? date.clone().subtract(1, 'week') : date.clone().subtract(1, 'month'));
    };

    const showNext = () => {
        setDate(view === 'monthly' ? date.clone().add(1, 'week') : date.clone().add(1, 'month'));
    };

    const handleDateChanged = (newDate) => {
        setDate(moment(newDate));
    };

    const handleEventChange = (updatedEvent) => {
        if (isFunction(onEventChange)) {
            onEventChange(updatedEvent);
        }
    }

    const handleEventDelete = (deletedEvent) => {
        if (isFunction(onEventChange)) {
            onEventDelete(deletedEvent);
        }
    }

    const handleViewChanged = (event, value) => {
        setQueryParams({view: value});
    };

    const calendarContentClasses = [css['calendar-content'], 'stack-col-3', 'stack-stretch'];

    return (
        <>
            <div className={`${css.header} stack-even-center-3 p-3`}>
                <span className="stack-start-center-3">
                    <Button onClick={setToday}>Today</Button>
                    <span className="stack-start-center-3">
                        <Icon className="like-link no-color" name="chevron_left" onClick={showPrev} />
                        <DatePickerField className={css['date-picker']} selected={new Date(startDate)} onChange={handleDateChanged} />
                        <Icon className="like-link no-color" name="chevron_right" onClick={showNext}/>
                    </span>
                    <EasterEgg eggId="10" style={{ float: 'right', margin: '0 0 0 400px' }} />
                </span>
                <span className="stack-end-center-4">
                    <Checkbox label="Hide media costs" checked={hideMediaCosts === 'true'} onChange={(event) => setQueryParams({hideMediaCosts: event.target?.checked})} />
                    <ButtonGroup activeColor="primary" onChange={handleViewChanged} value={view}>
                        <Button value="monthly">Monthly</Button>
                        <Button value="annual">Annual</Button>
                    </ButtonGroup>
                </span>
            </div>
            <div className={calendarContentClasses.join(' ')}>
                <div className={`${css.body} stack-col-3 stack-stretch stack-stretch-items`}>
                    {
                        view === 'monthly' && (
                            <MonthlyTimeline
                                endDate={endDate || date.clone().add(3, 'months')}
                                events={events}
                                onEventChange={handleEventChange}
                                onEventDelete={handleEventDelete}
                                sort={sort}
                                startDate={startDate}
                                exportCalendar={exportCalendar}
                                setExportCalendar={setExportCalendar}
                                hideMediaCosts={hideMediaCosts}
                            />
                        )
                    }
                    {
                        view === 'annual' && (
                            <AnnualTimeline
                                endDate={endDate || date.clone().add(12, 'months').toDate()}
                                events={events}
                                onEventChange={handleEventChange}
                                onEventDelete={handleEventDelete}
                                sort={sort}
                                startDate={startDate}
                                exportCalendar={exportCalendar}
                                setExportCalendar={setExportCalendar}
                                hideMediaCosts={hideMediaCosts}
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default Calendar;
