import moment from 'moment';
import { includes } from 'lodash-es';

function isSubscriptionValid(subscription) {
    return subscription.startDate
        && subscription.endDate
        && moment(subscription.endDate).isAfter()
        && moment(subscription.startDate).isBefore();
};

function isValid(subscriptions = [], policy) {
    return Boolean(subscriptions.find(subscription => {
        return isSubscriptionValid(subscription)
        && policy ? includes(subscription.policies, policy) : true;
    }));
};

function getValid(subscriptions = []) {
    return subscriptions.filter(subscription => isSubscriptionValid(subscription));
};

// Returns true if there is at least one active subscription that includes the specified policy
function hasPolicy(subscriptions = [], policy) {
    return getValid(subscriptions)
        .flatMap(subscription => subscription.policies)
        .includes(policy);
};

export default {
    isValid,
    getValid,
    hasPolicy,
};
