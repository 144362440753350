import { ColoredPanel } from 'Components';

const Message = (props) => {
    const { children, className, level = 'info', text, ...restProps } = props;

    const color = level;
    return (
        <ColoredPanel className={className} color={color} {...restProps}>{children || text}</ColoredPanel>
    );
};

const Info = (props) => (<Message level="info-light" {...props} />);
const Success = (props) => (<Message level="success-light" {...props} />);
const Warning = (props) => (<Message level="warn-light" {...props} />);
const Error = (props) => (<Message level="danger-light" {...props} />);

export default Message;
export {
    Info,
    Success,
    Warning,
    Error,
};
