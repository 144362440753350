import { connect } from 'formik';
import { get } from 'lodash';

import { Error } from 'Components';

/**
 * Displays an errorMessage at form-level (as opposed to field-level errors)
 */
const FlashError = (props) => {
    const { className, message } = props;

    const errorMessage = get(props, 'formik.status.errorMessage', message);

    const classes = [];
    if (className) classes.push(className);

    return errorMessage
        ? <Error className={classes.join(' ').trim()} text={errorMessage} />
        : null;
}

export default connect(FlashError);
