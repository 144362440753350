import css from './Legend.module.scss'

const Legend = (props) => {
    const { className, color, label, ...restProps } = props;

    const classes = [css.circle];
    if (color && css[color]) classes.push(css[color]);
    if (label) classes.push('mr-2');

    return (
        <div className={className} {...restProps}>
            <div className="stack-start-center">
                {color && <span className={classes.join(' ')}></span>}
                {label && <span>{label}</span>}
            </div>
        </div>
    );
}

export default Legend;
