import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { get, isEmpty, map } from 'lodash';

import { BreadcrumbToolbar, Http, Layout, PageLoader6, useTitle } from '../../Components';
import { config } from 'smg-common';

/**
 * A page that displays the report of all the users that have access to a company's data.
 */
const ViewCompanyUsersPage = (props) => {
    const { targetCompany, companiesWithAccess } = props.report;

    // This page is visible in two places: inside the admin/edit-company (for admins) and at the
    // user-avatar dropdown (for clients). Therefore it can be rendered under two different URLs,
    // with different navigation for each one.
    const { pathname } = useLocation();
    const seenAsAdmin = /^admin\/collections\/companies\/[a-f0-9]+\/users/.test(pathname);

    useTitle(`${targetCompany.name} users`);

    let toolbar, pageTitle;
    if (seenAsAdmin) {
        pageTitle = 'Admin';
        const backLink = get(props, 'location.state.adminTableReferer', '/admin/collections/companies');
        toolbar = (
            <BreadcrumbToolbar breadcrumbs={[
                { label: 'Admin', link: '/admin' },
                { label: 'Companies', link: backLink },
                { label: targetCompany.name, link: `/admin/collections/companies/${targetCompany._id}` },
                { label: 'Users' },
            ]} />
        );
    } else {
        toolbar = (
            <BreadcrumbToolbar breadcrumbs={[
                { label: `${targetCompany.name} users` },
            ]} />
        );
    }

    return (
        <Layout className="box-raised p-3 m-3" pageTitle={pageTitle} toolbar={toolbar}>
            <div className="box-flat p-3 mb-5">
                <div className="my-2">This is a list of all the users that have access to the data of <b>{targetCompany.name}</b>.</div>
                { !isEmpty(targetCompany.acceptedEmailDomains) && (
                    <div className="my-2">
                        New users will automatically be added to this list if they register in Plan-Apps using an email with one of the following domains:
                        <ul style={{ margin: '0 '}}>
                            { map(targetCompany.acceptedEmailDomains, domain => <li>{domain}</li>) }
                        </ul>
                    </div>
                )}
                <div className="my-2">For any queries, please contact support at <a href={`mailto:${config.supportEmailAddress}`}>{config.supportEmailAddress}</a>.</div>
            </div>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Email</th>
                            <th>Role(s)</th>
                        </tr>
                    </thead>
                    <tbody>

                        { map(companiesWithAccess, company => (
                            <Fragment key={company._id}>
                                {/* Company name */}
                                <tr style={{ backgroundColor: '#f3f3f3' }} >
                                    <td className="p-3" colSpan="3">{company.name}</td>
                                </tr>
                                {/* Users */}
                                { map(company.users, (user) => (
                                    <tr key={user._id}>
                                        <td className="pl-5">{user.firstName} {user.lastName}</td>
                                        <td>{user.email}</td>
                                        <td>{ map(user.roles, role => role.name).join(', ') }</td>
                                    </tr>
                                ))}
                                {/* Message when there are no users */}
                                { isEmpty(company.users) && (
                                    <tr>
                                        <td colSpan="3" className="p-3 pl-5">
                                            No users for {company.name}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        )) }
                    </tbody>
                </table>
            </div>
        </Layout>
    );
}

export default PageLoader6(ViewCompanyUsersPage, 'Admin', ({ params }) => ({
    report: Http.get(`/admin/companies/${params.companyId}/user-access-report`),
}));
