const policies = [
    {
        apps: ['beta'],
        slug: 'beta',
        label: 'BETA',
        helpMsg: 'Provides access to the experimental features',
    },
    {
        apps: ['allocator'],
        slug: 'allocator',
        label: 'Allocator',
        helpMsg: 'Provides access to the Allocator app',
    },
    {
        apps: ['audiences'],
        slug: 'audiences',
        label: 'Audiences',
        helpMsg: 'Provides access to the Audiences app',
    },
    {
        apps: ['budgeting'],
        slug: 'budgeting',
        label: 'Budgeting',
        helpMsg: 'Provides access to the Budgeting app',
    },
    {
        apps: ['dashboard'],
        slug: 'dashboard',
        label: 'Dashboard',
        helpMsg: 'Provides access to the dashboard app',
    },
    {
        apps: ['evaluations'],
        slug: 'evaluations',
        label: 'Evaluations',
        helpMsg: 'Provides access to the Evaluations app',
    },
    {
        slug: 'confidentialInformation',
        label: 'Confidential information',
        helpMsg: 'Can see handover information in Plans, and briefing form actual spend',
    },
    {
        apps: ['media'],
        slug: 'media',
        label: 'Media',
        helpMsg: 'Provides access to the Media app',
    },
    {
        apps: ['campaigns'],
        slug: 'campaignsBasic',
        label: 'Campaigns',
        helpMsg: 'Provides access to the Campaigns app',
    },
    {
        apps: ['selfServeCampaigns'],
        slug: 'selfServeCampaigns',
        label: 'Self-serve campaigns',
        helpMsg: 'Provides access to the Self-serve campaigns app'
    },
    {
        apps: ['trends'],
        slug: 'trends',
        label: 'Trends',
        helpMsg: 'Provides access to the Trends app',
    },
    {
        slug: 'digitalMediaSupplierApis',
        label: 'Digital media supplier APIs',
        helpMsg: 'Allows users to access 3rd-party digital media supplier APIs (Meta, The Trade Desk etc)',
    },
    {
        slug: 'objectiveVsActualKpis',
        label: 'Show Actual KPIs',
        helpMsg: 'Shows the objective vs actual KPIs in Evaluations',
    },
    {
        slug: 'pandaDoc',
        label: 'PandaDoc',
        helpMsg: 'Allows users to create booking forms in Campaigns',
    },
] as const;

type Policy = typeof policies[number]['slug'];
type PolicySet = Record<Policy, boolean>;

export type {
    Policy,
    PolicySet,
};

export default policies;
