import { forwardRef } from 'react';

import css from './FormControl.module.scss';
import AsFormik from './AsFormik';
import FormGroup from './FormGroup';
import Icon from '../Icon/Icon';

/**
 * A wrapper for input fields.
 */
const TextField = forwardRef((props, ref) => {
    const {
        className = '',
        errorMsg,
        helpModal,
        helpMsg,
        hidden,
        id,
        isClearable = false,
        label,
        name,
        onChange = () => null,
        required,
        successMsg,
        tabIndex = 1,
        theme,
        topRightSlot,
        ...restProps
    } = props;

    const inputClasses = [css['form-control']];
    if (errorMsg) inputClasses.push(css['is-invalid']);
    if (theme) inputClasses.push(css[`${theme}-theme`]);

    return (
        <FormGroup
            className={className}
            data-smg-field={label}
            elementId={id}
            errorMsg={errorMsg}
            helpModal={helpModal}
            helpMsg={helpMsg}
            hidden={hidden}
            label={label}
            required={required}
            successMsg={successMsg}
            topRightSlot={topRightSlot}
        >
            <div className={css.inputContainer}>
                <input
                    {...restProps}
                    className={inputClasses.join(' ')}
                    id={id}
                    name={name}
                    onChange={event => onChange(event.target.value)}
                    ref={ref}
                    tabIndex={tabIndex}
                    required={required}
                />
                {isClearable && <Icon name="close" className={css.clearIcon} onClick={() => onChange('')} />}
            </div>
        </FormGroup>
    );
});
TextField.displayName = 'TextField';

const FTextField = AsFormik(TextField, {
    extractValue: value => value,
});

export { TextField, FTextField };
