import { useState } from 'react';

import { Http, RichTextContent, Tooltip } from '../';

import css from './GlossaryTerm.module.scss';

const GlossaryTerm = (props) => {
    const { align = 'right', children, className, defaultDefinition, slug, ...rest } = props;
    const [definition, setDefinition] = useState(undefined);

    const handleMouseEntered = () => {
        if (slug) {
            Http.get(`/glossary/terms/${slug}`).then((term) => setDefinition(term?.definition || defaultDefinition));
        } else {
            setDefinition(defaultDefinition)
        }
    };

    const tooltipContent = <RichTextContent text={definition} />;

    return (
        <Tooltip align={align} hover={definition && tooltipContent}>
            <span
                className={`${css['glossary-term']} ${className}`.trim()}
                onMouseEnter={handleMouseEntered}
                {...rest}
            >
                {children}
            </span>
        </Tooltip>
    );
}

export default GlossaryTerm;
