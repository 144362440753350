import { isPlanCancelled } from 'smg-common';

import { Pill } from '../';

import css from './PlanStatus.module.scss';

const PlanStatus = (props) => {
    const { plan, showBoth } = props;
    const cancelledPlan = isPlanCancelled(plan);

    if (showBoth) {
        // Cancelled status
        if (cancelledPlan) {
            return <Pill text="Cancelled" className={css.grey} type="status" />;
        } else {
            // All non cancelled statuses
            return (
                <>
                    { (plan.archived || plan.historic) &&
                        <Pill text={plan.archived ? 'Archived' : 'Historic'} className={`mb-1 ${css.grey}`} type="status" />
                    }
                    <Pill text={plan.status} className={plan.status === 'final' ? css.blue : css['light-blue']} type="status"/>
                </>
            );
        }
    } else {
        let text = plan.status;
        let color = plan.status === 'final' ? 'blue' : 'light-blue';
        if (cancelledPlan) {
            text = 'Cancelled';
            color = 'grey';
        } else if (plan.archived) {
            text = 'Archived';
            color = 'grey';
        } else if (plan.historic) {
            text = 'Historic';
            color = 'grey';
        }

        return <Pill text={text} className={css[color]} type="status" />;
    }
}

export default PlanStatus;