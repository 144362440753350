import { isArray } from 'lodash';
import { CSSObjectWithLabel, GroupBase, OnChangeValue, PropsValue } from 'react-select';
import Async, { AsyncProps } from 'react-select/async';
import { HTMLProps, ReactNode, useState } from 'react';

import css from './ReactSelect.module.scss';
import FormGroup from './FormGroup';
import AsFormik from './AsFormik';

// Note: This should be in the FormGroup component once it's converted to TS
interface FormGroupProps extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
    errorMsg?: ReactNode,
    helpMsg?: ReactNode,
    successMsg?: ReactNode,
    helpModal?: {
        title: string,
        body: ReactNode,
    },
    topRightSlot?: ReactNode,
    label?: ReactNode
}

interface AsyncSelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> extends
    AsyncProps<Option, IsMulti, Group>,
    Pick<FormGroupProps, 'errorMsg' | 'helpModal' | 'helpMsg' | 'label' | 'successMsg' | 'topRightSlot' | 'style'>
{
    controlStyle?: CSSObjectWithLabel,
    initialValue?: PropsValue<Option>,
}

const AsyncSelect = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    className,
    errorMsg,
    helpModal,
    helpMsg,
    label,
    required,
    successMsg,
    style,
    topRightSlot,
    theme,
    isMulti,
    controlStyle = {},
    initialValue,
    onChange,
    value, // Not used, but needs to be destructured to avoid passing it to the Async component
    ...props
}: AsyncSelectProps<Option, IsMulti, Group>) => {
    // Options are loaded asynchronously, so we need a way to set an initial value without executing an async call
    const [selectedOption, setSelectedOption] = useState(initialValue)

    const handleChange = (selectedOption: OnChangeValue<Option, IsMulti>) => {
        setSelectedOption(selectedOption);
        if (onChange) {
            // This will set the Formik field value to the selected option's value if used in a Formik form
            onChange(selectedOption, { action: 'select-option', option: selectedOption as Option });
        }
    };

    // Stolen from reactSelect component to keep styling consistent
    const compiledControlStyle = (base: CSSObjectWithLabel) => ({
        ...base,
        ...(isMulti ? {} : { height: '35px' }), // Align the single-select with the other form components, while allowing the multi-select to wrap lines
        marginTop: '3px',
        minHeight: '35px',
        backgroundColor: '#f3f3f3',
        ...controlStyle,
    });

    const classes = [css['react-select-container']];
    if (errorMsg) classes.push(css['is-invalid']);
    if (theme) classes.push(css[`${theme}-theme`]);
    return (
        <FormGroup
            className={className}
            errorMsg={errorMsg}
            // @ts-expect-error Prop-type is incorrectly defined
            helpModal={helpModal}
            helpMsg={helpMsg}
            label={label}
            required={required}
            successMsg={successMsg}
            style={style}
            topRightSlot={topRightSlot}
        >
            <Async
                className={classes.join(' ').trim()}
                classNamePrefix="react-select"
                isMulti={isMulti}
                styles={{
                    control: compiledControlStyle
                }}
                onChange={handleChange}
                value={selectedOption}
                {...props}
            />
        </FormGroup>
    )
}

const FAsyncSelect = AsFormik(AsyncSelect, {
    extractValue: (newValue: any) => {
        if (isArray(newValue)) {
            return newValue.map(option => option.value)
        } else {
            return newValue ? newValue.value : null;
        }
    }
})

export {
    AsyncSelect,
    FAsyncSelect,
};