import { useState } from 'react';
import { Analytics, Http, Icon, Loader, Pill, Tooltip } from '../../../Components';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';
import { LoaderProps } from 'Components/Loader/Loader';

interface RecentlyAddedResponse {
    _id: string,
    type: 'campaign' | 'evaluation',
    title: string,
    publishedAt: string,
}

const getLabelColour = (itemType: RecentlyAddedResponse['type']) => {
    switch (itemType) {
    case 'campaign':
        return 'smg-sea'
    case 'evaluation':
        return 'smg-beach'
    default:
        return '';
    }
}

const getLink = (itemType: RecentlyAddedResponse['type'], id: RecentlyAddedResponse['_id']) => {
    switch (itemType) {
    case 'campaign':
        return `planner/campaigns/${id}`
    case 'evaluation':
        return `evaluations/studies/${id}`
    default:
        return '';
    }
}

type RecentlyAddedProps = Omit<LoaderProps<RecentlyAddedResponse>, 'promise'>

const RecentlyAdded = ({ className, ...restProps }: RecentlyAddedProps) => {
    const [recentlyAdded, setRecentlyAdded] = useState([])

    const fetchData = () => Http.get('/homepage/recently-added').then(setRecentlyAdded);

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    return (
        <Loader promise={fetchData} className={classes.join(' ').trim()} {...restProps}>
            <section>
                <h2 className="mb-2">Recently added</h2>
                <hr />
                {recentlyAdded.map((item: RecentlyAddedResponse) => (
                    <article className="box-flat mt-2 p-2 stack-even-center" style={{backgroundColor: 'white'}} key={item._id}>
                        <div className="stack-col-3">
                            <Tooltip hover={item.title.length >= 100 && item.title}>
                                <h3 className="mb-2">{truncate(item.title, { length: 100 })}</h3>
                            </Tooltip>
                            <Pill colour={getLabelColour(item.type)} small text={item.type} className="m-0" />
                        </div>
                        <Link onClick={() => Analytics.log('DASHBOARD-RECENTLY-ADDED-CLICKED')} to={getLink(item.type, item._id)}><Icon name="chevron_right" size="2rem" /></Link>
                    </article>
                ))}
                {recentlyAdded.length === 0 && <h3 className="mt-3">You have no recently added content to view</h3>}
            </section>
        </Loader>
    );
};

export default RecentlyAdded;
