import Icon from '../Icon/Icon';
import Button from './Button';

import css from './IconButton.module.scss';

/**
 * A special button that contains only one icon and displays as square
 */
const IconButton = ({ name, className='', ...restProps}) => {
    return (
        <Button className={`${className} ${css['icon-button']}`} {...restProps}>
            <Icon className={css.offset} name={name} />
        </Button>
    );
}

export default IconButton;
