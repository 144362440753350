const termsAndConditionsContent = <>
    <p>By using this application you agree to the following terms and conditions, disclaimer and privacy policy.</p>
    <h2>1. Copyright Information</h2>
    <p>Copyright and all other intellectual property rights, in the material available on this site, is owned by Shopper Media Group Ltd and may only be used in the ways described in this Legal Notice.</p>
    <h2>2. Authorised usage and confidentiality</h2>
    <p>For information contained in the Campaigns part of the application: you may view, export, download, print, share or publicise copies of the information provided that in so doing you do not breach or contravene any other agreement you may have entered into concerning the use of that information.</p>
    <p>For information contained within the Trends, Media and any other part of the application: you may view, export, download, print and share internally (ie. with other employees of the company shown in your profile page) copies of the information provided that in so doing you do not breach or contravene any other agreement you may have entered into concerning the use of that information.</p>
    <p>You may not publicise the information or allow the information to be publicised outside your organisation without the prior written agreement of Shopper Media Group Ltd.</p>
    <p>All authorised usage of the information in the application is subject to the following general conditions:</p>
    <ul>
        <li>No part of the information may be altered in any way;</li>
        <li>No copyright notices from charts or graphs are removed;</li>
        <li>Any slides, spreadsheets or other documents in using information from the application contain a footnote “Source: PlanVault”.</li>
    </ul>
    <p>All other rights, title and interest not expressly granted herein are expressly reserved.</p>
    <h2>Copyright, trademarks and intellectual property</h2>
    <p>All information contained in the application is copyright Shopper Media Group (SMG) 2017.</p>
    <p>All company, product or service names referenced in this Web Site are used for identification purposes only and may be trademarks of their respective owners.</p>
    <p>SMG’s trademarks may be used only with permission from SMG. Trademarks referenced in this application include but are not limited to: Lobster, PlanVault, PlanFlex.</p>
    <p>Third Party trademarks - All other brands and names are property of their respective owners.</p>
    <p>The information contained in the application is governed by the rules and notices above. The application itself - the database, application design and any associated technology is owned exclusively by SMG and no licence to the software beyond access to the information itself for the licence period is implied or assumed.</p>
    <h2>Warranty</h2>
    <p>The information in the application is provided in good faith and no warranty is provided as to its accuracy. Errors and omissions are excepted.</p>
    <h2>Cookies</h2>
    <p>This application requires cookies to function correctly. Cookies are small pieces of information stored on your computer by your web browser. The application uses cookies to remember your preferences and to store information which is presented to you on screen. By using this application you accept the usage of cookies.</p>
    <h2>Governing Law &amp; Jurisdiction</h2>
    <p>This application is controlled by SMG from its offices within the United Kingdom. The interpretation, construction and effect of this Legal Notice and Disclaimer shall be governed and construed in all respects in accordance with the Laws of England. In the event of a legal dispute, you and SMG also agree to submit to the exclusive jurisdiction of the English Courts.</p>
    <h2>Disclaimer</h2>
    <p>In no event shall SMG be liable for any direct, indirect, incidental, special or consequential damages for loss of profits, revenue, data or use incurred by you or any third party, whether in action in contract, tort, or otherwise, arising from your access to, or use of, this application. SMG reserves the right to make improvements, changes or updates to the application continuously at any time without notice.</p>
    <h2>Company details</h2>
    <p>Lobster Agency Ltd is a subsidiary of Shopper Media Group Ltd.</p>
    <p>Shopper Media Group Ltd is a company registered in England and Wales number 10366845 and is based at: 60 Great Portland Street, London, W1W 7RT.</p>
</>

export default termsAndConditionsContent;
