import { useNavigate } from 'react-router-dom';
import { get, includes } from 'lodash';

import Observable from '../Observable';
import { Http, useAppState } from '../';
import { useEffect } from 'react';

const authObservable = new Observable();

// Will be used to notify Http when useAuth has finished initialising and is listening to events
// Fixes the issue where accessing Plan-Apps with expired access/refresh tokens would result into
// waffle messages
let useAuthInitialised = false;

function logoutCleanup() {
    // Wipe localStorage, but keep some keys
    const keysToKeep = ['agreedToCookies', 'version', 'defaultCompany'];
    Object.keys(localStorage)
        .filter(key => !includes(keysToKeep, key))
        .forEach(key => localStorage.removeItem(key));
}

// React hook to get/set the current user, along with a method that checks if a user is authenticated
// and redirects to the login page if not.
const useAuth = () => {
    const [user, setUser] = useAppState('auth.user');
    const [loginRedirection, setLoginRedirection] = useAppState('auth.loginRedirection');

    useEffect(() => {
        // Update auth user when updateUser() is triggered
        const subscriptionCallback = (name, data) => {
            switch(name) {
            case 'update user':
                setUser(data);
                break;
            case 'logout':
                setUser(null)
                logoutCleanup();
                break;
            default:
            }
        };

        authObservable.subscribe(subscriptionCallback);
        useAuthInitialised = true;

        return () => authObservable.unsubscribe();
    });

    const navigate = useNavigate();

    const getLoginRedirection = () => loginRedirection;

    const logout = () => {
        setUser(null);
        logoutCleanup();

        Http.get('/users/logout')
            .then(() => navigate('/auth/login'))
            .catch(error => console.error('Cannot logout', error));
    };

    const syncUser = () => {
        Http.get('/users/me')
            .then((res) => {
                setUser(res.user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return {
        can: (permissionSlug) => get(user, `permissions[${permissionSlug}]`),
        canAccess: (policySlug) => get(user, `policies[${policySlug}]`),
        getMfaRedirection: () => '/auth/login/mfa',
        getLoginRedirection,
        logout,
        me: user,
        setLoginRedirection,
        setUser,
        syncUser,
    };
};

function updateUser(user) {
    authObservable.notify('update user', user);
}

function authLogout() {
    authObservable.notify('logout');
}

export { authLogout, updateUser, useAuthInitialised };
export default useAuth;
