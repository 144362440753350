import immer from 'immer';
import { get, set } from 'lodash';

import { Http, toast, useAuth } from 'Components';

// This API can be used in two ways:
// const [prefs, setPrefs] = useUserPreferences(); // No arguments: it returns the whole preferences object
// const [value, setValue] = useUserPreferences(path, defaultValue); // Two arguments: it returns a single value
const useUserPreferences = (path, defaultValue) => {
    const { me, setUser } = useAuth();

    const setUserPreferences = (preferences) => {
        const updatedPreferences = immer(me.preferences, (draft) => {
            if (path) {
                set(draft, path, preferences);
            } else {
                draft = preferences;
            }

            return draft;
        });

        // Optimistically update the state in the front-end. Avoids "jumpy" UI when we want the change to be immediate
        setUser({ ...me, preferences: updatedPreferences });

        return Http.put('/users/preferences', updatedPreferences)
            .then(() => {
                toast.success('User preferences updated successfully');
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message || 'Could not update user preferences');
            });
    };

    const value = path
        ? get(me.preferences, path, defaultValue)
        : me.preferences;

    return [value, setUserPreferences];
};

export default useUserPreferences;
