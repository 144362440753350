import { useState } from 'react';

import CommentsPanel from './CommentsPanel';
import { Badge, Icon, Modal } from '..';

import css from './CommentsIcon.module.scss';

const CommentsIcon = (props) => {
    const {
        className,
        endpoint,
        method,
        onClick,
        onCreate,
        title,
        unread,
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [unreadCounter, setUnreadCounter] = useState(unread);

    const handleCloseCommentsModal = (event) => {
        event.stopPropagation();
        setIsModalVisible(false);
    };

    const handleClick = (event) => {
        if (typeof onClick === 'function') {
            onClick(event);
        }
        setIsModalVisible(true);
    };

    const classes = [
        className,
        css.comments,
    ];
    if (unreadCounter > 0) classes.push(css.unread);

    return (
        <div onClick={handleClick}>
            <Modal
                clickOutsideToClose={true}
                data-smg="notes-panel"
                onCancel={handleCloseCommentsModal}
                show={isModalVisible}
                title={title}
                width="lg"
                {...props}
            >
                <CommentsPanel
                    className="p-3"
                    endpoint={endpoint}
                    method={method}
                    onCreate={onCreate}
                    setUnreadCounter={setUnreadCounter}
                />
            </Modal>
            <div className={classes.join(' ')}>
                {unreadCounter > 0 ? <Badge label={unreadCounter}><Icon name="message" /></Badge> : <Icon name="message" />}
            </div>
        </div>
    );
};

export default CommentsIcon;
