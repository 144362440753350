import css from './Drawer.module.scss';

const Drawer = (props) => {
    const { children, isOpen } = props
    return (
        <div
            className={`${css['drawer']} ${(isOpen) && css['is-open']}`}
            style={{ maxWidth: '400px '}}
        >
            {children}
        </div>
    )
}

export default Drawer;
