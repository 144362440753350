const whatsNew = {
    contentTypeOptions: [
        { value: 'media-alert', label: 'Media alert' },
        { value: 'news', label: 'News' },
        { value: 'new-feature', label: 'New feature' },
        { value: 'other', label: 'Other' },
    ],
};

export default whatsNew;
