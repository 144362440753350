import { WorkflowFormField } from 'smg-common';
import { Icon, Tooltip } from 'Components'
import { FCheckbox, FDatePickerField, FMultiSelect, FReactSelect, FTextField, FTextareaField } from './Forms';

type DynamicFormElementProps = {
  field: WorkflowFormField<string> & {isDisabled?: boolean};
  name: string;
  className?: string;
};

const DynamicFormElement = ({ field, name, className }: DynamicFormElementProps) => {
    const infoTooltip = field.helpText ? (
        <Tooltip hover={field.helpText}>
            <Icon name="info" className="like-link no-color" />
        </Tooltip>
    ) : null;

    switch (field.type) {
    case 'date':
        return (
            <FDatePickerField
                name={name}
                label={field.label}
                topRightSlot={infoTooltip}
                required={field.required}
                className={className}
                disabled={field.isDisabled}
            />
        );
    case 'number':
        return (
            <FTextField
                name={name}
                type="number"
                label={field.label}
                topRightSlot={infoTooltip}
                required={field.required}
                className={className}
                disabled={field.isDisabled}
            />
        );

    case 'select':
        return (
            <FReactSelect
                name={name}
                label={field.label}
                topRightSlot={infoTooltip}
                options={field.options}
                required={field.required}
                className={className}
                isDisabled={field.isDisabled}
            />
        );

    case 'multiSelect':
        return (
            <FMultiSelect
                name={name}
                label={field.label}
                topRightSlot={infoTooltip}
                options={field.options}
                required={field.required}
                className={className}
                disabled={field.isDisabled}
            />
        )

    case 'text':
        return (
            <FTextField
                name={name}
                type="text"
                label={field.label}
                topRightSlot={infoTooltip}
                required={field.required}
                className={className}
                disabled={field.isDisabled}
            />
        );

    case 'multiLineText':
        return (
            <FTextareaField
                name={name}
                type="text"
                label={field.label}
                topRightSlot={infoTooltip}
                required={field.required}
                className={className}
                disabled={field.isDisabled}
            />
        );
    case 'checkbox':
        return (
            // Checkbox does not have a topRightSlot
            <div className={`my-4 d-flex align-items-center ${className}`}>
                <FCheckbox
                    name={name}
                    label={field.label}
                    required={field.required}
                    disabled={field.isDisabled}
                />
                <span className="ml-1">{infoTooltip}</span>
            </div>
        );
    default:
        return <></>;
    }
};

export default DynamicFormElement;
