import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import FormGroup from './FormGroup';
import type{ CSSObjectWithLabel, GroupBase } from 'react-select';
import type { HTMLProps, ReactNode } from 'react';

import css from './ReactSelect.module.scss';
import AsFormik from './AsFormik';

// Note: This should be in the FormGroup component once it's converted to TS
interface FormGroupProps extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
    errorMsg?: ReactNode,
    helpMsg?: ReactNode,
    successMsg?: ReactNode,
    helpModal?: {
        title: string,
        body: ReactNode,
    },
    topRightSlot?: ReactNode,
    label?: ReactNode
}

interface TagSelectProps<Option, IsMulti extends boolean> extends
    CreatableProps<Option, IsMulti, GroupBase<Option>>,
    Pick<FormGroupProps, 'errorMsg' | 'helpModal' | 'helpMsg' | 'label' | 'successMsg' | 'topRightSlot' | 'style'>
{
    controlStyle?: CSSObjectWithLabel,
}

const TagSelect = <Option, IsMulti extends boolean>({
    className,
    errorMsg,
    helpModal,
    helpMsg,
    label,
    required,
    successMsg,
    style,
    topRightSlot,
    theme,
    isMulti,
    controlStyle = {},
    ...props
}: TagSelectProps<Option, IsMulti>) => {
    // Stolen from reactSelect component to keep styling consistent
    const compiledControlStyle = (base: CSSObjectWithLabel) => ({
        ...base,
        ...(isMulti ? {} : { height: '35px' }), // Align the single-select with the other form components, while allowing the multi-select to wrap lines
        marginTop: '3px',
        minHeight: '35px',
        backgroundColor: '#f3f3f3',
        ...controlStyle,
    });

    const classes = [css['react-select-container']];
    if (errorMsg) classes.push(css['is-invalid']);
    if (theme) classes.push(css[`${theme}-theme`]);

    return (
        <FormGroup
            className={className}
            errorMsg={errorMsg}
            // @ts-expect-error Prop-type is incorrectly defined
            helpModal={helpModal}
            helpMsg={helpMsg}
            label={label}
            required={required}
            successMsg={successMsg}
            style={style}
            topRightSlot={topRightSlot}
        >
            <CreatableSelect
                className={classes.join(' ').trim()}
                classNamePrefix="react-select"
                isMulti={isMulti}
                styles={{
                    control: compiledControlStyle
                }}
                {...props}
            />
        </FormGroup>
    )
}

const FTagSelect = AsFormik(TagSelect, {
    extractValue: (newValue: any | any[]) => newValue
})

export {
    TagSelect,
    FTagSelect,
};