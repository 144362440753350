import { connect } from 'formik';
import Button from '../Button/Button';

/**
 * A wrapper around Button component. It attaches to formik context, and disables the button while the form is submitting
 */
const Submit = ({ formik, ...props }) => {
    const { isSubmitting } = formik;
    return <Button
        color="primary"
        type="submit"
        disabled={isSubmitting}
        formNoValidate={true} // disables the HTML form validation API so we can use attributes like `required` for accessibility without triggering browser popups
        {...props}
    />;
}

export default connect(Submit);
