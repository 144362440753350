import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';

import Modal from './Modal';
import Observable from '../Observable';

export const modalObservable = new Observable();


const ModalContainer = () => {
    const [options, setOptions] = useState(undefined);

    useEffect(() => {
        const handle = (newOptions) => setOptions(newOptions);
        modalObservable.subscribe(handle);
        return () => modalObservable.unsubscribe(handle);
    });

    if (!options || !options.isVisible) return null;

    const ModalComponent = options.template;

    let title;
    if (isFunction(options.title)) {
        title = options.title(options.context);
    } else if (options.title) {
        title = options.title;
    } else {
        if (options.header) title = options.header;
    }

    return (<Modal
        className={options.className}
        clickOutsideToClose={options.clickOutsideToClose}
        onCancel={options.onCancel || options.hide}
        show={options.isVisible}
        title={title}
        width={options.size}
    >
        <ModalComponent modal={options} {...options.context} />
    </Modal>);
}

export default ModalContainer;