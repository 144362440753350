import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import css from './Tooltip.module.scss';
import { ReactNode } from 'react';

interface TooltipProps extends Omit<TooltipPrimitive.TooltipContentProps, 'align'> {
    align?: TooltipPrimitive.TooltipContentProps['side'];
    children: ReactNode
    gap?: TooltipPrimitive.TooltipContentProps['sideOffset'];
    position?: TooltipPrimitive.TooltipContentProps['align'];
    hover: ReactNode;
    title?: string;
    triggerButtonType?: TooltipPrimitive.TooltipTriggerProps['type'];
}

/**
 * A wrapper around the Radix Tooltip component.
 * The component extends the Radix Tooltip, but some props have been renamed for backward compatibility.
 * */
const Tooltip = (props: TooltipProps) => {
    const {
        align = 'right',
        className,
        children,
        collisionPadding = 100,
        gap = 3,
        hover,
        position = 'start',
        title,
        triggerButtonType = 'button',
        ...restProps
    } = props;

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={0}>
                {/* The default tooltip trigger is a button element. The default type for buttons in most
                browsers is 'submit'. If a tooltip is inside a Formik form, clicking the tooltip trigger will
                therefore submit the form. We give the button a default of 'button' to fix this. */}
                <TooltipPrimitive.Trigger type={triggerButtonType}>
                    {title || children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal container={document.getElementById('tooltip-portal')!}>
                    {hover && <TooltipPrimitive.Content
                        align={position}
                        className={[css.content, className].join(' ')}
                        collisionPadding={collisionPadding}
                        side={align}
                        sideOffset={gap}
                        {...restProps}
                    >
                        {hover}
                    </TooltipPrimitive.Content>}
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export default Tooltip;
