import moment from 'moment';
import {
    formatters,
    getStartAndEndDatesOfActivitiesAndItems,
    getTotalCostOfSelectedActivitiesAndItems,
} from '../../index.js';

const morrisonsOnlineTemplate = {
    name: 'Morrisons - Online',
    pandaDocId: 'j7bA6d8oeYVyMqUzHS2W92',
    companies: ['5b3dddac93c1d436671c07ac'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'Link to online Media Booking Form', value: 'bookingFormLink' },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        return `[${now}] ${plan.customPlanId || plan.name}`;
    },

    getTokens: (campaign, plan, activities, items) => {
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, items);
        const totalCost = getTotalCostOfSelectedActivitiesAndItems(plan, activities, items);
        const planLink = `https://app.plan-apps.com/planner/campaigns/${campaign._id}/plans/${plan._id}`;

        return [
            { label: 'Custom plan ID', name: 'plan.customPlanId', value: plan.customPlanId || plan.name },
            { label: `Plan owner's email`, name: 'plan.owner.email', value: plan.owner.user.email },
            { label: 'Link to plan', name: 'plan.link', value: planLink },
            { label: 'Company name', name: 'company.name', value: campaign.company.name },
            { label: 'Brand name', name: 'brand.name', value: campaign.brands[0].name },
            { label: 'Campaign name', name: 'campaign.name', value: campaign.name },
            { label: 'Campaign total cost', name: 'campaign.totalCost', value: formatters.asCurrency(totalCost, { decimals: 2 }) },
            { label: 'Campaign start date', name: 'campaign.startDate', value: formatters.asDate(startDate) },
            { label: 'Campaign end date', name: 'campaign.endDate', value: formatters.asDate(endDate) },
            { label: 'Plan retailer category', name: 'category.name', value: plan.rCategory?.name || '-' },
            { label: 'Plan retailer sub-category', name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
        ];
    },
};

export default morrisonsOnlineTemplate;
