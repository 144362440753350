const allocator = {
    nominationStatusOptions: [
        { value: 'draft', label: 'Draft', deletable: true },
        { value: 'completed', label: 'Uploaded', deletable: true },
        { value: 'reserved', label: 'Reserved' },
        { value: 'confirmed', label: 'Confirmed' },
        { value: 'allocated', label: 'Allocated' },
    ],
};

export default allocator;
