import FocusTrap from 'focus-trap-react';
import ReactDOM from 'react-dom';
import { useCallback, useEffect } from 'react';

import Icon from '../Icon/Icon.js';
import ModalBody from './ModalBody.js';
import ModalHeader from './ModalHeader.js';
import ModalFooter from './ModalFooter.js';

import css from './Modal.module.scss';

const Modal = (props) => {
    const { children, className, clickOutsideToClose, onCancel, show, title, width = 'md' } = props;

    const closeModal = useCallback((event) => {
        if (show && onCancel) {
            onCancel(event);
        }
    }, [onCancel, show]);

    useEffect(() => {
        // Fix body scroll
        if (show) {
            document.body.classList.add(css['modal-open']);
        } else {
            document.body.classList.remove(css['modal-open']);
        }

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.classList.remove(css['modal-open']);
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [closeModal, show]);

    if (!show) return null;

    const handleClose = (event) => {
        if (event.target === event.currentTarget) {
            closeModal(event);
        }
    };

    const handleClickBackdrop = (event) => {
        if (clickOutsideToClose) {
            handleClose(event);
        }
    };

    const classes = [css.modal, css[width]];
    if (className) classes.push(className);

    return ReactDOM.createPortal(
        <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <div className={css['modal-backdrop']} onClick={handleClickBackdrop}>
                <div className={classes.join(' ')} tabIndex="1">
                    {title && (<div className={css['modal-header']}>
                        <h2 className={css['modal-title']}>{title}</h2>
                        {onCancel &&
                            <Icon className="like-link no-color" name="clear" onClick={handleClose} />
                        }
                    </div>)}
                    {children}
                </div>
            </div>
        </FocusTrap>,
        document.getElementById('modal-portal')
    );
};

Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
