import { formatters } from 'smg-common';

interface AsTimeFromNowProps {
    value: Date | string;
}

const AsTimeFromNow = (props: AsTimeFromNowProps) => {
    const { value } = props;

    return <>{formatters.asTimeFromNow(value)}</>;
};

export default AsTimeFromNow;
