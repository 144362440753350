import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import _ from 'lodash';

import { authLogout, useAuthInitialised } from './hooks/useAuth';

// Create a "naked" instance of axios, to use when we don't want any of the Plan-Apps configuration
const defaultAxiosInstance = axios.create();

// Create an instance of axios for calls to Plan-Apps API.
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'x-client-app-id': process.env.REACT_APP_CLIENT_APP_ID!,
    },
});

api.interceptors.request.use(
    (request) => {
        // Add x-app slug
        const path = window.location.pathname.slice(process.env.REACT_APP_BASE_FOLDER!.length);

        let pathName = path.split('/')[0];
        // After switch from /campaigns to /planner, we want to use campaigns as the x-app slug
        if (pathName === 'planner') {
            pathName = 'campaigns';
        }

        if (request.headers) {
            request.headers['x-app'] = pathName;
        }

        return request;
    }
);

// Refresh token interceptor

/* Initialise variables for retry mechanism */
// attempts object used to track retried requests by endpoint. Allows us to cap maximum retries by endpoint.
// e.g. { "/homepage/panels": 3, "/homepage/featured-touchpoint": 2 }
const attempts: Record<string, number> = {};
// 10 attempts at 1 second apart should catch 99% of requests failed due to an invalid access token.
const maxAttempts = 10;
const msRetryDelay = 1000;

let isRefreshingToken = false;

api.interceptors.response.use(
    (response) => {
        // Clear entry in attempts object on successful request.
        if (response.config.url) delete attempts[response.config.url];
        return response;
    },
    function (err) {
        const originalRequest = err.config;
        const responseData = err.response?.data;
        const isLoggingOut = originalRequest.url === '/users/logout';

        // If the user has a valid refresh token, and a token refresh has not already been initiated, begin token refresh.
        if (responseData?.refreshToken && !isRefreshingToken && !isLoggingOut) {
            isRefreshingToken = true;
            const tokenRefreshFailed = originalRequest.url === '/tokens/refresh';

            if (tokenRefreshFailed) {
                // Refresh token is tainted, remove it and all login info from localStorage
                Object.keys(localStorage)
                    .filter(key => key !== 'agreedToCookies' && key !== 'version') // keep those keys
                    .forEach(key => localStorage.removeItem(key));
                isRefreshingToken = false;
            }

            const refreshToken = localStorage.getItem('rt');

            if (!refreshToken) {
                // No refresh token available, go to login page
                authLogout();

                isRefreshingToken = false;
            } else {
                return refreshAccessToken(refreshToken)
                    .then(() => {
                        isRefreshingToken = false;
                        return api(originalRequest);
                    });
            }
        }
        // If a token refresh is already in progress, we add a delay before attempting the request again.
        // This will handle most cases of seeing waffle messages when the user's access token has expired.
        else if (responseData?.refreshToken && isRefreshingToken) {
            // Track number of attempts to the route
            attempts[originalRequest.url] = attempts[originalRequest.url] ? attempts[originalRequest.url] + 1 : 1;

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (attempts[originalRequest.url] > maxAttempts) {
                        delete attempts[originalRequest.url];
                        reject(err)
                    } else {
                        resolve(api(originalRequest));
                    }
                }, msRetryDelay);
            });
        }
        else if (responseData?.logout) {
            const interval = setInterval(() => {
                // We need to wait for useAuth to start listening for events
                if (useAuthInitialised) {
                    authLogout();
                    clearInterval(interval);
                }
            }, 100);
        }

        return Promise.reject(err);
    }
);

function refreshAccessToken(refreshToken: string) {
    return api.post('/tokens/refresh', { refreshToken })
        .then(res => {
            const newRefreshToken = _.get(res, 'data.refreshToken');
            if (newRefreshToken) {
                localStorage.setItem('rt', newRefreshToken);
                return res;
            } else {
                // TODO YD: Does this work? We should either add BASE_URL or, better, use history API (react-router-dom)
                window.location.pathname = '/auth/login';
            }
        });
}

interface AsyncDownloadConfig {
    /**
     * An AbortController signal to allow the user to cancel polling before tasks are done.
     * Note that the task itself will continue on the worker server.
     * Example use case: Prevent the user's browser from awaiting many instances of the same task concurrently.
     */
    signal?: AbortSignal;
    /** Any payload required for $asyncTask to work */
    body?: unknown;
    /** The length of time (ms) the browser should wait before polling the server. Use case: A task is known to take a long time by default. */
    initialDelay?: number;
    /** The length of time (ms) the browser should wait between requests to the server. */
    interval?: number;
}

/**
 * Fetches a resource from the API asynchronously.
 * For use with the app.$asyncTask route handler on the back end.
 * @param route The shared base segment of the routes used for async communication. Similar to a normal request.
 * @param config
 */
async function asyncTask(route: string, config: AsyncDownloadConfig = {}, requestConfig: AxiosRequestConfig = {}) {
    const {
        signal,
        body,
        initialDelay = 0,
        interval = 1000,
    } = config;

    const taskId = await Http.post(`${route}/async`, body, requestConfig);

    // Optionally delay polling
    await new Promise((resolve) => setTimeout(resolve, initialDelay));

    // eslint-disable-next-line no-constant-condition
    while (true) {
        // Check if the user has aborted the operation
        signal?.throwIfAborted();

        const task = await Http.get(`${route}/async/${taskId}`);

        if (task.status === 'done') {
            return task.result;
        }
        if (task.status === 'error') {
            throw task.result;
        }

        // If execution reaches this point, then the task status must either be 'queued' or 'in-progress'.
        // Optionally delay the next cycle.
        await new Promise((resolve) => setTimeout(resolve, interval));
    }
}

/**
 * A sugar syntax wrapper for asyncTask that abstracts the process of generating an S3 file on the back end and downloading it directly.
 * Note that the back end task must be set up to return a signed GET URL.
 */
async function asyncDownload(...params: Parameters<typeof asyncTask>) {
    const { url } = await asyncTask(...params);

    window.location.href = url;
}

function uploadtoS3(signedUrl: string, file: File) {
    return defaultAxiosInstance.put(signedUrl, file, {
        headers: {
            'Content-Type': file.type,
        },
    });
}

function wrap(request: () => Promise<AxiosResponse>) {
    return request()
        .then((res) => res.data)
        .catch((err) => {
            console.error(err);
            const errData = _.get(err, 'response.data', err.toJSON());
            return Promise.reject(errData);
        });
}

function wrappedGet(url: string, config?: AxiosRequestConfig) {
    return wrap(() => api.get(url, config));
}

function wrappedPatch(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return wrap(() => api.patch(url, data, config));
}

function wrappedPut(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return wrap(() => api.put(url, data, config));
}

function wrappedPost(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return wrap(() => api.post(url, data, config));
}

function wrappedDelete(url: string, config?: AxiosRequestConfig) {
    return wrap(() => api.delete(url, config));
}

const Http = {
    asyncTask,
    asyncDownload,
    uploadtoS3,
    get: wrappedGet,
    patch: wrappedPatch,
    put: wrappedPut,
    post: wrappedPost,
    delete: wrappedDelete,
};

export default Http;
