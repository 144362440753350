import { createRef, Component } from 'react';
import { SketchPicker } from 'react-color';

import AsFormik from './AsFormik';
import FormGroup from './FormGroup';
import { Icon } from '../../Components';
import { TextField } from './TextField';

import css from './FormControl.module.scss';

class ColorPicker extends Component {
    colorPickerRef = createRef();
    state = {
        isVisible: false,
    };

    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (!this.state.isVisible) {
            return;
        }
        const clickedOutside = !this.colorPickerRef.current.contains(event.target);
        if (clickedOutside) {
            this.handleToggleVisibility();
        }
    }

    handleToggleVisibility = () => this.setState({isVisible: !this.state.isVisible});

    render() {
        const { className='', onChange, value='', ...restProps } = this.props;
        return (
            <FormGroup className={className}>
                <div className={css.inputContainer}>
                    <TextField {...restProps} onChange={value => onChange(value)} type="text" value={value} />
                    <Icon name="colorize" className={css.iconPosition} onClick={this.handleToggleVisibility} size="20px" />
                    { this.state.isVisible &&
                        <div className={css.popover} ref={this.colorPickerRef}>
                            <SketchPicker color={value} disableAlpha={true} onChange={color => onChange(color.hex)}/>
                        </div>
                    }
                </div>
            </FormGroup>
        )
    }
}

const FColorPicker = AsFormik(ColorPicker, {
    extractValue: value => value,
});

export {
    ColorPicker,
    FColorPicker,
};
