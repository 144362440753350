import { formatters } from 'smg-common';

import { Pill, useAppState, useModal } from '../';
import EasterEggFoundModal from './EasterEggFoundModal/EasterEggFoundModal';

import css from './EasterEggCount.module.scss';

const EasterEggCount = () => {
    const [fetchedEasterEggs] = useAppState('easterEggs');
    const count = fetchedEasterEggs?.length;

    const { show: showEasterEggModal } = useModal({
        clickOutsideToClose: true,
        title: 'Egg-cellent progress!',
        template: () => <EasterEggFoundModal numOfEggsFound={count} />,
    });

    // automatically hide after Easter
    if (fetchedEasterEggs === null || fetchedEasterEggs === undefined) {
        return null;
    }

    const src = 'https://plan-apps-assets.s3.eu-west-2.amazonaws.com/easter-eggs/8.png';

    return (
        <span className="stack-center" onClick={() => showEasterEggModal()} style={{ cursor: 'pointer' }}>
            <Pill className={css.pill} text={formatters.asNumber(count, { na: '0' })} />
            <img className={css.egg} src={src} alt="Easter egg count" loading="lazy" />
        </span>
    )
}

export default EasterEggCount;
