import { useState } from 'react';
import { isFunction } from 'lodash';

import AsFormik from './AsFormik';
import css from './Switch.module.scss';

const Switch = (props) => {
    const {
        align = 'left',
        className,
        dynamicLabel = true,
        onChange,
        offLabel,
        onLabel,
        offValue = false,
        onValue = true,
    } = props;

    const [isEnabled, setIsEnabled] = useState(props.value);

    const handleClick = () => {
        const valueFlag = !isEnabled;
        const value = valueFlag ? onValue : offValue;
        setIsEnabled(valueFlag);
        if (isFunction(onChange)) {
            onChange(value);
        }
    };

    return (
        <div className={`${css.switch} ${isEnabled ? css.on : ''} stack-start-center-3 ${className}`.trim()} onClick={handleClick}>
            {align === 'right' && dynamicLabel && <div className={`${css.label}`}>{isEnabled ? onLabel : offLabel}</div>}
            {!dynamicLabel && offLabel && <div className={`${css.label} ${css['off-label']}`}>{offLabel}</div>}
            <div className={css.track}>
                <div className={css.toggle}>&nbsp;</div>
            </div>
            {!dynamicLabel && onLabel && <div className={`${css.label} ${css['on-label']}`}>{onLabel}</div>}
            {align === 'left' && dynamicLabel && <div className={`${css.label}`}>{isEnabled ? onLabel : offLabel}</div>}
        </div>
    );
};

const FSwitch = AsFormik(Switch, {
    extractValue: value => value,
});

export {
    Switch,
    FSwitch,
};
