import { useState } from 'react';
import { simpleDate } from 'smg-common';
import { Options, SeriesLineOptions } from 'highcharts';
import type { Option } from 'smg-common';

import { DailyInsightsMetrics } from 'Types/CriteoCampaignInsights';
import { Highcharts, MultiSelect, ReactSelect } from 'Components';
import { CriteoInsightMetricOption } from './InFlight.types';
import { criteoInsightMetricOptions } from './helperFunctions';
import { ChartDailyMetrics } from './ViewCriteoInsights';

interface CriteoDailyInsightsChartProps {
    data: ChartDailyMetrics[],
    nodeSelectLabel?: string,
}

const buildChartOptions = (nodes: ChartDailyMetrics[], insightMetric: CriteoInsightMetricOption, selectedNodes: string[]): Options => {
    const formatInsightDate = (insightDate: string | Date) => simpleDate.format(new Date(insightDate), 'DD/MM/YYYY')

    const buildCategories = (node: ChartDailyMetrics | undefined) => {
        return simpleDate.datesBetween(node?.startDate, node?.endDate);
    }

    const buildSeries = (nodes: ChartDailyMetrics[], property: keyof DailyInsightsMetrics, categories: string[]): SeriesLineOptions[] => (
        nodes.map(node => ({
            type: 'line',
            name: node.seriesName,
            // For each date in the campaign, plot a data point if it exists
            data: categories.map(category => {
                const matchingNode = node.data.find(item => item.date && formatInsightDate(new Date(item.date)) === category)

                return matchingNode ? matchingNode[property] : null
            })
        }))
    )

    const selectedCriteoNodes = nodes.filter(node => selectedNodes.includes(node.id));

    const categories = buildCategories(nodes[0]) ?? [];
    const series = buildSeries(selectedCriteoNodes, insightMetric.value, categories)

    return {
        title: { text: `Daily ${insightMetric.label}` },
        yAxis: {
            title: {
                text: insightMetric.label
            }
        },
        xAxis: {
            categories,
        },
        tooltip: {
            formatter: function () {
                const rows: string[] = [
                    `<strong>${this.series.name}</strong>`,
                    `${this.x}`,
                    `${insightMetric.label}: <strong>${insightMetric.formatter(this.y)}</strong>`,
                ];

                return rows.join('<br />');
            },
        },
        series,
    }
}

const buildNodeOptions = (data: ChartDailyMetrics[]): Option[] => {
    return data.reduce<Option[]>((prev, curr) => {
        if (curr.group) {
            const groupNode = prev.find(option => option.value === curr.group)
                ?? { label: curr.group, value: curr.group, options: [] }

            groupNode.options = [...(groupNode.options ?? []), {
                label: curr.seriesName,
                value: curr.id
            }]

            return [...(prev.filter(item => item.value !== groupNode.value)), groupNode]
        } else {
            return [...prev, {
                label: curr.seriesName,
                value: curr.id
            }]
        }
    }, [])
}

const CriteoDailyInsightsChart = ({ data, nodeSelectLabel }: CriteoDailyInsightsChartProps) => {
    const [selectedInsightMetric, setSelectedInsightMetric] = useState<CriteoInsightMetricOption>(criteoInsightMetricOptions[0]);
    const [selectedNodes, setSelectedNodes] = useState<string[]>([data[0]?.id]);

    const chartOptions = buildChartOptions(data, selectedInsightMetric, selectedNodes);

    return (
        <div className="box-raised p-3 mb-3">
            <div className="mb-3 stack-end-center-3">
                <MultiSelect
                    label={nodeSelectLabel ?? 'Node'}
                    options={buildNodeOptions(data)}
                    value={selectedNodes}
                    onChange={setSelectedNodes}
                    placeholder="Select..."
                    style={{ width: '15em' }}
                />
                <ReactSelect
                    label="Metric"
                    options={criteoInsightMetricOptions}
                    value={selectedInsightMetric.value}
                    onChange={setSelectedInsightMetric}
                    style={{ width: '15em' }}
                />
            </div>
            <Highcharts
                options={chartOptions}
            />
        </div>
    )
}

export default CriteoDailyInsightsChart;