import type { PropsWithChildren } from 'react';

import Copyright from '../Copyright/Copyright';
import EnvironmentBanner from '../EnvironmentBanner';
import Icon from '../../Icon/Icon';
import SmgLogo from '../../SmgLogo/SmgLogo';

import css from './SimpleLayout.module.scss';

interface SimpleLayoutProps {
    pageTitle?: string;
}

/** A simplified version of the layout that does not use useAuth */
const SimpleLayout = (props: PropsWithChildren<SimpleLayoutProps>) => {
    const { children, pageTitle } = props;

    return (
        <div className={css.container}>
            <header className={css.header}>
                <EnvironmentBanner />
                <div className="m-2 row">
                    <div className="col-4">
                        <h1>{pageTitle}</h1>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <SmgLogo height="small" />
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                        <a className="like-link no-color" href="https://plan-apps.getlearnworlds.com/" rel="noreferrer" target="_blank">
                            <Icon aria-label="Learn more" name="question_mark" />
                        </a>
                    </div>
                </div>
            </header>
            <main className={css.main}>{children}</main>
            <footer>
                <Copyright />
            </footer>
        </div>
    );
};

export default SimpleLayout;
