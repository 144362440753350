import css from './FormRow.module.scss';

const FormRow = (props) => {
    const { actions, children, className, gutter = true } = props;

    const classes = [
        css.row,
        'stack-start-center-3',
    ];
    if (!gutter) classes.push(css['no-gutter']);
    if (className) classes.push(className);

    return (<div className={classes.join(' ').trim()}>
        <div className="stack-start-center-3 stack-stretch">{children}</div>
        {actions && (<div className={css.actions}>{actions}</div>)}
    </div>)
};

export default FormRow;
