import { Button, Icon, useModal } from '..';

import ViewBookingFormsModal from './ViewBookingFormsModal/ViewBookingFormsModal';

const BookingFormButton = (props) => {
    const { campaign, plan, selfServeCampaign, size = 'standard', onClick = () => null } = props;

    const handleClick = () => {
        onClick()
        showViewBookingFormsModal({ campaign, plan, selfServeCampaign })
    }

    const { show: showViewBookingFormsModal } = useModal({title: 'Booking forms', size: 'lg', template: ViewBookingFormsModal});

    return (<>
        {size === 'small' && <span className="like-link" onClick={handleClick}>Book plan</span>}

        {size === 'standard' && <Button onClick={handleClick}>Book plan</Button>}

        {size === 'large' &&
            <div className="stack-col-center-center like-link no-color" onClick={handleClick}>
                <Icon name="check_circle" size="32px" />
                <div>Book</div>
            </div>
        }
    </>)
};

export default BookingFormButton;