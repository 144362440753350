import moment from 'moment';
import { Icon, Http, Tooltip, UserAvatar, dialog, toast, useAuth } from '..';
import css from './Comment.module.scss';

const Comment = (props) => {
    const { comment, endpoint, onChange } = props;
    const { user } = props.comment;

    const { me } = useAuth();
    const myUser = comment.user?._id === me._id;

    const createdMoment = moment(comment.createdAt);
    const moreThanAMonthAgo = createdMoment.isBefore(moment().subtract(1, 'month'));
    const dateTime = createdMoment.format('Do MMMM YYYY, HH:mm:ss');
    const humanisedDateTime = createdMoment.fromNow();

    const deleteComment = () => {
        dialog.confirm('Are you sure you want to delete?', 'Delete')
            .then(() => {
                Http.delete(`${endpoint}/${comment._id}`)
                    .then((comments) => {
                        onChange(comments);
                        toast.success('Deleted successfully.');
                    });
            })
            .catch(err => {
                console.error('Failed to delete', err);
                toast.error('Failed to delete.');
            });
    };

    return (
        <div className={`${css.note} stack-start-4`}>
            <UserAvatar user={user} />
            <div className="stack-col-2 stack-stretch">
                <div className="stack-even-center stack-stretch">
                    <h4 className="stack-stretch">{user?.firstName + ' ' + user?.lastName}</h4>
                    {
                        moreThanAMonthAgo
                            ? <span className={css.timestamp}>{dateTime}</span>
                            : (
                                <Tooltip hover={dateTime} className={css.timestamp}>
                                    {humanisedDateTime}
                                </Tooltip>
                            )
                    }
                </div>
                <div className="stack-even-center stack-stretch">
                    <div className={css.comment}>{comment.text}</div>
                    {myUser && <Icon className="ml-2 like-link subtle" name="delete" onClick={() => deleteComment()} /> }
                </div>
            </div>
        </div>
    );
}

export default Comment;