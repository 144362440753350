
import _ from 'lodash';
import { config, formatters } from 'smg-common';

const daysToWeeks = (days) => {
    if (days < 7) {
        return days === 1 ? `${days} day` : `${days} days`;
    }

    const weeks = Math.ceil(days / 7);
    return weeks === 1 ? `${weeks} week` : `${weeks} weeks`;
};

const getCreativeTypeLabel = (creativeTypeSlug) => {
    const creativeTypeOptions = config.touchpoints.creativeTypeOptions;
    const creativeType = creativeTypeOptions.find((option) => option.value === creativeTypeSlug);
    return creativeType?.label;
};

const getAttachmentType = (configType, documentTypeSlug) => {
    const documentTypeOptions = config[configType].documentTypes;
    const documentType = documentTypeOptions.find(option => option.value === documentTypeSlug);
    return documentType?.label;
}

const getAttachmentTypeForTouchpoints = (documentTypeSlug) => {
    return getAttachmentType('touchpoints', documentTypeSlug);
}

const getHfssLabel = (hfssSlug) => {
    const hfssCompliantOptions = config.touchpoints.hfssCompliantOptions;
    const hfssCompliant = hfssCompliantOptions.find((option) => option.value === hfssSlug);
    return hfssCompliant?.label;
};

const renderCost = (touchpoint) => {
    if (!touchpoint.cost) {
        return null;
    }

    const cost = formatters.asCurrency(touchpoint.cost, { decimals: 2 });
    let suffix = '';

    switch (touchpoint.costStructure) {
    case 'per-store':
        suffix = ' (p/s)';
        break;
    case 'per-issue':
        suffix = ' (c/i)';
        break;
    default:
    }

    return <p>{`${cost}${suffix}`}</p>;
};

const hasSelectedCreativeType = (selectedCreativeTypeOptions, touchpoint) => {
    if(!_.isEmpty(touchpoint.workflowTemplates)){
        return touchpoint.workflowTemplates.some(template => _.includes(selectedCreativeTypeOptions, template.creativeType) )
    }
    return false;
}

export {
    daysToWeeks,
    getCreativeTypeLabel,
    getHfssLabel,
    renderCost,
    getAttachmentTypeForTouchpoints,
    hasSelectedCreativeType
};