const retailerFormatOptions = [
    { label: 'E-Commerce', value: 'e-commerce' },
    { label: 'Convenience', value: 'convenience' },
    { label: 'Store', value: 'store' },
    { label: 'Superstore', value: 'superstore' },
    { label: 'App', value: 'app' },
    { label: 'Petrol stations', value: 'petrol-stations' },
    { label: 'Targeted comms', value: 'targeted-comms' },
    { label: 'Quick commerce', value: 'quick-commerce' },
    { label: 'Pharmacy', value: 'pharmacy' },
    { label: 'Airports / Stations / Services', value: 'airports-stations-services' },
];

export default retailerFormatOptions;
