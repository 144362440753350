import { Pill, Tooltip } from 'Components';
import css from './RetailerPill.module.scss';

const RetailerPill = (props) => {
    const { className, disabled, retailer, style = {}, ...restProps } = props;

    const newStyle = { ...style };

    if (!disabled) {
        newStyle.backgroundColor = retailer.backgroundColor;
        newStyle.color = retailer.textColor;
    }

    const classes = [css['retailer-pill'], css.fixedWidth];
    if (disabled) classes.push(css.disabled);
    if (className) classes.push(className);

    return (
        <Tooltip hover={retailer.name}>
            <Pill
                className={classes.join(' ').trim()}
                style={newStyle}
                text={retailer.shortName}
                type="retailer"
                {...restProps}
            />
        </Tooltip>
    )
}

export default RetailerPill;
