import moment from 'moment';
import { useState } from 'react';
import { formatters } from 'smg-common';

import { Icon, Tooltip } from '../';

import css from './DeadlineDate.module.scss';

function getWeekDaysUntilDate(date) {
    const today = new Date();
    const dateIsInThePast = moment(date).isBefore(today);
    const startDate = dateIsInThePast ? moment(date) : moment(today);
    const endDate = dateIsInThePast ? moment(today) : moment(date);
    const days = moment(date).endOf('day').diff(moment().startOf('day'), 'days');

    // Calculate weekdays
    let weekdays = 0;
    while (startDate.isSameOrBefore(endDate)) {
        const isSaturday = startDate.isoWeekday() === 6;
        const isSunday = startDate.isoWeekday() === 7;

        if (!isSaturday && !isSunday) {
            weekdays += 1;
        }
        startDate.add(1, 'day');
    }

    if (days === 0) return 'Today';
    if (days === 1) return 'Tomorrow';
    if (days === -1) return 'Yesterday';

    const weekdaysLabel = Math.abs(weekdays) === 1 ? 'weekday' : 'weekdays';

    if (days < 0) return `${Math.abs(weekdays)} ${weekdaysLabel} over`;
    return `in ${weekdays} ${weekdaysLabel}`;
}

const DeadlineDate = (props) => {
    const { date } = props;
    const [showWeekDate, setShowWeekDate] = useState();

    if (!date) return 'n/a';

    const isToday = moment(date).isSame(new Date(), 'day');

    const classes = ['like-link'];
    if (isToday) classes.push(css.danger);

    const compiledDate = (showWeekDate
        ? getWeekDaysUntilDate(date)
        : formatters.asDate(date));

    return date
        ? (<span className={classes.join(' ').trim()} onClick={() => setShowWeekDate(!showWeekDate)}>
            {isToday && (<Tooltip hover="This deadline is today">
                <div className="stack-start-center-2">
                    <div>{compiledDate}</div>
                    <Icon name="warning" />
                </div>
            </Tooltip>)}
            {!isToday && compiledDate}
        </span>)
        : null;
};

export default DeadlineDate;
