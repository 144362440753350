const campaigns = {
    kpis: [
        {
            label: 'Sales',
            options: [
                { value: 'brand-uplift-pound', label: '£ Uplift (Brand)', format: 'pound', decimals: 0 },
                { value: 'feat-sku-uplift-pound', label: '£ Uplift (Feat. SKU)', format: 'pound', decimals: 0 },
                { value: 'brand-uplift-percent', label: '% Uplift (Brand)', format: 'percent', decimals: 1 },
                { value: 'feat-sku-uplift-percent', label: '% Uplift (Feat. SKU)', format: 'percent', decimals: 1 },
                { value: 'brand-roi', label: 'ROI (Brand)', format: 'pound', decimals: 2 },
                { value: 'feat-sku-roi', label: 'ROI (Feat. SKU)', format: 'pound', decimals: 2 },
                { value: 'aov-pound', label: '£ AOV', format: 'pound', decimals: 0 },
            ]
        },
        {
            label: 'Performance',
            options: [
                { value: 'cpm', label: 'CPM', format: 'pound', decimals: 2 },
                { value: 'impressions', label: 'Impressions', format: 'number', humanise: true, decimals: 0 },
                { value: 'reach', label: 'Reach', format: 'number', decimals: 0 },
                { value: 'clicks', label: 'Clicks', format: 'number', decimals: 0 },
                { value: 'ctr-percent', label: '% CTR', format: 'percent', decimals: 0 },
            ]
        },
    ],
    statuses: [{ label: 'Draft', value: 'draft' }, { label: 'Published', value: 'published' }],
    viewableIn: [{ label: 'Allocator', value: 'allocator' }, { label: 'Evaluations', value: 'evaluations' }, { label: 'Campaigns', value: 'campaigns' }],
    plans: {
        activities: {
            statuses: [
                { label: 'Planned', value: 'planned' },
                { label: 'Confirmed', value: 'confirmed' },
                { label: 'Booked', value: 'booked' },
                { label: 'Briefed', value: 'briefed' },
            ],
            touchpointTypeOptions: [
                { label: 'In-store', value: 'in-store'},
                { label: 'Digital', value: 'digital'},
                { label: 'Other', value: 'other'}
            ],
            artworkStatuses: [
                { colour: 'smg-darkGrey', label: 'n/a', value: undefined },
                { colour: 'warn', label: 'Working', value: 'working' },
                { colour: 'primary', label: 'Submitted', value: 'submitted' },
                { colour: 'success', label: 'Approved', value: 'approved' },
            ],
            complianceRanges: [
                { minValue: 80, color: 'color-success' },
                { minValue: 50, color: 'color-warn' },
                { minValue: 0, color: 'color-danger' },
            ],
            tableColumns: [
                { value: 'dragHandle', isRequired: true },
                { value: 'selectActivities', isRequired: true },
                { value: 'description', label: 'Description', isRequired: true },
                { value: 'preview', label: '', isRequired: true },
                { value: 'startDate', label: 'Live date', isRequired: true },
                { value: 'endDate', label: 'End date', },
                { value: 'status', label: 'Status', align: 'center', isRequired: true },
                { value: 'cost', label: 'Media value', align: 'right', isRequired: true },
                { value: 'discounts', label: 'Discounts', align: 'right', isDiscounts: true },
                { value: 'actualCost', label: 'Actual cost', align: 'right', isDiscounts: true },
                { value: 'shopperJourney', label: 'Shopper journey', align: 'center', isDefault: true },
                { value: 'stores', label: 'Stores', align: 'right', isDefault: true },
                { value: 'impressions', label: 'Impressions', align: 'right', isDefault: true },
                { value: 'cpm', label: 'CPM', align: 'right', isDefault: true },
                { value: 'estimatedRoi', label: 'Est. ROI', align: 'right', isDefault: true },
                { value: 'estimatedPoundUplift', label: 'Est. £ Uplift', align: 'right' },
                { value: 'notes', label: 'Notes', isDefault: true },
                { value: 'label', label: 'Label' },
                { value: 'actions', isRequired: true },
            ],
        },
        items: {
            statuses: [
                { label: 'Planned', value: 'planned' },
                { label: 'Booked', value: 'booked' },
            ],
        },
        documentTypes: [
            { label: 'Creative', value: 'creative' },
            { label: 'PCR', value: 'pcr' },
            { label: 'PO', value: 'po' },
            { label: 'Store list', value: 'store-list' },
            { label: 'Other', value: 'other' },
            { label: 'Proofs', value: 'proofs' },
            { label: 'Proposal', value: 'proposal' },
        ],
        statuses: [{ label: 'Draft', value: 'draft' }, { label: 'Final', value: 'final' }],
        promoTypes: [{
            value: 'unknown',
            label: 'Unknown',
        },{
            value: 'price-reduction',
            label: 'Price Reduction',
        }, {
            value: 'multibuy',
            label: 'Multibuy',
        }, {
            value: 'bogof',
            label: 'BOGOF',
        }, {
            value: 'round-pound',
            label: 'Round £',
        }, {
            value: 'edlp',
            label: 'EDLP',
        }, {
            value: null,
            label: 'None',
        }],
    },
};

export default campaigns;
