import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { Icon } from '../index';

import css from './DropdownMenu.module.scss';

interface CSSClasses {
    content?: string;
    items?: string;
    trigger?: string;
}

interface Styles {
    content?: { [key: string]: string };
    items?: { [key: string]: string };
    trigger?: { [key: string]: string };
}

interface DropdownMenuOption extends DropdownPrimitive.DropdownMenuItemProps, React.RefAttributes<HTMLDivElement> {
    label: string;
    icon?: string;
    // TODO: These props are for features that haven't yet been added to the dropdown menu,
    // but are possible using Radix UI and would be nice to add in future: sub-menus,
    // check boxes, and radio groups.
    // type?: "submenu" | "checkbox" | "radio-group";
    // options: Option[];
    // checked?: boolean;
    // onChange?: () => void;
}

interface DropdownMenuProps {
    // Required props - orientation and dropdown options
    align?: 'start' | 'center' | 'end';
    direction?: 'up' | 'right' | 'down' | 'left';
    options: DropdownMenuOption[];

    // TODO: how to make one of either icon or trigger required, but not both?
    icon?: string;
    trigger?: React.ReactNode;

    // Styling
    classes?: CSSClasses;
    styles?: Styles;
}

/**
 * @prop {string} align Alignment of content relative to trigger: 'start', 'center' or 'end'.
 * @prop {string} direction The direction prop specifies which direction the menu pops out relative to the trigger element: 'up', 'down', 'left', or 'right'.
 * @prop {string} icon If given, will render the menu trigger as `<Icon name={icon} />`.
 * @prop {string} trigger Any React node that defines a menu trigger. Allows for non-icon triggers.
 * @prop {Object[]} options Array of objects defining menu options. Objects must have `label`, `icon`, and `onClick` props.
 * @prop {Object} styles - Styles for sub-components. Object may have `content`, `trigger` and `item` properties. Value of each must be a style object.
 * @prop {Object} classes - Classes for sub-components. Object may have `content`, `trigger` and `item` properties. Value of each must be a `className` string.
 */
const DropdownMenu = (props: DropdownMenuProps) => {
    const {
        align = 'start',
        classes,
        direction = 'down',
        icon,
        options = [],
        styles,
        trigger,
    } = props;

    enum DirMap {
        up = 'top',
        down = 'bottom',
        left = 'left',
        right = 'right'
    }

    const side = DirMap[direction] || 'down';

    const triggerElement = icon
        ? <Icon name={icon} />
        : trigger;

    return (
        <DropdownPrimitive.Root>
            <DropdownPrimitive.Trigger
                className={`${classes?.trigger} ${css.trigger}`}
                style={styles?.trigger}
            >
                {triggerElement}
            </DropdownPrimitive.Trigger>

            <DropdownPrimitive.Content
                className={`${classes?.content} ${css.content}`}
                style={styles?.content}
                align={align}
                side={side}
            >
                {options.map((o, index) => {
                    return (
                        // TODO: add submenu, checkbox, and radio-group item types
                        <DropdownPrimitive.Item
                            {...o}
                            key={`dropdown-item-${index}`}
                            className={`${classes?.items} ${css.item}`}
                            style={styles?.items}
                        >
                            {o.icon && <Icon className="mr-2" name={o.icon} />}
                            {o.label}
                        </DropdownPrimitive.Item>
                    );
                })}
            </DropdownPrimitive.Content>
        </DropdownPrimitive.Root>
    );
}

export default DropdownMenu;

export {
    type DropdownMenuProps
}
