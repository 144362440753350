const channelOptions = [
    { label: 'Convenience', value: 'Convenience' },
    { label: 'Discounters', value: 'Discounters' },
    { label: 'Food Delivery', value: 'Food Delivery' },
    { label: 'Global Travel Retail', value: 'Global Travel Retail' },
    { label: 'Grocery', value: 'Grocery' },
    { label: 'High Street', value: 'High Street' },
    { label: 'On Trade', value: 'On Trade' },
    { label: 'Online', value: 'Online' },
    { label: 'Wholesale', value: 'Wholesale' }
];

export default channelOptions;
