const yesNoUnknownOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
    { value: 'unknown', label: 'Unknown' },
];

const touchpoints = {
    impressionsGroupOptions: [
        { value: 'digital', label: 'Digital CPM calculator' },
        { value: 'engagement', label: 'Engagement' },
        { value: 'impressions-only', label: 'Impressions only' },
        { value: 'issuance', label: 'Issuance' },
        { value: 'press-and-print', label: 'Press and print' },
        { value: 'store-footfall', label: 'Store footfall' },
    ],
    costStructureOptions: [
        { label: 'Per store', value: 'per-store', impressionsGroups: ['store-footfall'] },
        { label: 'Tenancy', value: 'tenancy', impressionsGroups: ['store-footfall'] },
        { label: 'Per issue', value: 'per-issue', impressionsGroups: ['issuance'] },
        { label: 'Flat fee', value: 'flat-fee', impressionsGroups: ['issuance'] },
    ],
    // Should match touchpointCreative options in config/studies.js
    creativeTypeOptions: [
        { label: 'Unknown', value: 'unknown' },
        { label: 'Templated, no price', value: 'templated-no-price' },
        { label: 'Templated & price', value: 'templated-and-price' },
        { label: 'Branded, no price', value: 'brand-no-price' },
        { label: 'Branded & price', value: 'brand-and-price' },
        { label: 'Templated, event', value: 'templated-event' },
        { label: 'Part Branded', value: 'part-branded' },
    ],
    hfssCompliantOptions: yesNoUnknownOptions,
    documentTypes: [
        { label: 'Artwork specifications', value: 'artwork-specifications' },
        { label: 'Briefing documents', value: 'briefing-documents' },
        { label: 'Deadlines', value: 'deadlines' },
    ],
    integrationOptions: [
        { label: 'Criteo', value: 'criteo' },
        { label: 'Meta', value: 'meta' },
        { label: 'The Trade Desk', value: 'theTradeDesk' },
        { label: 'Pinterest', value: 'pinterest' },
    ],
    criteoBuyTypeOptions: [
        { label: 'Open auction', value: 'auction' },
        { label: 'Preferred deals', value: 'preferred' },
    ],
    criteoPageTypeOptions: [
        { label: 'Category', value: 'category' },
        { label: 'Checkout', value: 'checkout' },
        { label: 'Home', value: 'home' },
        { label: 'Merchandising', value: 'merchandising' },
        { label: 'Product detail', value: 'productDetail' },
        { label: 'Search', value: 'search' },
    ],
    audienceSegmentEstimateOptions: [
        { label: 'Display estimate', value: 'displayEstimate' },
        { label: 'Social estimate', value: 'socialEstimate' },
    ],
} as const;

export default touchpoints;
