import _ from 'lodash';

function sanitiseValue(valueArray = [], options = []) {
    return _(valueArray)
        .filter((value) => {
            if (_.find(options, { value })) return true;

            // If a value cannot be found at the top level, search sub-options
            for (const option of options) {
                if (_.find(option.options, { value })) return true;
            }

            return false;
        })
        // We need to keep arrays sorted for the camparison that will toggle the 'apply' button
        .sort()
        .value();
}

export default sanitiseValue;
