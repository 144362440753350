import { Children, useState } from 'react';
import { get, isArray, isFunction, isObject, isString } from 'lodash';

import { Checkbox, DropdownMenu, Icon, RichTextContent } from '../';

import css from './Table.module.scss';

function parseCells(row) {
    return row.map((item) => {
        if (!item) return { label: null };

        const classes = [];
        if (item.numeric) classes.push(css.numeric);
        if (item.classes) classes.push(item.classes);

        if (isString(item)) return { classes: classes.join(' ').trim(), label: item };
        if (isFunction(item)) return { ...item, classes: classes.join(' ').trim(), label: item() };
        if (isObject(item) && isString(item.label)) return { ...item, classes: classes.join(' ').trim(), label: item.label };
        if (isObject(item) && isFunction(item.render)) return { ...item, classes: classes.join(' ').trim(), label: item.render() };

        return item;
    });
}

function compileFooterRow(row, isSortable, bulkActions) {
    // A row can be an object with a 'cells' array property or an array of cells
    if (isObject(row) && row.cells) {
        const cells = row.cells.slice();
        if (isSortable) cells.unshift({ label: '' });
        if (bulkActions) cells.unshift({ label: '' });
        return parseCells(cells.map((cell) => ({ classes: row.classes, ...cell })));
    } else if (isArray(row)) {
        const cells = row.slice();
        if (isSortable) cells.unshift({ label: '' });
        if (bulkActions) cells.unshift({ label: '' });
        return parseCells(cells);
    }
}

const Table = (props) => {
    const {
        bulkActions,
        children,
        fixed,
        footer,
        headers = [],
        items = [],
        noItemsLabel = 'No items to show',
    } = props;

    const [popClass, setPopClass] = useState(undefined); // Used to make the highlight effect for the multi-select checkbox
    const [selectedItems, setSelectedItems] = useState([]);
    const [sortOptions, setSortOptions] = useState({
        field: null, // Either field name as a string, or true for sorting based on the modelValue of the cell
        order: null,
    });

    const tableClasses = ['table', css['selectable-table']];
    if (fixed) tableClasses.push(css.fixed);

    // Parse headers
    const compiledHeaders = headers.map((header) => {
        if (!header) return { label: '' };

        const classes = [];

        if (header.classes) classes.push(header.classes);
        if (header.numeric) classes.push(css.numeric);

        if (isString(header)) return { classes: classes.join().trim(), label: header };
        if (isFunction(header)) return { ...header, classes: classes.join().trim(), label: header() };
        if (isObject(header) && isString(header.label)) return { ...header, classes: classes.join().trim(), label: header.label };
        if (isObject(header) && isFunction(header.render)) return { ...header, classes: classes.join().trim(), label: header.render() };

        return header;
    });

    const handleSortClicked = (header) => {
        if (!header.sort) return null;
        setSortOptions({
            field: header.sort,
            order: sortOptions.order === 'asc' ? 'desc' : 'asc',
        });
    };

    const handleBulkSelectItems = () => {
        setSelectedItems(selectedItems.length > 0 ? [] : compiledItems.filter((item) => item.selectable !== false)
            .map((item) => item.data._id));
    }
    const handleBulkActionClicked = async (event, action) => {
        await action.onClick(event, selectedItems);
        setSelectedItems([]);
    };

    const handleToggleItemSelection = (item) => {
        setPopClass(undefined);
        if (selectedItems.includes(item.data._id)) {
            setSelectedItems(selectedItems.filter((id) => id !== item.data._id));
        } else {
            if (selectedItems.length === 0) {
                setPopClass(css.highlight);
            }
            setSelectedItems([...selectedItems, item.data._id]);
        }
    };

    const rows = children
        ? Children.toArray(children).map((child) => child.props.items).flat()
        : items;

    let isSortable = false;
    const compiledItems = rows.map((item = {}) => {
        if (item.$meta?.sortable) {
            isSortable = true;
        }

        return {
            data: item,
            style: item.$meta?.style,
            cells: compiledHeaders.map((header) => {
                let cellValue;
                const cellField = header.cell?.field || header?.label;

                if (!cellField && !isFunction(header.cell?.render)) {
                    return { label: '' };
                } else if (isFunction(header.cell?.render)) {
                    cellValue = header.cell.render(item);
                } else if (isString(cellField)) {
                    cellValue = get(item, cellField);
                } else if (isFunction(cellField)) {
                    cellValue = get(item, cellField)(item);
                } else {
                    cellValue = get(item, cellField);
                }

                const classes = [];
                if (header.cell?.classes) classes.push(header.cell.classes);
                if (header.numeric) classes.push(css.numeric);

                return {
                    label: cellValue,
                    classes: classes.join(' ').trim(),
                    rich: header.rich || header.cell?.rich,
                    sortField: isFunction(header.sort) ? header.sort(item) : header.sort,
                };
            }),
            selectable: item.$meta?.selectable !== false,
            sortable: item.$meta?.sortable,
        };
    })
        .sort((i1, i2) => {
            if (!sortOptions.field) return 0;

            const i1Cell = (isFunction(sortOptions.field) ? sortOptions.field(i1.data) : i1.data[sortOptions.field]) || null;
            const i2Cell = (isFunction(sortOptions.field) ? sortOptions.field(i2.data) : i2.data[sortOptions.field]) || null;

            if (i1Cell >= i2Cell) return sortOptions.order === 'asc' ? 1 : -1;
            if (i1Cell < i2Cell) return sortOptions.order === 'asc' ? -1 : 1;
            return 0;
        });

    const selectableRows = rows.filter(item => item?.$meta?.isActivity);

    const Tbody = ({ items }) => (
        <tbody>
            {items.length > 0 && items.map((item, index) => {
                const rowClasses = [css.selectable];
                if (selectedItems.includes(item.data._id)) rowClasses.push(css.selected);
                if (item.numeric) rowClasses.push(css.numeric);

                const Row = () => (
                    <tr className={rowClasses.join(' ')} style={{ ...(item?.style || {}) }}>
                        {bulkActions && (<td className={css.selection}>
                            {item.selectable && (<Checkbox checked={selectedItems.includes(item.data._id)} onClick={() => handleToggleItemSelection(item)} />)}
                        </td>)}

                        {item.cells.map((cell, cellIndex) => {
                            return (
                                <td colSpan={cell.colSpan} className={cell.classes} key={`item-${item._id}-${cellIndex}`}>
                                    {(cell.rich
                                        ? <RichTextContent text={cell.label} />
                                        : cell.label)}
                                </td>
                            )
                        })}
                    </tr>
                );

                return (<Row index={index} key={item.data._id || index} />);
            })}

            {items.length === 0 && (
                <tr>
                    <td colSpan={headers.length}>{noItemsLabel}</td>
                </tr>
            )}
        </tbody>
    );

    // Parse footer
    const compiledFooterRows = [];

    // If footer is an array of arrays, create a multi-row footer
    if (isArray(footer?.[0])) {
        footer.forEach((row) => compiledFooterRows.push(compileFooterRow(row, isSortable, bulkActions)));
    } else if (footer) {
        compiledFooterRows.push(compileFooterRow(footer, isSortable, bulkActions));
    }

    return (<>
        <table className={tableClasses.join(' ').trim()}>
            {headers && (<thead>
                <tr>
                    {isSortable && <th></th>}
                    {bulkActions && (<th className={css.selection}>
                        <div className="stack">
                            <Checkbox
                                checked={selectedItems.length === selectableRows.length}
                                className={popClass}
                                icon={(selectedItems.length > 0 && selectedItems.length !== selectableRows.length) ? 'indeterminate_check_box' : undefined}
                                onClick={handleBulkSelectItems}
                            />
                            <DropdownMenu
                                trigger={<Icon name="keyboard_arrow_down" />}
                                options={bulkActions.map(action => ({ label: action.label, onClick: (event) => handleBulkActionClicked(event, action)}))}
                                styles={{
                                    items: {
                                        lineHeight: '1.5em',
                                        fontWeight: 'normal',
                                    }
                                }}
                            />
                        </div>
                    </th>)}
                    {compiledHeaders.map((header, index) => {
                        let value = header;
                        if (isString(header.label)) {
                            value = header.label;
                        } else if (isObject(header) && isFunction(header.render)) {
                            value = header.render();
                        }

                        const classes = [css.header];
                        if (header.sort) classes.push(css.sortable);
                        if (sortOptions.field === header.sort) classes.push(css[sortOptions.order]);
                        if (header.numeric) classes.push(css.numeric);
                        if (header.classes) classes.push(header.classes);

                        return (<th colSpan={header.colSpan} className={classes.join(' ')} key={isString(value) ? value : index} onClick={() => handleSortClicked(header)}>
                            {header.rich ? <RichTextContent text={value} /> : value}
                        </th>);
                    })}
                </tr>
            </thead>)}

            {compiledItems && <Tbody items={compiledItems} />}

            {footer && (
                <tfoot className={css.footer}>
                    {compiledFooterRows.map((row, rowIndex) => (<tr key={`row-${rowIndex}`}>
                        {row.map((item, cellIndex) => (item && (<th colSpan={item.colSpan} className={item.classes} key={`col-${cellIndex}`}>{item.label}</th>)))}
                    </tr>))}
                </tfoot>
            )}
        </table>
    </>);
};

// Tbody is a 'fake' element to allow grouping of array data
Table.Tbody = () => (<></>);
Table.Tbody.displayName = 'Table.Tbody';

export default Table;
