import { useState, useEffect } from 'react';
import { dialogObservable } from './Dialog';

import { Button, Modal } from '..';

// The component that displays the dialog modals.
// Do not use it directly. Instead call: `dialog("Are you sure?")`
const DialogContainer = () => {
    const [options, setOptions] = useState(undefined);

    // Subscribe/unsubscribe to the "confrim" observable
    useEffect(() => {
        const handle = (newOptions) => setOptions(newOptions);
        dialogObservable.subscribe(handle);
        return () => dialogObservable.unsubscribe(handle);
    });

    if (!options) {
        return null;
    }

    const handleClick = (success) => {
        setOptions(undefined);
        if (success) {
            options.resolve();
        } else if (options.rejectOnClose) {
            options.reject();
        }
    }

    return (
        <Modal onCancel={() => setOptions(undefined)} show={true}>
            <Modal.Body>
                {options.content}
            </Modal.Body>
            <Modal.Footer>
                { options.cancelLabel && <Button color={options.cancelColor} onClick={() => handleClick(false)}>{options.cancelLabel}</Button> }
                { options.okLabel && <Button color={options.okColor} onClick={() => handleClick(true)}>{options.okLabel}</Button> }
            </Modal.Footer>
        </Modal>
    );
};

export default DialogContainer;
