import TouchpointChart from 'Media/TouchpointOverview/TouchpointChart.js';
import { Http, Loader, RetailerPill, ShopperJourneyPill } from '../../../Components';
import { useState } from 'react';

const FeaturedTouchpoint = (props) => {
    const { className, ...restProps } = props;

    const [featuredTouchpoint, setFeaturedTouchpoint] = useState({});
    const fetchData = () => Http.get('/homepage/featured-touchpoint').then(setFeaturedTouchpoint);

    const classes = ['box-raised', 'p-3'];
    if (className) classes.push(className);

    return (<Loader className={classes.join(' ').trim()} promise={fetchData} {...restProps}>
        <h2>Featured touchpoint</h2>

        <hr />
        {featuredTouchpoint && <>
            <div className="mt-3">
                <div className="stack-even-center mb-3">
                    <h3>{featuredTouchpoint.touchpointName}</h3>
                    <div className="stack-3">
                        {featuredTouchpoint.retailer && (<RetailerPill retailer={featuredTouchpoint.retailer} />)}
                        {featuredTouchpoint.shopperJourney && (<ShopperJourneyPill shopperJourney={featuredTouchpoint.shopperJourney} />)}
                    </div>
                </div>
                <TouchpointChart benchmarkData={featuredTouchpoint} />
            </div>
        </> }
        {!featuredTouchpoint &&
            <h3 className="mt-3">No touchpoint available</h3>
        }
    </Loader>);
};

export default FeaturedTouchpoint;
