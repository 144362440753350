import { useState } from 'react';

import { Http, toast } from 'Components';

import type { DigitalMediaSearchCampaign } from './DigitalMediaSearch';
import DigitalMediaSearch from './DigitalMediaSearch';

interface CriteoDigitalMediaSearchProps {
    criteoAccountIds: string[];
    linkedCampaignId?: string;
    onLinkClick: (campaignId: string, supplierKey: string) => void;
}

const CriteoDigitalMediaSearch = (props: CriteoDigitalMediaSearchProps) => {
    const { criteoAccountIds, linkedCampaignId, onLinkClick } = props;

    const [searchCampaigns, setSearchCampaigns] = useState<DigitalMediaSearchCampaign[]>();

    const handleSearchClick = (searchString: string) => {
        return Http
            .post('/in-flight/search/criteo', { criteoAccountIds, searchString })
            .then((result) => {
                setSearchCampaigns(result.searchCampaigns);
                if (result.inaccessibleAccountIds.length > 0) {
                    toast.warn('Could not access campaigns for all Criteo account IDs.');
                    console.warn(`Could not access campaigns for Criteo account IDs: ${result.inaccessibleAccountIds.join(', ')}. These accounts may need to grant Plan-Apps access to their Criteo data.`);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Could not connect to Criteo');
            });
    };

    return (
        <DigitalMediaSearch
            linkedCampaignId={linkedCampaignId}
            linkedCampaignLabel="Linked to Criteo campaign: "
            onLinkClick={(campaignId) => onLinkClick(campaignId, 'criteo')}
            onSearchClick={handleSearchClick}
            searchCampaigns={searchCampaigns}
            searchFieldLabel="Search Criteo campaign ID..."
        />
    )
};

export default CriteoDigitalMediaSearch;
