import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Icon from '../Icon/Icon';

const SortableListItem = (props) => {
    const { id, index, item, renderItem } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const sortStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const dragHandle = (
        <Icon
            {...attributes}
            {...listeners}
            name="drag_indicator"
            style={{ cursor: 'move' }}
        />
    );

    return renderItem({item, index, setNodeRef, dragHandle, sortStyle });
};

export default SortableListItem;
