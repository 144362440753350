/**
 * This component is for formatting purposes.
 */
const Code = (props) => {
    const {
        component,
        description,
        title,
    } = props;

    return (<>
        {title && (<>
            <h2>{title}</h2>
            <hr className="mb-4"></hr>
        </>)}
        {description && <div className="mb-4">{description}</div>}
        {component && component}
    </>);
}

export default Code;
