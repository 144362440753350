import { forwardRef } from 'react';

import css from './RichTextContent.module.scss';

const RichTextContent = forwardRef((props, ref) => {
    const { className = '', text, ...restProps } = props;

    const classes = [css['rich-text-content']];
    if (className) classes.push(className);

    return (
        <div
            className={classes.join(' ').trim()}
            ref={ref}
            { ...restProps }
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
});
RichTextContent.displayName = 'RichTextContent';

export default RichTextContent;
