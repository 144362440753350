import { Checkbox, GlossaryTerm } from 'Components';
import { useState } from 'react';
import { AttributionWindowOption, ConversionLevelOption, InsightMetricOption, MetaInsightMetricOption } from './InFlight.types';
import { CustomConversionId, MetaNodeInsights } from 'Types/MetaCampaignInsights';
import { insightMetricOptions } from './helperFunctions';

import css from './OverviewTable.module.scss';
import { isEmpty } from 'lodash';

interface MetaOverviewTableProps {
    attributionWindow: AttributionWindowOption,
    data: MetaNodeInsights[],
    conversionLevel: ConversionLevelOption,
    isSelfServe: boolean,
}

const MetaOverviewTable = ({ data, attributionWindow, conversionLevel, isSelfServe }: MetaOverviewTableProps) => {
    const [areAdsVisible, setAreAdsVisible] = useState<boolean>(false);

    function buildRow(node: MetaNodeInsights) {
        if (!areAdsVisible && node.level === 'ad') {
            return null;
        }

        const sumAttributions = (
            conversions: MetaNodeInsights['attributedConversions'] | MetaNodeInsights['attributedSalesValues'],
            customConversionLevel: CustomConversionId['level'],
            attributionWindow: AttributionWindowOption,
        ) => {
            return (
                !isEmpty(conversions) &&
                conversions
                    .filter((conversion) => conversion.level === customConversionLevel)
                    .reduce((total, currentConversion) => total + (currentConversion.attributionData[attributionWindow.value] || 0), 0)
            );
        };

        // Use common formats for numbers to ensure consistency between chart and table
        const formatInsightMetric = (field: MetaInsightMetricOption['value']) => {
            let metric;

            const totalConversions = sumAttributions(node.attributedConversions, conversionLevel.value, attributionWindow);
            const totalSalesValues = sumAttributions(node.attributedSalesValues, conversionLevel.value, attributionWindow);

            switch (field) {
            case 'conversions':
                metric = totalConversions;
                break;
            case 'salesValues':
                metric = totalSalesValues;
                break;
            case 'roas':
                if (totalSalesValues && node.totalInsightsMetrics?.netMediaSpend){
                    metric = totalSalesValues / node.totalInsightsMetrics.netMediaSpend;
                }
                break;
            case 'aov':
                if (totalSalesValues && totalConversions) {
                    metric = totalSalesValues / totalConversions;
                }
                break;
            case 'roi':
                if (totalSalesValues && node.totalInsightsMetrics?.spend){
                    metric = totalSalesValues / node.totalInsightsMetrics.spend;
                }
                break;
            default:
                metric = node.totalInsightsMetrics && node.totalInsightsMetrics[field];
                break;
            }

            const option: InsightMetricOption | undefined = insightMetricOptions.find((o) => o.value === field);

            if (!metric || !option) return '-';

            return option.formatter(metric);
        };

        return (
            <tr key={node.metaId}>
                <td className={css[`${node.level}Row`]}>{node.level === 'campaign' ? 'Campaign total' : node.name}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('impressions')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('reach')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('inlineLinkClicks')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('inlineLinkClickCtr')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('frequency')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('spend')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('cpm')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('conversions')}</td>
                <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('salesValues')}</td>
                {isSelfServe && (
                    <>
                        <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('roas')}</td>
                        <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('aov')}</td>
                        <td className={`text-right ${css[`${node.level}Row`]}`}>{formatInsightMetric('roi')}</td>
                    </>
                )}
            </tr>
        );
    }

    return (
        <div className="box-raised p-3 my-3">
            <div className="stack-even">
                <h2>Performance analysis</h2>
                <div className="stack-center-center">
                    <Checkbox
                        checked={areAdsVisible}
                        label="Display individual ads"
                        onChange={() => setAreAdsVisible(!areAdsVisible)}
                    />
                </div>
            </div>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="text-right"><GlossaryTerm slug="impressions">Impressions</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="Reach">Reach</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="link-clicks">Clicks</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="link-click-ctr">CTR</GlossaryTerm></th>
                            <th className="text-right"><GlossaryTerm slug="Frequency">Frequency</GlossaryTerm></th>
                            <th className="text-right">Spend</th>
                            <th className="text-right"><GlossaryTerm slug="cpm">CPM</GlossaryTerm></th>
                            <th className="text-right">No. of sales</th>
                            <th className="text-right">Sales value</th>
                            {isSelfServe && (
                                <>
                                    <th className="text-right"><GlossaryTerm slug="roas">ROAS</GlossaryTerm></th>
                                    <th className="text-right">AOV</th>
                                    <th className="text-right"><GlossaryTerm slug="roi">ROI</GlossaryTerm></th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((node) => buildRow(node))}
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default MetaOverviewTable;