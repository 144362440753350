import { find, isEmpty, isFunction } from 'lodash';

import { ReactSelect, useAppState, useAuth } from '../';

const useCompanySelect = (options = {}) => {
    let { companyOptions } = options;

    const { me } = useAuth();

    // If no companyOptions has been specified, use all the companies that the user has access to
    if (isEmpty(companyOptions)) {
        companyOptions = me?.canAccessDataFromCompanies?.map((company) => ({ label: company.name, value: company._id })) || [];
    }

    // Determine what is the default company, through a list of fall-back rules
    let defaultCompanyOption;
    const lastUsedCompanyId = window.localStorage.defaultCompany;
    if (lastUsedCompanyId) {
        defaultCompanyOption = find(companyOptions, { value: lastUsedCompanyId });
    }
    if (!defaultCompanyOption && companyOptions.length > 0) {
        defaultCompanyOption = companyOptions[0];
    }

    // Store the current companyOption in local state (required to trigger a re-render when it changes)
    const [companyOption, setCompanyOption] = useAppState('defaultCompany', defaultCompanyOption);

    // Create an instance of a CompanySelect component.
    const DynamicCompanySelect = (props) => {
        const { onChange, ...restProps } = props;

        return (companyOptions.length > 1
            ? (<ReactSelect
                options={companyOptions}
                onChange={newOption => {
                    setCompanyOption(newOption);
                    window.localStorage.setItem('defaultCompany', newOption.value);
                    window.localStorage.removeItem('campaigns.filters');
                    if (isFunction(onChange)) {
                        onChange(newOption);
                    }
                }}
                value={companyOption.value}
                {...restProps}
            />)
            : null
        );
    };

    return {
        companyOption,
        companyOptions,
        DynamicCompanySelect,
    };
}

export default useCompanySelect;
