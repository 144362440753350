const briefingForm = {
    evaluationsStatuses: [
        { label: 'Incomplete', value: undefined, color: 'warn' },
        { label: 'Submitted', value: 'submitted' },
        { label: 'Verified', value: 'verified' },
    ],
    salesDataStatuses: [
        { label: 'Incomplete', value: undefined, color: 'warn' },
        { label: 'Submitted', value: 'submitted' },
        { label: 'Verified', value: 'verified' },
    ],
    status: [
        { label: 'Draft', value: undefined },
        { label: 'Submitted', value: 'submitted' },
        { label: 'Completed', value: 'completed' },
    ],
};

export default briefingForm;
